<template>
  <app-onboarding container-flex :is-auth="false">
    <ui-card class="app-update">
      <template #header>
        <img class="app-update__img" src="@/assets/images/recovery-error.svg" alt="">
      </template>

      <div class="app-update__title">
        {{ $t('appUpdate.title') }}
      </div>
      <div class="app-update__text">
        {{ $t('appUpdate.description') }}
      </div>

      <template #footer>
        <div class="app-update__footer">
          <new-button
            v-if="linkToStore"
            type="primary"
            @click="goToStore"
          >
            {{ $t('form.buttons.update') }}
          </new-button>
          <new-button
            type="ghost"
            @click="$router.push({ name: 'logout' })"
          >
            {{ $t('form.buttons.exit') }}
          </new-button>
        </div>
      </template>
    </ui-card>
  </app-onboarding>
</template>

<script>
import { mapGetters } from 'vuex';
import getLinkToStore from '@/utils/getLinkToStore';
import AppOnboarding from '@/components/AppOnboarding.vue';

export default {
  components: {
    AppOnboarding,
  },

  data() {
    return {
      linkToStore: getLinkToStore(),
    };
  },

  computed: {
    ...mapGetters('user', [
      'needUpdateApplication',
    ]),
  },

  methods: {
    async goToStore() {
      if (this.linkToStore) {
        window.open(this.linkToStore, '_blank');
      }
    },
  },

  created() {
    if (!this.needUpdateApplication) {
      this.$router.push({ name: 'profile' });
    }
  },
};
</script>
