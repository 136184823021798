<template>
  <div>
    <sublocations-selector v-if="isMasterLocation" />
    <template v-else>
      <div v-if="showStatsCards" class="row">
        <div class="col-12 col-xl-6 pb-4">
          <stats-balance
            :key="`balance-${location.id}-${balance.total}-${balance.daily}-${locationCurrency}`"
            :is-loading="loading.balanceTotal || loading.balanceDaily"
            :location-id="location.id"
            :currency="locationCurrency"
            :total="balance.total"
            :daily="balance.daily"
            class="mw-100 h-100"
          />
        </div>
        <location-distributions
          v-if="showLocationDistributions"
          class="col-12 col-xl-6 pb-4"
          body-classes="mx-auto"
          :key="`distributions-${location.id}-${staffs}-${locationDistributions}`"
          :is-loading="distributionsLoading"
          :location="location"
          :staffs="staffs"
          :disabled="locationDistributionsDisabled"
          @update="loadDistributions"
        />
      </div>

      <staff-hours
        v-if="showStaffHours"
        :key="`hours-${location.id}-${distributionStaffs}-${staffHours}`"
        :is-loading="loading.hours"
        :disabled="staffHoursDisabled"
        :range="range"
        :min="minDate"
        :max="maxDate"
        :location="location"
        :staffs="distributionStaffs"
        :staff-hours="staffHours"
        @update:range="range = $event"
        @update:staff-hours="staffHours = $event"
        @calculate="calculateStaffPayouts"
      />

      <staff-payouts
        v-if="showStaffPayouts"
        :key="`payouts-${location.id}-${locationCurrency}-${locationDistributions}-${staffHours}-${staffPayouts}-${staffs}`"
        :is-loading="loading.payouts"
        :staffs="staffs"
        :staff-hours="staffHours"
        :staff-payouts="staffPayouts"
        :location="location"
        :location-distributions="locationDistributions"
        @done="donePayouts"
      />
    </template>

    <select-sublocation-card
      v-if="showSelectSublocationAlert"
    />
    <staff-payouts-history
      v-else-if="staffsLoaded"
      :key="`history-${location.id}-${locationCurrency}`"
      :location-id="location.id"
      :location-currency="locationCurrency"
      :is-loading="loading.history"
      :history-data="staffPayoutsHistoryData"
      :period-list="periodList"
      :period-id="periodId"
      :history-total="history.total"
      :history-page="history.page"
      :history-page-size="history.pageSize"
      @update:periodId="updatePeriod"
      @update:page="updatePage"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import axios from 'axios';
import api from '@/api';
import getFio from '@/utils/fio';
import toSQLDate from '@/utils/toSQLDate';
import LocationDistributions from '@/components/Location/LocationDistributions.vue';
import StaffHours from '@/components/StaffHours';
import StaffPayouts from '@/components/StaffPayouts';
import StaffPayoutsHistory from '@/components/StaffPayouts/StaffPayoutsHistory.vue';
import StatsBalance from '@/components/Stats/StatsBalance.vue';
import DistributionStaffsPresenter from '@/utils/presenters/distributionStaffs';
import DistributionStaffsPayoutHistoryPresenter from '@/utils/presenters/distributionStaffsPayoutHistory';
import SublocationsSelector from '@/components/SublocationsSelector.vue';
import SelectSublocationCard from '@/components/SelectSublocationCard.vue';

export default {

  components: {
    SublocationsSelector,
    LocationDistributions,
    StaffHours,
    StaffPayouts,
    StaffPayoutsHistory,
    StatsBalance,
    SelectSublocationCard,
  },

  data: () => ({
    date: null,
    range: null,
    requiredDate: null,

    balance: {
      total: 0,
      daily: 0,
    },
    loading: {
      balanceTotal: false,
      balanceDaily: false,
      hours: false,
      payouts: false,
      requiredDate: false,
      history: false,
    },
    cancel: {
      balance: null,
    },

    staffHours: [],
    staffPayouts: [],

    distributionStaffs: [],

    staffPayoutsHistoryData: [],

    periodList: [],
    periodId: null,

    history: {
      page: 1,
      pageSize: 10000,
      total: 0,
    },
  }),

  computed: {
    ...mapState({
      distributionsLoading: (state) => state.distributions.loading,
      currentLocation: (state) => state.user.location,
      selectedSublocation: (state) => state.sublocations.selectedSublocation,
    }),
    ...mapGetters({
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
      isTroncMaster: 'auth/isTroncMaster',
      isDistributionMaster: 'auth/isDistributionMaster',
      locationDistributions: 'distributions/parsedDistributions',
      isMasterLocation: 'user/isMasterLocation',
      selectedAllSublocations: 'sublocations/selectedAllSublocations',
    }),
    start() {
      return (this.history.page - 1) * this.history.pageSize;
    },
    staffs() {
      const items = [
        ...this.$store.state.staffs.items,
      ];

      items.sort((a, b) => {
        return this.getStaffRank(b) - this.getStaffRank(a);
      });

      return items;
    },
    staffsLoaded() {
      return this.$store.state.staffs.loaded;
    },
    location() {
      return this.selectedSublocation || this.currentLocation;
    },
    locationCurrency() {
      return this.location.currencyCode;
    },
    minDate() {
      return this.requiredDate;
    },
    maxDate() {
      return this.minDate ? DateTime.local().endOf('day').toJSDate() : null;
    },
    staffHoursDisabled() {
      return !this.date;
    },
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    showStatsCards() {
      return !this.isOwnerUAECommon || this.isDistributionMaster;
    },
    showStaffHours() {
      if (this.isOwnerUAECommon && !this.isDistributionMaster) {
        return false;
      }

      return this.staffsLoaded && !this.distributionsLoading;
    },
    showStaffPayouts() {
      if (this.isOwnerUAECommon && !this.isDistributionMaster) {
        return false;
      }

      return this.showStaffHours && this.minDate;
    },
    showLocationDistributions() {
      return this.isTroncMaster && this.location.showSetDistributionModule;
    },
    locationDistributionsDisabled() {
      return this.location.country === 'de' || this.location.country === 'ae';
    },
    period() {
      return this.periodList.find((item) => item.id === this.periodId);
    },
    showSelectSublocationAlert() {
      return this.isMasterLocation && this.selectedAllSublocations;
    },
  },

  watch: {
    date(newValue, oldValue) {
      if (!newValue) {
        return;
      }

      this.loadBalance(newValue);
    },
    location() {
      this.loadBalance(this.date);
    },
    requiredDate(requiredDate) {
      this.range = [
        requiredDate,
        null,
      ];
    },
    range(range) {
      this.staffHours.splice(0);
      this.staffPayouts.splice(0);
      this.distributionStaffs.splice(0);

      if (range && Array.isArray(range)) {
        this.range[0] = this.requiredDate;

        if (this.range[1] === null) {
          this.range[1] = DateTime.local().startOf('day').toJSDate();
        }

        [, this.date] = range;

        this.loadDistributionStaffs();
      }
    },
    'location.id': {
      handler() {
        this.initDistributions();
      },
    },
  },

  mounted() {
    this.initDistributions();
  },

  methods: {
    ...mapActions('distributions', {
      loadSetDistributionsData: 'init',
    }),

    /* Initializing data page */

    async initDistributions() {
      if (!this.isOwnerUAECommon || this.isDistributionMaster) {
        this.loadStaffs();
        this.loadBalance();
        this.loadDistributions();
      }

      await this.loadRequiredDate();
      await this.loadHistoryPeriods();
      this.loadHistoryData();
    },

    getStaffRank({ staffType }) {
      switch (staffType) {
        case 'WAITER':
          return 10;
        case 'BAR':
          return 9;
        case 'KITCHEN':
          return 8;
        case 'FRONT_DESK':
          return 7;
        case 'CLEANING':
          return 6;
        default:
          return 1;
      }
    },

    /* load distributions percentage */

    async loadDistributions() {
      this.loadSetDistributionsData({
        location: this.location,
      });
    },

    /* Load min distrubution date */

    async loadRequiredDate() {
      this.loading.requiredDate = true;

      try {
        const {
          data: {
            date,
          },
        } = await api.locations.getMinDistributionsDate({
          locationId: this.location.id,
        });

        if (date) {
          this.requiredDate = DateTime.fromSQL(date).toJSDate();
        }
      } catch (e) {
        this.requiredDate = null;
        this.range = null;
        this.balance.daily = 0;
      } finally {
        this.loading.requiredDate = false;
      }
    },

    /* Load balance. */

    async loadBalance(date) {
      const attribute = date ? 'Daily' : 'Total';
      const request = api.locations.getBalance({
        locationId: this.location.id,
        periodEndDate: date ? DateTime.fromJSDate(date).toFormat('yyyy-LL-dd') : undefined,
      });

      this.loading[`balance${attribute}`] = true;

      if (date) {
        if (this.cancel.balance) {
          this.cancel.balance();
        }

        this.cancel.balance = request.cancel;
      }

      try {
        const { data } = await request;

        if (data) {
          this.balance[attribute.toLowerCase()] = (data?.amount?.amount || 0);
        }

        this.loading[`balance${attribute}`] = false;
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.$showServerError(e);
          this.loading[`balance${attribute}`] = false;
        }
      } finally {
        if (date) {
          this.cancel.balance = null;
        }
      }
    },

    /* Load initial data. */

    loadStaffs() {
      if (this.staffsLoaded) {
        return true;
      }

      return this.$store.dispatch('staffs/loadItems', {
        locationId: this.location.id,
      });
    },

    /* Load history data */

    async loadHistoryData() {
      if (!this.periodId) {
        return;
      }

      this.loading.history = true;

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.locations.getPayoutHistory({
          start: this.start,
          pageSize: this.history.pageSize,
          locationId: this.location.id,
          periodId: this.periodId,
        });

        this.staffPayoutsHistoryData = DistributionStaffsPayoutHistoryPresenter(data);
        this.history.total = totalRecords;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.history = false;
      }
    },

    /* Load history period */

    async loadHistoryPeriods() {
      this.loading.history = true;

      try {
        const { data } = await api.locations.getPayoutTaxesPeriods({
          locationId: this.location.id,
          date: DateTime.local(),
        });

        this.periodList = data;

        if (data.length) {
          this.periodId = data[data.length - 1].id;
        } else {
          this.periodId = null;
          this.staffPayoutsHistoryData = [];
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.history = false;
      }
    },

    donePayouts() {
      this.range = null;
      this.date = new Date();
      this.initDistributions();
    },

    async loadDistributionStaffs() {
      this.loading.hours = true;

      try {
        const { data } = await api.locations.getDistributionStaffs({
          locationId: this.location.id,
          isActive: true,
          startDate: toSQLDate(this.range[0]),
          endDate: toSQLDate(this.range[1]),
        });

        this.distributionStaffs = data.map((item) => ({
          ...item,
          fio: getFio(item.user),
        }));
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.hours = false;
      }
    },

    /* Staff payouts calculation. */

    async calculateStaffPayouts() {
      this.loading.payouts = true;

      try {
        const { data } = await api.locations.calculateStaffPayouts({
          locationId: this.location.id,
          data: this.staffHours,
        });

        if (data) {
          this.staffPayouts = DistributionStaffsPresenter(data, this.date);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.payouts = false;
      }
    },

    getStaff({ staffId }) {
      return this.staffs.find((item) => item.id === staffId);
    },

    updatePage(pageIndex) {
      this.history.page = pageIndex;
      this.loadHistoryData();
    },

    updatePeriod(periodId) {
      this.periodId = periodId;
      this.loadHistoryData();
    },
  },
};
</script>
