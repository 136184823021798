<template>
  <ui-card
    class="registered-users"
    tag="form"
    :is-loading="isLoading"
  >
    <template #header>
      <div class="registered-users__header d-flex flex-nowrap justify-content-between">
        <div class="registered-users__header-wrapper">
          <h4 class="card-title">
            {{ $t('registeredUsers.listTitle') }}
          </h4>
          <ui-checkbox
            v-model="filter.includeNoneActive"
            class="registered-users__header-show-all"
            inline
            @input="handleSearch"
          >
            {{ $t('staffMembers.activeFilterTitle') }}
          </ui-checkbox>
        </div>
        <ui-icon-button
          v-if="staffs.length"
          class="mx-2 ml-4"
          size="22px"
          name="excel-sm"
        />
      </div>
    </template>

    <div class="row mb-4">
      <div class="col-12 col-md-4 col-lg-4 col-xl my-0 mt-1">
        <ui-select
          v-model="filter.countryCode"
          :items="countries"
          placeholder="Location country"
          class="my-0"
          label-key="name"
          value-key="iso"
          @change="handleSearch"
          bordered
          large
        />
      </div>
      <div class="col-12 col-md-4 col-lg-4 col-xl mt-1">
        <ui-input
          v-model="filter.locationNameOrPayoutId"
          :placeholder="$t('form.placeholders.locationNameOrId')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="handleSearch"
        />
      </div>
      <div class="col-12 col-md-4 col-lg-4 col-xl mt-1">
        <ui-input
          v-model="filter.staffNameOrPayoutId"
          :placeholder="$t('form.placeholders.staffNameOrId')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="handleSearch"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-2 mt-1">
        <ui-input
          v-model="filter.email"
          :placeholder="$t('form.placeholders.email')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="handleSearch"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-2 mt-1">
        <ui-input
          v-model="filter.phoneNumber"
          :placeholder="$t('form.placeholders.phone')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="handleSearch"
        />
      </div>
    </div>

    <staff-list-table
      :staffs="staffs"
      :columns="columns"
      :actions="actions"
      :page="page"
      :page-size="pageSize"
      :total="total"
      @update:page="loadPage"
    />
  </ui-card>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import staffDetails from '@/utils/presenters/staffDetails';
import StaffListTable from '@/components/StaffList/StaffListTable.vue';
import api from '@/api';

export default {
  name: 'RegisteredUsers',

  components: {
    StaffListTable,
  },

  data() {
    return {
      staffs: [],
      page: 1,
      total: 0,
      pageSize: 10,
      isLoading: false,
      filter: {
        countryCode: null,
        email: null,
        includeNoneActive: true,
        locationNameOrPayoutId: null,
        staffNameOrPayoutId: null,
        textStaffSearch: null,
        textWorkplaceSearch: null,
        phoneNumber: null,
      },
      columns: [
        'PAYOUT_ID',
        'NAME',
        'LOCATION_NAME',
        'PAYMENT_STATUS',
        'PAYMENT_SYSTEM',
        'PAYOUT_PLAN_PERIOD',
        'USER_TYPE',
        'BANK',
        'ACTION',
        'READ_MORE',
      ],
    };
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isAdminOrSales: 'auth/isAdminOrSales',
      isSupportAdmin: 'auth/isSupportAdmin',
    }),
    actions() {
      return [
        {
          id: 'EDIT',
          visible: this.isAdminOrSales,
        },
        {
          id: 'REGISTRATION_DETAILS',
          visible: true,
        },
        {
          id: 'ID_INFORMATION',
          visible: true,
        },
        {
          id: 'BANK_ACCOUNT_DETAILS',
          visible: true,
        },
        {
          id: 'VIEW_AS',
          visible: true,
        },
        {
          id: 'SEND_REQUEST_ID',
          visible: true,
        },
        {
          id: 'CANCEL_REQUEST_ID',
          visible: true,
        },
        {
          id: 'SEND_REQUEST_BANK_DETAILS',
          visible: true,
        },
        {
          id: 'CANCEL_REQUEST_BANK_DETAILS',
          visible: true,
        },
        {
          id: 'RESET_OTP_TRYOUTS',
          visible: this.isAdmin,
        },
        {
          id: 'CHANGE_PHONE',
          visible: this.isAdmin,
        },
      ]
        .filter(({ visible }) => visible)
        .map(({ id }) => id);
    },
    start() {
      return (this.page - 1) * this.pageSize;
    },
    filterKeys() {
      return Object.keys(this.filter);
    },
    filterEntries() {
      return Object.entries(this.filter)
        .map(([name, value]) => ({
          name,
          value,
        }));
    },
    filterNoEmpty() {
      return this.filterEntries.filter(({ value }) => value !== null && value !== undefined);
    },
    filterQuery() {
      return this.filterNoEmpty.reduce((memo, { name, value }) => ({
        ...memo,
        [name]: value,
      }), {});
    },
    countries() {
      return this.$getCountries({ empty: true });
    },
  },

  mounted() {
    this.page = parseInt(this.$route.query?.page || 1, 10);
    this.filterKeys.forEach((key) => {
      const value = this.$route.query?.[key];

      if (typeof value !== 'undefined') {
        this.filter[key] = value;
      }
    });

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      this.loadStaffs();
    }
  },

  methods: {
    loadStaffs: debounce(async function loadStaffDebounced() {
      this.isLoading = true;

      try {
        const { filterQuery, pageSize, start } = this;

        const {
          data: {
            data,
            totalRecords,
          },
        } = await api.staff.getStaffs(filterQuery, { pageSize, start });

        this.staffs = data.map(staffDetails);
        this.total = totalRecords;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    }, 500),
    loadPage(page) {
      this.page = page;
      this.syncRoute();
      this.loadStaffs();
    },
    handleSearch() {
      this.loadPage(1);
    },
    syncRoute() {
      this.$router.replace({
        query: {
          page: this.page,
          ...this.filterQuery,
        },
      });
    },
  },
};
</script>
