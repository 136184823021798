<template>
  <ui-card
    tag="form"
    :is-loading="loading"
    class="notifications-list"
  >
    <template #header>
      <h4 class="card-title">
        {{ $t('notifications.listTitle') }}
      </h4>
    </template>

    <div class="row mb-xl-5 mb-lg-5 mb-2 mt-3">
      <div class="col-12 col-md-auto mt-1">
        <ui-input
          v-model="filter.topic"
          placeholder="Topic"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="search"
        />
      </div>
      <div class="col-12 col-md-auto mt-1">
        <ui-input
          v-model="filter.locationName"
          :placeholder="$t('form.labels.locationName')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="search"
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.country"
          :items="countries"
          placeholder="Location country"
          class="my-0"
          label-key="name"
          value-key="iso"
          bordered
          large
          @change="search"
        />
      </div>
    </div>

    <ui-data-table
      :columns="columns"
      :data="items"
      class="notifications-list__table"
      data-table="notification"
      @sort="setSort"
    >
      <template #notification-topic="{ row }">
        <router-link
          v-if="row.topic"
          class="text-primary word-break-word"
          :to="{
            name: 'notification-view',
            params: {
              notificationId: row.id,
            },
          }"
        >
          {{ row.topic }}
        </router-link>
      </template>

      <template #notification-location-name="{ row }">
        <div v-if="row.workplaceList && row.workplaceList.length">
          <router-link
            class="text-primary"
            :to="{
              name: 'location-view',
              params: {
                locationId: row.workplaceList[0].id,
              },
            }"
          >
            {{ row.workplaceList[0].name }}
          </router-link>
          <span v-if="row.workplaceList.length > 1" class="text-grey">
            &nbsp;+{{ row.workplaceList.length }}
          </span>
        </div>
      </template>

      <template #notification-message="{ value }">
        <ui-popover
          v-if="value"
          width="188"
          trigger="click"
          placement="right"
        >
          {{ value }}
          <template #reference>
            <span class="truncate">
              {{ value }}
            </span>
          </template>
        </ui-popover>
      </template>

      <template #notification-sending-date="{ value }">
        <div v-if="value" class="d-flex flex-column align-items-start">
          <span>
            {{ value | date }}
          </span>
          <span>
            {{ value | time }}
          </span>
        </div>
      </template>

      <template #notification-action-completed="{ value }">
        <span v-if="value">{{ value }}</span>
        <span v-else>0</span>
      </template>

      <template #notification-seen="{ value }">
        <span v-if="value">{{ value }}</span>
        <span v-else>0</span>
      </template>

      <template #notification-sent="{ value }">
        <span v-if="value">{{ value }}</span>
        <span v-else>0</span>
      </template>

      <template #notification-status="{ value }">
        <div v-if="value === 'DRAFT'" class="draft">
          <ui-tag>
            <span>{{ value }}</span>
          </ui-tag>
        </div>
        <div v-else-if="value === 'PENDING'" class="pending">
          <ui-tag>
            <span>{{ value }}</span>
          </ui-tag>
        </div>
        <div v-else-if="value === 'FAILED'" class="failed">
          <ui-tag>
            <span>{{ value }}</span>
          </ui-tag>
        </div>
        <div v-else-if="value === 'SENT'" class="sent">
          <ui-tag>
            <span>{{ value }}</span>
            <ui-icon
              name="check"
              width="6px"
              height="4px"
            />
          </ui-tag>
        </div>
      </template>

      <template #notification-action="{ row }">
        <ui-loading v-if="row.loading" inline />
        <ui-action-down
          v-else
          :disabled="Boolean(loading || row.loading)"
          :items="notificationActions(row)"
          @select="handleAction($event, row)"
        />
      </template>
    </ui-data-table>

    <ui-pagination
      v-if="total > pageSize"
      v-model="page"
      :total="total"
      :per-page="pageSize"
      :show-pages="7"
      flex-control
      class="mt-4"
      @input="update"
    />
    <statistics-modal
      v-model="statistics.show"
      :notification-statistics="statistics.data"
      :loading="statistics.loading"
    />
  </ui-card>
</template>

<script>
import { DateTime } from 'luxon';
import api from '@/api';
import { allCountries } from '@/config/countries';
import StatisticsModal from '@/components/Statistics/StatisticsModal.vue';

export default {
  name: 'NotificationList',
  components: {
    StatisticsModal,
  },
  data() {
    return {
      filter: {
        topic: undefined,
        locationName: undefined,
        country: undefined,
        sortByField: 'createdDate',
        sortDirection: 'DESC',
      },
      items: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 10,
      statistics: {
        show: false,
        data: {},
        loading: false,
      },
    };
  },

  mounted() {
    this.loadItems();
  },

  watch: {
    '$route.query': {
      immediate: true,
      handler({ page, ...params }) {
        this.page = parseInt(page || 1, 10);
        this.filter = {
          ...this.filter,
          ...params,
        };

        this.loadItems();
      },
    },
  },

  computed: {
    start() {
      return (this.page - 1) * this.pageSize;
    },
    urlConfig() {
      return {
        page: this.page,
        ...this.filter,
      };
    },
    apiConfig() {
      return {
        start: this.start,
        pageSize: this.pageSize,
        ...this.filter,
      };
    },
    countries() {
      return this.$getCountries({ empty: true });
    },
    notificationTypes() {
      return [
        {
          id: 'PUSH',
          label: 'Push',
        },
        {
          id: 'SMS',
          label: 'SMS',
        },
      ];
    },
    notificationStatuses() {
      return [
        {
          id: 'DRAFT',
          label: 'DRAFT',
        },
        {
          id: 'PENDING',
          label: 'PENDING',
        },
        {
          id: 'FAILED',
          label: 'FAILED',
        },
        {
          id: 'SENT',
          label: 'SENT',
        },
      ];
    },
    columns() {
      return [
        {
          name: 'notification-topic',
          label: 'Topic',
          prop: 'topic',
          empty: '-',
        },
        {
          name: 'notification-location-name',
          label: 'Location name',
          prop: 'workplaceList',
          empty: '-',
        },
        {
          name: 'notification-message',
          label: 'Message',
          prop: 'text',
          empty: 'No message',
        },
        {
          name: 'notification-country',
          label: 'Country',
          prop: 'country',
          value: ({ row }) => {
            return allCountries.find(({ iso }) => row.country.toLowerCase() === iso).name;
          },
          empty: '-',
        },
        {
          name: 'notification-sending-date',
          label: 'Sending Time',
          prop: 'sendingDate',
          value: ({ row }) => {
            return row?.sendingDate
              ? DateTime.fromISO(row.sendingDate)
              : null;
          },
          empty: 'No date',
        },
        {
          name: 'notification-type',
          label: 'Type',
          prop: 'type',
          value: ({ row }) => {
            return this.notificationTypes
              .find(({ id }) => row?.type === id)?.label;
          },
          empty: 'No type',
        },
        {
          name: 'notification-action-completed',
          label: 'Action',
          prop: 'totalStats',
          value: ({ row }) => {
            return row?.totalStats?.actionCompleted;
          },
          align: 'center',
        },
        {
          name: 'notification-seen',
          label: 'Seen',
          prop: 'totalStats',
          value: ({ row }) => {
            return row?.totalStats?.totalSeen;
          },
          align: 'center',
        },
        {
          name: 'notification-sent',
          label: 'Sent',
          prop: 'totalStats',
          value: ({ row }) => {
            return row?.totalStats?.totalSent;
          },
          align: 'center',
        },
        {
          name: 'notification-status',
          label: 'Status',
          prop: 'status',
          value: ({ row }) => {
            return this.notificationStatuses
              .find(({ id }) => row.status === id)?.label;
          },
          empty: '-',
        },
        {
          name: 'notification-action',
          align: 'center',
        },
      ];
    },
  },

  methods: {
    notificationActions(row) {
      const actions = [
        {
          id: 'edit',
          label: 'Edit',
          visible: row.status === 'PENDING' || row.status === 'DRAFT',
        },
        {
          id: 'statistics',
          label: 'Statistics',
          visible: row.status !== 'DRAFT',
        },
      ];
      return actions.filter((action) => action.visible);
    },
    setSort({ sortBy, sortDirection }) {
      this.filter.sortByField = sortBy;
      this.filter.sortDirection = sortDirection;
      this.search();
    },
    search() {
      this.page = 1;
      this.loadItems();
    },
    update() {
      this.$emit('update', {
        query: this.urlConfig,
      });
    },
    setItems(items) {
      this.items = items.map((item) => ({
        ...item,
        loading: false,
      }));
    },
    async handleAction(event, item) {
      switch (event) {
        case 'edit':
          await this.edit(item);
          break;

        case 'statistics':
          await this.openStatisticsModal(item);
          break;

        default:
          break;
      }
    },
    async edit(item) {
      item.loading = true;

      try {
        this.$router.push({
          name: 'notification-view',
          params: {
            notificationId: item.id,
          },
        });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        item.loading = false;
      }
    },
    async openStatisticsModal(item) {
      try {
        this.statistics.loading = true;
        this.statistics.data = {};
        this.statistics.show = true;
        const { data } = await api.notifications.getNotificationStatistics(item.id);
        this.statistics.data = { id: item.id, ...data };
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.statistics.loading = false;
      }
    },
    async loadItems() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.update();

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.notifications.getNotifications(this.apiConfig);

        if (data) {
          this.total = totalRecords;
          this.setItems(data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
