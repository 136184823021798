import { allCountries as countries } from '@/config/countries';

/**
 * Get country data by name.
 *
 * @param  {string} value
 * @param  {string} [defaultCountry='gb']
 * @return {string}
 */
export default (value, defaultCountry = 'gb') => {
  if (value) {
    const found = countries.find(({ iso, name }) => {
      return [iso, name].includes(value);
    });

    if (found) {
      return found;
    }
  }

  return countries.find(({ iso }) => iso === defaultCountry);
};
