<template>
  <ui-modal
    class="statistics-modal"
    v-bind="$attrs"
    v-on="$listeners"
    @closed="clearSelectedStaffs"
  >
    <template #title>
      <span class="statistics-modal__title">Statistics</span>
    </template>
    <div class="statistics-modal__search-row">
      <ui-input
        v-model="locationFilter"
        placeholder="Location name"
        bordered
        search
      />
      <ui-input
        v-model="nameFilter"
        placeholder="Staff name"
        bordered
        search
      />
    </div>
    <ui-loading v-if="loading" />
    <statistics-table v-else>
      <statistics-table-row
        name="All"
        :action="totalActions"
        :seen="totalSeen"
        :sent="totalSent"
        all-row
        :checked="staffsCount === selectedStaffsCount"
        @select="selectAllStaffs"
      />
      <template v-for="location in locationsForShow">
        <statistics-table-row
          :key="location.workplaceId"
          :name="location.workplaceName"
          :action="location.totalStats.actionCompleted"
          :seen="location.totalStats.totalSeen"
          :sent="location.totalStats.totalSent"
          parent
        />
        <statistics-table-row
          v-for="staff of location.staffList"
          :key="staff.staffId"
          :name="`${staff.firstName || ''} ${staff.lastName || ''}`.trim() || staff.payoutId"
          :error-message="staff.errorMessage"
          :action="staff.notifications[0].actionCompleted"
          :seen="staff.notifications[0].isSeen"
          :sent="staff.notifications[0].isSent"
          :checked="isStaffSelected(staff)"
          @select="selectStaff({ value: $event, staff, location })"
        />
      </template>
    </statistics-table>
    <template #footer>
      <div class="statistics-modal__footer">
        <ui-button
          :disabled="!selectedStaffsCount"
          type="primary"
          class="statistics-modal__submit-btn"
          native-type="button"
          @click="resendNotificationToSelected"
        >
          Resend to selected
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import api from '@/api';
import StatisticsTable from '@/components/Statistics/StatisticsTable.vue';
import StatisticsTableRow from '@/components/Statistics/StatisticsTableRow.vue';

export default {
  name: 'StatisticsModal',

  components: {
    StatisticsTableRow,
    StatisticsTable,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    notificationStatistics: {
      type: Object,
      required: true,
    },
    loading: Boolean,
  },

  data() {
    return {
      selectedStaffs: {},
      locationFilter: '',
      nameFilter: '',
    };
  },

  computed: {
    locations() {
      return this.notificationStatistics.data || [];
    },
    totalActions() {
      return this.locations?.reduce((sum, location) => {
        return sum + location.totalStats.actionCompleted;
      }, 0);
    },
    totalSeen() {
      return this.locations?.reduce((sum, location) => {
        return sum + location.totalStats.totalSeen;
      }, 0);
    },
    totalSent() {
      return this.locations?.reduce((sum, location) => {
        return sum + location.totalStats.totalSent;
      }, 0);
    },
    staffsCount() {
      return this.locations?.reduce((sum, location) => {
        return sum + location.staffList.length;
      }, 0);
    },
    locationsForShow() {
      const filteredLocations = [];
      this.locations.forEach((location) => {
        if (!location.workplaceName.toLowerCase().includes(this.locationFilter.toLowerCase())) {
          return;
        }
        const filteredStaffs = location.staffList.filter(
          (staff) => `${staff.firstName} ${staff.lastName}`.toLowerCase().includes(this.nameFilter.toLowerCase()),
        );
        if (filteredStaffs.length) {
          filteredLocations.push({ ...location, staffList: filteredStaffs });
        }
      });
      return filteredLocations;
    },
    selectedStaffsCount() {
      return Object.keys(this.selectedStaffs)?.length || 0;
    },
  },

  methods: {
    async resendNotificationToSelected() {
      try {
        const requestBody = {
          id: this.notificationStatistics.id,
          staffIdList: Object.keys(this.selectedStaffs),
          workplaceIdList: [...new Set(Object.values(this.selectedStaffs))],
        };
        const { data } = await api.notifications.resendNotificationToSelectedStaffs(requestBody);
        this.$router.push({
          name: 'notification-view',
          params: {
            notificationId: data.id,
          },
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    selectStaff({ value, staff, location }) {
      if (value) {
        this.selectedStaffs = { ...this.selectedStaffs, [staff.staffId]: String(location.workplaceId) };
      } else {
        const { [staff.staffId]: _, ...newSelectedStaffs } = this.selectedStaffs;
        this.selectedStaffs = newSelectedStaffs;
      }
    },
    selectAllStaffs(value) {
      if (value) {
        const selectedStaffs = {};
        this.locations.forEach((location) => {
          location.staffList.forEach((staff) => {
            selectedStaffs[staff.staffId] = String(location.workplaceId);
          });
        });
        this.selectedStaffs = selectedStaffs;
      } else {
        this.selectedStaffs = {};
      }
    },
    isStaffSelected(staff) {
      return Boolean(this.selectedStaffs[staff.staffId]);
    },
    clearSelectedStaffs() {
      this.selectedStaffs = {};
    },
  },
};
</script>
