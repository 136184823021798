export default (data) => data.map((item) => ({
  ...item,
  staffId: item.staff.id,
  amount: item.amount.amount,
  currency: item.amount.currency,
  staff: {
    ...item.staff,
    firstName: item.staff.user.firstName,
    lastName: item.staff.user.lastName,
  },
  payoutInfoDto: {
    workDay: {
      hours: item.value,
      units: item.value,
    },
  },
}));
