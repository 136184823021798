var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui-modal',{staticClass:"user-change-phone-modal",attrs:{"show":"","no-footer":""},on:{"closed":_vm.handleClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"modal-title text-center"},[_vm._v(" Update phone ")])]},proxy:true}])},[[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-7 center-block"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.phoneNumber'),"rules":{
                required: {
                  invalid: _vm.phoneInvalid,
                },
              },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ui-phone',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.phoneNumber'),"required":"","disabled":_vm.isConfirmation,"error":errors[0],"country-code":_vm.countryCode},on:{"validate":function($event){_vm.phoneInvalid = $event}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])}),(_vm.showOtp)?_c('div',[_c('validation-provider',{key:"recovery-code",attrs:{"name":"Code","rules":"digits:6","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":"Enter OTP code","input-class":"form-control p-1","error":_vm.otpInvalidError || errors[0],"required":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,false,2446565565)})],1):_vm._e()],1)]),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"row justify-content-around"},[(!_vm.showOtp)?_c('ui-button',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-3 mt-3 ml-auto",attrs:{"type":"primary","block":"","is-loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.sendOtp.apply(null, arguments)}}},[_vm._v(" Update phone ")]):_vm._e(),(_vm.showOtp)?_c('ui-button',{staticClass:"col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3 mal-auto",attrs:{"type":"primary","block":"","disabled":!_vm.code},on:{"click":function($event){$event.preventDefault();return _vm.verify.apply(null, arguments)}}},[_vm._v(" Update ")]):_vm._e(),(_vm.showOtp)?_c('ui-button',{staticClass:"col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3 mal-auto",attrs:{"type":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClose.apply(null, arguments)}}},[_vm._v(" Cancel ")]):_vm._e(),(_vm.showOtp)?_c('resend-code',{on:{"resend":_vm.sendOtp}}):_vm._e()],1)])])])]],2),_c('ui-modal-status',{attrs:{"show":_vm.status.show,"title":_vm.status.title,"message":_vm.status.message,"type":_vm.status.type},on:{"closed":_vm.handleStatusClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }