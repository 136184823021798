<template>
  <ui-data-table
    data-table="payout"
    :columns="columns"
    :data="items"
    :empty-header="emptyHeader"
    no-empty-text
  >
    <template #payout-staff-status-value="{ value }">
      <span :class="value === 'ACTIVE' ? 'text-green' : 'text-primary'">
        {{ $t(`status.${value}`) }}
      </span>
    </template>

    <template #payout-percentage-value="{ value }">
      {{ value }}%
    </template>

    <template #payout-available-amount-label="{ col }">
      <div class="d-flex align-items-center">
        <span>{{ col.label }}</span>
        <ui-tooltip placement="top">
          <template #content>
            <span v-html="$t('staffPayouts.tooltip.available')" />
          </template>
          <i class="nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"></i>
        </ui-tooltip>
      </div>
    </template>

    <template #payout-available-amount-value="{ value, row }">
      {{ value }}
      <span v-if="row.partialWork" class="ml-1 text-danger">
        <b>*</b>
      </span>
    </template>

    <template #payout-available-amount-total>
      <span v-if="amountTotal" class="font-weight-bold">
        {{ amountTotal | money(locationCurrency) }}
      </span>
      <template v-else>
        &mdash;
      </template>
    </template>

    <template #payout-gross-amount-total>
      <span v-if="amountTotal" class="font-weight-bold">
        {{ amountTotal | money(locationCurrency) }}
      </span>
      <template v-else>
        &mdash;
      </template>
    </template>

    <template #after>
      <div class="my-4 d-flex align-items-center justify-content-between">
        <ui-pagination
          v-if="hasPages"
          v-model="pageIndex"
          :total="total"
          :per-page="pageSize"
        />
        <div class="d-flex flex-column text-grey">
          <span v-if="hasPartials">* Based on start / leave date</span>
        </div>
      </div>

      <slot name="after" v-bind="{ amountTotal }" />
    </template>
  </ui-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { sumBy } from 'lodash';
import fio from '@/utils/fio';
import money from '@/filters/money';
import convertTimeToFixed from '@/utils/convertTimeToFixed';

export default {

  props: {
    items: {
      type: Array,
      required: true,
    },
    staffs: {
      type: Array,
      default: () => [],
    },
    locationCountry: String,
    locationCurrency: String,
    total: Number,
    page: Number,
    pageSize: Number,
    emptyHeader: Boolean,
  },

  async mounted() {
    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  computed: {
    ...mapGetters({
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
      isTroncMaster: 'auth/isTroncMaster',
    }),
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    isTroncUAECommon() {
      return this.isTroncMaster && this.isUAE && this.isCommon;
    },
    timesheetType() {
      return this.$store.state.user.location.timesheetType;
    },
    pageIndex: {
      get() {
        return this.page;
      },
      set(index) {
        this.$emit('update', index);
      },
    },
    hasPages() {
      return this.total > this.pageSize;
    },
    amountTotal() {
      // console.log(+Number(sumBy(this.items, 'amount')));
      return +Number(sumBy(this.items, 'amount')).toFixed(2);
    },
    hasPartials() {
      return this.items.some(({ partialWork }) => partialWork);
    },
    columns() {
      return [
        {
          name: 'payout-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          value: ({ row }) => row.staff?.payoutId,
          empty: this.$t('table.empty.easytipId'),
          align: 'center',
        },
        {
          name: 'payout-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          value: ({ row }) => (row.staff ? fio(row.staff) || row.login : null),
          empty: this.$t('table.empty.name'),
          align: 'center',
        },
        {
          name: 'payout-staff-status',
          label: this.$t('table.columns.status'),
          prop: 'staffStatus',
          value: ({ row }) => row.staff?.status,
          empty: this.$t('table.empty.status'),
          align: 'center',
        },
        {
          name: 'payout-percentage',
          label: this.$t('table.columns.percentage'),
          prop: 'percentage',
          empty: this.$t('table.empty.percentage'),
          align: 'center',
        },
        {
          name: 'payout-hours',
          label: this.$t('table.columns.hours'),
          prop: 'hours',
          value: ({ row }) => convertTimeToFixed(row?.payoutInfoDto?.workDay?.hours),
          empty: this.$t('table.empty.hours'),
          align: 'center',
          visible: this.timesheetType === 'HOURS',
        },
        {
          name: 'payout-units',
          label: this.$t('table.columns.units'),
          prop: 'units',
          value: ({ row }) => convertTimeToFixed(row?.payoutInfoDto?.workDay?.units),
          empty: this.$t('table.empty.units'),
          align: 'center',
          visible: this.timesheetType === 'UNITS',
        },
        {
          name: 'payout-available-amount',
          label: this.$t('table.columns.availableAmount'),
          prop: 'amount',
          value: ({ row }) => money(row.amount, row.currency),
          empty: this.$t('table.empty.availableAmount'),
          align: 'center',
          total: true,
          visible: !this.isOwnerUAECommon && !this.isTroncUAECommon,
        },
        {
          name: 'payout-gross-amount',
          label: this.$t('table.columns.grossAmount'),
          prop: 'amount',
          value: ({ row }) => money(row.amount, row.currency),
          empty: this.$t('table.empty.grossAmount'),
          align: 'center',
          total: true,
          visible: this.isOwnerUAECommon || this.isTroncUAECommon,
        },
        {
          name: 'payout-staff-type',
          label: this.$t('table.columns.staffType'),
          prop: 'staffType',
          value: ({ row }) => {
            if (!row.staff) {
              return '';
            }

            return this.$getStaffTypeName(row.staff.staffType)
              + (row.staff.isTronc && row.staff.isTroncmaster ? ` (${this.$t('form.labels.troncMaster', this.locationCountry)})` : '')
              + (row.staff.isTronc && !row.staff.isTroncmaster ? ` (Deputy ${this.$tl('form.labels.troncMaster', this.locationCountry)})` : '');
          },
          empty: this.$t('table.empty.staffType'),
          align: 'center',
        },
      ];
    },
  },

  methods: {
    convertTimeToFixed,
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
  },
};
</script>
