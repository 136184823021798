import currencies from '@/config/currencies';
import getCountry from './getCountry';

/**
 * Get currency data by country name.
 *
 * @param  {string} value
 * @param  {string} [defaultCountry='gb']
 * @return {string}
 */
export default (value, defaultCountry = 'gb') => {
  const country = getCountry(value, defaultCountry);

  if (country.iso in currencies) {
    return currencies[country.iso];
  }

  return currencies[defaultCountry];
};
