<template>
  <div class="location-pay-settings__wrapper">
    <ui-loading v-if="loading.data" />
    <validation-observer
      v-else
      tag="div"
      v-slot="{ validate }"
      class="location-pay-settings"
    >
      <div class="location-pay-settings__subtitle">
        Captions
      </div>

      <div class="location-pay-settings__inputs">
        <validation-provider
          tag="div"
          :name="$t('form.labels.caption.sublocations')"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
          class="location-pay-settings__field"
        >
          <ui-input
            v-model="form.selectSubLocationText"
            :label="$t('form.labels.caption.sublocations')"
            class="location-pay-settings__input"
            required
            :error="errors[0]"
          />
          <img src="@/assets/images/pay/pay-sublocations.svg">
        </validation-provider>

        <validation-provider
          tag="div"
          :name="$t('form.labels.caption.groups')"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
          class="location-pay-settings__field"
        >
          <ui-input
            v-model="form.selectGroupText"
            :label="$t('form.labels.caption.groups')"
            class="location-pay-settings__input"
            required
            :error="errors[0]"
          />
          <img src="@/assets/images/pay/pay-groups.svg">
        </validation-provider>

        <validation-provider
          tag="div"
          :name="$t('form.labels.caption.staffList')"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
          class="location-pay-settings__field"
        >
          <ui-input
            v-model="form.selectStaffText"
            :label="$t('form.labels.caption.staffList')"
            class="location-pay-settings__input"
            required
            :error="errors[0]"
          />
          <img src="@/assets/images/pay/pay-staff-list.svg">
        </validation-provider>
      </div>
      <ui-button
        action
        type="primary"
        :is-loading="loading.form"
        :disabled="loading.form"
        class="location-pay-settings__submit"
        @click.prevent="submit(validate)"
      >
        {{ $t('form.buttons.save') }}
      </ui-button>
    </validation-observer>
  </div>
</template>

<script>
import api from '@/api';

export default {
  name: 'LocationPaySettings',

  props: {
    locationId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: {
        data: false,
        form: false,
      },
      form: {
        selectSubLocationText: '',
        selectGroupText: '',
        selectStaffText: '',
      },
    };
  },

  methods: {
    async loadPaySettings() {
      this.loading.data = true;

      try {
        const { data } = await api.locations.getPaySettingsConfig(this.locationId);

        this.form.selectSubLocationText = data.selectSubLocationText;
        this.form.selectGroupText = data.selectGroupText;
        this.form.selectStaffText = data.selectStaffText;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.data = false;
      }
    },

    async submit(validate) {
      if (!await validate()) {
        return;
      }

      this.loading.form = true;

      try {
        await api.locations.setPaySettingsConfig(
          this.locationId,
          {
            selectSubLocationText: this.form.selectSubLocationText.trim(),
            selectGroupText: this.form.selectGroupText.trim(),
            selectStaffText: this.form.selectStaffText.trim(),
            workplaceId: this.locationId,
          },
        );

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
  },

  mounted() {
    this.loadPaySettings();
  },
};
</script>

<style lang="scss">
.location-pay-settings {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 24px;

  &__wrapper {
    min-height: 500px;
  }

  &__subtitle {
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  &__inputs {
    display: flex;
    gap: 30px;
    width: 100%;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__field {
    width: 295px;
    max-width: 100%;

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__submit {
    width: 295px;
    max-width: 100%;
  }
}
</style>
