export const DELIVERY_TYPE = {
  value: 'DELIVERY',
  label: 'Delivery',
};

export const HOTEL_TYPE = {
  value: 'HOTEL',
  label: 'Hotel',
};

export const RESTAURANT_TYPE = {
  value: 'RESTAURANT',
  label: 'Restaurant',
};

export const SPA_TYPE = {
  value: 'SPA',
  label: 'SPA',
};

export const BEAUTY_SALON_TYPE = {
  value: 'BEAUTY_SALON',
  label: 'Salon',
};

export const SWITZERLAND_TYPES = [
  HOTEL_TYPE,
  DELIVERY_TYPE,
];

export const DEFAULT_TYPES = [
  HOTEL_TYPE,
  RESTAURANT_TYPE,
  DELIVERY_TYPE,
  SPA_TYPE,
  BEAUTY_SALON_TYPE,
];
