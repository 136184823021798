<template>
  <component
    v-if="reportingComponent"
    :is="reportingComponent"
    :key="`${accessToken}-${payoutId}`"
  />
  <ui-loading v-else inline />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StaffReporting from '@/components/Staff/StaffReporting.vue';
import ManagerReporting from '@/components/Manager/ManagerReporting.vue';

export default {

  components: {
    StaffReporting,
    ManagerReporting,
  },

  computed: {
    ...mapGetters('auth', [
      'isStaff',
      'isOwner',
      'isManager',
      'isAccountant',
      'isDistributionMaster',
    ]),
    ...mapGetters('user', [
      'defaultLocationRequired',
    ]),
    ...mapState({
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
    }),
    reportingComponent() {
      if (this.defaultLocationRequired) {
        return false;
      }

      if (this.isManager || this.isOwner || this.isAccountant || this.isDistributionMaster) {
        return 'manager-reporting';
      }

      if (this.isStaff) {
        return 'staff-reporting';
      }

      return false;
    },
  },

  mounted() {
    if (this.viewAsMode === null) {
      this.$amplitude.event('OPENED_PAGE', {
        userRoles: this.userRoles,
        hostname: window.location.hostname,
        pagepath: window.location.pathname,
        url: window.location.href,
      });
    }
  },
};
</script>
