var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-card',{staticClass:"notifications-list",attrs:{"tag":"form","is-loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('notifications.listTitle'))+" ")])]},proxy:true}])},[_c('div',{staticClass:"row mb-xl-5 mb-lg-5 mb-2 mt-3"},[_c('div',{staticClass:"col-12 col-md-auto mt-1"},[_c('ui-input',{staticClass:"my-0",attrs:{"placeholder":"Topic","bordered":"","search":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.search.apply(null, arguments)}},model:{value:(_vm.filter.topic),callback:function ($$v) {_vm.$set(_vm.filter, "topic", $$v)},expression:"filter.topic"}})],1),_c('div',{staticClass:"col-12 col-md-auto mt-1"},[_c('ui-input',{staticClass:"my-0",attrs:{"placeholder":_vm.$t('form.labels.locationName'),"bordered":"","search":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.search.apply(null, arguments)}},model:{value:(_vm.filter.locationName),callback:function ($$v) {_vm.$set(_vm.filter, "locationName", $$v)},expression:"filter.locationName"}})],1),_c('div',{staticClass:"col-12 col-md-auto mt-1 my-0"},[_c('ui-select',{staticClass:"my-0",attrs:{"items":_vm.countries,"placeholder":"Location country","label-key":"name","value-key":"iso","bordered":"","large":""},on:{"change":_vm.search},model:{value:(_vm.filter.country),callback:function ($$v) {_vm.$set(_vm.filter, "country", $$v)},expression:"filter.country"}})],1)]),_c('ui-data-table',{staticClass:"notifications-list__table",attrs:{"columns":_vm.columns,"data":_vm.items,"data-table":"notification"},on:{"sort":_vm.setSort},scopedSlots:_vm._u([{key:"notification-topic",fn:function(ref){
var row = ref.row;
return [(row.topic)?_c('router-link',{staticClass:"text-primary word-break-word",attrs:{"to":{
          name: 'notification-view',
          params: {
            notificationId: row.id,
          },
        }}},[_vm._v(" "+_vm._s(row.topic)+" ")]):_vm._e()]}},{key:"notification-location-name",fn:function(ref){
        var row = ref.row;
return [(row.workplaceList && row.workplaceList.length)?_c('div',[_c('router-link',{staticClass:"text-primary",attrs:{"to":{
            name: 'location-view',
            params: {
              locationId: row.workplaceList[0].id,
            },
          }}},[_vm._v(" "+_vm._s(row.workplaceList[0].name)+" ")]),(row.workplaceList.length > 1)?_c('span',{staticClass:"text-grey"},[_vm._v("  +"+_vm._s(row.workplaceList.length)+" ")]):_vm._e()],1):_vm._e()]}},{key:"notification-message",fn:function(ref){
          var value = ref.value;
return [(value)?_c('ui-popover',{attrs:{"width":"188","trigger":"click","placement":"right"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(value)+" ")])]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"notification-sending-date",fn:function(ref){
          var value = ref.value;
return [(value)?_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("date")(value))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("time")(value))+" ")])]):_vm._e()]}},{key:"notification-action-completed",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("0")])]}},{key:"notification-seen",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("0")])]}},{key:"notification-sent",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v(_vm._s(value))]):_c('span',[_vm._v("0")])]}},{key:"notification-status",fn:function(ref){
          var value = ref.value;
return [(value === 'DRAFT')?_c('div',{staticClass:"draft"},[_c('ui-tag',[_c('span',[_vm._v(_vm._s(value))])])],1):(value === 'PENDING')?_c('div',{staticClass:"pending"},[_c('ui-tag',[_c('span',[_vm._v(_vm._s(value))])])],1):(value === 'FAILED')?_c('div',{staticClass:"failed"},[_c('ui-tag',[_c('span',[_vm._v(_vm._s(value))])])],1):(value === 'SENT')?_c('div',{staticClass:"sent"},[_c('ui-tag',[_c('span',[_vm._v(_vm._s(value))]),_c('ui-icon',{attrs:{"name":"check","width":"6px","height":"4px"}})],1)],1):_vm._e()]}},{key:"notification-action",fn:function(ref){
          var row = ref.row;
return [(row.loading)?_c('ui-loading',{attrs:{"inline":""}}):_c('ui-action-down',{attrs:{"disabled":Boolean(_vm.loading || row.loading),"items":_vm.notificationActions(row)},on:{"select":function($event){return _vm.handleAction($event, row)}}})]}}])}),(_vm.total > _vm.pageSize)?_c('ui-pagination',{staticClass:"mt-4",attrs:{"total":_vm.total,"per-page":_vm.pageSize,"show-pages":7,"flex-control":""},on:{"input":_vm.update},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('statistics-modal',{attrs:{"notification-statistics":_vm.statistics.data,"loading":_vm.statistics.loading},model:{value:(_vm.statistics.show),callback:function ($$v) {_vm.$set(_vm.statistics, "show", $$v)},expression:"statistics.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }