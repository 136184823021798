<template>
  <ui-modal-alert
    class="shared-card-terms-modal"
    :show="show"
    :closable="false"
    :title="$t(`sharedCard.${termsType}.title`)"
    close-button="Cancel"
    apply-button="Allow"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <h3 class="shared-card-terms-modal__sub-title">
      {{ $t(`sharedCard.${termsType}.notice`) }}
    </h3>
    <ul class="shared-card-terms-modal__list">
      <li
        v-for="(agreement, index) in $t(`sharedCard.${termsType}.agreements`).length"
        :key="agreement"
      >
        {{ $t(`sharedCard.${termsType}.agreements[${index}]`) }}
      </li>
    </ul>
  </ui-modal-alert>
</template>

<script>
export default {
  name: 'SharedCardTermsModal',

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    show: Boolean,
    userRole: {
      type: String,
      default: 'owner',
    },
  },

  computed: {
    isStaff() {
      return this.userRole === 'staff';
    },
    termsType() {
      return this.isStaff ? 'staffTerms' : 'ownerTerms';
    },
  },
};
</script>
