/**
 * Get local image from @/assets/images/ folder.
 *
 * @param  {string} filename
 * @param  {string} format
 * @param  {string} folder
 * @return {String}
 */
export default (filename, format = 'png', folder) => {
  return folder
    // eslint-disable-next-line global-require, import/no-dynamic-require
    ? require(`@/assets/images/${folder}/${filename}.${format}`)
    // eslint-disable-next-line global-require, import/no-dynamic-require
    : require(`@/assets/images/${filename}.${format}`);
};
