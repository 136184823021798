<template>
  <ui-card
    v-if="locationloading"
    is-loading
  >
    <div class="py-5">
      Loading..
    </div>
  </ui-card>
  <div
    v-else
    :key="accessToken"
  >
    <template v-if="!location.isMasterLocation">
      <staff-register
        :key="`staff-register-${location.id}`"
        :location="location"
        :is-loading="groupsLoading"
        :groups="groups"
        :distributions="distributions"
        :is-common="isCommon"
        :disable-registration="disableStaffRegistration"
        @completed="onUpdateStaffs"
      />
      <template v-if="showGroups">
        <template v-for="(group, index) in groups">
          <staff-list-all
            :key="`staff-list-groups-${location.id}-${group.id}`"
            :location="location"
            :group="group"
            :groups="groups"
            :is-loading="staffsLoading"
            :staffs="group.staffs"
            :route-prefix="`staffListGroup${index}`"
            @updated="onUpdateStaffs"
          />
        </template>
      </template>
      <staff-list-location
        v-else
        :reload.sync="staffsUpdated"
        :location="location"
        route-prefix="staffList"
      />
    </template>

    <location-form
      class="mt-4"
      :form-data="location"
      :show-cancel="false"
      @completed="loadLocation"
      @update="location = $event"
    />

    <div class="app-title app-title_secondary mt-4">
      Transactions
    </div>

    <div class="row">
      <div class="col-auto" v-if="showGroups">
        <ui-select
          v-model="groupId"
          :items="groupOptions"
          class="select-width"
          label-key="name"
          value-key="id"
          bordered
        />
      </div>
      <div class="col-auto">
        <ui-calendar-period v-model="period" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 pb-4">
        <stats-total-amount
          :key="`total-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          @toggle-promo-date="togglePromoDate"
          class="mw-100 h-100"
        />
      </div>
      <div v-if="showStatsBonusTips" class="col-12 col-lg-6 pb-4">
        <stats-bonus-tips
          :key="`bonus-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :location="location"
          :group-id="groupId"
          :currency="locationCurrency"
          :to="statsBonusRoute"
          :date-from="from"
          :date-to="to"
          class="mw-100 h-100"
        />
      </div>
      <div v-if="showVenueCharge" class="col-12 col-lg-6 pb-4">
        <stats-venue
          :key="`venue-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          class="mw-100 h-100"
        />
      </div>
      <div v-if="isCommon" class="col-12 col-lg-6 pb-4">
        <stats-total-rating
          :key="`rating-${location.id}-${groupId}-${from}-${to}`"
          :title="$t('reporting.ratingTitle.venue')"
          :location-id="location.id"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          class="mw-100 h-100"
        />
      </div>
    </div>

    <transaction-graph
      :key="`graph-${location.id}-${groupId}-${from}-${to}`"
      :location="location"
      :group-id="groupId"
      :date-from="from"
      :date-to="to"
    />
    <transaction-list
      id="transaction-list"
      :key="`table-${location.id}-${groupId}-${from}-${to}`"
      :location="location"
      :group-id="groupId"
      :show-promo="isAdmin"
      :date-from="from"
      :date-to="to"
      show-id
      show-name
      show-amount
      class="mt-4"
    />

    <location-distributions
      v-if="showLocationDistributions"
      class="mt-4"
      body-classes="col-xl-7"
      :key="`distributions-${location.id}-${staffs}-${distributions}`"
      :is-loading="distributionsLoading"
      :location="location"
      :staffs="staffs"
      confirm-message="The flag will be dropped for all employees<br>are you sure you want to continue?"
      :confirm-modal="showDistributionsConfirmModal"
      @update="loadDistributions"
    />

    <location-reviews
      v-if="!location.isMasterLocation"
      class="mt-4"
      :key="`location-reviews-${location.id}`"
      :location="location"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import api from '@/api';
import storage from '@/plugins/storage';
import StaffRegister from '@/components/Staff/StaffRegister.vue';
import StaffListAll from '@/components/StaffList/StaffListAll.vue';
import StaffListLocation from '@/components/StaffList/StaffListLocation.vue';
import StatsTotalAmount from '@/components/Stats/StatsTotalAmount.vue';
import StatsBonusTips from '@/components/Stats/StatsBonusTips.vue';
import StatsVenue from '@/components/Stats/StatsVenue.vue';
import StatsTotalRating from '@/components/Stats/StatsTotalRating.vue';
import TransactionGraph from '@/components/Payins/TransactionGraph.vue';
import TransactionList from '@/components/Payins/TransactionList.vue';
import LocationForm from '@/components/Location/LocationForm.vue';
import LocationDistributions from '@/components/Location/LocationDistributions.vue';
import LocationReviews from '@/components/Location/LocationReviews.vue';
import { CASE_TYPE } from '@/config/caseType';

export default {

  components: {
    StaffRegister,
    StaffListAll,
    StaffListLocation,
    StatsTotalAmount,
    StatsBonusTips,
    StatsVenue,
    StatsTotalRating,
    TransactionGraph,
    TransactionList,
    LocationForm,
    LocationDistributions,
    LocationReviews,
  },

  props: {
    locationId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    locationloading: false,
    location: null,
    groupId: 0,
    period: [],
    staffsUpdated: false,
  }),

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  computed: {
    ...mapState({
      staffs: (state) => state.staffs.items,
      staffsLoading: (state) => state.staffs.loading,
      groups: (state) => state.groups.items,
      groupsLoading: (state) => state.groups.loading,
      accessToken: (state) => state.auth.accessToken,
      distributionsLoading: (state) => state.distributions.loading,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      distributions: 'distributions/parsedDistributions',
    }),
    locationCurrency() {
      return this.location?.currencyCode;
    },
    isTipPayoutEmployer() {
      return this.location.tipPayoutType === 'EMPLOYER';
    },
    isCommon() {
      return this.location?.tipsType === 'COMMON' || this.location?.tipsType === 'COMMON_PRIORITY';
    },
    showVenueCharge() {
      return this.location?.workplaceFee > 0 && this.isCommon;
    },
    showGroups() {
      return this.location?.paymentPage === 'GROUPS';
    },
    showDistributionsConfirmModal() {
      return this.location?.country !== 'ae';
    },
    groupOptions() {
      return [
        {
          id: 0,
          name: this.$t('form.labels.allGroups'),
        },
        ...this.groups,
      ];
    },
    showLocationDistributions() {
      return this.isCommon
        && this.location?.showSetDistributionModule
        && !this.isTipPayoutEmployer
        && this.location?.country !== 'gb'
        && this.location.caseType !== CASE_TYPE.CASE_3;
    },
    distributionsSummary() {
      if (!Array.isArray(this.distributions)) {
        return 100;
      }

      return this.distributions.reduce((carry, { percentage }) => carry + percentage, 0);
    },
    disableStaffRegistration() {
      return this.showLocationDistributions && this.distributionsSummary !== 100;
    },
    showStatsBonusTips() {
      return this.location.activePromo && this.isAdmin;
    },
    statsBonusRoute() {
      if (!this.isAdmin) {
        return null;
      }

      return {
        name: 'promotion-view',
        params: {
          promotionId: this.location.activePromo?.id,
        },
      };
    },
    from() {
      return this.period?.[0]
        || DateTime.local().minus({ days: 30 }).toJSDate();
    },
    to() {
      return this.period?.[1]
        || DateTime.local().toJSDate();
    },
    storeUpdated() {
      return `${this.locationId}-${this.accessToken}`;
    },
  },

  watch: {
    storeUpdated: {
      immediate: true,
      handler: 'loadData',
    },
    locationloading: {
      immediate: true,
      handler(value) {
        if (!value) {
          const scrollTop = storage.get('scrollTopPosition');

          if (scrollTop) {
            this.$nextTick(() => {
              window.scrollTo({
                top: scrollTop,
                behavior: 'smooth',
              });
            });

            storage.set('scrollTopPosition', 0);
          }
        }
      },
    },
  },

  methods: {
    ...mapActions({
      loadStaffItems: 'staffs/loadItems',
      loadGroupItems: 'groups/loadItems',
      loadSetDistributionsData: 'distributions/init',
    }),
    handleScroll(event) {
      storage.set('scrollTopPosition', window.scrollY);
    },
    selectedPeriod({ dateFrom, dateTo }) {
      this.from = dateFrom;
      this.to = dateTo;
    },
    async loadLocation() {
      this.locationloading = true;

      try {
        const { data } = await api.locations.getLocation({
          locationId: this.locationId,
        });

        if (data) {
          this.location = data;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.locationloading = false;
      }
    },
    async loadStaffs(loadGroups = false) {
      try {
        if (this.showLocationDistributions || !this.showGroups) {
          await this.loadStaffItems({
            locationId: this.locationId,
            onlyActive: false,
          });
        }

        // First load groups in location-form on mounted
        if (this.showGroups && loadGroups) {
          await this.loadGroupItems({
            locationId: this.locationId,
          });
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadDistributions() {
      this.loadSetDistributionsData({
        location: this.location,
      });
    },
    onUpdateStaffs() {
      this.staffsUpdated = true;

      return this.loadStaffs(true /* reload groups */);
    },
    async loadData() {
      await this.loadLocation();
      await this.loadStaffs();
      this.loadDistributions();
    },
    togglePromoDate(value) {
      this.period = [
        DateTime.fromISO(value[0]).toJSDate(),
        DateTime.fromISO(value[1]).toJSDate(),
      ];

      this.$scroll('#transaction-list');
    },
  },
};
</script>
