<template>
  <ui-popover
    placement="top-start"
    width="188"
    trigger="hover"
    title="Overdue"
    icon="info"
    icon-size="16px"
    icon-class="text-primary"
  >
    <p class="d-flex justify-content-between align-items-center">
      Days:
      <span class="ml-2">
        {{ days || '-' }}
      </span>
    </p>
    <p class="d-flex justify-content-between">
      Since:
      <span class="ml-2">
        <template v-if="since">
          {{ since | date }}
        </template>
        <template v-else>
          -
        </template>
      </span>
    </p>
  </ui-popover>
</template>

<script>
export default {

  props: {
    days: {
      type: [String, Number],
      required: true,
    },
    since: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
