<template>
  <app-onboarding container-flex>
    <ui-card class="recovery-success">
      <template #header>
        <img class="recovery-success__img" src="@/assets/images/recovery-success.svg" alt="">
      </template>

      <div class="recovery-success__title">
        Success
      </div>
      <div class="recovery-success__text">
        Your password has been changed successfully. Use your new password to log in.
      </div>

      <template #footer>
        <ui-button
          native-type="submit"
          type="primary"
          block
          class="mt-3 mb-3"
          @click="ok">
          Ok
        </ui-button>
      </template>
    </ui-card>
  </app-onboarding>
</template>

<script>
import AppOnboarding from '@/components/AppOnboarding.vue';

export default {
  components: {
    AppOnboarding,
  },

  methods: {
    ok() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
