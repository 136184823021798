<template>
  <div class="menu-multi-section-item">
    <div class="menu-multi-section-item__info_desktop">
      <validation-provider
        class="menu-multi-section-item__input"
        tag="div"
        :name="`Section ${sectionId}`"
        rules="required"
        mode="passive"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="fileName"
          v-protected
          :label="`Section ${sectionId}`"
          :error="errors[0]"
        />
      </validation-provider>
      <ui-icon-upload
        v-protected
        class="menu-multi-section-item__upload"
        accept-files="application/pdf"
        :disabled="loading.file"
        @upload="handleFileUpload({
          fileData: $event,
          sectionId: id,
        })"
      />
      <ui-icon-button
        v-protected
        name="trash"
        width="16px"
        height="17px"
        class="menu-multi-section-item__delete text-primary"
        :disabled="loading.file"
        @click="triggerDeleteSectionModal"
      />
    </div>
    <div class="menu-multi-section-item__info_mobile">
      <validation-provider
        class="menu-multi-section-item__input"
        tag="div"
        :name="`Section ${sectionId}`"
        rules="required"
        mode="passive"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="fileName"
          v-protected
          :label="`Section ${sectionId}`"
          :error="errors[0]"
        />
      </validation-provider>
      <div class="menu-multi-section-item__info_mobile-controls">
        <ui-button-upload
          v-protected
          class="menu-multi-section-item__upload"
          accept-files="application/pdf"
          title="Add file(s)"
          action
          multiple
          :disabled="loading.file"
          @upload="handleFileUpload({
            fileData: $event,
            sectionId: id,
          })"
        />
        <ui-button
          v-protected
          type="primary"
          size="sm"
          outline
          class="menu-multi-section-item__delete text-primary"
          :disabled="loading.file"
          @click="triggerDeleteSectionModal"
        >
          Remove section
        </ui-button>
      </div>
    </div>

    <div @touchend="fixActionRestriction">
      <Container
        v-protected
        tag="ul"
        class="menu-multi-section-item__files"
        group-name="multi-section"
        drag-class="file-ghost"
        :drop-placeholder="{
          animationDuration: '150',
          showOnTop: true,
          className: 'file-drop',
        }"
        :get-child-payload="(itemId) => getChildPayload(itemId)"
        @drop="handleDrop(id, $event)"
      >
        <Draggable
          tag="li"
          v-for="(file, index) in data.files"
          :key="index"
        >
          <section-file
            v-protected
            :id="index"
            :data="file"
            @select-file="handleSelectFile({
              data: $event.data,
              fileId: $event.fileId,
              sectionId: id,
            })"
            @delete-file="handleDeleteFile({
              fileId: $event,
              sectionId: id,
            })"
          />
        </Draggable>
        <div
          v-if="loading.file && currentUploadSectionId === id"
          class="menu-multi-section-item__loader"
        >
          <ui-loading inline />
        </div>
      </Container>
    </div>

    <hr v-if="data.files.length">

    <ui-modal-alert
      v-model="deleteSectionModal"
      :title="$t('onlineMenu.modal.deleteSection.title')"
      :apply-button="$t('form.buttons.remove')"
      :close-button="$t('form.buttons.cancel')"
      @apply="handleDeleteSection(id)"
    >
      {{ $t('onlineMenu.modal.deleteSection.description') }}
    </ui-modal-alert>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';

import applyDrag from '@/utils/applyDrag';
import fromBytesToMb from '@/utils/fromBytesToMb';
import SectionFile from '@/components/OnlineMenu/SectionFile/SectionFile.vue';

export default {
  name: 'MultiSectionItem',

  components: {
    Container,
    Draggable,
    SectionFile,
  },

  props: {
    id: Number,
    data: Object,
  },

  data() {
    return {
      maxFileSize: 10e6,
      deleteSectionModal: false,
    };
  },

  computed: {
    ...mapState('menu', [
      'multiSectionList',
      'loading',
      'currentUploadSectionId',
    ]),
    sectionId() {
      return this.id + 1;
    },
    fileName: {
      get() {
        return this.data.name;
      },
      set(value) {
        this.updateSectionName({
          sectionName: value,
          sectionId: this.id,
        });
      },
    },
  },

  mounted() {
    this.$watch(() => this.multiSectionList[this.id].files.filter((file) => file.selected), (value) => {
      if (value.length) {
        this.$emit('select-file', {
          ...value[0],
          sectionId: this.id,
        });
      }
    }, { immediate: true, deep: true });
  },

  methods: {
    ...mapMutations('menu', [
      'deleteSection',
      'deleteSectionFile',
      'updateSectionFiles',
      'updateSectionName',
      'selectSectionFile',
      'updateSingleSectionFileOrder',
    ]),
    ...mapActions('menu', [
      'loadFilePages',
    ]),
    fixActionRestriction() {
      // issue: https://github.com/kutlugsahin/vue-smooth-dnd/issues/134
      document.body.classList.remove(
        'smooth-dnd-no-user-select',
        'smooth-dnd-disable-touch-action',
      );
    },
    parseFiles(fileData) {
      const uploadedFiles = Array.from(fileData.target.files);

      return uploadedFiles
        .filter(({ type }) => type === 'application/pdf')
        .map((item) => ({
          fileName: item.name,
          fileSize: item.size,
          file: item,
        }));
    },
    filterFilesBySize(fileData) {
      let filteredFiles = this.parseFiles(fileData);

      if (!filteredFiles.length) {
        return;
      }

      filteredFiles.forEach((file) => {
        if (file.fileSize > this.maxFileSize) {
          this.$showServerError({
            message: `Max file size: ${fromBytesToMb(this.maxFileSize)}Mb`,
          });
          file.isBig = true;
        } else {
          file.isBig = false;
        }
      });

      filteredFiles = filteredFiles.filter(({ isBig }) => !isBig);

      /* eslint consistent-return: "off" */
      return filteredFiles;
    },
    getChildPayload(itemId) {
      return this.data.files[itemId];
    },
    triggerDeleteSectionModal() {
      this.deleteSectionModal = true;
    },
    handleDrop(id, event) {
      const sectionData = {
        sectionId: id,
        files: applyDrag(this.data.files, event),
      };

      this.updateSectionFiles({
        sectionId: sectionData.sectionId,
        fileData: sectionData.files,
      });
    },
    handleSelectFile({ data, fileId, sectionId }) {
      this.selectSectionFile({ fileId, sectionId });
      this.$emit('select-file', {
        ...data,
        sectionId,
      });
    },
    handleDeleteFile(data) {
      this.$emit('delete-file', data);
      this.deleteSectionFile(data);
      this.updateSingleSectionFileOrder();
    },
    handleDeleteSection(id) {
      this.$emit('delete-section', {
        sectionId: id,
      });
      this.deleteSection(id);
    },
    async handleFileUpload({ fileData, sectionId }) {
      const parsedFiles = this.filterFilesBySize(fileData);

      if (!parsedFiles.length) {
        return;
      }

      try {
        await this.loadFilePages({
          parsedFiles,
          sectionId,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
  },
};
</script>
