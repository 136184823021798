<template>
  <ui-modal v-bind="$attrs" v-on="$listeners" size="xl">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      {{ $t('reviews.complimentsTitle') }}
    </template>

    <div class="container">
      <div class="row justify-content-between">
        <div
          v-for="item in reviews"
          :key="item.id"
          class="col-12 col-md-5 px-0"
        >
          <div class="d-flex justify-content-between align-items-center mt-4">
            <review-icon
              :name="item.name"
              :location-type="locationType"
              size="32px"
            />
            <div class="card-label ml-4 mr-auto">
              {{ $t(item.name, locationCountry) }}
            </div>
            <div class="card-value">
              {{ item.stats }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import ReviewIcon from '@/components/ReviewIcon.vue';

export default {

  components: {
    ReviewIcon,
  },

  props: {
    reviews: {
      type: Array,
      required: true,
    },
    locationType: {
      type: String,
      default: 'restaurant',
    },
    locationCountry: String,
  },
};
</script>
