<template>
  <div
    class="statistics-table-row__cell statistics-table-row__cell_center"
  >
    <span v-if="showNumber">{{ value }}</span>
    <template v-else>
      <ui-icon
        v-if="value"
        name="check"
        size="14px"
      />
      <ui-icon
        v-else
        name="dash"
        size="8px"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'StatisticsTableValueCell',

  props: {
    showNumber: Boolean,
    value: {
      type: [Number, Boolean],
      required: true,
    },
  },
};
</script>
