<template>
  <ui-modal-alert
    class="staff-payouts-confirm"
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('staffPayouts.confirmation.title')"
    :apply-button="$t('form.buttons.send')"
    :close-button="$t('form.buttons.cancel')"
  >
    {{ $t('staffPayouts.confirmation.text[0]') }}
    <b>{{ amount || 0 | money(currency) }}</b>
    <br/>
    {{ $t('staffPayouts.confirmation.text[1]') }}
    {{ items.length }}
    {{
      items.length > 1
        ? $t('staffPayouts.confirmation.text[2]')
        : $t('staffPayouts.confirmation.text[3]')
    }}.
  </ui-modal-alert>
</template>

<script>
export default {

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
