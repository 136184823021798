<template>
  <div class="position-relative">
    <div class="row">
      <div class="col-12 col-lg">
        <div class="app-title app-title_secondary">
          Ranking
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-sm-12">
        <ui-select
          v-model="filter.period"
          :items="periodPresets"
          label-key="name"
          class="select-width mt-0"
          value-key="value"
          @change="updateFilter"
          bordered
        />
      </div>
    </div>

    <ui-card v-if="isLoading" is-loading class="py-5" />
    <ui-data-table
      v-show="!isLoading"
      :columns="columns"
      :data="items"
      data-table="sales"
      @sort="setSort"
      separatable
    >
      <template #sales-ranking="{ index }">
        {{ index + 1 }}
      </template>

      <template #sales-gmv-per-clients-value="{ value, row }">
        {{ value | money(row.avgGMV.currency) }}
      </template>

      <template #sales-gmv-total-value="{ value, row }">
        {{ value | money(row.totalGMV.currency) }}
      </template>
    </ui-data-table>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { pickBy, isEqual } from 'lodash';
import getFio from '@/utils/fio';
import api from '@/api';

export default {

  props: {
    staffId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    filter: {
      period: null,
      sortByField: null,
      sortDirection: null,
    },
    items: [],
    isLoading: false,
  }),

  computed: {
    columns() {
      return [
        {
          name: 'sales-ranking',
          label: 'Ranking',
          align: 'left',
        },
        {
          name: 'sales-staff',
          label: 'Name',
          prop: 'user',
          value: ({ row }) => getFio(row.user),
          align: 'left',
        },
        {
          name: 'sales-clients',
          label: 'New clients',
          prop: 'cntNewClients',
          sorting: true,
          empty: 'No clients',
          align: 'left',
        },
        {
          name: 'sales-gmv-per-clients',
          label: 'GMV per clients',
          prop: 'avgGMV',
          value: ({ row }) => row.avgGMV?.amount,
          empty: 'No value',
          sorting: true,
          align: 'left',
        },
        {
          name: 'sales-gmv-total',
          label: 'Total GMV',
          prop: 'totalGMV',
          value: ({ row }) => row.totalGMV?.amount,
          empty: 'No value',
          sorting: true,
          align: 'left',
        },
      ];
    },
    dateFrom() {
      let dateFrom = DateTime.local();

      switch (this.filter.period) {
        case '7DAYS':
          dateFrom = dateFrom.minus({ days: 7 });
          break;
        case '30DAYS':
          dateFrom = dateFrom.minus({ days: 30 });
          break;
        case '3MONTHS':
          dateFrom = dateFrom.minus({ months: 3 });
          break;
        case '1YEAR':
          dateFrom = dateFrom.minus({ years: 1 });
          break;
        default:
          dateFrom = dateFrom.minus({ months: 1 });
          break;
      }

      return dateFrom.toJSDate();
    },
    dateTo() {
      return DateTime.local().toJSDate();
    },
    periodPresets() {
      return [
        {
          value: '7DAYS',
          name: this.$t('transactionGraph.datePresets.7DAYS'),
        },
        {
          value: '30DAYS',
          name: this.$t('transactionGraph.datePresets.30DAYS'),
        },
        {
          value: '3MONTHS',
          name: this.$t('transactionGraph.datePresets.3MONTHS'),
        },
        {
          value: '1YEAR',
          name: this.$t('transactionGraph.datePresets.1YEAR'),
        },
      ];
    },
    apiConfig() {
      return {
        salesId: this.staffId || undefined,
        dateFrom: this.dateFrom || undefined,
        dateTo: this.dateTo || undefined,
        sortByField: this.filter.sortByField || undefined,
        sortDirection: this.filter.sortDirection || undefined,
      };
    },
    urlConfig() {
      return {
        ...this.$route.query,
        ranking: this.filter,
      };
    },
  },

  watch: {
    '$route.query': {
      immediate: true,
      handler({ ranking }) {
        ranking = {
          period: '30DAYS',
          sortByField: 'NEW_CLIENTS',
          sortDirection: 'DESC',
          ...pickBy(ranking),
        };

        if (!isEqual(ranking, this.filter)) {
          this.filter = {
            ...this.filter,
            ...ranking,
          };

          this.loadSales();
        }
      },
    },
  },

  methods: {
    updateFilter() {
      this.$router.replace({
        query: this.urlConfig,
      }, () => {});

      this.loadSales();
    },
    setSort({ sortBy, sortDirection }) {
      if (sortBy === 'cntNewClients') {
        sortBy = 'NEW_CLIENTS';
      }

      if (sortBy === 'avgGMV') {
        sortBy = 'GMV_AVG';
      }

      if (sortBy === 'totalGMV') {
        sortBy = 'GMV_TOTAL';
      }

      this.filter.sortByField = sortBy.toUpperCase();
      this.filter.sortDirection = sortDirection;

      this.updateFilter();
    },
    async loadSales() {
      this.isLoading = true;

      try {
        const { data } = await api.locations.getStaffSales(this.apiConfig);

        if (data) {
          this.items = data;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
