<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-card tag="form">
      <template #header>
        <h4 class="card-title">{{ $t('phoneNumber.title') }}</h4>
      </template>

      <div class="row mt-2">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <validation-provider
            tag="div"
            :name="$t('form.labels.phoneNumber')"
            :rules="{
              required: {
                invalid: phoneInvalid,
              },
            }"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-phone
              v-model="phone"
              v-protected
              :label="$t('form.labels.phoneNumber')"
              required
              :is-loading="!isLoaded"
              :disabled="isConfirmation"
              :error="errors[0]"
              :country-code="countryCode"
              @validate="phoneInvalid = $event"
            />
          </validation-provider>
        </div>
      </div>

      <div class="row" v-if="isConfirmation">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <validation-provider
            tag="div"
            :name="$t('form.labels.code')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="formConfirmation.confirmation"
              :label="$t('form.placeholders.code')"
              required
              :disabled="loading.confirmation"
              :error="errors[0]"
            />
          </validation-provider>
        </div>
      </div>

      <div v-if="isConfirmation" class="row mt-2">
        <div class="col-12 col-md-auto mt-2">
          <ui-button
            type="primary"
            :is-loading="loading.send"
            :disabled="someLoading"
            @click.prevent="handleSubmit(confirm)"
          >
            {{ $t('form.buttons.update') }}
          </ui-button>
        </div>
        <div class="col-12 col-md-auto mt-2">
          <ui-button
            native-type="button"
            :disabled="someLoading"
            @click="cancel"
          >
            {{ $t('form.buttons.cancel') }}
          </ui-button>
        </div>
        <div class="col-12 col-md-auto mt-2">
          <ui-button
            v-if="formConfirmation.token"
            native-type="button"
            :is-loading="loading.resend"
            :disabled="someLoading"
            @click="resend"
          >
            {{ $t('form.buttons.resendCode') }}
          </ui-button>
        </div>
      </div>

      <ui-button
        v-else
        v-protected
        action
        type="primary"
        :is-loading="loading.send"
        :disabled="isDisabled"
        class="mt-4"
        @click.prevent="handleSubmit(send)"
      >
        {{ $t('form.buttons.update') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import resetMixin from '@/mixins/reset-mixin';

export default {

  mixins: [
    resetMixin(() => ({
      loading: {
        send: false,
        confirmation: false,
        resend: false,
      },
      isLoaded: false,
      isConfirmation: false,
      formConfirmation: {
        token: '',
        confirmation: '',
      },
      phoneInvalid: false,
    })),
  ],

  data: () => ({
    phone: null,
  }),

  computed: {
    ...mapState('user', [
      'profile',
      'location',
    ]),
    countryCode() {
      return this.location.country.toUpperCase();
    },
    someLoading() {
      return Object.values(this.loading).some((v) => v);
    },
    isDirty() {
      return this.phone !== this.profile.phoneNumber;
    },
    isDisabled() {
      return this.someLoading;
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    ...mapActions('user', [
      'updatePhone',
      'confirmPhone',
      'getPhoneToken',
    ]),
    loadData() {
      this.phone = this.profile.phoneNumber || '';
      this.isLoaded = true;
    },
    async send() {
      if (!this.isDirty || this.isDisabled) {
        return;
      }

      this.loading.send = true;

      try {
        const { token } = await this.updatePhone({
          phone: this.phone,
        });

        this.formConfirmation.token = token;
        this.isConfirmation = true;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.send = false;
      }
    },
    async confirm() {
      if (this.loading.confirmation) {
        return;
      }

      this.loading.confirmation = true;

      try {
        await this.confirmPhone(this.formConfirmation);

        this.profile.phoneNumber = this.phone;
        this.isConfirmation = false;
        this.loadData();
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.confirmation = false;
      }
    },
    async resend() {
      if (this.loading.resend) {
        return;
      }

      if (!(this.isConfirmation && this.formConfirmation.token)) {
        return;
      }

      this.loading.resend = true;

      try {
        const { token } = await this.getPhoneToken(this.formConfirmation.token);

        this.formConfirmation.token = token;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.resend = false;
      }
    },
    cancel() {
      this.reset();
      this.loadData();
    },
  },
};
</script>
