var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-card',{attrs:{"tag":"form","is-loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row"},[_c('h4',{staticClass:"col-12 col-lg card-title"},[_vm._v(" "+_vm._s(_vm.$t('promotions.list.title'))+" ")]),_c('div',{staticClass:"col-12 col-lg-auto ml-auto d-flex align-items-end mt-xl-0 mt-lg-0 mt-3"},[_c('ui-checkbox',{staticClass:"mb-4",attrs:{"inline":""},on:{"input":_vm.search},model:{value:(_vm.filter.includeNonActive),callback:function ($$v) {_vm.$set(_vm.filter, "includeNonActive", $$v)},expression:"filter.includeNonActive"}},[_vm._v(" "+_vm._s(_vm.$t('promotions.list.activeFilterTitle'))+" ")])],1)])]},proxy:true}])},[_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-auto mt-1"},[_c('ui-input',{staticClass:"my-0",attrs:{"placeholder":_vm.$t('form.labels.locationName'),"bordered":"","search":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.search.apply(null, arguments)}},model:{value:(_vm.filter.workplaceName),callback:function ($$v) {_vm.$set(_vm.filter, "workplaceName", $$v)},expression:"filter.workplaceName"}})],1),_c('div',{staticClass:"col-12 col-md-auto mt-1 my-0"},[_c('ui-select',{staticClass:"my-0",attrs:{"items":_vm.countries,"placeholder":"Location country","label-key":"name","value-key":"iso","bordered":"","large":""},on:{"change":_vm.search},model:{value:(_vm.filter.countryISOCode),callback:function ($$v) {_vm.$set(_vm.filter, "countryISOCode", $$v)},expression:"filter.countryISOCode"}})],1)]),_c('ui-data-table',{attrs:{"columns":_vm.columns,"data":_vm.items,"row-class":_vm.getRowClass,"data-table":"promotion"},on:{"sort":_vm.setSort},scopedSlots:_vm._u([{key:"promotion-location-name",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-primary word-break-word",attrs:{"to":{
          name: 'location-view',
          params: {
            locationId: row.workplace.id,
          },
        }}},[_vm._v(" "+_vm._s(row.workplace.name)+" ")])]}},{key:"promotion-location-address",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"word-break-word"},[_vm._v(" "+_vm._s(_vm.getFullAddress(row.workplace))+" ")])]}},{key:"promotion-name",fn:function(ref){
        var row = ref.row;
return [(row.name)?_c('router-link',{staticClass:"text-primary word-break-word",attrs:{"to":{
          name: 'promotion-view',
          params: {
            promotionId: row.id,
          },
        }}},[_vm._v(" "+_vm._s(row.name)+" ")]):_vm._e()]}},{key:"promotion-bonus",fn:function(ref){
        var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value)+"% ")]):_vm._e()]}},{key:"promotion-period",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex flex-column"},[(row.dateFrom)?_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(row.dateFrom)))]),_c('ui-tooltip',{attrs:{"placement":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('promotions.list.promoPeriod.dateFrom'))}})]},proxy:true}],null,true)},[_c('i',{staticClass:"nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"})])],1):_vm._e(),(row.dateTo)?_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(row.dateTo)))]),_c('ui-tooltip',{attrs:{"placement":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('promotions.list.promoPeriod.dateTo'))}})]},proxy:true}],null,true)},[_c('i',{staticClass:"nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"})])],1):_c('div',[_c('span',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.$t('table.empty.promoPeriod')))])])])]}}])}),(_vm.total > _vm.pageSize)?_c('ui-pagination',{staticClass:"mt-4",attrs:{"total":_vm.total,"per-page":_vm.pageSize,"show-pages":7},on:{"input":_vm.update},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }