<template>
  <div>
    <ui-select
      v-model="form.typeOfReview"
      :items="reviewTypes"
      label="Type of review"
      :placeholder="$t('form.placeholders.select')"
      label-key="label"
      value-key="value"
    />

    <template v-if="showLinks">
      <validation-provider
        :name="$t(`form.labels.googleMapsLink`)"
        :rules="{ required: someRequired }"
        :custom-messages="{ required: 'Someone of links is required' }"
        mode="passive"
        tag="div"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="googleMapsLink"
          :label="$t(`form.labels.googleMapsLink`)"
          :error="errors[0]"
        />
      </validation-provider>

      <validation-provider
        :name="$t(`form.labels.tripAdvisorLink`)"
        :rules="{ required: someRequired, url: true }"
        :custom-messages="{ required: 'Someone of links is required' }"
        mode="passive"
        tag="div"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="form.tripAdvisorLink"
          :label="$t(`form.labels.tripAdvisorLink`)"
          :error="errors[0]"
        />
      </validation-provider>

      <validation-provider
        :name="$t(`form.labels.trustPilotLink`)"
        :rules="{ required: someRequired, url: true }"
        :custom-messages="{ required: 'Someone of links is required' }"
        mode="passive"
        tag="div"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="form.trustPilotLink"
          :label="$t(`form.labels.trustPilotLink`)"
          :error="errors[0]"
        />
      </validation-provider>
    </template>
  </div>
</template>

<script>
import reviewTypes from '@/config/reviewTypes';

const REVIEWS_LINKS = [
  'googleMapsLink',
  'tripAdvisorLink',
  'trustPilotLink',
];
const REVIEWS_TYPES_SHOW_LINKS = [
  'PUBLIC_REVIEWS_ONLY',
  'STANDARD_AND_PUBLIC_REVIEWS_ONLY',
  'SOCIAL_MEDIA_PUBLIC_REVIEWS_ONLY',
];
const REVIEWS_GOOGLE_LINK = 'https://search.google.com/local/writereview?placeid=';

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    reviewTypes,
  }),

  computed: {
    showLinks() {
      return REVIEWS_TYPES_SHOW_LINKS.includes(this.form.typeOfReview);
    },
    someRequired() {
      return !REVIEWS_LINKS.some((attribute) => this.form[attribute]);
    },
    googleMapsLink: {
      get() {
        if (this.form.googleMapsLink) {
          return this.form.googleMapsLink.replace(REVIEWS_GOOGLE_LINK, '');
        }

        return this.form.googleMapsLink;
      },
      set(value) {
        if (!value) {
          this.form.googleMapsLink = null;
        } else if (value.includes(REVIEWS_GOOGLE_LINK)) {
          this.form.googleMapsLink = value;
        } else {
          this.form.googleMapsLink = `${REVIEWS_GOOGLE_LINK}${value}`;
        }
      },
    },
  },

  watch: {
    showLinks(value) {
      if (!value) {
        REVIEWS_LINKS.forEach((attribute) => {
          this.form[attribute] = null;
        });
      }
    },
  },
};
</script>
