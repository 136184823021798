var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ui-card',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('phoneNumber.title')))])]},proxy:true}],null,true)},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.phoneNumber'),"rules":{
            required: {
              invalid: _vm.phoneInvalid,
            },
          },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-phone',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.phoneNumber'),"required":"","is-loading":!_vm.isLoaded,"disabled":_vm.isConfirmation,"error":errors[0],"country-code":_vm.countryCode},on:{"validate":function($event){_vm.phoneInvalid = $event}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1)]),(_vm.isConfirmation)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.code'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.placeholders.code'),"required":"","disabled":_vm.loading.confirmation,"error":errors[0]},model:{value:(_vm.formConfirmation.confirmation),callback:function ($$v) {_vm.$set(_vm.formConfirmation, "confirmation", $$v)},expression:"formConfirmation.confirmation"}})]}}],null,true)})],1)]):_vm._e(),(_vm.isConfirmation)?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-12 col-md-auto mt-2"},[_c('ui-button',{attrs:{"type":"primary","is-loading":_vm.loading.send,"disabled":_vm.someLoading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.confirm)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.update'))+" ")])],1),_c('div',{staticClass:"col-12 col-md-auto mt-2"},[_c('ui-button',{attrs:{"native-type":"button","disabled":_vm.someLoading},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.cancel'))+" ")])],1),_c('div',{staticClass:"col-12 col-md-auto mt-2"},[(_vm.formConfirmation.token)?_c('ui-button',{attrs:{"native-type":"button","is-loading":_vm.loading.resend,"disabled":_vm.someLoading},on:{"click":_vm.resend}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.resendCode'))+" ")]):_vm._e()],1)]):_c('ui-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"mt-4",attrs:{"action":"","type":"primary","is-loading":_vm.loading.send,"disabled":_vm.isDisabled},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.update'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }