<template>
  <div>
    <location-form
      id="location-form"
      :key="formData ? formData.id : 'new'"
      :form-data="formData"
      :form-loading="formLoading"
      show-cancel
      @completed="onCompleted"
      @cancel="onCancel"
    />
    <location-list
      v-bind="$attrs"
      :reload.sync="reload"
      :selected="formData"
      @select="onSelect"
      @update="onUpdate"
    />
  </div>
</template>

<script>
import api from '@/api';
import LocationForm from '@/components/Location/LocationForm.vue';
import LocationList from '@/components/Location/LocationList.vue';

export default {

  scrollToTop: false,

  components: {
    LocationForm,
    LocationList,
  },

  data: () => ({
    reload: false,
    formData: null,
    formLoading: false,
  }),

  methods: {
    onCompleted({ id }) {
      this.$router.push(
        {
          name: 'location-view',
          params: {
            locationId: id,
          },
        },
      );
    },
    onCancel() {
      this.formData = null;
    },
    async onSelect({ id }) {
      this.formLoading = true;

      try {
        const { data } = await api.locations.getLocation({
          locationId: id,
        });

        if (data) {
          this.formData = data;

          // Double $nextTick - yes, its right.
          this.$nextTick(() => {
            this.$scroll('#location-form');
          });
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.formLoading = false;
      }
    },
    onUpdate(route) {
      this.$router.push({
        ...route,
      }, () => {});
    },
  },
};
</script>
