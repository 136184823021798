<template>
  <ui-stats-card
    v-bind="$attrs"
    :title="$t('reporting.bonusTitle')"
    :value="value || 0 | money(currency)"
    :is-loading="isLoading"
    icon="bonus-lg"
  >
    <template #link>
      <stats-bonus-tips-modal
        :location="location"
        class="ml-auto"
        :class="{
          'mr-4': to,
        }"
      >
        <template #activator="{ open }">
          <ui-button
            link
            type="primary"
            class="d-flex justify-content-center"
            @click="open"
          >
            {{ $t('form.buttons.learnMore') }}
          </ui-button>
        </template>
      </stats-bonus-tips-modal>

      <router-link
        v-if="to"
        :to="to"
      >
        <ui-icon-button
          bg-color="danger"
          name="arrow-right"
          class="d-flex justify-content-center align-items-center text-primary"
          size="14"
        />
      </router-link>
    </template>
  </ui-stats-card>
</template>

<script>
import api from '@/api';
import StatsBonusTipsModal from './StatsBonusTipsModal.vue';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    StatsBonusTipsModal,
  },

  props: {
    to: {
      type: [Object, String],
      description: 'Card link',
    },
    groupId: [Number, String],
    currency: String,
    location: Object,
  },

  data: () => ({
    value: null,
  }),

  computed: {
    apiMethod() {
      return this.staffId ? api.staff.getBonus : api.locations.getBonus;
    },
    apiConfig() {
      return {
        locationId: this.locationId,
        staffId: this.staffId || undefined,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
  },

  methods: {
    async load() {
      const { data } = await this.apiMethod(this.apiConfig);

      if (data) {
        this.value = data.amount;
      }
    },
  },
};
</script>
