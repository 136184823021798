var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('ui-card',{attrs:{"tag":"form"}},[_c('div',{staticClass:"form-group mt-0 mb-4"},[_c('ui-avatar',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"image":_vm.image,"is-loading":_vm.loading.avatar,"title":_vm.$t('UI.avatar.managerTitle')},on:{"save":_vm.uploadAvatar}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.firstName'),"rules":"required|min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.firstName'),"maxlength":"30","minlength":"2","capitalized":"","required":"","error":errors[0]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.lastName'),"rules":"required|min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.lastName'),"maxlength":"30","minlength":"2","capitalized":"","required":"","error":errors[0]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),(_vm.isOwner)?[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.staffType'),"rules":{ required: !_vm.staffTypeDisabled },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.filteredStaffTypes,"label":_vm.$t('form.labels.staffType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"value","required":!_vm.staffTypeDisabled,"error":errors[0],"disabled":_vm.staffTypeDisabled},on:{"input":_vm.onStaffTypeChanged},model:{value:(_vm.form.staffType),callback:function ($$v) {_vm.$set(_vm.form, "staffType", $$v)},expression:"form.staffType"}})]}}],null,true)})]:_vm._e(),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.email'),"rules":"required|email","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.email'),"required":"","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","rules":_vm.dateOfBirthRequired ? 'required' : '',"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-birth',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"required":_vm.dateOfBirthRequired,"input-classes":"w-auto","error":errors[0]},model:{value:(_vm.form.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "dateOfBirth", $$v)},expression:"form.dateOfBirth"}})]}}],null,true)}),(_vm.isOwner)?[(_vm.showNickName)?_c('validation-provider',{attrs:{"tag":"div","name":"Nickname (for tipping page)","rules":"min:2|max:30","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":"Nickname (for tipping page)","maxlength":"30","minlength":"2","error":errors[0]},model:{value:(_vm.form.nickName),callback:function ($$v) {_vm.$set(_vm.form, "nickName", $$v)},expression:"form.nickName"}})]}}],null,true)}):_vm._e(),(_vm.showTaxReportingTypes)?_c('validation-provider',{attrs:{"tag":"div","name":"Tax reporting","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.MAIN_TAX_REPORTING_TYPES,"label-key":"label","value-key":"value","label":"Tax reporting","placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disableTaxReportingType(_vm.location.taxReportingType),"required":"","error":errors[0]},model:{value:(_vm.form.taxReportingType),callback:function ($$v) {_vm.$set(_vm.form, "taxReportingType", $$v)},expression:"form.taxReportingType"}})]}}],null,true)}):_vm._e(),(_vm.showGroups)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.group'),"rules":{ required: !_vm.groupDisabled },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.groups,"label":_vm.$t('form.labels.group'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"id","required":!_vm.groupDisabled,"disabled":_vm.groupDisabled,"error":errors[0]},model:{value:(_vm.form.groupId),callback:function ($$v) {_vm.$set(_vm.form, "groupId", $$v)},expression:"form.groupId"}})]}}],null,true)}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('ui-input',{attrs:{"label":_vm.$t('form.labels.locationName'),"disabled":""},model:{value:(_vm.location.name),callback:function ($$v) {_vm.$set(_vm.location, "name", $$v)},expression:"location.name"}}),_c('ui-input',{attrs:{"value":_vm.location.groupName || 'No group',"label":_vm.$t('form.labels.groupName'),"disabled":""}}),_c('ui-input',{attrs:{"label":_vm.$t('form.labels.country'),"disabled":""},model:{value:(_vm.countryName),callback:function ($$v) {_vm.countryName=$$v},expression:"countryName"}}),_c('ui-input',{attrs:{"label":_vm.$t('form.labels.city'),"disabled":""},model:{value:(_vm.location.city),callback:function ($$v) {_vm.$set(_vm.location, "city", $$v)},expression:"location.city"}}),_c('ui-input',{attrs:{"label":_vm.$t('form.labels.address'),"disabled":""},model:{value:(_vm.location.address),callback:function ($$v) {_vm.$set(_vm.location, "address", $$v)},expression:"location.address"}})],1)]),_c('ui-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"mt-4",attrs:{"action":"","type":"primary","is-loading":_vm.loading.form,"disabled":_vm.loading.form},on:{"click":function($event){$event.preventDefault();return _vm.send(validate)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.update'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }