<template>
  <ui-card tag="form" :is-loading="loading">
    <template #header>
      <div class="row">
        <h4 class="col-12 col-lg card-title">
          {{ $t('promotions.list.title') }}
        </h4>

        <div class="col-12 col-lg-auto ml-auto d-flex align-items-end mt-xl-0 mt-lg-0 mt-3">
          <ui-checkbox
            v-model="filter.includeNonActive"
            class="mb-4"
            inline
            @input="search"
          >
            {{ $t('promotions.list.activeFilterTitle') }}
          </ui-checkbox>
        </div>
      </div>
    </template>

    <div class="row mb-4">
      <div class="col-12 col-md-auto mt-1">
        <ui-input
          v-model="filter.workplaceName"
          :placeholder="$t('form.labels.locationName')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="search"
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.countryISOCode"
          :items="countries"
          placeholder="Location country"
          class="my-0"
          label-key="name"
          value-key="iso"
          @change="search"
          bordered
          large
        />
      </div>
    </div>

    <ui-data-table
      :columns="columns"
      :data="items"
      :row-class="getRowClass"
      data-table="promotion"
      @sort="setSort"
    >
      <template #promotion-location-name="{ row }">
        <router-link
          class="text-primary word-break-word"
          :to="{
            name: 'location-view',
            params: {
              locationId: row.workplace.id,
            },
          }"
        >
          {{ row.workplace.name }}
        </router-link>
      </template>

      <template #promotion-location-address="{ row }">
        <div class="word-break-word">
          {{ getFullAddress(row.workplace) }}
        </div>
      </template>

      <template #promotion-name="{ row }">
        <router-link
          v-if="row.name"
          class="text-primary word-break-word"
          :to="{
            name: 'promotion-view',
            params: {
              promotionId: row.id,
            },
          }"
        >
          {{ row.name }}
        </router-link>
      </template>

      <template #promotion-bonus="{ value }">
        <span v-if="value">
          {{ value }}%
        </span>
      </template>

      <template #promotion-period="{ row }">
        <div class="d-flex flex-column">
          <div v-if="row.dateFrom" class="d-flex align-items-center">
            <span>{{ row.dateFrom | date }}</span>

            <ui-tooltip placement="right">
              <template #content>
                <span v-html="$t('promotions.list.promoPeriod.dateFrom')" />
              </template>
              <i class="nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"></i>
            </ui-tooltip>
          </div>
          <div v-if="row.dateTo" class="d-flex align-items-center">
            <span>{{ row.dateTo | date }}</span>

            <ui-tooltip placement="right">
              <template #content>
                <span v-html="$t('promotions.list.promoPeriod.dateTo')" />
              </template>
              <i class="nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"></i>
            </ui-tooltip>
          </div>
          <div v-else>
            <span class="text-gray">{{ $t('table.empty.promoPeriod') }}</span>
          </div>
        </div>
      </template>
    </ui-data-table>

    <ui-pagination
      v-if="total > pageSize"
      v-model="page"
      :total="total"
      :per-page="pageSize"
      :show-pages="7"
      class="mt-4"
      @input="update"
    />
  </ui-card>
</template>

<script>
import api from '@/api';
import getCountry from '@/utils/getCountry';

export default {

  props: {
    reload: Boolean,
  },

  data() {
    return {
      loading: false,
      filter: {
        countryISOCode: undefined,
        workplaceName: undefined,
        sortByField: undefined,
        sortDirection: undefined,
        includeNonActive: false,
      },
      items: [],
      page: 1,
      total: 0,
      pageSize: 10,
      columns: [
        {
          name: 'promotion-location-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          value: ({ row }) => row.workplace.payoutId,
          empty: this.$t('table.empty.easytipId'),
          align: 'center',
          sorting: true,
        },
        {
          name: 'promotion-location-name',
          label: this.$t('table.columns.locationName'),
          prop: 'workplace.name',
          align: 'center',
          sorting: true,
        },
        {
          name: 'promotion-location-address',
          label: this.$t('table.columns.address'),
          prop: 'address',
          align: 'center',
          empty: this.$t('table.empty.address'),
          sorting: true,
        },
        {
          name: 'promotion-name',
          label: this.$t('table.columns.promoName'),
          prop: 'name',
          align: 'center',
          empty: 'No name',
          sorting: true,
        },
        {
          name: 'promotion-period',
          label: 'Promo period',
          align: 'center',
          empty: 'No period',
          sorting: true,
        },
        {
          name: 'promotion-bonus',
          label: 'Bonus',
          prop: 'bonusPercentage',
          align: 'center',
          empty: 'No bonus',
          sorting: true,
        },
        {
          name: 'promotion-amount',
          label: 'Total amount',
          prop: 'totalAmount',
          align: 'center',
          empty: 'No amount',
          sorting: true,
        },
      ],
    };
  },

  computed: {
    start() {
      return (this.page - 1) * this.pageSize;
    },
    urlConfig() {
      return {
        page: this.page,
        ...this.filter,
      };
    },
    apiConfig() {
      return {
        start: this.start,
        pageSize: this.pageSize,
        ...this.filter,
      };
    },
    countries() {
      return this.$getCountries({ empty: true });
    },
  },

  watch: {
    reload: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.loadItems();

          this.$emit('update:reload', false);
        }
      },
    },
    '$route.query': {
      immediate: true,
      handler({ page, ...params }) {
        this.page = parseInt(page || 1, 10);
        this.filter = {
          ...this.filter,
          ...params,
        };

        this.loadItems();
      },
    },
  },

  methods: {
    setSort({ sortBy, sortDirection }) {
      this.filter.sortByField = sortBy;
      this.filter.sortDirection = sortDirection;
      this.search();
    },
    getRowClass({ row }) {
      if (row.status !== 'ACTIVE') {
        return 'opacity-60';
      }

      return null;
    },
    getFullAddress({ country, city, address }) {
      const countryName = getCountry(country, null)?.name;

      return [
        countryName,
        city,
        address,
      ].filter((v) => v).join(', ');
    },
    setItems(items) {
      this.items.push(
        ...items.map((item) => ({
          ...item,
        })),
      );
    },
    async loadItems() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.items.splice(0);
      this.update();

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.promotions.getPromotions(this.apiConfig);

        if (data) {
          this.total = totalRecords;
          this.setItems(data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    search() {
      this.page = 1;
      this.loadItems();
    },
    update() {
      this.$emit('update', {
        query: this.urlConfig,
      });
    },
  },

  mounted() {
    this.loadItems();
  },
};
</script>
