<template>
  <div v-if="isLoading || statsAccounts.length">
    <ui-card
      :is-loading="isLoading"
      class="overview-card h-100"
    >
      <div class="card-header overview-card__header mb-4 pb-2">
        <ui-icon name="incomplete-accountant-registrations" size="32px" />
        <h4 class="card-title overview-card__title">
          Restricted soon accounts
        </h4>
      </div>
      <div
        v-for="item in previewAccounts"
        :key="item.id"
        class="d-flex justify-content-between mt-3"
      >
        <router-link
          :to="{ name: 'location-view', params: { locationId: item.id }}"
          class="card-label overview-card__label"
        >
          {{ item.fio }}
        </router-link>
      </div>

      <template #footer>
        <stats-restricted-soon-accounts-modal
          v-if="statsAccounts.length > previewCount"
          :accounts="statsAccounts"
        >
          <template #activator="{ open }">
            <ui-button
              link
              type="primary"
              @click="open"
            >
              {{ $t('form.buttons.viewAll') }}
            </ui-button>
          </template>
        </stats-restricted-soon-accounts-modal>
      </template>
    </ui-card>
  </div>
</template>

<script>
import api from '@/api';
import getFio from '@/utils/fio';
import StatsRestrictedSoonAccountsModal from '@/components/Stats/StatsRestrictedSoonAccountsModal.vue';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    StatsRestrictedSoonAccountsModal,
  },

  data: () => ({
    items: [],
    previewCount: 5,
  }),

  computed: {
    statsAccounts() {
      return this.items.map(({ workplace, ...item }) => ({
        ...workplace,
        fio: getFio(item),
      }));
    },
    previewAccounts() {
      return this.statsAccounts.slice(0, this.previewCount);
    },
  },

  methods: {
    async load() {
      const { data } = await api.locations.getRestrictedSoonAccounts({
        salesId: this.staffId,
      });

      if (data) {
        this.items = data;
      }
    },
  },
};
</script>
