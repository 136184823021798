var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ui-card',{attrs:{"tag":"form"}},[_c('div',{staticClass:"form-group mt-0 mb-4"},[_c('ui-avatar',{attrs:{"image":_vm.image,"title":_vm.$t('UI.avatar.userTitle')},on:{"save":_vm.uploadImage}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.firstName'),"rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.firstName'),"maxlength":"30","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.lastName'),"rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.lastName'),"maxlength":"30","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),(_vm.isSalesPerson)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.country'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('form.labels.country'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"iso","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})]}}],null,true)}):_vm._e()],1)]),_c('ui-button',{staticClass:"mt-4",attrs:{"action":"","type":"primary","is-loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.update'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }