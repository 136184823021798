export default [
  {
    label: 'No reviews',
    value: 'DISABLE_REVIEWS',
  },
  {
    label: 'Easytip reviews',
    value: 'STANDARD',
  },
  {
    label: 'Easytip reviews + Instagram',
    value: 'SOCIAL_MEDIA',
  },
  {
    label: 'Easytip stars + Public reviews',
    value: 'STANDARD_AND_PUBLIC_REVIEWS_ONLY',
  },
  {
    label: 'Public reviews only',
    value: 'PUBLIC_REVIEWS_ONLY',
  },
];
