var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ui-card',{attrs:{"tag":"form","is-loading":_vm.loading.data || _vm.isLoading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('staffMembers.registerTitle'))+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"row"},[(_vm.showGroups)?_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.group'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.groups,"label":_vm.$t('form.labels.group'),"placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disableRegistration,"label-key":"name","value-key":"id","required":"","error":errors[0]},model:{value:(_vm.form.workplaceGroup),callback:function ($$v) {_vm.$set(_vm.form, "workplaceGroup", $$v)},expression:"form.workplaceGroup"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.phoneNumber'),"rules":{
            required: {
              invalid: _vm.phoneInvalid,
            },
          },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-phone',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.staffPhone'),"disabled":_vm.disableRegistration,"error":_vm.phoneError || errors[0],"country-code":_vm.countryCode,"required":""},on:{"validate":function($event){_vm.phoneInvalid = $event}},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.id'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.staffSlots,"label":_vm.$t('form.labels.id'),"placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disableRegistration,"required":"","error":errors[0]},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})]}}],null,true)})],1),(_vm.showTaxReportingTypes)?_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":"Tax reporting","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.MAIN_TAX_REPORTING_TYPES,"label-key":"label","value-key":"value","label":"Tax reporting","placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disableRegistration || _vm.disableTaxReportingType(_vm.location.taxReportingType),"required":"","error":errors[0]},model:{value:(_vm.form.taxReportingType),callback:function ($$v) {_vm.$set(_vm.form, "taxReportingType", $$v)},expression:"form.taxReportingType"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.staffType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-select',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"items":_vm.staffTypes,"label":_vm.$t('form.labels.staffType'),"placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disableRegistration,"required":"","error":errors[0]},model:{value:(_vm.form.staffType),callback:function ($$v) {_vm.$set(_vm.form, "staffType", $$v)},expression:"form.staffType"}})]}}],null,true)})],1),(_vm.isCommon)?_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.staffStartDate'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-calendar-picker',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.staffStartDate'),"calendar-info":_vm.calendarInfo,"disabled":_vm.disableRegistration,"input-icon":"calendar","no-direction":"","required":"","error":errors[0]},model:{value:(_vm.form.startWorkDate),callback:function ($$v) {_vm.$set(_vm.form, "startWorkDate", $$v)},expression:"form.startWorkDate"}})]}}],null,true)})],1):_vm._e(),(_vm.isUK)?_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.email'),"rules":"email","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.email'),"error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-12"},[_c('ui-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"mt-4",attrs:{"action":"","type":"primary","is-loading":_vm.loading.form,"disabled":_vm.loading.form || _vm.disableRegistration},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.sendRegistrationLink'))+" ")]),(_vm.disableRegistration)?_c('p',{staticClass:"mt-3 ml-3 text-primary"},[_vm._v(" Please fill out the module 'Set distribution' ")]):_vm._e()],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }