<template>
  <div class="card" :key="payoutId">
    <div class="card-body">
      <privacy-policy-gb v-if="isUk || isSwitzerland" />
      <privacy-policy-uae v-if="isUAE" />
      <privacy-policy-de v-if="isGermany" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PrivacyPolicyGb from '@/components/PrivacyPolicyGb.vue';
import PrivacyPolicyDe from '@/components/PrivacyPolicyDe.vue';
import PrivacyPolicyUae from '@/components/PrivacyPolicyUae.vue';

export default {

  components: {
    PrivacyPolicyGb,
    PrivacyPolicyDe,
    PrivacyPolicyUae,
  },

  computed: {
    ...mapState({
      payoutId: (state) => state.user.location.payoutId,
    }),
    country() {
      return this.$store.state.user.location.country;
    },
    isUk() {
      return this.country === 'gb';
    },
    isGermany() {
      return this.country === 'de';
    },
    isUAE() {
      return this.country === 'ae';
    },
    isSwitzerland() {
      return this.country === 'ch';
    },
  },
};
</script>
