var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"tag":"form","disabled":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ui-modal',_vm._g(_vm._b({attrs:{"modal-classes":"mt-4"},on:{"before-open":_vm.beforeOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"modal-title text-center"},[_vm._v(" "+_vm._s(_vm.$t('staffs.title'))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center w-100 mt-4"},[_c('ui-button',{staticClass:"mx-2",attrs:{"action":"","type":"primary","native-type":"button"},on:{"click":function($event){return handleSubmit(_vm.apply)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.applyGroup'))+" ")])],1)]},proxy:true}],null,true)},'ui-modal',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-8 col-sm-8 col-12 mt-lg-0 mt-4 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.groupName'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.groupName'),"required":"","input-classes":"w-100","error":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-8 col-sm-8 col-12 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.tipCollectionType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.tipsTypes,"label":_vm.$t('form.labels.tipCollectionType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","required":"","error":errors[0]},model:{value:(_vm.form.tipsType),callback:function ($$v) {_vm.$set(_vm.form, "tipsType", $$v)},expression:"form.tipsType"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-lg-0 mt-4 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0"},[_c('validation-provider',{attrs:{"tag":"div","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('location-service-type',{staticClass:"is-filled",attrs:{"type-list":_vm.groupTypeList,"label":_vm.$t('staffs.selectIcon'),"error":errors[0]},on:{"toggle-icon":_vm.toggleIcon},model:{value:(_vm.activeType),callback:function ($$v) {_vm.activeType=$$v},expression:"activeType"}})]}}],null,true)})],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }