<template>
  <ui-modal v-bind="$attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      Incomplete KYC
    </template>

    <div
      v-for="item in registrations"
      :key="item.id"
      class="d-flex justify-content-between align-items-center mt-4"
    >
      <router-link
        :to="{ name: 'location-view', params: { locationId: item.id }}"
        class="card-label"
      >
        {{ item.name }}
      </router-link>
      <div class="card-value">
        {{ item.count }}
      </div>
    </div>
  </ui-modal>
</template>

<script>
export default {

  props: {
    registrations: {
      type: Array,
      required: true,
    },
  },
};
</script>
