<template>
  <div class="notifications-create-form">
    <validation-observer tag="form" v-slot="{ handleSubmit }">
      <ui-card>
        <template #header>
          <h4 class="card-title mb-xl-0 mb-lg-0 mb-3">
            {{ $t('notifications.form.createTitle') }}
          </h4>
        </template>

        <div class="row px-0">
          <validation-provider
            tag="div"
            class="col-12 col-xl-4 col-lg-4 col-md-6"
            :name="$t('form.labels.country')"
            rules="required"
            mode="passive"
          >
            <ui-select
              v-model="form.country"
              :items="countriesList"
              :label="$t('form.labels.country')"
              :placeholder="$t('form.placeholders.select')"
              label-key="name"
              value-key="iso"
              filterable
              required
              @input="changeCountry"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            class="col-12 col-xl-4 col-lg-4 col-md-6"
            :name="$t('form.labels.locations')"
            rules="required"
          >
            <ui-select
              v-model="form.workplaces"
              :items="modal.items"
              :label="$t('form.labels.locations')"
              :placeholder="$t('form.placeholders.select')"
              class="notifications-create-form__locations-control"
              multiple
              filterable
              collapse-tags
              hide-empty-dropdown
              hide-dropdown-loading
              input-icon="location-point"
              label-key="name"
              value-key="id"
              value-original
              required
              @focus="openModalLocations"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            class="col-12 col-xl-4 col-lg-4 col-md-6"
            name="Topic"
            rules="required"
          >
            <ui-input
              v-model="form.topic"
              label="Topic"
              placeholder="Enter title"
              required
            />
          </validation-provider>
        </div>

        <div class="row">
          <div class="col-12">
            <ui-button
              action
              type="primary"
              :disabled="!isFormFilled || loading"
              :is-loading="loading"
              @click.prevent="handleSubmit(create)"
            >
              {{ $t('form.buttons.create') }}
            </ui-button>
          </div>
        </div>
      </ui-card>
    </validation-observer>

    <notification-modal
      v-model="modal.show"
      class="notifications-modal__location"
      title="Choose locations"
      :is-loading="modal.loading.modal"
      @confirm="applyLocations"
      @scrolled-bottom="onModalLocationsScrolledBottom"
    >
      <template #control>
        <ui-input
          v-model="modal.searchField"
          class="notifications-modal__location-control"
          :placeholder="$t('form.placeholders.locationNameOrId')"
          input-icon="search"
          bordered
          @keypress.enter="filterLocations"
        />
      </template>

      <template #body>
        <div
          v-if="modal.items.length"
          class="notifications-modal__location-content"
        >
          <div class="notifications-modal__location-items">
            <ul>
              <li
                v-for="item in modal.items"
                :key="item.id"
                class="notifications-modal__location-item"
              >
                <ui-checkbox
                  v-model="item.selected"
                  class="notifications-modal__location-checkbox"
                  inline
                >
                  <span class="notifications-modal__location-title">
                    <span>
                      {{ item.name }}
                    </span>
                    <span
                      v-if="item.payoutId"
                      class="text-grey"
                    >
                      &nbsp;(ID {{ item.payoutId }})
                    </span>
                  </span>
                </ui-checkbox>
              </li>
              <div
                v-if="modal.loading.content"
                class="d-flex justify-content-center my-5"
              >
                <ui-loading inline />
              </div>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center mt-3 mb-5"
        >
          <ui-icon
            name="search-in-list"
            size="24"
            class="mb-2"
          />
          <p class="d-flex flex-column align-items-center text-grey text-center font-16">
            <span class="text-black mb-2">
              No data found
            </span>
            <span>
              There is no such location, <br>
              try changing the search field
            </span>
          </p>
        </div>
      </template>
    </notification-modal>
  </div>
</template>

<script>
import { unionBy } from 'lodash';
import api from '@/api';
import resetMixin from '@/mixins/reset-mixin';
import NotificationModal from '@/components/Notification/NotificationModal.vue';

export default {
  name: 'NotificationCreateForm',

  components: {
    NotificationModal,
  },

  mixins: [
    resetMixin(() => ({
      modal: {
        show: false,
        loading: {
          modal: false,
          content: false,
        },
        searchField: '',
        searchData: null,
        items: [],
        page: 1,
        pageSize: 10,
        total: 0,
      },
    })),
  ],

  data() {
    return {
      form: {
        topic: '',
        country: '',
        workplaces: [],
      },
      loading: false,
    };
  },

  computed: {
    isFormFilled() {
      return this.form.topic
        && this.form.country
        && this.form.workplaces.length;
    },
    countriesList() {
      return this.$getCountries();
    },
    locationsStart() {
      return (this.modal.page - 1) * this.modal.pageSize;
    },
    locationsApiConfig() {
      return {
        country: this.form.country,
        start: this.locationsStart,
        pageSize: this.modal.pageSize,
        ...this.modal.searchData,
      };
    },
    createApiConfig() {
      const {
        workplaces,
        ...data
      } = this.form;

      return {
        ...data,
        workplaces: workplaces.map((item) => {
          const { selected, ...itemData } = item;

          return itemData;
        }),
      };
    },
  },

  methods: {
    changeCountry() {
      this.reset();
      this.form.workplaces.splice(0);
    },
    onModalLocationsScrolledBottom() {
      const totalPages = this.modal.total / this.modal.pageSize;
      const isEnoughItems = this.modal.total >= this.modal.pageSize;
      const isOutOfItems = this.modal.total === this.modal.items.length;

      if (this.modal.page >= totalPages || !isEnoughItems || isOutOfItems) {
        return;
      }

      this.modal.page += 1;
      this.loadLocations({
        append: true,
        entity: 'content',
      });
    },
    openModalLocations(e) {
      e.target.blur();

      if (!this.modal.items.length) {
        this.loadLocations({
          append: true,
          entity: 'modal',
        });
      }

      this.modal.show = true;
    },
    filterLocations() {
      const query = this.modal.searchField;

      if (!query) {
        this.modal.searchData = null;
      } else if (!Number.isNaN(+query)) {
        this.modal.searchData = {
          payoutId: query,
        };
      } else {
        this.modal.searchData = {
          locationName: query,
        };
      }

      this.modal.page = 1;
      this.loadLocations({
        append: false,
        query: this.modal.searchData,
        entity: 'modal',
      });
    },
    setItems(items, append) {
      const data = items.map((item) => ({
        id: item.id,
        name: item.name,
        country: item.country,
        payoutId: item.payoutId,
        staffs: item.staffs,
        selected: false,
      }));

      const selectedLocations = this.modal.items
        .filter(({ selected }) => selected);

      if (append) {
        this.modal.items.push(...data);
      } else {
        this.modal.items = data;
      }

      this.modal.items = unionBy(selectedLocations, this.modal.items, 'id');
    },
    applyLocations() {
      const locations = this.modal.items
        .filter(({ selected }) => selected);

      this.form.workplaces = locations;
    },
    async loadLocations(config) {
      this.modal.loading[config.entity] = true;

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.notifications.getLocations({
          ...this.locationsApiConfig,
          ...config?.query,
        });

        if (data) {
          this.modal.total = totalRecords;
          this.setItems(data, config.append);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.modal.loading[config.entity] = false;
      }
    },
    async create() {
      this.loading = true;

      try {
        const {
          data: notification,
        } = await api.notifications.createNotification(this.createApiConfig);

        this.$emit('completed', notification);
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
