<template>
  <ui-input
    :value="activeIcon"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
    class="location-service-type"
  >
    <div class="location-service-type__container">
      <location-service-icon
        v-for="type in typeList"
        :key="type.name"
        :label="type.label"
        :icon="type.icon"
        class="location-service-type__icon"
        :is-active="isActiveIcon(type.name)"
        @click.native="toggleIcon(type.name)"
      />
    </div>
  </ui-input>
</template>

<script>
import LocationServiceIcon from '@/components/Location/LocationServiceIcon.vue';

export default {
  name: 'LocationServiceType',
  components: { LocationServiceIcon },

  model: {
    prop: 'activeIcon',
    event: 'input',
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    activeIcon: {
      type: String,
      default: '',
    },
    typeList: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isActiveIcon(name) {
      return this.activeIcon === name;
    },
    toggleIcon(name) {
      if (this.isActiveIcon(name)) {
        this.$emit('toggle-icon', null);
      } else {
        this.$emit('toggle-icon', name);
      }
    },
  },
};
</script>
