export const MULTI_TIPS_MODES = [
  {
    name: 'Off',
    value: 'OFF',
  },
  {
    name: 'On',
    value: 'ON',
  },
  {
    name: 'On and set as default',
    value: 'DEFAULT_ON',
  },
];
