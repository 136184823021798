<template>
  <div>
    <slot
      v-bind="{
        updateFilters,
        filters,
      }"
    />
  </div>
</template>

<script>
import { upperFirst } from 'lodash';

export default {
  name: 'StaffListRoute',

  props: {
    pageSize: {
      type: Number,
      required: true,
    },
    routePrefix: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    filters: {
      page: 1,
      showAll: false,
    },
  }),

  computed: {
    routePage() {
      return this.$route.query?.[this.routeParam('page')];
    },
    routeShowAll() {
      return this.$route.query?.[this.routeParam('showAll')];
    },
    routeFilters() {
      return {
        [this.routeParam('page')]: this.filters.page,
        [this.routeParam('showAll')]: this.filters.showAll ? 1 : 0,
      };
    },
  },

  mounted() {
    this.filters.page = parseInt(this.routePage || 1, 10);
    this.filters.showAll = parseInt(this.routeShowAll || 0, 10) > 0;
    this.handleLoad();
  },

  methods: {
    routeParam(name) {
      if (this.routePrefix) {
        return `${this.routePrefix}${upperFirst(name)}`;
      }

      return name;
    },
    routeSync() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.routeFilters,
        },
      });
    },
    updateFilters(value) {
      if ('showAll' in value) {
        value.page = 1;
      }

      this.filters = {
        ...this.filters,
        ...value,
      };
      this.routeSync();
      this.handleLoad();
    },
    handleLoad() {
      this.$emit('load', this.filters);
    },
  },
};
</script>
