<template>
  <div class="menu-single-section" @touchend="fixActionRestriction">
    <Container
      v-protected
      tag="ul"
      drag-class="file-ghost"
      :drop-placeholder="{
        animationDuration: '150',
        showOnTop: true,
        className: 'file-drop',
      }"
      :get-child-payload="(itemId) => getChildPayload(itemId)"
      @drop="handleDrop($event)"
    >
      <Draggable
        tag="li"
        v-for="(file, index) in singleSectionList"
        :key="index"
      >
        <section-file
          v-protected
          :id="index"
          :data="file"
          @select-file="handleSelectFile"
          @delete-file="handleDeleteFile"
        />
      </Draggable>
      <div
        v-if="loading.file"
        class="menu-single-section__loader"
      >
        <ui-loading inline />
      </div>
    </Container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';
import applyDrag from '@/utils/applyDrag';
import SectionFile from '@/components/OnlineMenu/SectionFile/SectionFile.vue';

export default {
  name: 'SingleSection',

  components: {
    Container,
    Draggable,
    SectionFile,
  },

  computed: {
    ...mapState('menu', [
      'singleSectionList',
      'loading',
    ]),
  },

  mounted() {
    this.$watch(() => this.singleSectionList.filter((file) => file.selected), (value) => {
      if (value.length) {
        this.$emit('select-file', value[0]);
      }
    }, { immediate: true, deep: true });
  },

  methods: {
    ...mapMutations('menu', [
      'updateSingleSectionFiles',
      'deleteSingleSectionFile',
      'selectSingleSectionFile',
      'updateSingleSectionFileOrder',
    ]),
    fixActionRestriction() {
      // issue: https://github.com/kutlugsahin/vue-smooth-dnd/issues/134
      document.body.classList.remove(
        'smooth-dnd-no-user-select',
        'smooth-dnd-disable-touch-action',
      );
    },
    getChildPayload(itemId) {
      return this.singleSectionList[itemId];
    },
    handleDrop(event) {
      const fileData = applyDrag(this.singleSectionList, event);

      this.updateSingleSectionFiles(fileData);
    },
    handleSelectFile({ fileId, data }) {
      this.selectSingleSectionFile(fileId);
      this.$emit('select-file', data);
    },
    handleDeleteFile(id) {
      this.$emit('delete-file', id);
      this.deleteSingleSectionFile(id);
      this.updateSingleSectionFileOrder();
    },
  },
};
</script>
