<template>
  <ui-card
    v-if="modeList.length"
    class="payouts-history"
    header-classes="pb-0"
    card-body-classes="pt-0"
  >
    <template #header>
      <div class="d-flex flex-nowrap justify-content-between">
        <h4 class="card-title">
          {{ $t('payoutHistory.title') }}
        </h4>
        <div v-if="data.length">
          <ui-icon-button
            size="22px"
            name="pdf"
            class="mr-2"
            @click="exportPdf"
          />
          <ui-icon-button
            size="22px"
            name="excel-sm"
            class="mr-2"
            @click="exportXls"
          />
        </div>
      </div>

      <div class="payouts-history__controls">
        <div
          v-if="showModeControl"
          class="payouts-history__mode"
        >
          <ui-toggle
            :elements="modeList"
            :selected-value="modeSelected"
            @select="handleMode"
          />
        </div>
        <h3 class="payouts-history__title">
          {{ $t('payoutHistory.subtitle') }}
        </h3>
        <ui-select
          v-if="showYearControl"
          v-model="yearSelected"
          :items="yearList"
          label-key="label"
          value-key="value"
          placeholder="Select year"
          class="payouts-history__year-control"
          value-original
          bordered
          @change="handleYear"
        />
        <ui-date-period
          v-if="showPeriodControl"
          v-model="periodSelected"
          :items="periodList"
          class="payouts-history__period-control"
          @input="handlePeriod"
        />
      </div>
    </template>

    <ui-data-table
      :columns="columns"
      :data="data"
      class="payouts-history__table"
      data-table="payout-history"
      no-empty-text
    >
      <template #payout-history-payroll-id-value="{ value }">
        <span class="text-primary">
          {{ value }}
        </span>
      </template>

      <template #payout-history-payout-id-value="{ value }">
        <div class="d-flex justify-content-between align-items-center">
          <span class="d-inline-block text-truncate mr-1 text-primary">
            {{ value }}
          </span>
          <ui-text-copy
            :text="value"
          />
        </div>
      </template>

      <template #payout-history-id-value="{ value }">
        <span class="text-primary">
          {{ value }}
        </span>
      </template>

      <template #payout-history-name-value="{ value }">
        <div class="d-flex flex-column align-items-start">
          <span>{{ value[0] }}</span>
          <span>{{ value[1] }}</span>
        </div>
      </template>

      <template #payout-history-gross-amount-value="{ row, value }">
        <div class="d-flex align-items-center flex-nowrap text-nowrap">
          <div>
            {{ value.amount | money(value.currency) }}
            <template v-if="row.type === 'PROMO_BONUS'">
              <ui-tooltip content="Bonus" placement="top" effect="light">
                <ui-icon name="bonus-sm" size="20px"></ui-icon>
              </ui-tooltip>
            </template>
          </div>
        </div>
      </template>

      <template #payout-history-status="{ value }">
        <div
          v-if="value === 'SUCCESS'"
          class="d-flex align-items-center success"
        >
          <span>
            {{ $t('payoutHistory.table.status.succeeded') }}
          </span>
          <ui-icon
            name="check"
            size="8px"
          />
        </div>
        <div v-else-if="value === 'PENDING'" class="pending">
          <ui-tag>
            {{ $t('payoutHistory.table.status.pending') }}
          </ui-tag>
        </div>
      </template>

      <template #payout-history-tax-amount="{ value }">
        <span v-if="value">
          {{ value.amount | money(value.currency) }}
        </span>
        <span v-else>
          N/A
        </span>
      </template>

      <template #payout-history-net-amount="{ value }">
        <span v-if="value">
          {{ value.amount | money(value.currency) }}
        </span>
        <span v-else>
          N/A
        </span>
      </template>

      <template #payout-history-more-actions="{ row }">
        <ui-action-down
          :items="payoutMoreActions"
          @select="handleAction($event, row)"
        />
      </template>

      <template #after>
        <div class="payouts-history__table-footer">
          <ui-loading
            v-if="loading.data"
            class="my-5 mx-auto"
            inline
          />
        </div>
      </template>
    </ui-data-table>

    <template v-if="data.length" #footer>
      <div
        v-if="showMore"
        class="payouts-history__view-more"
      >
        <ui-button
          outline
          action
          type="primary"
          native-type="button"
          :disabled="someLoading"
          :is-loading="loading.next"
          @click="handleViewMore"
        >
          {{ $t('form.buttons.viewMore') }} ({{ moreCount }})
        </ui-button>
      </div>
      <div
        v-if="showTotal"
        class="payouts-history__total"
      >
        {{ $t('table.totalForPeriod') }}: <span>{{ totalAmount | money(currency) }}</span>
      </div>
    </template>
    <payout-details-modal
      v-model="showDetailsModal"
      :payout-info="selectedPayout"
      :profile-id="profile.id"
    />
  </ui-card>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import { DateTime } from 'luxon';
import api from '@/api';
import formatDate from '@/filters/date';
import parseDate from '@/utils/parseDate';
import formatPeriod from '@/utils/formatPeriod';
import download from '@/utils/download';
import PayoutDetailsModal from '@/components/Payouts/PayoutDetailsModal.vue';

const MODE_TYPES = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
};

export default {

  components: {
    PayoutDetailsModal,
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: 'usd',
    },
    forDistributions: Boolean,
    reload: Boolean,
  },

  data() {
    return {
      loading: {
        data: false,
        next: false,
      },
      data: [],
      modeSelected: '',
      yearSelected: null,
      yearList: [],
      periodSelected: null,
      periodList: [],
      total: 0,
      pageSize: 10,
      showDetailsModal: false,
      selectedPayout: {},
    };
  },

  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
    ...mapGetters({
      isLocationEmployerReporting: 'user/isEmployerReportingTypeLocation',
      isLocationMixReporting: 'user/isMixReportingTypeLocation',
      isProfileSelfReporting: 'user/isSelfReportingTypeProfile',
      isProfileEmployerReporting: 'user/isEmployerReportingTypeProfile',
      isProfileCheckout: 'user/isProfileCheckout',
      isOwner: 'auth/isOwner',
      isStaff: 'auth/isStaff',
      isCommonStaff: 'user/isCommonStaff',
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
    }),
    start() {
      return this.data.length;
    },
    moreCount() {
      return Math.min(this.pageSize, this.total - this.data.length);
    },
    showMore() {
      return this.moreCount > 0;
    },
    someLoading() {
      return Object.values(this.loading).some((v) => v);
    },
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    isPersonalMode() {
      return this.modeSelected === MODE_TYPES.PERSONAL;
    },
    isCorporateMode() {
      return this.modeSelected === MODE_TYPES.CORPORATE;
    },
    modeList() {
      return [
        {
          label: this.$t('table.columns.personal'),
          value: MODE_TYPES.PERSONAL,
          visible: this.canModePersonal,
        },
        {
          label: this.$t('table.columns.company'),
          value: MODE_TYPES.CORPORATE,
          visible: this.canModeCorporate,
        },
      ].filter(({ visible }) => visible);
    },
    canModePersonal() {
      if (this.isOwnerUAECommon && !this.isStaff) {
        return false;
      }
      if (this.forDistributions) {
        return false;
      }

      return this.isStaff || (this.isOwner && this.location.isAllowedSharedCard);
    },
    canModeCorporate() {
      if (this.isOwnerUAECommon && (this.location.workplaceFee || this.location.isAllowedSharedCard)) {
        return true;
      }
      if (this.forDistributions) {
        return true;
      }

      return this.isOwner && (this.isLocationEmployerReporting || this.isLocationMixReporting);
    },
    showModeControl() {
      return this.modeList.length > 1;
    },
    showYearControl() {
      // Year control inside ui-date-period.
      return !this.showPeriodControl;
    },
    showPeriodControl() {
      if (this.forDistributions) {
        return true;
      }

      return this.isOwner && (this.isLocationEmployerReporting || this.isLocationMixReporting);
    },
    hasNetPayouts() {
      if (this.periodSelected) {
        return !this.periodSelected.isGrossPayouts;
      }

      return this.data.some(({ period }) => !period.isGrossPayouts);
    },
    hasCheckoutPayoutId() {
      return this.data.some(({ checkoutPayoutId }) => checkoutPayoutId);
    },
    showTotal() {
      return !this.showMore;
    },
    totalAmount() {
      return this.data.reduce((prev, curr) => prev + (curr.totalAmount?.amount || 0), 0);
    },
    exportConfig() {
      let requestConfig;

      if (this.isCorporateMode && this.isOwnerUAECommon) {
        requestConfig = {
          isOwnerUAECommon: this.isOwnerUAECommon,
          locationId: this.location.id,
        };
      } else if (this.isProfileCheckout) {
        requestConfig = {
          isCheckout: true,
          userId: this.profile.id,
        };
      } else {
        requestConfig = {
          isCheckout: false,
          locationId: this.location.id,
          staffId: this.profile.staffId,
        };
      }

      if (this.showPeriodControl) {
        requestConfig.dateFrom = this.periodSelected.startDate;
        requestConfig.dateTo = this.periodSelected.endDate;
      } else {
        const {
          dateFrom,
          dateTo,
        } = this.getPersonalPeriod();

        requestConfig.dateFrom = dateFrom;
        requestConfig.dateTo = dateTo;
      }

      return {
        ...requestConfig,
        dateFrom: parseDate(requestConfig.dateFrom),
        dateTo: parseDate(requestConfig.dateTo),
      };
    },
    columns() {
      return [
        {
          name: 'payout-history-payroll-id',
          label: this.$t('table.columns.payrollId'),
          prop: 'payrollId',
          empty: this.$t('table.empty.payrollId'),
          visible: this.isCorporateMode && !this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-payout-id',
          label: this.$t('table.columns.checkoutPayoutId'),
          prop: 'checkoutPayoutId',
          empty: this.$t('table.empty.payoutId'),
          visible: this.hasCheckoutPayoutId,
        },
        {
          name: 'payout-history-period',
          label: 'Distribution period',
          prop: 'from',
          value: ({ row }) => {
            return formatPeriod(row.period.startDate, row.period.endDate);
          },
          empty: this.$t('table.empty.period'),
          visible: this.isCommonStaff || (this.isPersonalMode && this.isProfileEmployerReporting)
            || (this.isCorporateMode && (this.isLocationEmployerReporting || this.isLocationMixReporting)),
        },
        {
          name: 'payout-history-date',
          label: this.$t('table.columns.payoutDate'),
          prop: 'payoutDate',
          value: ({ row }) => {
            return row.payoutDate
              ? formatDate(row.payoutDate)
              : row.payoutDate;
          },
          empty: this.$t('table.empty.payoutDate'),
          visible: (this.isPersonalMode && this.isProfileSelfReporting) || this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          empty: this.$t('table.empty.easytipId'),
          visible: this.isCorporateMode && !this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          empty: this.$t('table.empty.name'),
          visible: this.isCorporateMode && !this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-gross-amount',
          label: this.$t('table.columns.grossAmount'),
          prop: 'grossAmount',
          empty: '—',
          visible: true,
        },
        {
          name: 'payout-history-status',
          label: this.$t('table.columns.status'),
          prop: 'status',
          empty: '—',
          align: 'center',
          visible: true,
        },
        {
          name: 'payout-history-tax-amount',
          label: this.$t('table.columns.taxAmount'),
          prop: 'taxAmount',
          visible: this.isCorporateMode && this.hasNetPayouts && !this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-net-amount',
          label: this.$t('table.columns.netAmount'),
          prop: 'netAmount',
          visible: this.isCorporateMode && this.hasNetPayouts && !this.isOwnerUAECommon,
        },
        {
          name: 'payout-history-more-actions',
          visible: this.payoutMoreActions.length,
        },
      ].filter(({ visible }) => visible);
    },
    payoutMoreActions() {
      const actions = [];
      if (this.isOwner && this.location.isAllowedSharedCard) {
        actions.push({ id: 'details', label: 'Details' });
      }
      return actions;
    },
  },

  watch: {
    location: {
      immediate: false,
      handler() {
        this.initialize();
      },
    },
    reload: {
      immediate: false,
      async handler(value) {
        if (value) {
          await this.initialize();

          this.$emit('update:reload', false);
        }
      },
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions('user', [
      'loadPeriods',
    ]),
    clearData() {
      this.data.splice(0);
      this.total = 0;
    },
    async openDetails(row) {
      this.selectedPayout = row;
      this.showDetailsModal = true;
    },
    setData(data) {
      data = data.map((item) => {
        const period = this.periodSelected
          ? this.periodSelected
          : item.period;

        const name = item.firstName && item.lastName
          ? [item.firstName, item.lastName]
          : '';

        const grossAmount = this.getDataAmount(item.grossAmount);
        const netAmount = this.getDataAmount(item.netAmount);
        const taxAmount = this.getDataAmount(item.taxAmount);
        const totalAmount = period.isGrossPayouts
          ? grossAmount
          : netAmount;

        return {
          ...item,
          name,
          period,
          grossAmount,
          netAmount,
          taxAmount,
          totalAmount,
        };
      });

      this.data.push(...data);
    },
    getDataPeriod(item) {
      if (this.periodSelected) {
        return this.periodSelected;
      }

      if (item.period) {
        return item.period;
      }

      return {
        id: null,
        isGrossPayouts: true,
        startDate: item.startDate,
        endDate: item.endDate,
        status: '',
      };
    },
    getDataAmount(value) {
      if (typeof value === 'number') {
        return {
          amount: value,
          currency: this.currency,
        };
      }

      if (typeof value?.amount === 'number') {
        return value;
      }

      return null;
    },
    getPersonalPeriod() {
      const selectedYearValue = this.yearSelected?.value;
      const currentDate = DateTime.local();
      const dateFrom = DateTime.local(selectedYearValue, 1, 1).toISODate();
      let dateTo;

      if (currentDate.year === selectedYearValue) {
        dateTo = currentDate.toISODate();
      } else {
        dateTo = DateTime.local(selectedYearValue, 12, 31).toISODate();
      }

      return {
        dateFrom,
        dateTo,
      };
    },
    async initialize() {
      await this.loadFilter();
      await this.loadData();
    },
    async loadYearList() {
      let request;
      let method;

      if (this.isCorporateMode && this.isOwnerUAECommon) {
        request = {
          locationId: this.location.id,
        };
        method = 'getYears';
      } else {
        method = 'getPayoutYears';

        if (this.isProfileCheckout) {
          request = {
            isCheckout: true,
            userId: this.profile.id,
          };
        } else {
          if (!this.showYearControl || !this.profile.staffId) {
            return;
          }

          request = {
            isCheckout: false,
            locationId: this.location.id,
            staffId: this.profile.staffId,
          };
        }
      }

      try {
        const { data } = await api.locations[method](request);

        this.yearList = data.map((year) => ({
          label: year,
          value: Number(year),
        }));
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadPeriodList() {
      if (!this.showPeriodControl) {
        return;
      }

      try {
        const { data } = await api.locations.getPayoutPeriods({
          locationId: this.location.id,
        });

        this.periodList = data.map(({ startDate, endDate, ...item }) => ({
          ...item,
          startDate,
          endDate,
          title: formatPeriod(startDate, endDate),
        }));
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadFilter() {
      this.yearSelected = null;
      this.periodSelected = null;

      if (!this.modeSelected) {
        this.modeSelected = this.modeList[0]?.value;
      }

      await Promise.all([
        this.loadYearList(),
        this.loadPeriodList(),
      ]);

      if (this.showPeriodControl) {
        this.periodSelected = this.periodList?.[0];
      }

      if (this.showYearControl) {
        this.yearSelected = this.yearList?.[0];
      }
    },
    async loadData(append = false) {
      if (!append) {
        this.clearData();
      }

      this.loading.data = true;

      try {
        if (this.isCorporateMode) {
          await this.loadCorporateData();
        } else {
          await this.loadPersonalData();
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.data = false;
      }
    },
    async loadCorporateData() {
      if (!this.periodSelected && !this.isOwnerUAECommon) {
        return;
      }

      let request;
      let method;

      if (this.isOwnerUAECommon) {
        request = {
          locationId: this.location.id,
          start: this.start,
          pageSize: this.pageSize,
          ...this.getPersonalPeriod(),
        };
        method = 'getPayouts';
      } else {
        request = {
          locationId: this.location.id,
          periodId: this.periodSelected.id,
          start: this.start,
          pageSize: this.pageSize,
        };
        method = 'getPayoutPeriod';
      }

      const {
        data: {
          data,
          recordsFiltered,
          totalRecords,
        },
      } = await api.locations[method](request);

      this.setData(data);
      this.total = totalRecords;
    },
    async loadPersonalData() {
      if (!this.yearSelected) {
        return;
      }

      let request;

      if (this.isProfileCheckout) {
        request = {
          isCheckout: true,
          userId: this.profile.id,
        };
      } else {
        request = {
          isCheckout: false,
          locationId: this.location.id,
          staffId: this.profile.staffId,
        };
      }

      const {
        data: {
          data,
          recordsFiltered,
          totalRecords,
        },
      } = await api.staff.getPayoutPeriod({
        start: this.start,
        pageSize: this.pageSize,
        ...this.getPersonalPeriod(),
        ...request,
      });

      this.setData(data);
      this.total = totalRecords;
    },
    async handleViewMore() {
      if (this.loading.next) {
        return;
      }

      this.loading.next = true;

      try {
        await this.loadData(true);
      } finally {
        this.loading.next = false;
      }
    },
    handleMode(value) {
      this.modeSelected = value;
      this.initialize();
    },
    handleYear() {
      this.loadData();
    },
    handlePeriod() {
      this.loadData();
    },
    async exportXls() {
      let method;

      if (this.isCorporateMode && this.isOwnerUAECommon) {
        method = 'getLocationHistoryExcel';
      } else if (this.exportConfig.isCheckout) {
        method = 'getCheckoutHistoryExcel';
      } else {
        method = this.isCorporateMode
          ? 'getLocationHistoryExcel'
          : 'getStaffHistoryExcel';
      }

      try {
        const { data, filename } = await api.payouts[method](this.exportConfig);

        if (data) {
          download(data, filename || 'transactions.xlsx');
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async exportPdf() {
      try {
        let method;

        if (this.isCorporateMode && this.isOwnerUAECommon) {
          method = 'getLocationHistoryPdf';
        } else if (this.exportConfig.isCheckout) {
          method = 'getCheckoutHistoryPdf';
        } else {
          method = this.isCorporateMode
            ? 'getLocationHistoryPdf'
            : 'getStaffHistoryPdf';
        }

        const { data, filename } = await api.payouts[method](this.exportConfig);

        if (data) {
          download(data, filename || 'transactions.pdf');
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleAction(event, row) {
      if (event === 'details') {
        this.openDetails(row);
      }
    },
  },
};
</script>
