<template>
  <div class="d-flex flex-column h-100">
    <div v-if="staffHours.length" class="staff-payouts-members">
      <div
        v-for="item in staffHours"
        :key="item.staffId"
        class="d-flex justify-content-between align-items-center mt-4"
      >
        <strong>
          {{ getStaffName(item) }} &mdash; {{ convertTimeToFixed(item.workDay.hours) }}{{ timesheet.short }}
        </strong>
        <ui-action-down
          v-if="!disabled"
          :items="formActions"
          value-key="id"
          @select="handleAction($event, item)"
        />
      </div>
    </div>

    <ui-button
      v-if="!disabled"
      outline
      type="primary"
      class="w-100"
      :class="{'mt-4': staffHours.length}"
      :is-loading="isLoading"
      :disabled="!formStaffs.length || isLoading"
      @click="openForm"
    >
      {{
        staffHours.length
          ? 'Edit'
          : `Add ${hoursOrUnits}`
      }}
    </ui-button>

    <template>
      <span class="text-center text-grey font-14 my-4">or</span>

      <ui-button
        v-if="!disabled"
        type="primary"
        class="w-100"
        :is-loading="isLoading"
        :disabled="!formStaffs.length || isLoading"
        @click="openUpload"
        link
      >
        Upload Excel with {{ hoursOrUnits }}
      </ui-button>
    </template>

    <staff-hours-form
      v-if="!disabled"
      v-model="formShow"
      :data="formData"
      :location="location"
      :staffs="formStaffs"
      @update:data="applyForm"
    />

    <staff-hours-upload
      v-if="!disabled"
      v-model="uploadShow"
      :location="location"
      :range="range"
      :hours-or-units="hoursOrUnits"
      @uploaded="onDistributedData"
    />
  </div>
</template>

<script>
import convertTimeToFixed from '@/utils/convertTimeToFixed';
import StaffHoursForm from './StaffHoursForm.vue';
import StaffHoursUpload from './StaffHoursUpload.vue';

export default {

  components: {
    StaffHoursForm,
    StaffHoursUpload,
  },

  props: {
    isLoading: Boolean,
    disabled: Boolean,
    range: Array,
    location: Object,
    staffs: {
      type: Array,
      default: () => [],
    },
    staffHours: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    formShow: false,
    uploadShow: false,
    distributedData: null,
  }),

  computed: {
    hoursOrUnits() {
      return this.location.timesheetType.toLowerCase();
    },
    date() {
      return this.range[1];
    },
    formActions() {
      return [
        {
          id: 'edit',
          label: this.$t('form.buttons.edit'),
        },
        {
          id: 'remove',
          label: this.$t('form.buttons.remove'),
        },
      ];
    },
    formStaffs() {
      if (this.isDelivery) {
        return this.staffs.map((item) => ({
          ...item,
          name: item?.fio || item.login,
        }));
      }

      return this.staffs.filter((item) => {
        if (this.location.tipsType === 'COMMON_PRIORITY') {
          return item.staffType !== this.location.primaryStaff;
        }

        return true;
      }).map((item) => ({
        ...item,
        name: item?.fio || item.login,
      }));
    },
    formData() {
      const data = this.distributedData ? this.distributedData : this.staffHours;

      return data.filter(({ staffId }) => {
        return this.formStaffs.some((item) => item.id === staffId);
      }).map(({ staffId, workDay }) => {
        const { hours } = {
          hours: '',
          ...workDay,
        };

        return {
          staffId,
          hours,
        };
      });
    },
    timesheet() {
      if (this.location.timesheetType === 'UNITS') {
        return {
          label: 'units',
          short: 'u',
        };
      }

      return {
        label: 'hours',
        short: 'h',
      };
    },
    isDelivery() {
      return this.location.type === 'DELIVERY';
    },
  },

  methods: {
    convertTimeToFixed,
    getStaffName({ staffId }) {
      const staff = this.staffs.find((item) => item.id === staffId);

      return staff?.fio || staff?.login;
    },
    openForm() {
      this.formShow = true;
    },
    openUpload() {
      this.uploadShow = true;
    },
    updateStaff(staffHours) {
      this.$emit('update:staff-hours', staffHours);
    },
    removeStaff({ staffId }) {
      const staffHours = [...this.staffHours];
      const index = staffHours.findIndex((item) => item.staffId === staffId);

      if (index > -1) {
        staffHours.splice(index, 1);
      }

      this.updateStaff(staffHours);
    },
    applyForm(staffHours) {
      staffHours = staffHours.map(({ staffId, hours }) => ({
        staffId,
        workDay: {
          date: this.date,
          hours,
        },
      }));

      this.updateStaff(staffHours);
      this.formShow = false;
    },
    handleAction(event, item) {
      if (event === 'edit') {
        this.openForm();
      }

      if (event === 'remove') {
        this.removeStaff(item);
      }
    },
    onDistributedData(formData) {
      this.distributedData = formData;
      this.openForm();
    },
  },
};
</script>
