<template>
  <ui-modal
    :show="value"
    :is-loading="loading.show"
    :closable="false"
    class="location-turn-off-pay-modal"
    @closed="$emit('closed')"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <template #title>
      <div class="location-turn-off-pay-modal__title">
        Turn off pay
      </div>
    </template>

    <div class="location-turn-off-pay-modal__body">
      <div class="location-turn-off-pay-modal__description">
        Please specify the reason why
        you are turning off tip payments for this location.
      </div>

      <div class="location-turn-off-pay-modal__reasons-list">
        <ui-radio
          v-for="reason in reasonsList"
          :key="reason.label"
          :label="reason.label"
          :value="reason.value"
          :disabled="loading.ok"
          name="reasons-list"
          @input="handleChoose(reason)"
        >
          {{ reason.label }}
        </ui-radio>
      </div>
    </div>

    <template #footer>
      <div class="location-turn-off-pay-modal__footer">
        <ui-button
          size="sm"
          type="primary"
          outline
          class="location-turn-off-pay-modal__button"
          :disabled="loading.ok"
          @click="$emit('closed')"
        >
          {{ $t('form.buttons.cancel') }}
        </ui-button>

        <ui-button
          size="sm"
          type="primary"
          class="location-turn-off-pay-modal__button"
          :is-loading="loading.ok"
          :disabled="isDisabled"
          @click="handleOk(() => $emit('closed'))"
        >
          {{ $t('form.buttons.ok') }}
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import api from '@/api';

export default {
  name: 'LocationTurnOffPayModal',

  model: {
    prop: 'value',
    event: 'closed',
  },

  props: {
    value: Boolean,
    data: Object,
  },

  data() {
    return {
      currentReason: null,
      reasonsList: null,
      loading: {
        show: false,
        ok: false,
      },
    };
  },

  computed: {
    isDisabled() {
      return !this.currentReason;
    },
  },

  methods: {
    handleChoose(reason) {
      this.reasonsList = this.reasonsList.map((item) => {
        if (item.value === reason.value) {
          return {
            ...item,
            active: true,
          };
        }

        return {
          ...item,
          active: false,
        };
      });

      this.currentReason = this.reasonsList.find(({ active }) => active);
    },
    async loadReasons() {
      this.loading.show = true;

      try {
        const { data } = await api.handbook.getRemoveReasons();
        this.reasonsList = data;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.show = false;
      }
    },
    async handleOk(close) {
      if (this.loading.ok || !this.currentReason) {
        return;
      }

      this.loading.ok = true;

      try {
        await api.locations.deleteLocation({
          locationId: this.data.id,
          data: {
            reason: this.currentReason,
            status: 'NOT_ACTIVE',
          },
        });
        this.$emit('completed', this.currentReason.value);
        close();
      } catch (err) {
        this.$showServerError(err);
      } finally {
        this.loading.ok = false;
      }
    },
    beforeClose() {
      this.currentReason = null;
      this.reasonsList = [];
    },
    beforeOpen() {
      this.loadReasons();
    },
  },
};
</script>
