<template>
  <ui-loading v-if="defaultLocationRequired" inline />
  <ui-card
    v-else
    :title="$t('alerts.subTitle[0]')"
    :key="storeUpdated"
  >
    <ui-modal-status
      v-model="successModal"
      :message="$t('alerts.successModal.subTitle')"
      :closable="false"
    />
    <validation-observer v-slot="{ handleSubmit }">
      <ui-modal
        v-model="newEmailModal"
      >
        <template #header>
          <span class="alerts__email-modal__title">
            {{ $t('alerts.addNewEmail') }}
          </span>
        </template>
        <validation-provider
          tag="div"
          name="Email"
          rules="required|email"
          class="alerts__email-modal py-5"
          v-slot="{ errors }"
        >
          <ui-input
            v-model="newEmail"
            class="alerts__email-modal__email"
            label="Email address"
            :error="errors[0]"
          />
        </validation-provider>
        <template #footer>
          <ui-button
            action
            type="primary"
            class="alerts__email-modal__button"
            :is-loading="emailLoading"
            @click.prevent="handleSubmit(addEmail)"
          >
            Add email
          </ui-button>
        </template>
      </ui-modal>
    </validation-observer>
    <validation-observer v-slot="{ handleSubmit }">
      <div class="alerts">
        <p class="alerts__subtitle">
          {{ $t('alerts.subTitle[1]') }}<br>
          {{ $t('alerts.subTitle[2]') }}
          01.01.1988
        </p>
        <div class="alerts__calendar-wrapper">
          <h2 class="alerts__calendar-title">
            Report period
          </h2>
          <div class="alerts__calendar">
            <div class="col-12">
              <ui-calendar-range
                v-model="calendar.range"
                v-protected
                :min="calendar.min"
                :max="calendar.max"
              />
            </div>
          </div>
        </div>
        <div class="alerts__data">
          <h2 class="alerts__data-title">
            Data:
          </h2>
          <div class="alerts__data-group">
            <ui-checkbox
              v-for="item in exportData"
              v-protected
              :key="item.id"
              :checked="item.checked"
              class="alerts__data-checkbox"
            >
              {{ item.title }}
            </ui-checkbox>
          </div>
        </div>
        <div class="alerts__type">
          <h2 class="alerts__type-title">
            Type:
          </h2>
          <div class="alerts__type-group">
            <ui-checkbox
              v-for="item in exportTypes"
              v-protected
              :key="item.id"
              :checked="item.checked"
              class="alerts__type-checkbox"
            >
              {{ item.title }}
            </ui-checkbox>
          </div>
        </div>
        <div class="alerts__emails">
          <div class="alerts__emails-title">
            {{ $t('alerts.sendTo') }}
          </div>
          <ul class="alerts__emails-list">
            <li
              v-for="item in emails"
              :key="item.email"
              class="alerts__emails-item"
            >
              <span>{{ item.email }}</span>
              <ui-icon-button
                v-protected
                name="trash"
                size="18"
                class="alerts__emails-trash"
                @click.prevent="deleteEmail(item.email)"
              />
            </li>
          </ul>
          <ui-button
            v-protected
            outline
            action
            type="primary"
            @click.prevent="showEmailModal"
            size="sm"
          >
            {{ $t('alerts.addNewEmail') }}
          </ui-button>
        </div>
        <ui-button
          v-protected
          action
          type="primary"
          class="alerts__button"
          :is-loading="dataLoading"
          @click.prevent="handleSubmit(sendData)"
          :disabled="isDisabled"
        >
          Send
        </ui-button>
      </div>
    </validation-observer>
  </ui-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import api from '@/api';

export default {

  data() {
    return {
      exportData: [
        {
          id: 1,
          title: 'Full Name',
          value: 'fullName',
          checked: true,
        },
        {
          id: 2,
          title: 'Hours',
          value: 'dateOfBirth',
          checked: true,
        },
        {
          id: 3,
          title: 'Staff Id',
          value: 'hours',
          checked: true,
        },
        {
          id: 4,
          title: 'Location Name',
          value: 'staffType',
          checked: true,
        },
        {
          id: 5,
          title: 'Date of birth',
          value: 'staffId',
          checked: true,
        },
        {
          id: 6,
          title: 'Staff type',
          value: 'amount',
          checked: true,
        },
        {
          id: 7,
          title: 'Amount',
          value: 'locationName',
          checked: true,
        },
        {
          id: 8,
          title: 'Location address',
          value: 'locationAddress',
          checked: true,
        },
      ],
      exportTypes: [
        {
          id: 1,
          title: 'Microsoft Excel',
          value: 'excel',
          checked: true,
        },
        {
          id: 2,
          title: 'PDF',
          value: 'pdf',
          checked: true,
        },
        {
          id: 3,
          title: 'CSV',
          value: 'csv',
          checked: true,
        },
      ],
      emails: [],
      newEmail: '',
      newEmailModal: false,
      successModal: false,
      emailLoading: false,
      dataLoading: false,
      calendar: {
        range: [],
        min: null,
        max: DateTime.local().minus({ day: 1 }).endOf('day').toJSDate(),
      },
    };
  },

  computed: {
    ...mapState({
      location: (state) => state.user.location,
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
    }),
    ...mapGetters('user', [
      'defaultLocationRequired',
    ]),
    isEmptyCalendarRange() {
      return this.calendar.range.length === 0 || this.calendar.range.some((item) => item === null);
    },
    isDisabled() {
      return this.dataLoading
       || this.isEmptyCalendarRange;
    },
    storeUpdated() {
      return `${this.accessToken}-${this.payoutId}`;
    },
  },

  mounted() {
    if (this.viewAsMode === null) {
      this.$amplitude.event('OPENED_PAGE', {
        userRoles: this.userRoles,
        hostname: window.location.hostname,
        pagepath: window.location.pathname,
        url: window.location.href,
      });
    }
  },

  watch: {
    defaultLocationRequired: {
      immediate: true,
      async handler(value) {
        if (value) {
          return;
        }

        this.loadData();
      },
    },
    storeUpdated: {
      immediate: true,
      async handler() {
        this.loadData();
      },
    },
  },

  methods: {
    async deleteEmail(email) {
      await api.alerts.deleteEmail({
        email,
        restaurantId: this.location.id,
      });
      this.emails = this.emails.filter((x) => x.email !== email);
    },
    async addEmail() {
      if (this.newEmail.length === 0) {
        return;
      }

      if (this.emailLoading) {
        return;
      }

      this.emailLoading = true;

      try {
        const { data } = await api.alerts.addEmail({
          email: this.newEmail,
          restaurantId: this.location.id,
        });
        this.emails = data;
        this.newEmail = '';
        this.newEmailModal = false;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.emailLoading = false;
      }
    },
    showEmailModal() {
      this.newEmailModal = true;
    },
    async sendData() {
      if (this.dataLoading || this.isEmptyCalendarRange) {
        return;
      }

      this.dataLoading = true;

      try {
        const expData = {};
        const expFormat = {};

        this.exportData.forEach((x) => {
          expData[x.value] = x.checked;
        });

        this.exportTypes.forEach((x) => {
          expFormat[x.value] = x.checked;
        });

        const calendarRange = this.calendar.range.map((d) => {
          return DateTime.fromJSDate(d).toISODate();
        });

        await api.alerts.getAlerts({
          startDate: calendarRange[0],
          endDate: calendarRange[1],
          exportData: expData,
          exportFormat: expFormat,
          restaurantId: this.location.id,
        });
        this.calendar.range = [];
        this.exportData.forEach((x) => { x.checked = true; });
        this.exportTypes.forEach((x) => { x.checked = true; });
        this.successModal = true;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.dataLoading = false;
      }
    },
    async loadData() {
      const { data } = await api.alerts.getEmails({
        restaurantId: this.location.id,
      });

      if (data) {
        this.emails = data;
      }
    },
  },
};
</script>
