var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ui-card',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('password.title')))]),_c('h3',{staticClass:"card-category mt-2"},[_vm._v(_vm._s(_vm.$t('password.subtitle')))])]},proxy:true}],null,true)},[_c('ui-modal-status',{attrs:{"message":_vm.$t('profile.successModal.subTitle'),"closable":false},on:{"closed":_vm.logoutHandler},model:{value:(_vm.successModal),callback:function ($$v) {_vm.successModal=$$v},expression:"successModal"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.oldPassword'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-password',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.oldPassword'),"name":"oldPassword","required":"","error":errors[0]},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{attrs:{"tag":"div","vid":"newPassword","name":_vm.$t('form.labels.newPassword'),"rules":"required|min:8|max:30|password","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-password',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.newPassword'),"maxlength":"30","minlength":"8","required":"","error":errors[0]},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-8 col-lg-4"},[_c('validation-provider',{ref:"newPasswordConfirm",attrs:{"tag":"div","name":_vm.$t('form.labels.repeatPassword'),"rules":"required|min:8|max:30|password|confirmedPassword:newPassword","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-password',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":_vm.$t('form.labels.repeatPassword'),"maxlength":"30","minlength":"8","required":"","error":errors[0]},model:{value:(_vm.form.newPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.form, "newPasswordConfirm", $$v)},expression:"form.newPasswordConfirm"}})]}}],null,true)})],1)]),_c('ui-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"mt-4",attrs:{"action":"","type":"primary","is-loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.update'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }