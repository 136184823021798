<template>
  <div class="radio-card-wrapper" @click="$emit('select')">
    <div
      class="radio-card"
      :class="{
        'radio-card_selected': selected,
        'radio-card_disabled': disabled,
      }"
    >
      <div class="radio-card__header">
        <div v-if="header">
          {{ header }}
        </div>
        <div
          v-if="icon"
          class="radio-card__icon-wrapper"
        >
          <ui-icon
            :name="icon"
            class="radio-card__icon"
          />
        </div>
      </div>
      <div class="radio-card__body">
        <div class="radio-card__main-row">
          <div class="radio-card__title">
            <span class="radio-card__title-value">
              {{ title }}
            </span>
            <span
              class="radio-card__title-plus"
            >
              +
            </span>
          </div>
          <div class="radio-card__info">
            <div class="radio-card__info-value">
              {{ info.value }}
            </div>
            <div class="radio-card__info-description">
              {{ info.description }}
            </div>
          </div>
        </div>
        <div class="radio-card__secondary-row">
          <div class="radio-card__status-description">
            {{ description }}
          </div>
          <div class="radio-card__status">
            <div class="radio-card__status-value" />
          </div>
        </div>
      </div>
    </div>
    <template v-if="Array.isArray(example)">
      <div class="radio-card__description">
        <div
          v-for="exampleRow in example"
          :key="exampleRow"
          class="radio-card__description-string"
        >
          {{ exampleRow }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="radio-card__description">
        {{ description }}
      </div>
    </template>
  </div>
</template>

<script>
import UiIcon from '@/components/UI/Icon.vue';

export default {
  name: 'RadioCard',

  components: {
    UiIcon,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default: () => {},
    },
    example: {
      type: [String, Array],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    selected: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.design .radio-card__title {
  font-family: "RobotoBold", sans-serif !important;
}
</style>
