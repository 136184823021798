<template>
  <validation-observer tag="form" #default="{ invalid, handleSubmit }">
    <ui-modal-alert
      v-bind="$attrs"
      :title="$t('Add Payroll ID')"
      :apply-disabled="invalid"
      :apply-button="$t('form.buttons.add')"
      :close-button="$t('form.buttons.cancel')"
      @apply="handleSubmit(handleApply)"
      @cancel="handleCancel"
    >
      <strong>
        {{ staff.fio || staff.login }}
      </strong>
      <validation-provider
        tag="div"
        rules="required"
      >
        <ui-input
          v-model="payrollId"
          :placeholder="'Enter Payroll ID'"
          class="w-75 pt-4 m-auto"
          input-classes="text-center"
          required
        />
      </validation-provider>
    </ui-modal-alert>
  </validation-observer>
</template>

<script>
import api from '@/api';

export default {
  name: 'StaffPayrollModal',

  props: {
    staff: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    payrollId: null,
  }),

  created() {
    this.payrollId = this.staff.payrollId;
  },

  methods: {
    async handleApply() {
      try {
        await api.locations.addPayroll({
          locationId: this.location.id,
          staffId: this.staff.id,
          payrollId: this.payrollId,
        });

        this.$emit('apply', this.payrollId);
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
