/**
 * Преобразовать строку, в строку с дробным значением
 * @param {string} value
 * @param {number|null} afterPoint
 * @returns {string}
 */
export default (value, afterPoint = null) => {
  value = String(value).replace(',', '.').replace(/[^.\d]+/g, '').replace(/^([^.]*\.)|\./g, '$1');
  const valueArray = value.split('.');
  if (valueArray[0] === '') valueArray[0] = 0;
  if (valueArray.length > 1) {
    value = `${valueArray[0]}.${valueArray[1].substr(0, afterPoint || valueArray[1].length)}`;
  } else {
    value = parseInt(value, 10);
  }
  return value || 0;
};
