var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-6 col-sm-12"},[_c('ui-select',{staticClass:"select-width mt-0",attrs:{"items":_vm.periodPresets,"label-key":"name","value-key":"value","bordered":""},on:{"change":_vm.updateFilter},model:{value:(_vm.filter.period),callback:function ($$v) {_vm.$set(_vm.filter, "period", $$v)},expression:"filter.period"}})],1)]),(_vm.isLoading)?_c('ui-card',{staticClass:"py-5",attrs:{"is-loading":""}}):_vm._e(),_c('ui-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],attrs:{"columns":_vm.columns,"data":_vm.items,"data-table":"sales","separatable":""},on:{"sort":_vm.setSort},scopedSlots:_vm._u([{key:"sales-ranking",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"sales-gmv-per-clients-value",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("money")(value,row.avgGMV.currency))+" ")]}},{key:"sales-gmv-total-value",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("money")(value,row.totalGMV.currency))+" ")]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg"},[_c('div',{staticClass:"app-title app-title_secondary"},[_vm._v(" Ranking ")])])])}]

export { render, staticRenderFns }