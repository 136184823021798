<template>
  <validation-observer v-slot="{ validate }">
    <ui-card tag="form">
      <div class="form-group mt-0 mb-4">
        <ui-avatar
          v-protected
          :image="image"
          :is-loading="loading.avatar"
          :title="avatarTitle"
          @save="uploadAvatar"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <validation-provider
            tag="div"
            :name="$t('form.labels.firstName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.firstName"
              v-protected
              :label="$t('form.labels.firstName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.lastName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.lastName"
              v-protected
              :label="$t('form.labels.lastName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            v-if="isStaff || isTroncMaster"
            tag="div"
            :name="$t('form.labels.staffType')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.staffType"
              v-protected
              :items="staffTypes"
              :label="$t('form.labels.staffType')"
              :placeholder="$t('form.placeholders.select')"
              required
              :disabled="!isTroncMaster"
              :error="errors[0]"
              @input="onStaffTypeChanged"
            />
          </validation-provider>

          <validation-provider
            v-if="showNickName"
            tag="div"
            name="Nickname (for tipping page)"
            rules="min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.nickName"
              v-protected
              label="Nickname (for tipping page)"
              maxlength="30"
              minlength="2"
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            v-if="showTaxReportingTypes"
            tag="div"
            name="Tax reporting"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.taxReportingType"
              v-protected
              :items="MAIN_TAX_REPORTING_TYPES"
              label-key="label"
              value-key="value"
              label="Tax reporting"
              :placeholder="$t('form.placeholders.select')"
              required
              :error="errors[0]"
              :disabled="isTaxReportingDisabled"
            />
          </validation-provider>

          <validation-provider
            v-if="!isAccountant && !isCommon"
            tag="div"
            :name="$t('form.labels.wish')"
            rules="min:2|max:140"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.wish"
              v-protected
              :label="$t('form.placeholders.wish')"
              :label-info="$t('form.help.wish')"
              tag="textarea"
              rows="1"
              maxlength="140"
              minlength="2"
              :error="errors[0]"
              class="input-wish"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.email')"
            rules="required|email"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.email"
              v-protected
              :label="$t('form.labels.email')"
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.dateOfBirth')"
            :rules="{
              required: !isAccountant,
              adult: true,
            }"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-date-birth
              v-model="form.dateOfBirth"
              v-protected
              :required="!isAccountant"
              :error="errors[0]"
              input-classes="w-auto"
            />
          </validation-provider>

        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <ui-input
            v-model="location.name"
            :label="$t('form.labels.locationName')"
            disabled
          />
          <ui-input
            v-model="countryName"
            :label="$t('form.labels.country')"
            disabled
          />
          <ui-input
            v-model="location.city"
            :label="$t('form.labels.city')"
            disabled
          />
          <ui-input
            v-model="location.address"
            :label="$t('form.labels.address')"
            disabled
          />
        </div>
      </div>

      <ui-button
        v-protected
        action
        type="primary"
        :is-loading="loading.form"
        :disabled="loading.form"
        class="mt-4"
        @click.prevent="send(validate)"
      >
        {{ $t('form.buttons.update') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/api';
import parseDate from '@/utils/parseDate';
import toSQLDate from '@/utils/toSQLDate';
import { allCountries } from '@/config/countries';
import { MAIN_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import { NOBODY } from '@/config/staffTypes';
import disableTaxReportingType from '@/utils/disableTaxReportingType';

export default {

  data: () => ({
    loading: {
      avatar: false,
      form: false,
    },
    form: {
      email: '',
      firstName: '',
      lastName: '',
      wish: '',
      dateOfBirth: null,
      staffType: null,
      taxReportingType: null,
      nickName: null,
    },
    MAIN_TAX_REPORTING_TYPES,
  }),

  computed: {
    ...mapGetters('auth', [
      'isAccountant',
      'isTroncMaster',
      'isStaff',
    ]),
    ...mapGetters('user', [
      'isCommon',
      'isSelfReportingTypeLocation',
      'isUndefinedReportingTypeLocation',
    ]),
    ...mapState('user', [
      'profile',
      'image',
      'location',
    ]),
    countryName() {
      return allCountries.find(({ iso }) => iso === this.location.country)?.name
        || this.location.country;
    },
    staffTypes() {
      const filtered = this.$store.getters['handbook/staffTypes']
        .filter((staff) => staff.types.includes(this.location.type))
        .filter((staff) => staff.countries.includes(this.location.country));

      return [
        ...filtered,
        {
          name: 'N/A',
          value: NOBODY,
          type: this.location.type,
        },
      ];
    },
    avatarTitle() {
      return this.isAccountant
        ? this.$t('UI.avatar.managerTitle')
        : this.$t('UI.avatar.userTitle');
    },
    showTaxReportingTypes() {
      return this.isTroncMaster
        && this.form.staffType
        && this.form.staffType !== NOBODY
        && !this.isUndefinedReportingTypeLocation
        && !this.isSelfReportingTypeLocation;
    },
    showNickName() {
      if (this.isCommon) {
        return false;
      }
      if (this.isStaff) {
        return true;
      }

      return this.isTroncMaster && this.form.staffType && this.form.staffType !== NOBODY;
    },
    isTaxReportingDisabled() {
      return this.disableTaxReportingType(this.location.taxReportingType);
    },
  },

  async mounted() {
    this.loadData();

    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  methods: {
    ...mapActions({
      initializeSupport: 'support/initialize',
      loadInfo: 'user/loadInfo',
      uploadImage: 'user/uploadImage',
      getStaffTypes: 'handbook/getStaffTypes',
      refreshToken: 'auth/refreshToken',
    }),
    disableTaxReportingType,
    loadData() {
      this.form.email = this.profile.email;
      this.form.firstName = this.profile.firstName;
      this.form.lastName = this.profile.lastName;
      this.form.wish = this.profile.wish;
      this.form.dateOfBirth = this.profile.dateOfBirth
        ? parseDate(this.profile.dateOfBirth)
        : null;
      this.form.staffType = this.profile.staffType;
      this.form.nickName = this.profile.nickName;
      if (this.isTaxReportingDisabled) {
        this.form.taxReportingType = this.location.taxReportingType;
      } else {
        this.form.taxReportingType = this.profile.taxReportingType;
      }
    },
    async uploadAvatar(file) {
      this.loading.avatar = true;

      try {
        await this.uploadImage(file);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.avatar = false;
      }
    },
    async send(validate) {
      if (!await validate()) {
        this.$warning({
          message: this.$t('alerts.requiredModal.message'),
        });

        return;
      }

      if (this.loading.form) {
        return;
      }

      this.loading.form = true;

      try {
        await api.user.updateProfile({
          ...this.form,
          dateOfBirth: toSQLDate(this.form.dateOfBirth),
        });

        const isOldNullable = [NOBODY, null].includes(this.profile.staffType);
        const isNewNullable = [NOBODY, null].includes(this.form.staffType);

        await this.loadInfo();
        await this.initializeSupport();

        if (isOldNullable !== isNewNullable) {
          await this.refreshToken(() => {});
        }

        this.loadData();
        this.$showSuccess();
        this.$emit('updated');
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
    onStaffTypeChanged() {
      if (this.form.staffType === NOBODY) {
        this.form.taxReportingType = null;
        this.form.nickName = null;
      }
    },
  },
};
</script>
