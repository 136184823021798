<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="reviews-modal"
    size="xl"
    no-footer
  >
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      {{ $t('reviews.lastReviewsTitle') }}
    </template>

    <div
      v-if="reviews.length"
      class="last-reviews__list"
    >
      <div
        v-for="(review, index) in reviews"
        :key="`${review.message}-${index}`"
        class="last-reviews__review"
      >
        <div class="last-reviews__tools">
          <div class="last-reviews__stars">
            <ui-stars
              :value="review.stars"
              size="16"
            />
          </div>
          <div
            v-if="showSocialMediaButton"
            v-protected
            class="last-reviews__link"
            @click="openCreatePictureModal(review)"
          >
            <ui-icon
              size="20"
              name="instagram"
              class="mr-1"
            />
            <ui-button
              type="primary"
              link
            >
              Create picture
            </ui-button>
          </div>
        </div>
        <p class="last-reviews__message">
          {{ review.message }}
        </p>
        <div class="last-reviews__cards">
          <ui-badge
            v-for="item in review.cards"
            :key="item"
            type="default"
          >
            {{ item }}
          </ui-badge>
        </div>
        <div class="last-reviews__date">
          {{ review.time }}, {{ review.date }}
        </div>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import { mapState } from 'vuex';
import socialMediaReviewTypes from '@/config/socialMediaReviewTypes';

export default {

  props: {
    reviews: Array,
  },

  computed: {
    ...mapState({
      location: (state) => state.user.location,
    }),
    showSocialMediaButton() {
      return socialMediaReviewTypes.includes(this.location.typeOfReview);
    },
  },

  methods: {
    openCreatePictureModal(review) {
      this.$emit('create-picture', review);
    },
  },
};
</script>
