<template>
  <ui-card>
    <template #header>
      <h4 class="card-title">
        {{ $t('stripeAccount.title') }}
      </h4>
    </template>

    <ui-alert
      v-if="!isProfileFilled"
      title="Attention!"
      message="Please, fill out all the information about yourself!"
      icon="warning"
      type="danger"
    />

    <p>{{ $t('stripeAccount.text[0]') }}</p>
    <user-stripe />
  </ui-card>
</template>

<script>
import { mapGetters } from 'vuex';
import UserStripe from '@/components/User/UserStripe.vue';

export default {
  components: { UserStripe },

  computed: {
    ...mapGetters('user', [
      'isProfileFilled',
    ]),
  },
};
</script>
