<template>
  <ui-modal v-bind="$attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      {{ 'Top earners' }}
    </template>

    <div
      v-for="(item, index) in items"
      :key="index"
      class="d-flex justify-content-between"
    >
      <div class="card-label mr-auto">
        <b>{{ index + 1 }}.</b> {{ item.fio }}
      </div>
      <div class="card-value">
        <template v-if="item.sum">
          {{ item.sum.amount | money(item.sum.currency) }}
        </template>
      </div>
    </div>
  </ui-modal>
</template>

<script>
export default {

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
