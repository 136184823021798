<template>
  <div class="sublocations-selector">
    <ui-select
      v-model="selectedSublocationId"
      :items="sublocations"
      class="sublocations-selector__select"
      label-key="name"
      value-key="id"
      bordered
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const ROUTES_WHERE_ONLY_COMMON_NEEDED = [
  'distributions',
];

export default {
  name: 'SublocationsSelector',

  computed: {
    ...mapState({
      allSublocations: (state) => state.sublocations.sublocations,
      selectedSublocation: (state) => state.sublocations.selectedSublocation,
    }),
    ...mapGetters({
      isMasterLocation: 'user/isMasterLocation',
      commonSublocations: 'sublocations/commonSublocations',
      selectedAllSublocations: 'sublocations/selectedAllSublocations',
    }),
    sublocations() {
      if (ROUTES_WHERE_ONLY_COMMON_NEEDED.includes(this.$route.name)) {
        return this.commonSublocations;
      }
      return this.allSublocations;
    },
    selectedSublocationId: {
      get() {
        return this.selectedSublocation?.id;
      },
      set(sublocationId) {
        this.setSelectedSublocation(sublocationId);
      },
    },
  },

  methods: {
    ...mapActions({
      setSelectedSublocation: 'sublocations/setSelectedSublocation',
      resetSelectedSublocation: 'sublocations/resetSelectedSublocation',
    }),
    resetSelectedSublocationHandler() {
      const sublocationIsActual = this.sublocations.some((sublocation) => sublocation.id === this.selectedSublocation.id);
      if (!sublocationIsActual) {
        this.resetSelectedSublocation();
      }
    },
  },

  mounted() {
    this.resetSelectedSublocationHandler();
  },
};
</script>
