/**
 * Преобразовать время в часах, в дробное значение с двумя знаками после точки
 * @param {number} num
 * @returns {number|number}
 */
export default (num) => {
  num = Number(num);
  if (Number.isNaN(num)) {
    return 0;
  }
  if (String(num).split('.').length < 2 || String(num).split('.')[1].length <= 2) {
    num = num.toFixed(2);
  }
  return num;
};
