import getFio from '../fio';

export default ({
  user,
  workplace,
  id,
  ...staff
}) => {
  const {
    kycStatus: paymentStatus,
    gateway: paymentSystem,
    ...info
  } = user;

  const country = workplace.country || workplace.countryIsoCode;
  const type = workplace.type || workplace.workplaceType;
  const location = {
    ...workplace,
    country,
    type,
  };

  return {
    ...staff,
    ...info,
    id,
    user: {
      workPlace: location,
      ...user,
    },
    fio: getFio(info),
    paymentSystem,
    paymentStatus,
    location,
  };
};
