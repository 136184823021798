<template>
  <div v-if="items.length">
    <ui-card
      v-bind="$attrs"
      :is-loading="isLoading"
      class="card-compliments h-100"
    >
      <div class="card-header pt-0 px-0 card-compliments__title">
        <h4 class="card-title">
          {{ $t('reviews.complimentsTitle') }}
        </h4>
      </div>
      <template v-if="previewReviews.length">
        <div class="card-compliments__preview">
          <div
            v-for="item in previewReviews"
            :key="item.id"
            class="card-compliments__compliment"
          >
            <review-icon
              :name="item.name"
              :location-type="locationType"
              size="36px"
              class="card-compliments__icon"
            />
            <div class="card-label card-compliments__name">
              {{ $t(item.name, locationCountry) }}
            </div>
            <div class="card-value">
              {{ item.stats }}
            </div>
          </div>
        </div>
      </template>
      <span v-else class="text-gray">
      {{ $t('noData') }}
    </span>

      <template #footer>
        <stats-compliments-modal
          v-if="statsReviews.length > previewCount"
          :reviews="statsReviews"
          :location-type="locationType"
          :location-country="locationCountry"
        >
          <template #activator="{ open }">
            <ui-button
              link
              type="primary"
              @click="open"
            >
              {{ $t('form.buttons.viewAll') }}
            </ui-button>
          </template>
        </stats-compliments-modal>
      </template>
    </ui-card>
  </div>
</template>

<script>
import api from '@/api';
import ReviewIcon from '@/components/ReviewIcon.vue';
import StatsComplimentsModal from './StatsComplimentsModal.vue';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    ReviewIcon,
    StatsComplimentsModal,
  },

  props: {
    reviews: {
      type: Array,
      required: true,
    },
    locationType: {
      type: String,
      default: 'restaurant',
    },
    groupId: [Number, String],
    locationCountry: String,
  },

  data: () => ({
    items: [],
    previewCount: 3,
  }),

  watch: {
    items: {
      handler(value) {
        if (value.length) {
          this.$emit('visible');
        }
      },
    },
  },

  computed: {
    statsReviews() {
      const result = this.reviews
        .filter(({ type, workPlaceType }) => {
          return workPlaceType === this.locationType;
        })
        .map(({ id, ...params }) => {
          const stats = this.items.find((item) => String(item.id) === String(id));

          return {
            ...params,
            id,
            stats: parseInt(stats?.cnt || 0, 10),
          };
        })
        .filter(({ stats }) => {
          return stats > 0;
        });

      result.sort((a, b) => b.stats - a.stats);

      return result;
    },
    previewReviews() {
      return this.statsReviews.slice(0, this.previewCount);
    },
  },

  methods: {
    async load() {
      const { data } = await api.locations.getTopCompliments({
        locationId: this.locationId,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });

      if (data) {
        this.items = data;
      }
    },
  },
};
</script>
