<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="picture-modal"
    size="lg"
    no-footer
    :is-loading="isModalLoading"
    @closed="onClosed"
    @before-open="beforeOpen"
  >
    <template #title>
      {{ $t('reviews.lastReviewsPictureTitle') }}
    </template>

    <div class="picture-modal__content">
      <div
        v-if="isCanvasLoading"
        class="picture-modal__loader-wrapper"
      >
        <ui-loading class="picture-modal__loader" inline />
      </div>
      <div v-else class="picture-modal__preview canvas-wrapper"></div>
      <div class="picture-modal__controls">
        <div class="picture-modal__images">
          <img
            v-for="item in backgrounds"
            :key="item.title"
            :src="item.src"
            :alt="item.title"
            :class="{'picture-modal__img-active': item.isSelected }"
            @click="selectBackground(item)"
          >
        </div>
        <div class="picture-modal__btn-preview">
          <ui-button
            :class="{'picture-modal__btn-active': btnPostPreview }"
            size="sm"
            link
            @click="handlePreview('post')"
          >
            Post preview
          </ui-button>
          <ui-button
            :class="{'picture-modal__btn-active': btnStoryPreview }"
            size="sm"
            link
            @click="handlePreview('story')"
          >
            Stories preview
          </ui-button>
        </div>
        <ui-button
          type="primary"
          class="picture-modal__btn-download"
          :disabled="!selectedBackground || isCanvasLoading"
          @click="downloadImage"
        >
          Download picture
        </ui-button>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import api from '@/api';
import images from '@/config/igBackgrounds';
import toBlob from '@/utils/toBlob';
import download from '@/utils/download';
import getImage from '@/utils/getImage';
import ResetMixin from '@/mixins/reset-mixin';

const IG_POST = {
  width: 1080,
  height: 1080,
};

const IG_STORY = {
  width: 1080,
  height: 1920,
};

export default {

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    review: Object,
    locationImage: String,
  },

  mixins: [
    ResetMixin(() => ({
      canvas: null,
      ctx: null,
      dw: null,
      dh: null,
      isModalLoading: false,
      isCanvasLoading: false,
      backgrounds: null,
      selectedBackground: null,
      btnPostPreview: true,
      btnStoryPreview: false,
    })),
  ],

  watch: {
    selectedBackground: {
      deep: true,
      handler(value) {
        if (value) {
          this.initCanvas();
        }
      },
    },
  },

  methods: {
    getImage,
    onClosed() {
      this.reset();
      this.destroyCanvas();
    },
    beforeOpen() {
      this.initBackgrounds();
      this.createCanvas();
    },
    handlePreview(preview) {
      if (this.isCanvasLoading) {
        return;
      }

      this.btnPostPreview = preview === 'post';
      this.btnStoryPreview = !this.btnPostPreview;

      this.initCanvas();
    },
    initBackgrounds() {
      this.isModalLoading = true;
      const backgrounds = [];

      const totalImages = images.length;
      let loadedImages = 0;
      images.forEach(async (title) => {
        const {
          data: imageBlob,
        } = await api.user.getReviewsImages({
          name: title,
          quality: 'lq',
        });

        const img = new Image();
        img.src = window.URL.createObjectURL(imageBlob);

        img.onload = () => {
          loadedImages += 1;

          backgrounds.push({
            title,
            isSelected: false,
            src: img.src,
          });

          if (loadedImages === totalImages) {
            backgrounds.sort((a, b) => parseInt(a.title, 10) - parseInt(b.title, 10));
            this.backgrounds = backgrounds;
            this.isModalLoading = false;
            this.selectBackground(this.backgrounds[0]);
          }
        };
      });
    },
    selectBackground(item) {
      if (this.isCanvasLoading) {
        return;
      }

      this.backgrounds = this.backgrounds.map((b) => {
        return {
          ...b,
          isSelected: b.title === item.title,
        };
      });

      this.selectedBackground = item;
    },
    createCanvas() {
      const canvasWrapper = document.querySelector('.canvas-wrapper');
      const canvas = document.createElement('canvas');
      canvas.id = 'canvas';

      canvasWrapper.append(canvas);
      this.canvas = canvas;
      this.ctx = this.canvas.getContext('2d');
    },
    destroyCanvas() {
      const canvas = document.querySelector('#canvas');
      canvas.remove();
    },
    async initCanvas() {
      if (!this.selectedBackground) {
        return;
      }

      if (this.btnPostPreview) {
        this.canvas.width = IG_POST.width;
        this.canvas.height = IG_POST.height;
      }

      if (this.btnStoryPreview) {
        this.canvas.width = IG_STORY.width;
        this.canvas.height = IG_STORY.height;
      }

      this.dw = this.canvas.width;
      this.dh = this.canvas.height;

      // Draw main background
      await this.drawBgImage(this.ctx);

      // Draw Post
      if (this.btnPostPreview) {
        // Draw location logo
        await this.drawLogoImage(this.ctx, 200, 0, 0.65);

        // Draw review message (post)
        this.drawText({
          ctx: this.ctx,
          font: '40px RobotoRegular',
          text: this.review.message,
          bgColor: '#fff',
          x: 220,
          y: 400,
          lineHeight: 78,
          fitWidth: 750,
          maxLine: 6,
        });

        // Draw review quotes (post)
        await this.drawQuotesImage(this.ctx, 120, 310, 0.50);

        // Draw review stars (post)
        await this.drawStarsImage(this.ctx, 180, 250, 60, 0, 0.40);

        // Draw easytip logo (post)
        await this.drawEasytipImage(this.ctx, 200, 0.7);

        // Draw easytip text (post)
        const text = 'Get your best reviews at';
        const textWidth = this.ctx.measureText(text).width;
        const ftextOffset = this.dw - textWidth - 120;
        const stextOffset = this.dw - textWidth + 210;
        const offset = {
          first: {
            x: this.dw - textWidth - 120,
            y: this.dh - 70,
          },
          second: {
            x: this.dw - textWidth + 175,
            y: this.dh - 70,
          },
        };

        this.drawText({
          ctx: this.ctx,
          font: '27px RobotoRegular',
          text,
          x: offset.first.x,
          y: offset.first.y,
          lineHeight: 70,
          fitWidth: 750,
        });

        this.drawText({
          ctx: this.ctx,
          font: '27px RobotoBold',
          text: 'easytip.net',
          x: offset.second.x,
          y: offset.second.y,
          lineHeight: 70,
          fitWidth: 750,
        });
      }

      // Draw Story
      if (this.btnStoryPreview) {
        // Draw location logo
        await this.drawLogoImage(this.ctx, 150, 0, 0.75);

        // Draw review quotes (story)
        await this.drawQuotesImage(this.ctx, 90, 510, 0.8);

        // Draw review message (story)
        this.drawText({
          ctx: this.ctx,
          font: '60px RobotoRegular',
          text: this.review.message,
          bgColor: '#fff',
          x: 170,
          y: 650,
          lineHeight: 98,
          fitWidth: 800,
          maxLine: 9,
        });

        // Draw review stars (story)
        await this.drawStarsImage(this.ctx, 60, 350, 80, 0, 0.70);

        // Draw easytip logo (story)
        await this.drawEasytipImage(this.ctx, 150, 0.8);

        // Draw easytip text (story)
        const text = 'Get your best reviews';
        const textWidth = this.ctx.measureText(text).width;
        const offset = {
          first: {
            x: this.dw - textWidth + 170,
            y: this.dh - 130,
          },
          second: {
            x: this.dw - textWidth + 275,
            y: this.dh - 90,
          },
          third: {
            x: this.dw - textWidth + 310,
            y: this.dh - 90,
          },
        };

        this.drawText({
          ctx: this.ctx,
          font: '30px RobotoRegular',
          text,
          x: offset.first.x,
          y: offset.first.y,
          lineHeight: 70,
          fitWidth: 750,
        });

        this.drawText({
          ctx: this.ctx,
          font: '30px RobotoRegular',
          text: 'at',
          x: offset.second.x,
          y: offset.second.y,
          lineHeight: 70,
          fitWidth: 750,
        });

        this.drawText({
          ctx: this.ctx,
          font: '30px RobotoBold',
          text: 'easytip.net',
          x: offset.third.x,
          y: offset.third.y,
          lineHeight: 70,
          fitWidth: 750,
        });
      }
    },
    drawText(args) {
      const {
        ctx,
        font,
        text,
        bgColor,
        textColor = '#000',
        maxLine,
        x,
        y,
      } = args;

      let {
        lineHeight,
        fitWidth,
      } = args;

      fitWidth = fitWidth || 0;
      ctx.font = font;

      if (fitWidth <= 0) {
        ctx.fillText(text, x, y);
        return;
      }

      let words = text.split(' ');
      let currentLine = 0;
      let idx = 1;

      const longWord = [];
      if (!text.includes(' ') && text.length > 30) {
        let i = 0;
        let letters = 40;

        if (this.btnStoryPreview) {
          letters = 25;
        }

        while (i <= text.length) {
          const word = text.slice(i, i + letters);

          if (word) {
            longWord.push(word);
          }

          i += letters;
        }

        lineHeight -= 10;
        words = longWord;
      }

      while (words.length > 1 && idx <= words.length) {
        const str = words.slice(0, idx).join(' ');
        const w = ctx.measureText(str).width;

        if (w > fitWidth) {
          if (idx === 1) {
            idx = 2;
          }

          const textPerLineY = y + (lineHeight * currentLine);
          let textPerLine = words.slice(0, idx - 1).join(' ');
          let textPerLineWidth = ctx.measureText(textPerLine).width;

          if (currentLine === maxLine) {
            const tripleDots = '...';
            const tripleDotsWidth = ctx.measureText(tripleDots).width;

            textPerLineWidth += tripleDotsWidth;
            textPerLine += tripleDots;

            if (bgColor) {
              ctx.textBaseline = 'top';
              ctx.fillStyle = bgColor;
              ctx.fillRect(x - 20, textPerLineY - 25, textPerLineWidth + 30, parseInt(font, 10) + 50);
            }

            ctx.fillStyle = textColor;
            ctx.fillText(textPerLine, x, textPerLineY);

            idx = 0;

            return;
          }

          if (bgColor) {
            ctx.textBaseline = 'top';
            ctx.fillStyle = bgColor;
            ctx.fillRect(x - 20, textPerLineY - 25, textPerLineWidth + 30, parseInt(font, 10) + 50);
          }

          ctx.fillStyle = textColor;
          ctx.fillText(textPerLine, x, textPerLineY);

          currentLine += 1;
          words = words.splice(idx - 1);
          idx = 1;
        } else {
          idx += 1;
        }
      }

      if (idx > 0) {
        const textPerLineY = y + (lineHeight * currentLine);
        const textPerLine = words.join(' ');
        const textPerLineWidth = ctx.measureText(textPerLine).width;

        if (bgColor) {
          ctx.textBaseline = 'top';
          ctx.fillStyle = bgColor;
          ctx.fillRect(x - 20, textPerLineY - 25, textPerLineWidth + 30, parseInt(font, 10) + 50);
        }

        ctx.fillStyle = textColor;
        ctx.fillText(textPerLine, x, textPerLineY);
      }
    },
    loadCanvasImage(src) {
      return new Promise((resolve) => {
        const image = new Image();
        this.isCanvasLoading = true;
        this.canvas.style.display = 'none';

        image.addEventListener('load', () => {
          resolve(image);
          this.isCanvasLoading = false;
          this.canvas.style.display = 'block';
        });
        image.src = src;
      });
    },
    async drawBgImage(ctx) {
      this.isCanvasLoading = true;
      this.canvas.style.display = 'none';

      try {
        const {
          data: imageBlob,
        } = await api.user.getReviewsImages({
          name: this.selectedBackground.title,
          quality: 'hq',
        });
        const imageUrl = window.URL.createObjectURL(imageBlob);

        const image = await this.loadCanvasImage(imageUrl);

        let sx = 0;
        let sw = image.width;
        const sh = image.height;

        if (this.btnStoryPreview) {
          const dX = sw - this.dw;
          sx = dX / 2;
          sw -= dX;
        }

        ctx.drawImage(image, sx, 0, sw, sh, 0, 0, this.dw, this.dh);
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async drawLogoImage(ctx, x, y, ratio) {
      const image = await this.loadCanvasImage(this.locationImage);

      ctx.drawImage(image, x, y, image.width * ratio, image.height * ratio);
    },
    async drawQuotesImage(ctx, x, y, ratio) {
      const src = getImage('quotes', 'png', 'instagram');
      const image = await this.loadCanvasImage(src);

      ctx.drawImage(image, x, y, image.width * ratio, image.height * ratio);
    },
    async drawStarsImage(ctx, x, y, stepX, stepY, ratio) {
      const src = getImage('star', 'png', 'instagram');
      const image = await this.loadCanvasImage(src);

      let i = 1;
      let xstep = stepX;
      let ystep = stepY;

      while (i <= this.review.stars) {
        ctx.drawImage(image, x + xstep, y + ystep, image.width * ratio, image.height * ratio);

        xstep += stepX;
        ystep += stepY;
        i += 1;
      }
    },
    async drawEasytipImage(ctx, x, ratio) {
      const src = getImage('easytip-sm', 'png', 'instagram');
      const image = await this.loadCanvasImage(src);

      ctx.drawImage(image, x, this.dh - (image.height * ratio), image.width * ratio, image.height * ratio);
    },
    async downloadImage() {
      const imageBlob = await toBlob(this.canvas);
      const imageName = this.btnPostPreview
        ? 'ig-post-review'
        : 'ig-story-review';

      download(imageBlob, `${imageName}_${this.review.date}_${this.review.time}.jpg`);
    },
  },
};
</script>
