export const ORDERS_PERMISSION_ROLES = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Super admin',
    value: 'ORDERS_SUPERADMIN',
  },
  {
    label: 'Admin',
    value: 'ORDERS_ADMIN',
  },
];
