<template>
  <div class="info-card">
    <div class="info-card__container">
      <div class="info-card__header">
        <div class="info-card__header_left">
          <div class="info-card__title">{{ title }}</div>
          <div v-if="$slots['after-title']" class="info-card__after-title">
            <slot name="after-title" />
          </div>
          <div
            v-if="subtitle"
            class="info-card__subtitle"
          >
            {{ subtitle }}
          </div>
        </div>
        <div v-if="$slots['header-right']" class="info-card__header_right">
          <slot name="header-right" />
        </div>
      </div>
      <div v-if="$slots.subDescription" class="info-card__sub-description">
        <slot name="subDescription"/>
      </div>
      <div v-if="$slots.description" class="info-card__description">
        <slot name="description"/>
      </div>
      <slot />
      <div v-if="$slots.footer" class="info-card__footer">
        <slot name="footer"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'InfoCard',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>
