<template>
  <div class="reporting">
    <div class="row">
      <sublocations-selector
        v-if="isMasterLocation"
        class="col-auto"
      />
      <div class="col-auto" v-if="showGroups">
        <ui-select
          v-model="groupId"
          :items="groupOptions"
          class="select-width"
          label-key="name"
          value-key="id"
          bordered
        />
      </div>
      <div class="col-auto">
        <ui-calendar-period v-model="period" />
      </div>
    </div>

    <div v-if="!changeLocationLoading" class="reporting__cards" :class="{ 'reporting__cards_promo': showStatsBonusTips }">
      <div v-if="isStaff && profile.staffId" :class="{ 'reporting__double-card': showStatsBonusTips }">
        <stats-total-amount
          :key="`total-${profile.staffId}-${locationCurrency}-${from}-${to}`"
          :title="$t('reporting.tipsTitle.individual')"
          :location-id="location.id"
          :group-id="groupId"
          :staff-id="profile.staffId"
          :date-from="from"
          :date-to="to"
          @toggle-promo-date="togglePromoDate"
          class="m-0"
        />
        <stats-bonus-tips
          v-if="showStatsBonusTips"
          :key="`bonus-${location.id}-${profile.staffId}-${locationCurrency}-${from}-${to}`"
          :location-id="location.id"
          :location="location"
          :group-id="groupId"
          :staff-id="profile.staffId"
          :currency="locationCurrency"
          :date-from="from"
          :date-to="to"
        />
      </div>
      <div :class="{ 'reporting__double-card': showStatsBonusTips }">
        <stats-total-amount
          :key="`total-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :title="$t('reporting.tipsTitle.venue')"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          @toggle-promo-date="togglePromoDate"
          class="m-0"
        />
        <stats-bonus-tips
          v-if="showStatsBonusTips"
          :key="`bonus-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :location="location"
          :group-id="groupId"
          :currency="locationCurrency"
          :date-from="from"
          :date-to="to"
        />
      </div>
      <stats-venue
        v-if="showVenueCharge"
        :key="`venue-${location.id}-${groupId}-${from}-${to}`"
        :location-id="location.id"
        :group-id="groupId"
        :date-from="from"
        :date-to="to"
      />
      <stats-total-rating
        :key="`rating-first-${location.id}-${groupId}-${from}-${to}`"
        :title="$t('reporting.ratingTitle.venue')"
        :location-id="location.id"
        :group-id="groupId"
        :date-from="from"
        :date-to="to"
      />
      <stats-total-rating
        v-if="showIndividualRating"
        :key="`rating-second-${location.id}-${groupId}-${from}-${to}`"
        :title="$t('reporting.ratingTitle.individual')"
        :location-id="location.id"
        :staff-id="profile.staffId"
        :group-id="groupId"
        :date-from="from"
        :date-to="to"
      />
    </div>

    <transaction-graph
      :key="`graph-${location.id}-${groupId}-${from}-${to}`"
      :location="location"
      :group-id="groupId"
      :date-from="from"
      :date-to="to"
      class="mt-4"
    />
    <transaction-list
      id="transaction-list"
      :key="`table-${location.id}-${groupId}-${from}-${to}`"
      :location="location"
      :group-id="groupId"
      :show-amount="showAmount"
      :date-from="from"
      :date-to="to"
      :show-id="showTransactionsIdNameCols"
      :show-name="showTransactionsIdNameCols"
      show-promo
      class="mt-4"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import StatsTotalAmount from '@/components/Stats/StatsTotalAmount.vue';
import StatsVenue from '@/components/Stats/StatsVenue.vue';
import StatsTotalRating from '@/components/Stats/StatsTotalRating.vue';
import StatsBonusTips from '@/components/Stats/StatsBonusTips.vue';
import TransactionGraph from '@/components/Payins/TransactionGraph.vue';
import TransactionList from '@/components/Payins/TransactionList.vue';
import { NOBODY } from '@/config/staffTypes';
import SublocationsSelector from '@/components/SublocationsSelector.vue';

export default {

  components: {
    SublocationsSelector,
    StatsTotalAmount,
    StatsVenue,
    StatsTotalRating,
    StatsBonusTips,
    TransactionGraph,
    TransactionList,
  },

  data: () => ({
    groupId: 0,
    period: [],
  }),

  computed: {
    ...mapGetters({
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
      isAccountant: 'auth/isAccountant',
      isDistributionMaster: 'auth/isDistributionMaster',
      isStaff: 'auth/isStaff',
      hasLocationFee: 'user/hasLocationFee',
      isUAE: 'user/isUAE',
      isMasterLocation: 'user/isMasterLocation',
    }),
    ...mapState({
      image: (state) => state.user.image,
      currentLocation: (state) => state.user.location,
      selectedSublocation: (state) => state.sublocations.selectedSublocation,
      profile: (state) => state.user.profile,
      staffs: (state) => state.staffs.items,
      staffsLoaded: (state) => state.staffs.loaded,
      groups: (state) => state.groups.items,
      groupsLoaded: (state) => state.groups.loaded,
      isActivePromo: (state) => state.user.location.activePromo,
      changeLocationLoading: (state) => state.user.changeLocationLoading,
    }),
    location() {
      return this.selectedSublocation || this.currentLocation;
    },
    locationCurrency() {
      return this.currentLocation.currencyCode;
    },
    showVenueCharge() {
      return (this.location.workplaceFee > 0 && !this.isDistributionMaster)
        || this.showVenueChargeForCommon
        || this.showVenueChargeForMasterOwner;
    },
    showVenueChargeForCommon() {
      return this.isUAE && this.isCommon && this.isOwner && this.hasLocationFee;
    },
    showVenueChargeForMasterOwner() {
      return this.currentLocation.isMasterLocation && this.isOwner && this.location.tipsType === 'COMMON';
    },
    showIndividualRating() {
      return this.profile.staffType && this.profile.staffType !== NOBODY && (!this.isCommon && !this.isUAE);
    },
    showGroups() {
      return (this.isOwner || this.isAccountant) && this.location.paymentPage === 'GROUPS';
    },
    showTransactionsIdNameCols() {
      return (!this.isUAE && this.isCommon) || !this.isCommon;
    },
    groupOptions() {
      return [
        {
          id: 0,
          name: this.$t('form.labels.allGroups'),
        },
        ...this.groups,
      ];
    },
    showAmount() {
      if (!this.isOwner && !this.isAccountant) {
        return true;
      }

      return this.location.taxReportingStatus !== 'SELF_REPORTING';
    },
    showStatsBonusTips() {
      return this.isOwner && this.isActivePromo;
    },
    from() {
      return this.period?.[0]
        || DateTime.local().minus({ days: 30 }).toJSDate();
    },
    to() {
      return this.period?.[1]
        || DateTime.local().toJSDate();
    },
  },

  mounted() {
    if (!this.staffsLoaded) {
      this.loadStaffs();
    }

    if (!this.groupsLoaded) {
      this.loadGroups();
    }
  },

  methods: {
    ...mapActions({
      loadStaffItems: 'staffs/loadItems',
      loadGroupItems: 'groups/loadItems',
    }),
    selectedPeriod({ dateFrom, dateTo }) {
      this.from = dateFrom;
      this.to = dateTo;
    },
    async loadStaffs() {
      try {
        await this.loadStaffItems({
          locationId: this.location.id,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadGroups() {
      if (!this.showGroups) {
        return;
      }

      try {
        await this.loadGroupItems({
          locationId: this.location.id,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    togglePromoDate(value) {
      this.period = [
        DateTime.fromISO(value[0]).toJSDate(),
        DateTime.fromISO(value[1]).toJSDate(),
      ];

      this.$scroll('#transaction-list');
    },
  },
};
</script>
