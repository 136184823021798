<template>
  <div>
    <ui-modal
      show
      title="Update E-mail"
      class="change-email-modal"
      no-footer
      @closed="handleClose"
    >
      <template #header>
        <div class="change-email-modal__title">
          Update E-mail
        </div>
      </template>
      <div class="change-email-modal__body">
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            tag="div"
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="newEmail"
              class="change-email-modal__email"
              label="Email"
              required
              :error="errors[0]"
            />
          </validation-provider>
          <div class="change-email-modal__footer">
            <ui-button
              size="lg"
              type="primary"
              class="change-email-modal__update-btn"
              @click.prevent="handleSubmit(changeEmail)"
            >
              Update
            </ui-button>
          </div>
        </validation-observer>
      </div>
    </ui-modal>
    <ui-modal-status
      :show="status.show"
      :title="status.title"
      :message="status.message"
      :type="status.type"
      @closed="handleStatusClose"
    />
  </div>
</template>

<script>
import api from '@/api';

const INITIAL_STATUS = {
  show: false,
  type: 'success',
  title: 'E-mail has been updated',
  message: '',
};

export default {
  name: 'ChangeEmailModal',

  props: {
    user: {
      type: Object,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          'owners',
          'troncMasters',
          'accountants',
        ].includes(value);
      },
    },
  },

  data() {
    return {
      newEmail: '',
      status: { ...INITIAL_STATUS },
    };
  },

  methods: {
    handleClose() {
      this.status = { ...INITIAL_STATUS };
      this.$emit('close');
    },
    handleStatusClose() {
      if (this.status.type === 'success') {
        this.$emit('completed');
        this.handleClose();
      }

      this.status = { ...INITIAL_STATUS };
    },
    async changeEmail() {
      try {
        await api[this.userRole].update({
          id: this.user.id,
          email: this.newEmail,
          locationId: this.user.workplace?.id,
        });
        this.status = {
          show: true,
          type: 'success',
          title: 'E-mail has been updated',
        };
      } catch (e) {
        this.status = {
          show: true,
          type: 'warning',
          title: 'Failed to update E-mail',
          message: e.response.data.errorCode === 422007
              ? 'This E-mail is already in use by another user.'
              : 'Please try again or contact system administrator',
        };
      }
    },
  },

  mounted() {
    this.newEmail = this.user?.email || '';
  },
};
</script>
