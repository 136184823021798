<template>
  <div
    class="menu-section-file"
    :class="{ 'file-selected': data.selected }"
    :alt="data.name"
  >
    <div
      class="menu-section-file__content"
      @click="$emit('select-file', {
        fileId: id,
        data,
      })"
    >
      <ui-icon
        class="menu-section-file__tab-sm"
        name="tab-sm"
        width="15px"
        height="13px"
      />

      <ui-icon
        class="menu-section-file__tab-md"
        name="tab-md"
        width="20px"
        height="20px"
      />

      <ui-icon
        class="menu-section-file__pdf"
        name="pdf-outline"
        width="28px"
        height="28px"
      />

      <span class="menu-section-file__title">
        <span class="menu-section-file__title-filename">
          {{ data.fileName }}
        </span>
        <span class="menu-section-file__title-filesize">({{ this.data.fileSize }})</span>
      </span>
    </div>

    <ui-icon-button
      class="menu-section-file__delete text-primary"
      name="trash"
      width="16px"
      height="17px"
      @click="triggerDeleteSectionModal"
    />

    <ui-modal-alert
      v-model="deleteFileModal"
      :title="$t('onlineMenu.modal.deleteFile.title')"
      :apply-button="$t('form.buttons.remove')"
      :close-button="$t('form.buttons.cancel')"
      @apply="$emit('delete-file', id)"
    >
      {{ $t('onlineMenu.modal.deleteFile.description') }}
    </ui-modal-alert>
  </div>
</template>

<script>
export default {
  name: 'SectionFile',

  props: {
    id: Number,
    data: Object,
  },

  data() {
    return {
      deleteFileModal: false,
    };
  },

  methods: {
    triggerDeleteSectionModal() {
      this.deleteFileModal = true;
    },
  },
};
</script>
