<template>
  <div
    class="statistics-table-row"
    :class="{ 'statistics-table-row_parent': parent }"
  >
    <div
      class="statistics-table-row__cell statistics-table-row__cell_name"
    >
      <ui-checkbox
        v-if="!parent || allRow"
        :checked="checked"
        inline
        small
        @input="$emit('select', $event)"
      />
      <div>{{ name }}</div>
    </div>
    <div
      class="statistics-table-row__cell statistics-table-row__cell_error"
    >
      {{ errorMessage }}
    </div>
    <statistics-table-value-cell
      class="statistics-table-row__cell statistics-table-row__cell_center"
      :show-number="parent || allRow"
      :value="action"
    />
    <statistics-table-value-cell
      class="statistics-table-row__cell statistics-table-row__cell_center"
      :show-number="parent || allRow"
      :value="seen"
    />
    <statistics-table-value-cell
      class="statistics-table-row__cell statistics-table-row__cell_center"
      :show-number="parent || allRow"
      :value="sent"
    />
  </div>
</template>

<script>
import StatisticsTableValueCell from '@/components/Statistics/StatisticsTableValueCell.vue';

export default {
  name: 'StatisticsTableRow',
  components: {
    StatisticsTableValueCell,
  },

  model: {
    prop: 'checked',
    event: 'select',
  },

  props: {
    checked: Boolean,
    name: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    action: {
      type: [Number, Boolean],
      default: 0,
    },
    seen: {
      type: [Number, Boolean],
      default: 0,
    },
    sent: {
      type: [Number, Boolean],
      default: 0,
    },
    parent: Boolean,
    allRow: Boolean,
  },
};
</script>
