<template>
  <div class="menu-multi-section">
    <multi-section-item
      v-for="(section, index) in multiSectionList"
      :key="index"
      :id="index"
      :data="section"
      @select-file="$emit('select-file', $event)"
      @delete-file="$emit('delete-file', $event)"
      @delete-section="$emit('delete-section', $event)"
    />

    <div class="menu-multi-section__add-section_desktop">
      <span>Add section</span>
      <ui-icon-button
        v-protected
        name="plus"
        width="24px"
        height="24px"
        @click="addSection"
      />
    </div>
    <div class="menu-multi-section__add-section_mobile">
      <ui-button
        v-protected
        type="primary"
        size="sm"
        action
        outline
        @click="addSection"
      >
        Add section
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import MultiSectionItem from '@/components/OnlineMenu/MultiSection/MultiSectionItem.vue';

export default {
  name: 'MultiSection',

  components: {
    MultiSectionItem,
  },

  computed: {
    ...mapState('menu', [
      'multiSectionList',
    ]),
  },

  methods: {
    ...mapMutations('menu', [
      'addSection',
    ]),
  },
};
</script>
