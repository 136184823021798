<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-card
      tag="form"
      class="form-horizontal h-100"
      :is-loading="isLoading"
    >
      <template #header>
        <h4 class="card-title">
          {{ $t('distributions.setTitle') }}
        </h4>
      </template>

      <div
        v-if="distributions.length"
        class="row"
        :class="bodyClasses"
      >
        <validation-provider
          v-for="item in distributions"
          :key="item.staffType"
          tag="div"
          rules="required"
          mode="passive"
          class="col-12 col-sm-6 col-md-3 mx-0 px-0 pr-4 pb-4"
          v-slot="{ errors }"
        >
          <label>
            {{ item.label }}
          </label>
          <ui-input
            v-protected
            :value="item.percentage"
            @input="item.percentage = castInt($event)"
            :disabled="item.isDisabled || disabled"
            input-classes="w-100"
            required
            :error="errors[0]"
            class="min-w-60 mt-0"
            bordered
          >
            <template #addonRight>
              %
            </template>
          </ui-input>
        </validation-provider>
      </div>
      <span v-else class="text-gray">
        {{ $t('noData') }}
      </span>

      <ui-button
        v-if="!disabled"
        v-protected
        :is-loading="isLoading"
        :disabled="isLoading"
        type="primary"
        class="mt-4"
        action
        @click.prevent="handleSubmit(save)"
      >
        {{ $t('form.buttons.save') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {

  props: {
    location: {
      type: Object,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    confirmModal: Boolean,
    confirmMessage: String,
    bodyClasses: [Object, String],
    disabled: Boolean,
    isVisible: Boolean,
  },

  computed: {
    ...mapGetters({
      isAdminOrSales: 'auth/isAdminOrSales',
      distributions: 'distributions/parsedDistributions',
    }),
    isCommonPriority() {
      return this.location.tipsType === 'COMMON_PRIORITY';
    },
  },

  watch: {
    staffs: {
      immediate: true,
      handler(staffs) {
        if (!this.isAdminOrSales) {
          this.distributions.forEach((item) => {
            if (!item.isPrimaryStaff) {
              item.isDisabled = !staffs.some(({ staffType }) => staffType === item.staffType);
            }
          });
        }
      },
    },
  },

  methods: {
    ...mapActions('distributions', {
      updateDistributions: 'updateItems',
    }),
    castInt(value) {
      return parseInt(value, 10) || 0;
    },
    summary() {
      return this.distributions.reduce((carry, { percentage }) => carry + percentage, 0);
    },
    validate() {
      if (this.summary() !== 100) {
        this.$alert({
          title: this.$t('distributions.error.title'),
          message: `
            ${this.$t('distributions.error.text[0]')}
            <b>${this.$t('distributions.error.text[1]')}</b>
            ${this.$t('distributions.error.text[2]')}`,
          applyButton: this.$t('form.buttons.ok'),
        });

        return false;
      }

      return true;
    },
    confirmAction() {
      return this.$confirm({
        title: 'Confirmation',
        message: this.confirmMessage,
        applyButton: this.$t('form.buttons.ok'),
        closeButton: this.$t('form.buttons.cancel'),
      });
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      if (this.confirmModal && !await this.confirmAction()) {
        return;
      }

      const data = this.distributions.map(({ staffType, percentage }) => ({
        staffType,
        percentage,
      }));

      this.updateDistributions({
        payloadData: data,
      });
    },
  },
};
</script>
