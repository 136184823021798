<template>
  <staff-list-route
    :page-size="pageSize"
    :route-prefix="routePrefix"
    #default="{
      updateFilters,
      filters,
    }"
    @load="$emit('load', $event)"
  >
    <div class="row">
      <div class="col-12 col-lg">
        <div class="app-title app-title_secondary">
          {{ title }}
        </div>
      </div>
      <div v-if="showAllFilter" class="col-12 col-lg-auto ml-auto d-flex align-items-end">
        <ui-checkbox
          :checked="filters.showAll"
          class="mb-4"
          white
          inline
          @input="updateFilters({ showAll: $event })"
        >
          {{ $t('staffMembers.activeFilterTitle') }}
        </ui-checkbox>
      </div>
    </div>

    <staff-list-table
      :staffs="preparedStaffs"
      :columns="columns"
      :actions="actions"
      :page="filters.page"
      :page-size="pageSize"
      :total="total"
      :loading="isLoading"
      @update:page="updateFilters({ page: $event })"
      @updated="$emit('updated')"
    />
  </staff-list-route>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAYMENT_SYSTEMS_PLANS } from '@/config/paymentSystems';
import StaffListRoute from '@/components/StaffList/StaffListRoute.vue';
import StaffListTable from '@/components/StaffList/StaffListTable.vue';

export default {

  components: {
    StaffListRoute,
    StaffListTable,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    routePrefix: {
      type: String,
      required: false,
    },
    showAllFilter: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
    staffs: {
      type: Array,
      required: true,
    },
    sublocation: {
      type: Object,
      required: false,
    },
    group: {
      type: Object,
      required: false,
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters('auth', [
      'isAdminOrSales',
      'isTroncMaster',
      'isOwner',
      'isAccountant',
      'isStaffMaster',
      'isAdmin',
      'isViewAsAccess',
      'isSupportAdmin',
      'isSalesHead',
    ]),
    title() {
      const customName = this.sublocation?.name || this.group?.name;
      if (customName) {
        return `${customName} ${this.$t('staffMembers.listTitle').toLowerCase()}`;
      }
      return this.$t('staffMembers.listTitle');
    },
    isCommon() {
      return this.location.tipsType === 'COMMON' || this.location.tipsType === 'COMMON_PRIORITY';
    },
    isGermany() {
      return this.location.country === 'de';
    },
    isUAE() {
      return this.location.country === 'ae';
    },
    isUk() {
      return this.location.country === 'gb';
    },
    isSwitzerland() {
      return this.location.country === 'ch';
    },
    showTipsAmountColumn() {
      if (this.isAdminOrSales) {
        return true;
      }

      if (!this.isOwner && !this.isAccountant) {
        return true;
      }

      return this.location.taxReportingStatus !== 'SELF_REPORTING';
    },
    showPayoutPlanColumn() {
      if (PAYMENT_SYSTEMS_PLANS.includes(this.location.paymentSystem)) {
        return true;
      }

      return this.staffs.some(({ paymentSystem }) => PAYMENT_SYSTEMS_PLANS.includes(paymentSystem));
    },
    showSharedCardColumn() {
      return this.location.isAllowedSharedCard;
    },
    columns() {
      return [
        {
          id: 'INFO',
          visible: this.isAdminOrSales,
        },
        {
          id: 'QR',
          visible: this.isAdminOrSales,
        },
        {
          id: 'PAYROLL_ID',
          visible: this.isAccountant,
        },
        {
          id: 'PAYOUT_ID',
          visible: true,
        },
        {
          id: 'NAME',
          visible: true,
        },
        {
          id: 'TIPS_AMOUNT',
          visible: this.showTipsAmountColumn,
        },
        {
          id: 'STAFF_TYPE',
          visible: true,
        },
        {
          id: 'TAX_REPORTING',
          visible: this.isUk || this.isSwitzerland,
        },
        {
          id: 'PAYMENT_STATUS',
          visible: true,
        },
        {
          id: 'PAYMENT_SYSTEM',
          visible: true,
        },
        {
          id: 'PAYOUT_PLAN_NAME',
          visible: this.showPayoutPlanColumn,
        },
        {
          id: 'SHARED_CARD',
          visible: this.showSharedCardColumn,
        },
        {
          id: 'EMPLOYMENT_PERIOD',
          visible: true,
        },
        {
          id: 'ACTION',
          visible: true,
        },
        {
          id: 'READ_MORE',
          visible: this.isAdminOrSales,
        },
      ]
        .filter(({ visible }) => visible)
        .map(({ id }) => id);
    },
    troncMastersDisabled() {
      return !this.isCommon || this.isGermany || this.isSwitzerland;
    },
    showDelegateAction() {
      if (this.troncMastersDisabled) {
        return false;
      }

      if (this.isAdminOrSales) {
        return true;
      }

      return this.isStaffMaster && this.isTroncMaster;
    },
    actions() {
      return [
        {
          id: 'EDIT',
          visible: this.isAdminOrSales || this.isOwner,
        },
        {
          id: 'DELEGATE',
          visible: this.showDelegateAction,
        },
        {
          id: 'UNDELEGATE',
          visible: this.showDelegateAction,
        },
        {
          id: 'DEACTIVATE',
          visible: this.isAdminOrSales || this.isStaffMaster,
        },
        {
          id: 'MOVE_GROUP',
          visible: this.isAdminOrSales || this.isStaffMaster,
        },
        {
          id: 'VIEW_AS',
          visible: this.isAdmin || this.isViewAsAccess,
        },
        {
          id: 'BANK_ACCOUNT_DETAILS',
          visible: this.isAdminOrSales,
        },
        {
          id: 'SEND_REQUEST_ID',
          visible: this.isAdminOrSales || this.isViewAsAccess,
        },
        {
          id: 'CANCEL_REQUEST_ID',
          visible: this.isAdminOrSales || this.isViewAsAccess,
        },
        {
          id: 'SEND_REQUEST_BANK_DETAILS',
          visible: (this.isAdminOrSales || this.isViewAsAccess) && this.isUAE,
        },
        {
          id: 'CANCEL_REQUEST_BANK_DETAILS',
          visible: (this.isAdminOrSales || this.isViewAsAccess) && this.isUAE,
        },
        {
          id: 'ADD_PAYROLL_ID',
          visible: this.isAccountant,
        },
        {
          id: 'RESET_STRIPE',
          visible: this.isAdminOrSales,
        },
        {
          id: 'ALLOW_SHARED_CARD',
          visible: true,
        },
        {
          id: 'DISABLE_SHARED_CARD',
          visible: true,
        },
        {
          id: 'RESEND_INVITATION',
          visible: this.isAdminOrSales,
        },
        {
          id: 'RESET_OTP_TRYOUTS',
          visible: this.isAdmin,
        },
        {
          id: 'ACTIVATE_NYM_CARD',
          visible: this.isSupportAdmin || this.isSalesHead,
        },
        {
          id: 'SUSPEND_NYM_CARD',
          visible: this.isSupportAdmin || this.isSalesHead,
        },
        {
          id: 'DISABLE_NYM_CARD',
          visible: this.isSupportAdmin || this.isSalesHead,
        },
      ]
        .filter(({ visible }) => visible)
        .map(({ id }) => id);
    },
    preparedStaffs() {
      return this.staffs.map((staff) => ({
          ...staff,
          group: this.group,
          location: {
            ...this.location,
            groups: this.groups,
          },
        }));
    },
  },
};
</script>
