export default (data, date) => data.map((item) => ({
  ...item,
  staffId: item.staff.id,
  amount: item.amount.amount,
  currency: item.amount.currency,
  payoutInfoDto: {
    workDay: {
      hours: item.value,
    },
  },
  date: new Date(date),
}));
