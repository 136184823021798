<template>
  <div
    class="payout-detail-row"
    :class="{
      'payout-detail-row_bold': bold,
      'payout-detail-row_small': small
    }"
  >
    <div class="payout-detail-row__name">{{ name }}</div>

    <div
      v-if="valueTruncated && value"
      class="payout-detail-row__value-truncated"
    >
      <span class="payout-detail-row__label text-truncate">
        {{ value }}
      </span>
      <ui-text-copy
        v-if="valueTruncatedCopy"
        class="payout-detail-row__copy"
        :text="value"
      />
    </div>
    <div
      v-else
      class="payout-detail-row__value"
    >
      {{ value || '-' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayoutDetailRow',

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    bold: Boolean,
    small: Boolean,
    valueTruncated: Boolean,
    valueTruncatedCopy: Boolean,
  },
};
</script>
