var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-data-table',{attrs:{"data-table":"payout","columns":_vm.columns,"data":_vm.items,"empty-header":_vm.emptyHeader,"no-empty-text":""},scopedSlots:_vm._u([{key:"payout-staff-status-value",fn:function(ref){
var value = ref.value;
return [_c('span',{class:value === 'ACTIVE' ? 'text-green' : 'text-primary'},[_vm._v(" "+_vm._s(_vm.$t(("status." + value)))+" ")])]}},{key:"payout-percentage-value",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+"% ")]}},{key:"payout-available-amount-label",fn:function(ref){
var col = ref.col;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(col.label))]),_c('ui-tooltip',{attrs:{"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('staffPayouts.tooltip.available'))}})]},proxy:true}],null,true)},[_c('i',{staticClass:"nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"})])],1)]}},{key:"payout-available-amount-value",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [_vm._v(" "+_vm._s(value)+" "),(row.partialWork)?_c('span',{staticClass:"ml-1 text-danger"},[_c('b',[_vm._v("*")])]):_vm._e()]}},{key:"payout-available-amount-total",fn:function(){return [(_vm.amountTotal)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.amountTotal,_vm.locationCurrency))+" ")]):[_vm._v(" — ")]]},proxy:true},{key:"payout-gross-amount-total",fn:function(){return [(_vm.amountTotal)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.amountTotal,_vm.locationCurrency))+" ")]):[_vm._v(" — ")]]},proxy:true},{key:"after",fn:function(){return [_c('div',{staticClass:"my-4 d-flex align-items-center justify-content-between"},[(_vm.hasPages)?_c('ui-pagination',{attrs:{"total":_vm.total,"per-page":_vm.pageSize},model:{value:(_vm.pageIndex),callback:function ($$v) {_vm.pageIndex=$$v},expression:"pageIndex"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column text-grey"},[(_vm.hasPartials)?_c('span',[_vm._v("* Based on start / leave date")]):_vm._e()])],1),_vm._t("after",null,null,{ amountTotal: _vm.amountTotal })]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }