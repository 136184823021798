<template>
  <exports-list />
</template>

<script>
import ExportsList from '@/components/Exports/ExportsList.vue';

export default {

  components: {
    ExportsList,
  },
};
</script>
