<template>
  <ui-stats-card
    v-bind="$attrs"
    title="Venue charge"
    :value="value || 0 | money(currency)"
    :is-loading="isLoading"
    icon="coins"
  />
</template>

<script>
import api from '@/api';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    groupId: [Number, String],
  },

  data: () => ({
    value: null,
    currency: null,
  }),

  computed: {
    apiMethod() {
      return api.locations.getVenueCharge;
    },
    apiConfig() {
      return {
        locationId: this.locationId,
        groupId: this.groupId || undefined,
        staffId: this.staffId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
  },

  methods: {
    async load() {
      const { data } = await this.apiMethod(this.apiConfig);

      if (data) {
        this.value = data.amount;
        this.currency = data.currency;
      }
    },
  },
};
</script>
