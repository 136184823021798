<template>
  <notification-edit-form
    :notification-id="notificationId"
  />
</template>

<script>
import NotificationEditForm from '@/components/Notification/NotificationEditForm.vue';

export default {
  name: 'NotificationView',

  props: {
    notificationId: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    NotificationEditForm,
  },
};
</script>
