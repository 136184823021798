<template>
  <ui-card
    v-bind="$attrs"
    :is-loading="isLoading"
     class="card-stats"
  >
    <template #header>
      <h4 class="card-title">
        {{ $t('reviews.ratingTitle') }}
      </h4>
    </template>

    <template #default>
      <div class="card-stats-rating">
        <div class="d-flex mb-4">
          <div class="text-nowrap">
            <ui-icon name="rating" size="44" />
          </div>
          <div class="d-flex align-items-star flex-column">
            <div class="card-stats__value text-nowrap">
              <div>{{ avgRatings }}</div>
              <div class="font-14 card-label">
                {{ countOfReviews }} {{ $t('ratings') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="totalRatings.length" class="rating-statistics">
          <div
            v-for="item in totalRatings"
            :key="item.startCnt"
            class="rating-statistics__row"
          >
            <div class="rating-statistics__count">{{ item.cnt }}</div>
            <div class="rating-statistics__stars">
              <ui-stars :value="item.startCnt" size="10" disabled />
            </div>
            <div class="rating-statistics__bar">
              <div class="rating-statistics__progress" :style="{ width: `${item.cnt / countOfReviews * 100}%`}" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-card>
</template>

<script>
import { range } from 'lodash';
import api from '@/api';
import StatsMixin from './StatsMixin';

const MAX_STARS = 5;

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    groupId: [Number, String],
  },

  data: () => ({
    avgRatings: 0,
    totalRatings: [],
  }),

  computed: {
    apiConfig() {
      return {
        locationId: this.locationId,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
    countOfReviews() {
      return this.totalRatings.reduce((sum, review) => (sum + review.cnt), 0);
    },
  },

  methods: {
    load() {
      return Promise.all([
        this.loadAvgRatings(),
        this.loadTotalRatings(),
      ]);
    },
    async loadAvgRatings() {
      const { data } = await api.locations.getRating(this.apiConfig);

      if (data?.value) {
        this.avgRatings = data.value;
      }
    },
    async loadTotalRatings() {
      const { data } = await api.locations.getTotalRatings(this.apiConfig);

      if (data?.starsStat) {
        this.setTotalRatings(data.starsStat);
      }
    },
    setTotalRatings(items) {
      range(1, MAX_STARS + 1).forEach((index) => {
        if (!items.some(({ startCnt }) => startCnt === index)) {
          items.push({ startCnt: index, cnt: 0 });
        }
      });

      items = items.filter(({ startCnt }) => startCnt <= MAX_STARS);
      items.sort((a, b) => b.startCnt - a.startCnt);

      this.totalRatings = items;
    },
  },
};
</script>
