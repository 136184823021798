export default [
  '1-goldBack',
  '2-pinkFlowers',
  '3-woodBack',
  '4-retroStyle',
  '5-greyLeaves',
  '6-arabesque',
  '7-spa',
  '8-room',
  '9-tulip',
  '10-bricks',
  '11-bohoo',
  '12-darkWood',
  '13-altSpa',
  '14-lavanda',
  '15-bubbles',
  '16-Scissors',
  '17-blossom',
  '18-pampas',
  '19-greenLeaves',
  '20-duckArt',
  '21-birdHouse',
  '22-purple',
  '23-modern',
  '24-minimalistic',
  '25-colored',
  '26-floGreen',
  '27-africa',
  '28-otherLeaves',
  '29-british',
  '30-barber',
];
