<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    :is-loading="loading.png"
    @before-open="beforeOpen"
  >
    <template #header>
      QR Code
    </template>
    <template v-if="imageUrl" #footer>
      <div class="d-flex justify-content-center w-100">
        <ui-button
          round
          size="sm"
          type="primary"
          native-type="button"
          class="mx-2"
          :is-loading="loading.svg"
          :disabled="someLoading"
          @click="downloadSvg"
        >
          Download as SVG
        </ui-button>
        <ui-button
          round
          size="sm"
          type="primary"
          native-type="button"
          class="mx-2"
          :disabled="someLoading"
          @click="downloadPng"
        >
          Download as PNG
        </ui-button>
      </div>
    </template>

    <div class="text-center">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        width="250"
      />
      <p v-else class="text-gray">
        QR cannot display :(
      </p>
    </div>
  </ui-modal>
</template>

<script>
import api from '@/api';
import download from '@/utils/download';

export default {

  props: {
    staffId: [String, Number],
    locationId: [String, Number],
    groupId: [String, Number],
  },

  data() {
    return {
      loading: {
        png: false,
        svg: false,
      },
      imageBlob: null,
      imageUrl: null,
    };
  },

  computed: {
    someLoading() {
      return Object.values(this.loading).some((v) => v);
    },
    apiMethod() {
      if (this.staffId) {
        return api.locations.getStaffQR;
      }

      if (this.groupId) {
        return api.locations.getGroupQR;
      }

      return api.locations.getQR;
    },
  },

  methods: {
    async beforeOpen() {
      this.loading.png = true;
      this.image = null;
      this.imageBlob = null;
      this.imageUrl = null;

      try {
        const data = await this.request();

        if (data) {
          this.imageBlob = data.blob;
          this.imageUrl = data.url;
        }
      } finally {
        this.loading.png = false;
      }
    },
    async request(params = {}) {
      try {
        const { data } = await this.apiMethod({
          staffId: this.staffId,
          locationId: this.locationId,
          groupId: this.groupId,
          ...params,
        });

        if (data && data.size > 0) {
          return {
            blob: data,
            url: URL.createObjectURL(data),
          };
        }
      } catch (e) {
        console.log(e);
      }

      return null;
    },
    async downloadSvg() {
      this.loading.svg = true;

      try {
        const data = await this.request({ type: 'SVG' });

        if (data) {
          download(data.blob, 'QRCode.svg');
        }
      } finally {
        this.loading.svg = false;
      }
    },
    downloadPng() {
      if (this.imageBlob) {
        download(this.imageBlob, 'QRCode.png');
      }
    },
  },
};
</script>
