<template>
  <ui-card
    v-bind="$attrs"
    class="payouts-payout-plan"
  >
    <div class="card-header pt-0 px-0">
      <h4 class="payouts-payout-plan__title">
        {{ $t('sharedCard.payoutPlan.title') }}
      </h4>
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <div class="payouts-payout-plan__value text-nowrap">
        {{ payoutPlan }}
        <span v-if="feeLabel" class="payouts-payout-plan__label">
          {{ feeLabel }}
        </span>
      </div>
      <div class="card-stats__icon text-nowrap">
        <ui-icon name="stats-list" size="64" />
      </div>
    </div>
  </ui-card>
</template>

<script>
import money from '@/filters/money';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    payoutPlan: String,
    currency: String,
    fee: Number,
  },

  computed: {
    feeLabel() {
      if (this.fee === 0) {
        return 'FREE payouts';
      }

      if (this.fee > 0) {
        return `${money(this.fee, this.currency)} payout fee`;
      }

      return '';
    },
  },
};
</script>
