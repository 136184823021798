<template>
  <ui-card
    v-bind="$attrs"
    class="payouts-account-balance"
  >
    <div class="card-header pt-0 px-0">
      <h4 class="payouts-account-balance__title">
        {{ $t('sharedCard.payoutBalance.title') }}
      </h4>
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <div class="payouts-account-balance__value text-nowrap">
        <div>{{ amountCeil(amount) }}</div>
        <div class="payouts-account-balance__value-fraction">
          .{{ amountFraction(amount) }}
        </div>
      </div>
      <div class="card-stats__icon text-nowrap">
        <ui-icon name="coins" size="64" />
      </div>
    </div>
  </ui-card>
</template>

<script>
import money from '@/filters/money';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    amount: null,
    currency: null,
  },

  methods: {
    amountCeil(amount) {
      return money(amount, this.currency, {
        fractionDigits: 0,
        style: this.currency === 'aed' ? 'decimal' : 'currency',
      });
    },
    amountFraction(amount) {
      return money(amount, this.currency, {
        style: this.currency === 'aed' ? 'currency' : 'decimal',
      }).replace(/^[\d\s]+\./g, '');
    },
  },
};
</script>
