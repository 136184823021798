<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-card tag="form">
      <div class="form-group mt-0 mb-4">
        <ui-avatar
          :image="image"
          :title="$t('UI.avatar.userTitle')"
          @save="uploadImage"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <validation-provider
            tag="div"
            :name="$t('form.labels.firstName')"
            rules="required|min:2|max:30"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.firstName"
              :label="$t('form.labels.firstName')"
              maxlength="30"
              minlength="2"
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.lastName')"
            rules="required|min:2|max:30"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.lastName"
              :label="$t('form.labels.lastName')"
              maxlength="30"
              minlength="2"
              required
              :error="errors[0]"
            />
          </validation-provider>
          <validation-provider
            v-if="isSalesPerson"
            tag="div"
            :name="$t('form.labels.country')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.country"
              :items="countries"
              :label="$t('form.labels.country')"
              :placeholder="$t('form.placeholders.select')"
              label-key="name"
              value-key="iso"
              filterable
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>
      </div>

      <ui-button
        action
        type="primary"
        :is-loading="loading"
        :disabled="loading"
        class="mt-4"
        @click.prevent="handleSubmit(send)"
      >
        {{ $t('form.buttons.update') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';

export default {

  data: () => ({
    loading: false,
    form: {
      id: '',
      firstName: '',
      lastName: '',
      country: '',
    },
  }),

  computed: {
    ...mapState('user', [
      'profile',
      'image',
    ]),
    ...mapGetters('auth', [
      'isSalesPerson',
    ]),
    countries() {
      return this.$getCountries({ i18n: true });
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    ...mapActions('user', [
      'loadInfo',
      'uploadImage',
    ]),
    loadData() {
      this.form.id = this.profile.id;
      this.form.firstName = this.profile.firstName;
      this.form.lastName = this.profile.lastName;
      this.form.country = this.profile.country;
    },
    async send() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        if (this.isSalesPerson) {
          await api.user.updateSalesProfile(this.form);
        } else {
          await api.user.updateProfile(this.form);
        }

        await this.loadInfo();

        this.loadData();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
