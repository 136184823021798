<template>
  <div>
    <promotion-form
      :key="`promotion-${status}`"
      :promotion-id="promotionId"
      promotion-edit
      @loaded="onLoaded"
      @completed="onCompleted"
    />
    <promotion-message
      v-if="locationId"
      :promotion-id="promotionId"
      :location-id="locationId"
    />
  </div>
</template>

<script>
import PromotionForm from '@/components/Promotion/PromotionForm.vue';
import PromotionMessage from '@/components/Promotion/PromotionMessage.vue';

export default {

  props: {
    promotionId: {
      type: [String, Number],
      required: true,
    },
  },

  components: {
    PromotionForm,
    PromotionMessage,
  },

  data() {
    return {
      status: '',
      locationId: null,
    };
  },

  methods: {
    onCompleted({ status }) {
      if (status) {
        this.status = status;
      }
    },
    onLoaded({ locationId }) {
      if (locationId) {
        this.locationId = locationId;
      }
    },
  },
};
</script>
