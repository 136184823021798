<template>
  <ui-stats-card
    v-bind="$attrs"
    :title="title || $t('reporting.tipsTitle.total')"
    :is-loading="isLoading"
    class="card-promo"
  >
    <template #default>
      <div class="d-flex align-items-center">
        <div class="text-nowrap">
          <ui-icon name="coins" size="44px" />
        </div>
        <div>
          <div class="card-stats__value text-nowrap">
            {{ value || 0 | money(currency) }}
          </div>
          <div v-if="showBonusTips" class="mt-1 card-promo__content">
            Bonus tips: {{ sumBonusTips | money(currency) }}
            <ui-popover
              placement="bottom"
              trigger="hover"
              icon="info"
              icon-size="14px"
              icon-class="ml-2 text-grey card-promo__info"
            >
              <div
                v-for="item in promotions"
                :key="item.id"
                class="card-promo__row"
              >
                <div class="d-flex">
                  <div
                    @click="togglePromoDate(item)"
                    class="card-promo__date"
                  >
                    {{ item.dateFrom | date }} - {{ item.dateTo | date }}
                  </div>
                  <div class="ml-5 card-promo__amount">
                    {{ item.totalAmount | money(currency) }}
                  </div>
                </div>
                <div class="mt-1 card-promo__name">
                  {{ item.name }}
                </div>
              </div>
            </ui-popover>
          </div>
        </div>
      </div>
    </template>
  </ui-stats-card>
</template>

<script>
import api from '@/api';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    groupId: [Number, String],
    title: String,
  },

  data: () => ({
    value: null,
    currency: null,
    promotions: [],
  }),

  computed: {
    apiMethodTotalTips() {
      return this.staffId ? api.staff.getTotal : api.locations.getTotal;
    },
    apiMethodPromotions() {
      return this.staffId ? api.staff.getPromotions : api.locations.getPromotions;
    },
    apiConfigTotalTips() {
      return {
        locationId: this.locationId,
        staffId: this.staffId || undefined,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
    apiConfigPromotions() {
      return {
        locationId: this.locationId,
        staffId: this.staffId || undefined,
        status: 'COMPLETE',
      };
    },
    showBonusTips() {
      return this.promotions.length > 0;
    },
    sumBonusTips() {
      return this.promotions.reduce((sum, { totalAmount }) => sum + totalAmount, 0);
    },
  },

  methods: {
    load() {
      return Promise.all([
        this.loadTotalTips(),
        this.loadPromotions(),
      ]);
    },
    async loadTotalTips() {
      const { data } = await this.apiMethodTotalTips(this.apiConfigTotalTips);

      if (data) {
        this.value = data.amount;
        this.currency = data.currency;
      }
    },
    async loadPromotions() {
      const { data } = await this.apiMethodPromotions(this.apiConfigPromotions);

      if (data) {
        this.promotions = data;
      }
    },
    togglePromoDate({ dateFrom, dateTo }) {
      this.$emit('toggle-promo-date', [dateFrom, dateTo]);
    },
  },
};
</script>

<style lang="scss">
.card-promo {
  &__content {
    display: flex;
    margin-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
  }

  &__info {
    margin-top: -2px;
  }

  &__row + &__row {
    margin-top: 12px;
  }

  &__date {
    color: #A01212;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }

  &__amount {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  &__name {
    color: #808080;
    font-size: 12px;
    line-height: 15.6px;
  }
}
</style>
