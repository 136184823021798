<template>
  <div class="card" :key="payoutId">
    <div class="card-body">
      <terms-and-conditions-gb v-if="isUk || isSwitzerland" />
      <terms-and-conditions-uae v-if="isUAE" />
      <terms-and-conditions-de v-if="isGermany" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TermsAndConditionsGb from '@/components/TermsAndConditionsGb.vue';
import TermsAndConditionsDe from '@/components/TermsAndConditionsDe.vue';
import TermsAndConditionsUae from '@/components/TermsAndConditionsUae.vue';

export default {

  components: {
    TermsAndConditionsGb,
    TermsAndConditionsDe,
    TermsAndConditionsUae,
  },

  computed: {
    ...mapState({
      payoutId: (state) => state.user.location.payoutId,
    }),
    country() {
      return this.$store.state.user.location.country;
    },
    isUk() {
      return this.country === 'gb';
    },
    isGermany() {
      return this.country === 'de';
    },
    isUAE() {
      return this.country === 'ae';
    },
    isSwitzerland() {
      return this.country === 'ch';
    },
  },
};
</script>
