<template>
  <div :key="accessToken">
    <div class="row">
      <div class="col-12 col-xl-4 col-lg-6 pb-4">
        <ui-select
          v-model="filter.period"
          :items="periodPresets"
          class="select-width mt-0"
          label-key="name"
          value-key="value"
          @change="updateFilter"
          bordered
        />
      </div>
      <div class="col-12 col-xl-4 col-lg-6 pb-4">
        <ui-select
          v-if="isSalesHead"
          v-model="filter.salesStaffId"
          :items="headSalesPeople"
          class="select-width mt-0"
          label-key="name"
          value-key="id"
          @change="updateFilter"
          bordered
        />
      </div>
    </div>

    <ui-loading v-if="isLoading"></ui-loading>

    <template v-else-if="filter.salesStaffId">
      <div class="row">
        <stats-new-clients
          :key="`new-clients-${filter.salesStaffId}-${filter.period}`"
          :staff-id="filter.salesStaffId"
          :date-from="dateFrom"
          :date-to="dateTo"
          class="col-12 col-xl-4 pb-4"
        />
        <stats-best-performers
          :key="`best-performers-${filter.salesStaffId}-${filter.period}`"
          :staff-id="filter.salesStaffId"
          :date-from="dateFrom"
          :date-to="dateTo"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-worst-performers
          :key="`worst-performers-${filter.salesStaffId}-${filter.period}`"
          :staff-id="filter.salesStaffId"
          :date-from="dateFrom"
          :date-to="dateTo"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
      </div>

      <div class="app-title app-title_secondary mt-4">
        Registrations
      </div>

      <div class="row">
        <div class="col-12 col-lg-4 pb-4">
          <ui-button
            action
            type="primary"
            class="w-100"
            @click="goLocationForm"
          >
            Add new venue
          </ui-button>
        </div>
      </div>

      <div class="row">
        <stats-staff-registrations
          :key="`staff-registrations-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-kyc
          :key="`incomplete-kyc-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-restricted-soon-accounts
          :key="`restricted-soon-accounts-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-accountant-registrations
          v-if="isUk || isSwitzerland"
          :key="`accountant-registrations-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-overdue-distributions
          :key="`overdue-distributions-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-overdue-payouts
          v-if="isUk || isSwitzerland"
          :key="`overdue-payouts-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
        <stats-unclaimed-taxes
          v-if="isUk || isSwitzerland"
          :key="`unclaimed-taxes-${filter.salesStaffId}`"
          :staff-id="filter.salesStaffId"
          class="col-12 col-xl-4 col-lg-6 pb-4"
        />
      </div>

      <staff-sales
        :staff-id="filter.salesStaffId"
        class="mt-4"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { pickBy } from 'lodash';
import api from '@/api';
import getFio from '@/utils/fio';
import StatsNewClients from '@/components/Stats/StatsNewClients.vue';
import StatsBestPerformers from '@/components/Stats/StatsBestPerformers.vue';
import StatsWorstPerformers from '@/components/Stats/StatsWorstPerformers.vue';
import StatsStaffRegistrations from '@/components/Stats/StatsStaffRegistrations.vue';
import StatsKyc from '@/components/Stats/StatsKyc.vue';
import StatsRestrictedSoonAccounts from '@/components/Stats/StatsRestrictedSoonAccounts.vue';
import StatsAccountantRegistrations from '@/components/Stats/StatsAccountantRegistrations.vue';
import StatsOverduePayouts from '@/components/Stats/StatsOverduePayouts.vue';
import StatsOverdueDistributions from '@/components/Stats/StatsOverdueDistributions.vue';
import StatsUnclaimedTaxes from '@/components/Stats/StatsUnclaimedTaxes.vue';
import StaffSales from '@/components/Staff/StaffSales.vue';

export default {

  scrollToTop: false,

  components: {
    StatsNewClients,
    StatsBestPerformers,
    StatsWorstPerformers,
    StatsStaffRegistrations,
    StatsKyc,
    StatsRestrictedSoonAccounts,
    StatsAccountantRegistrations,
    StaffSales,
    StatsOverduePayouts,
    StatsOverdueDistributions,
    StatsUnclaimedTaxes,
  },

  data: () => ({
    filter: {
      period: null,
      salesStaffId: null,
    },
    headSalesPeople: [],
    isLoading: false,
  }),

  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      accessToken: (state) => state.auth.accessToken,
    }),
    ...mapGetters('auth', [
      'isSalesHead',
    ]),
    periodPresets() {
      return [
        {
          value: '7DAYS',
          name: this.$t('transactionGraph.datePresets.7DAYS'),
        },
        {
          value: '30DAYS',
          name: this.$t('transactionGraph.datePresets.30DAYS'),
        },
        {
          value: '3MONTHS',
          name: this.$t('transactionGraph.datePresets.3MONTHS'),
        },
        {
          value: '1YEAR',
          name: this.$t('transactionGraph.datePresets.1YEAR'),
        },
      ];
    },
    dateFrom() {
      let dateFrom = DateTime.local();

      switch (this.filter.period) {
        case '7DAYS':
          dateFrom = dateFrom.minus({ days: 7 });
          break;
        case '30DAYS':
          dateFrom = dateFrom.minus({ days: 30 });
          break;
        case '3MONTHS':
          dateFrom = dateFrom.minus({ months: 3 });
          break;
        case '1YEAR':
          dateFrom = dateFrom.minus({ years: 1 });
          break;
        default:
          dateFrom = dateFrom.minus({ months: 1 });
          break;
      }

      return dateFrom.toJSDate();
    },
    dateTo() {
      return DateTime.local().toJSDate();
    },
    isUk() {
      return this.profile.country === 'gb';
    },
    isSwitzerland() {
      return this.profile.country === 'ch';
    },
    urlConfig() {
      return {
        ...this.$route.query,
        ...this.filter,
      };
    },
  },

  watch: {
    '$route.query': {
      immediate: true,
      handler({ period, salesStaffId }) {
        const query = pickBy({
          period: period || '30DAYS',
          salesStaffId: salesStaffId || this.getDefaultSalesStaffId(),
        });

        this.filter = {
          ...this.filter,
          ...query,
        };
      },
    },
    accessToken: {
      immediate: true,
      async handler() {
        if (this.isSalesHead) {
          await this.loadSalesPeople();
        } else {
          this.filter.salesStaffId = null;
        }

        if (!this.filter.salesStaffId) {
          this.filter.salesStaffId = this.profile.id;
        }
      },
    },
  },

  methods: {
    goLocationForm() {
      this.$router.push({ name: 'locations' });
    },
    updateFilter() {
      this.$router.replace({
        query: this.urlConfig,
      }, () => {});
    },
    getDefaultSalesStaffId() {
      if (this.headSalesPeople.length) {
        const foundId = this.filter.salesStaffId || this.profile.id;
        const found = this.headSalesPeople.find(({ id }) => id === foundId);

        if (found) {
          return found.id;
        }

        return this.headSalesPeople[0].id;
      }

      return this.profile.id;
    },
    async loadSalesPeople() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        const { data } = await api.locations.getHeadSalesPeople({
          salesId: this.profile.id,
        });

        if (data) {
          this.headSalesPeople = data.map((item) => ({
            name: getFio(item),
            id: item.id,
          }));

          this.filter.salesStaffId = this.getDefaultSalesStaffId();
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
