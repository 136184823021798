var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-md-8 col-lg-6 col-xl-5"},[_c('validation-provider',{attrs:{"tag":"div","name":"Landing type","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.landingTypes,"label":"Landing type","placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","disabled":Boolean(_vm.form.id),"error":errors[0]},on:{"input":_vm.onLandingTypeChange},model:{value:(_vm.form.landingType),callback:function ($$v) {_vm.$set(_vm.form, "landingType", $$v)},expression:"form.landingType"}})]}}])}),_c('validation-provider',{attrs:{"tag":"div","name":"Comission","rules":"required|decimal|min_value:1|max_value:100","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":"Comission, %","required":"","error":errors[0]},model:{value:(_vm.form.feePlan),callback:function ($$v) {_vm.$set(_vm.form, "feePlan", $$v)},expression:"form.feePlan"}})]}}])}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.promoPeriod'),"rules":"numeric","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"value":_vm.form.promoPeriod,"label":_vm.$t('form.labels.promoPeriod'),"error":errors[0]},on:{"input":function($event){_vm.form.promoPeriod = _vm.castInt($event)}}})]}}])}),(_vm.form.landingType === 'INDIVIDUAL')?_c('validation-provider',{attrs:{"tag":"div","name":"Tip distributions","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.tipDistributions,"label":"Tip distributions","placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","disabled":Boolean(_vm.form.id),"error":errors[0]},on:{"input":_vm.onTipDistributionsChange},model:{value:(_vm.form.tipDistributions),callback:function ($$v) {_vm.$set(_vm.form, "tipDistributions", $$v)},expression:"form.tipDistributions"}})]}}],null,false,3648654135)}):_vm._e(),(_vm.form.tipDistributions === 'COMMON_ADVANCED')?_c('validation-provider',{attrs:{"tag":"div","name":"Tipout from","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.staffTypes,"label":"Tipout from","placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","disabled":Boolean(_vm.form.id),"error":errors[0]},model:{value:(_vm.form.primaryStaff),callback:function ($$v) {_vm.$set(_vm.form, "primaryStaff", $$v)},expression:"form.primaryStaff"}})]}}],null,false,4246756223)}):_vm._e(),(_vm.form.primaryStaff)?_c('validation-provider',{attrs:{"tag":"div","name":"From comission","rules":"required|decimal|min_value:1|max_value:100","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":"From comission, %","required":"","error":errors[0]},model:{value:(_vm.form.primaryPercentage),callback:function ($$v) {_vm.$set(_vm.form, "primaryPercentage", $$v)},expression:"form.primaryPercentage"}})]}}],null,false,2981619304)}):_vm._e(),(_vm.form.tipDistributions === 'COMMON_SIMPLE' || _vm.form.tipDistributions === 'COMMON_ADVANCED')?_c('validation-provider',{attrs:{"tag":"div","name":"Distributions frequency","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.distributions,"label":"Distribution frequency","placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","disabled":Boolean(_vm.form.id),"error":errors[0]},model:{value:(_vm.form.distributionType),callback:function ($$v) {_vm.$set(_vm.form, "distributionType", $$v)},expression:"form.distributionType"}})]}}],null,false,471473112)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }