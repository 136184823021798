<template>
  <validation-observer tag="form" v-slot="{ handleSubmit, invalid }">
    <ui-card :is-loading="loading">
      <template #header>
        <div class="d-flex flex-nowrap justify-content-between">
          <h4 class="card-title mb-xl-0 mb-lg-0 mb-3">
            {{ title }}
          </h4>
          <div v-if="promotionEdit">
            <ui-icon-button
              size="22px"
              name="excel-sm"
              class="mr-2"
              @click="handleSubmit(exportXls)"
            />
          </div>
        </div>
      </template>

      <div class="row px-0">
        <validation-provider
          tag="div"
          class="col-12 col-xl-3 col-lg-4 col-md-6 mr-lg-3"
          :name="$t('form.labels.promo')"
          rules="required"
        >
          <ui-input
            v-model="form.name"
            :label="$t('form.labels.promo')"
            :placeholder="$t('form.placeholders.enter')"
            :disabled="disabled"
            required
          />
        </validation-provider>

        <validation-provider
          tag="div"
          class="col-12 col-xl-3 col-lg-4 col-md-6"
          :name="$t('form.labels.locationName')"
          rules="required"
        >
          <ui-select
            v-model="form.workplace"
            :items="[form.workplace]"
            :label="$t('form.labels.locationName')"
            :placeholder="$t('form.placeholders.search')"
            :remote-method="filterLocationsByName"
            :disabled="disabled"
            hide-empty-dropdown
            hide-dropdown-loading
            input-icon="search"
            label-key="label"
            value-key="id"
            value-original
            required
          />
        </validation-provider>
      </div>

      <div class="row px-0">
        <validation-provider
          tag="div"
          class="col-12 col-xl-3 col-lg-4 col-md-6 mr-lg-3"
          :name="$t('form.labels.bonus')"
          rules="required|numeric"
        >
          <ui-input
            v-model.number="form.bonusPercentage"
            :label="$t('form.labels.bonus')"
            :placeholder="$t('form.placeholders.enter')"
            :disabled="disabled"
            is-integer
            required
          />
        </validation-provider>

        <validation-provider
          tag="div"
          class="col-12 col-xl-3 col-lg-4 col-md-6"
          :name="$t('form.labels.period')"
          rules="required"
        >
          <ui-input
            label=""
            class="is-filled"
          >
            <ui-date-picker
              v-model="calendar.range"
              :placeholder="$t('form.placeholders.select')"
              type="daterange"
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              :min="calendar.min"
              :max="calendar.max"
              :disabled="disabled"
            />
          </ui-input>
        </validation-provider>
      </div>

      <div v-if="!loading && !disabled" class="row">
        <template v-if="promotionEdit">
          <div class="col-12 col-xl-auto">
            <ui-button
              action
              type="primary"
              :disabled="loading || invalid"
              class="w-100 mt-4"
              @click.prevent="handleSubmit(save)"
            >
              {{ $t('form.buttons.update') }}
            </ui-button>
          </div>

          <div class="col-12 col-xl-auto col-lg-6 col-md-6">
            <ui-button
              type="primary"
              outline
              :disabled="loading || invalid"
              class="px-5 w-100 mt-4"
              @click.prevent="handleSubmit(cancel)"
            >
              {{ $t('form.buttons.cancelPromo') }}
            </ui-button>
          </div>

          <div class="col-12 col-xl-auto col-lg-6 col-md-6">
            <ui-button
              type="primary"
              outline
              :disabled="loading || invalid"
              class="px-5 w-100 mt-4"
              @click.prevent="handleSubmit(complete)"
            >
              {{ $t('form.buttons.payoutComplete') }}
            </ui-button>
          </div>
        </template>

        <template v-else>
          <div class="col-12">
            <ui-button
              action
              type="primary"
              :disabled="loading || invalid"
              @click.prevent="handleSubmit(create)"
            >
              {{ $t('form.buttons.create') }}
            </ui-button>
          </div>
        </template>
      </div>
    </ui-card>
  </validation-observer>
</template>

<script>
import parseDate from '@/utils/parseDate';
import toSQLDate from '@/utils/toSQLDate';
import resetMixin from '@/mixins/reset-mixin';
import download from '@/utils/download';
import api from '@/api';

export default {

  props: {
    promotionId: [String, Number],
    promotionEdit: Boolean,
  },

  mixins: [
    resetMixin(() => ({
      form: {
        bonusPercentage: null,
        name: '',
        workplace: {},
      },
      calendar: {
        range: null,
        min: null,
        max: null,
      },
      loading: false,
    })),
  ],

  computed: {
    title() {
      return this.promotionEdit
        ? this.$t('promotions.form.editTitle')
        : this.$t('promotions.form.createTitle');
    },
    disabled() {
      return this.promotionEdit && this.form.status !== 'ACTIVE';
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    formatPayoutItem({ name, payoutId }) {
      return `${name} (ID ${payoutId})`;
    },
    async filterLocationsByName(query) {
      if (!query.length) {
        return [];
      }

      let searchField;

      if (!Number.isNaN(+query)) {
        searchField = {
          payoutId: query,
        };
      } else {
        searchField = {
          name: query,
        };
      }

      const {
        data: {
          data: locations,
        },
      } = await api.locations.getLocations(searchField);

      return locations.map((item, index) => ({
        id: item.id,
        name: item.name,
        payoutId: item.payoutId,
        label: this.formatPayoutItem(item),
      }));
    },
    async perform(method, resetAfter) {
      this.loading = true;

      try {
        const { label, ...workplace } = this.form.workplace;
        const { status, ...formData } = this.form;
        let data = {
          ...formData,
          dateFrom: toSQLDate(this.calendar.range[0]),
          dateTo: toSQLDate(this.calendar.range[1]),
          workplace,
        };

        if (this.promotionEdit) {
          data = {
            promotionId: this.promotionId,
            ...data,
          };
        }

        const {
          data: {
            status: promotionStatus,
          },
        } = await method(data);

        if (resetAfter) {
          this.reset();
        }

        this.$emit('completed', { status: promotionStatus });
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    async create() {
      this.perform(api.promotions.createPromotion, true);
    },
    save() {
      this.perform(api.promotions.updatePromotion, false);
    },
    cancel() {
      this.perform(api.promotions.cancelPromotion, false);
    },
    complete() {
      this.perform(api.promotions.completePromotion, false);
    },
    async exportXls() {
      this.loading = true;

      try {
        const {
          data,
          filename,
        } = await api.promotions.getPromotionsExcel({
          promotionId: this.promotionId,
        });

        if (data && filename) {
          download(data, filename);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.promotionEdit) {
        return;
      }

      this.loading = true;

      try {
        const {
          data,
        } = await api.promotions.getPromotion({ promotionId: this.promotionId });

        const {
          id,
          dateFrom,
          dateTo,
          workplace,
          ...formData
        } = data;

        this.form = {
          ...formData,
          workplace: {
            id: workplace.id,
            name: workplace.name,
            payoutId: workplace.payoutId,
            label: this.formatPayoutItem(workplace),
          },
        };
        this.calendar.range = [parseDate(dateFrom), parseDate(dateTo)];
        this.$emit('loaded', { locationId: workplace.id });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
