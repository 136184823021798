<template>
  <ui-card :is-loading="isLoading">
    <template #header>
      <h4 class="card-title">
        Distribution history
      </h4>
    </template>

    <p>
      Select periods to see distribution history
    </p>

    <ui-date-period
      :value="periodId"
      :items="periodList"
      value-key="id"
      @input="$emit('update:periodId', $event)"
    />

    <staff-payouts-table
      :items="historyData"
      :staffs="staffs"
      :total="historyTotal"
      :page="historyPage"
      :page-size="historyPageSize"
      :location-currency="locationCurrency"
      :empty-header="isEmptyHeader"
      @update="$emit('update:page', $event)"
    />
  </ui-card>
</template>

<script>
import { mapGetters } from 'vuex';
import StaffPayoutsTable from './StaffPayoutsTable.vue';

export default {
  name: 'StaffPayoutsHistory',

  components: {
    StaffPayoutsTable,
  },

  props: {
    historyData: Array,
    historyTotal: Number,
    historyPage: Number,
    historyPageSize: Number,
    locationId: [Number, String],
    locationCurrency: String,
    isLoading: Boolean,
    staffs: Array,
    periodId: null,
    periodList: null,
  },

  computed: {
    ...mapGetters({
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
      isTroncMaster: 'auth/isTroncMaster',
    }),
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    isTroncUAECommon() {
      return this.isTroncMaster && this.isUAE && this.isCommon;
    },
    isEmptyHeader() {
      return !this.isTroncUAECommon && !this.isOwnerUAECommon;
    },
  },

};
</script>
