var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-multi-section-item"},[_c('div',{staticClass:"menu-multi-section-item__info_desktop"},[_c('validation-provider',{staticClass:"menu-multi-section-item__input",attrs:{"tag":"div","name":("Section " + _vm.sectionId),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":("Section " + _vm.sectionId),"error":errors[0]},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})]}}])}),_c('ui-icon-upload',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"menu-multi-section-item__upload",attrs:{"accept-files":"application/pdf","disabled":_vm.loading.file},on:{"upload":function($event){return _vm.handleFileUpload({
        fileData: $event,
        sectionId: _vm.id,
      })}}}),_c('ui-icon-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"menu-multi-section-item__delete text-primary",attrs:{"name":"trash","width":"16px","height":"17px","disabled":_vm.loading.file},on:{"click":_vm.triggerDeleteSectionModal}})],1),_c('div',{staticClass:"menu-multi-section-item__info_mobile"},[_c('validation-provider',{staticClass:"menu-multi-section-item__input",attrs:{"tag":"div","name":("Section " + _vm.sectionId),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('ui-input',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"label":("Section " + _vm.sectionId),"error":errors[0]},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})]}}])}),_c('div',{staticClass:"menu-multi-section-item__info_mobile-controls"},[_c('ui-button-upload',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"menu-multi-section-item__upload",attrs:{"accept-files":"application/pdf","title":"Add file(s)","action":"","multiple":"","disabled":_vm.loading.file},on:{"upload":function($event){return _vm.handleFileUpload({
          fileData: $event,
          sectionId: _vm.id,
        })}}}),_c('ui-button',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"menu-multi-section-item__delete text-primary",attrs:{"type":"primary","size":"sm","outline":"","disabled":_vm.loading.file},on:{"click":_vm.triggerDeleteSectionModal}},[_vm._v(" Remove section ")])],1)],1),_c('div',{on:{"touchend":_vm.fixActionRestriction}},[_c('Container',{directives:[{name:"protected",rawName:"v-protected"}],staticClass:"menu-multi-section-item__files",attrs:{"tag":"ul","group-name":"multi-section","drag-class":"file-ghost","drop-placeholder":{
        animationDuration: '150',
        showOnTop: true,
        className: 'file-drop',
      },"get-child-payload":function (itemId) { return _vm.getChildPayload(itemId); }},on:{"drop":function($event){return _vm.handleDrop(_vm.id, $event)}}},[_vm._l((_vm.data.files),function(file,index){return _c('Draggable',{key:index,attrs:{"tag":"li"}},[_c('section-file',{directives:[{name:"protected",rawName:"v-protected"}],attrs:{"id":index,"data":file},on:{"select-file":function($event){return _vm.handleSelectFile({
            data: $event.data,
            fileId: $event.fileId,
            sectionId: _vm.id,
          })},"delete-file":function($event){return _vm.handleDeleteFile({
            fileId: $event,
            sectionId: _vm.id,
          })}}})],1)}),(_vm.loading.file && _vm.currentUploadSectionId === _vm.id)?_c('div',{staticClass:"menu-multi-section-item__loader"},[_c('ui-loading',{attrs:{"inline":""}})],1):_vm._e()],2)],1),(_vm.data.files.length)?_c('hr'):_vm._e(),_c('ui-modal-alert',{attrs:{"title":_vm.$t('onlineMenu.modal.deleteSection.title'),"apply-button":_vm.$t('form.buttons.remove'),"close-button":_vm.$t('form.buttons.cancel')},on:{"apply":function($event){return _vm.handleDeleteSection(_vm.id)}},model:{value:(_vm.deleteSectionModal),callback:function ($$v) {_vm.deleteSectionModal=$$v},expression:"deleteSectionModal"}},[_vm._v(" "+_vm._s(_vm.$t('onlineMenu.modal.deleteSection.description'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }