<template>
  <ui-card
    class="online-menu-card"
    :is-loading="loading.card"
  >
    <template #header>
      <h4 class="online-menu-card__title card-title">
        {{ $t('onlineMenu.menuCard.title') }}
      </h4>
    </template>

    <div class="online-menu-card__body">
      <div class="online-menu-card__settings">
        <p class="online-menu-card__subtitle">
          {{ $t('onlineMenu.menuCard.description') }}
        </p>

        <div class="online-menu-card__controls">
          <ui-switcher
            v-model="isSectionsMode"
            v-protected
            class="online-menu-card__toggle-sections"
            type="secondary"
            size="small"
            :label="$t('onlineMenu.menuCard.toggleSections')"
          />

          <ui-icon-upload
            v-if="!isSectionsMode"
            v-protected
            class="online-menu-card__icon-upload"
            accept-files="application/pdf"
            :disabled="loadingUpload.file"
            @upload="handleFileUpload"
          />

          <ui-button-upload
            v-if="!isSectionsMode"
            v-protected
            class="online-menu-card__button-upload"
            title="Add file(s)"
            accept-files="application/pdf"
            action
            multiple
            :disabled="loadingUpload.file"
            @upload="handleFileUpload"
          />
        </div>

        <multi-section
          v-if="isSectionsMode"
          ref="multiSection"
          @select-file="handleSelectFile"
          @delete-file="resetMultiSectionPreview"
          @delete-section="resetMultiSectionPreview"
        />

        <single-section
          v-else
          @select-file="handleSelectFile"
          @delete-file="resetSingleSectionPreview"
        />
      </div>

      <div class="online-menu-card__preview">
        <p class="online-menu-card__preview-subtitle">
          {{ $t('onlineMenu.menuCard.description') }}
        </p>

        <div class="online-menu-card__preview-image">
          <div
            v-if="preview.show || loading.page"
            class="online-menu-card__preview-image_has-file"
          >
            <div
              v-if="loading.page"
              class="online-menu-card__preview-image_loading"
            >
              <ui-loading />
            </div>

            <img
              v-else
              :src="preview.currentPage.link"
              alt="preview.currentPage.fileName"
            />

            <div class="online-menu-card__preview-image-controls">
              <ui-icon-button
                class="online-menu-card__preview-image-arrow-left text-primary"
                name="arrow-left"
                width="12px"
                height="10px"
                :disabled="preview.isArrowPrevDisabled || loading.page"
                @click="previewPagePrev"
              />
              <p class="online-menu-card__preview-image-description">
                <span
                  v-if="isSectionsMode"
                  class="online-menu-card__preview-image-section-name"
                >
                  Section {{ previewSectionIndex }}
                </span>
                <span class="online-menu-card__preview-image-filename">
                  {{ preview.currentPage.fileName }}
                </span>
              </p>
              <ui-icon-button
                class="online-menu-card__preview-image-arrow-right text-primary"
                name="arrow-right"
                width="12px"
                height="10px"
                :disabled="preview.isArrowNextDisabled || loading.page"
                @click="previewPageNext"
              />
            </div>
          </div>
          <div v-else class="online-menu-card__preview-image_no-file">
            <p
              v-if="isFilesExist"
              class="online-menu-card__preview-image-title"
            >
              No menu<br>selected
            </p>
            <p
              v-else
              class="online-menu-card__preview-image-title"
            >
              No menu<br>uploaded
            </p>
          </div>
        </div>
      </div>
    </div>
  </ui-card>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import resetMixin from '@/mixins/reset-mixin';
import fromBytesToMb from '@/utils/fromBytesToMb';
import SingleSection from '@/components/OnlineMenu/SingleSection/SingleSection.vue';
import MultiSection from '@/components/OnlineMenu/MultiSection/MultiSection.vue';

export default {
  name: 'OnlineMenuCard',

  components: {
    MultiSection,
    SingleSection,
  },

  mixins: [
    resetMixin(() => {
      return {
        preview: {
          show: false,
          isArrowPrevDisabled: true,
          isArrowNextDisabled: false,
          currentFileId: '',
          currentPage: null,
          pageList: [],
          pageIndex: 0,
          sectionIndex: 0,
        },
      };
    }),
  ],

  data() {
    return {
      loading: {
        card: false,
        file: false,
        page: false,
      },
      maxFileSize: 10e6,
    };
  },

  computed: {
    ...mapState('menu', {
      sectionsMode: 'sectionsMode',
      singleSectionList: 'singleSectionList',
      multiSectionList: 'multiSectionList',
      loadingUpload: 'loading',
      sectionValidationKey: 'sectionValidationKey',
    }),
    ...mapGetters('menu', [
      'getSingleSectionSelectedFileId',
      'getMultiSectionSelectedFileId',
    ]),
    isSectionsMode: {
      get() {
        return this.sectionsMode;
      },
      set(value) {
        this.toggleSections(value);
      },
    },
    previewSectionIndex() {
      return this.preview.sectionIndex + 1;
    },
    isFilesExist() {
      if (this.isSectionsMode) {
        return this.multiSectionList.some((section) => section.files.length);
      }

      return this.singleSectionList.length;
    },
  },

  watch: {
    isSectionsMode: {
      immediate: true,
      handler(value) {
        this.reset();

        if (value) {
          this.resetSingleSectionSelectedFile();
        } else {
          this.resetMultiSectionSelectedFile();
        }
      },
    },
    'preview.pageIndex': {
      immediate: true,
      handler(value) {
        if (value === 0) {
          this.preview.isArrowPrevDisabled = true;
        } else if (value === this.preview.pageList.length - 1) {
          this.preview.isArrowNextDisabled = true;
        }
      },
    },
  },

  mounted() {
    this.$watch(() => this.sectionValidationKey, () => {
      const sectionNameList = this.$refs.multiSection.$children
        .filter((element) => element.$el.className === 'menu-multi-section-item');

      sectionNameList.forEach((section) => section.$children[0].validate());
      sectionNameList.forEach((section) => section.$children[3].validate());
    });

    this.loadData();
  },

  methods: {
    ...mapActions('menu', [
      'loadFilePages',
      'loadFiles',
    ]),
    ...mapMutations('menu', [
      'resetMultiSectionSelectedFile',
      'updateSectionFileOrder',
      'resetSingleSectionSelectedFile',
      'updateSingleSectionFileOrder',
      'toggleSections',
    ]),
    parseFiles(fileData) {
      const uploadedFiles = Array.from(fileData.target.files);

      return uploadedFiles
        .filter(({ type }) => type === 'application/pdf')
        .map((item) => ({
          fileName: item.name,
          fileSize: item.size,
          file: item,
        }));
    },
    filterFilesBySize(fileData) {
      let filteredFiles = this.parseFiles(fileData);

      if (!filteredFiles.length) {
        return;
      }

      filteredFiles.forEach((file) => {
        if (file.fileSize > this.maxFileSize) {
          this.$showServerError({
            message: `Max file size: ${fromBytesToMb(this.maxFileSize)}Mb`,
          });
          file.isBig = true;
        } else {
          file.isBig = false;
        }
      });

      filteredFiles = filteredFiles.filter(({ isBig }) => !isBig);

      /* eslint consistent-return: "off" */
      return filteredFiles;
    },
    loadPage(page) {
      this.loading.page = true;

      const img = new Image();
      img.src = page?.link;

      img.onload = () => {
        this.loading.page = false;
      };

      return page;
    },
    previewPagePrev() {
      this.preview.isArrowNextDisabled = false;

      if (this.preview.pageIndex > 0) {
        this.preview.pageIndex -= 1;
        this.preview.currentPage = this.loadPage(this.preview.pageList[this.preview.pageIndex]);
      } else {
        this.preview.isArrowPrevDisabled = true;
      }
    },
    previewPageNext() {
      const maxIndex = this.preview.pageList.length - 1;
      this.preview.isArrowPrevDisabled = false;

      if (this.preview.pageIndex < maxIndex) {
        this.preview.pageIndex += 1;
        this.preview.currentPage = this.loadPage(this.preview.pageList[this.preview.pageIndex]);
      } else {
        this.preview.isArrowNextDisabled = true;
      }
    },
    resetSingleSectionPreview(fileId) {
      this.updateSingleSectionFileOrder();

      if (this.getSingleSectionSelectedFileId === fileId) {
        this.reset();
        this.resetSingleSectionSelectedFile();
      }
    },
    resetMultiSectionPreview({ fileId, sectionId }) {
      this.updateSectionFileOrder();

      const selectedFileId = this.getMultiSectionSelectedFileId(sectionId);

      if ((selectedFileId === fileId)
        || (selectedFileId !== -1 && fileId === undefined)
      ) {
        this.reset();
        this.resetMultiSectionSelectedFile();
      }
    },
    async handleSelectFile(data) {
      if (!data) {
        this.reset();
        return;
      }

      if (data.fileId === this.preview.currentFileId) {
        return;
      }

      this.reset();

      if (data.pages?.length) {
        this.preview = {
          ...this.preview,
          show: true,
          pageList: data.pages,
          currentPage: this.loadPage(data.pages?.[0]),
          currentFileId: data.fileId,
          sectionIndex: data.sectionId,
        };

        if (this.preview.pageList.length < 2) {
          this.preview = {
            ...this.preview,
            isArrowPrevDisabled: true,
            isArrowNextDisabled: true,
          };
        } else {
          this.preview = {
            ...this.preview,
            isArrowNextDisabled: false,
          };
        }
      } else {
        this.preview = {
          ...this.preview,
          show: false,
          currentPage: null,
        };
      }
    },
    async handleFileUpload(fileData) {
      const parsedFiles = this.filterFilesBySize(fileData);

      if (!parsedFiles.length) {
        return;
      }

      try {
        await this.loadFilePages({ parsedFiles });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadData() {
      this.loading.card = true;

      try {
        await this.loadFiles();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.card = false;
      }
    },
  },
};
</script>
