<template>
  <div>
    <ui-modal
      show
      title="Permissions for orders"
      class="change-orders-permission-modal"
      no-footer
      @closed="handleClose"
    >
      <template #header>
        <div class="change-orders-permission-modal__title">
          Permissions for orders
        </div>
      </template>
      <div class="change-orders-permission-modal__body">
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            tag="div"
            name="Permissions for orders"
            mode="passive"
            class="my-2 mt-5"
          >
            <ui-select
              v-model="permissionRole"
              :items="ORDERS_PERMISSION_ROLES"
              class="change-orders-permission-modal__select"
              label="Permissions for orders"
              :placeholder="$t('form.placeholders.select')"
              label-key="label"
              value-key="value"
            />
          </validation-provider>

          <div class="change-orders-permission-modal__footer">
            <ui-button
              size="lg"
              type="primary"
              class="change-orders-permission-modal__update-btn"
              @click.prevent="handleSubmit(changeOrdersPermission)"
            >
              Apply
            </ui-button>
          </div>
        </validation-observer>
      </div>
    </ui-modal>

    <ui-modal-status
      :show="status.show"
      :title="status.title"
      :message="status.message"
      :type="status.type"
      @closed="handleStatusClose"
    />
  </div>
</template>

<script>
import api from '@/api';
import { ORDERS_PERMISSION_ROLES } from '@/config/ordersPermissionRoles';

const INITIAL_STATUS = {
  show: false,
  type: 'success',
  title: 'Orders permission has been updated',
  message: '',
};

export default {
  name: 'ChangeOrdersPermissionModal',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      permissionRole: null,
      status: { ...INITIAL_STATUS },
      ORDERS_PERMISSION_ROLES,
    };
  },

  methods: {
    handleClose() {
      this.status = { ...INITIAL_STATUS };
      this.$emit('close');
    },
    handleStatusClose() {
      if (this.status.type === 'success') {
        this.$emit('completed');
        this.handleClose();
      }

      this.status = { ...INITIAL_STATUS };
    },
    async changeOrdersPermission() {
      try {
        await api.owners.update({
          id: this.user.id,
          ordersPermissionRole: this.permissionRole,
          locationId: this.user.workPlace?.id,
        });
        this.status = {
          show: true,
          type: 'success',
          title: 'Orders permission has been updated',
        };
      } catch (e) {
        this.status = {
          show: true,
          type: 'warning',
          title: 'Failed to update orders permission',
          message: 'Please try again or contact system administrator',
        };
      }
    },
  },

  mounted() {
    this.permissionRole = this.user?.ordersPermissionRole || '';
  },
};
</script>
