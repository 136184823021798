<template>
  <div>
    <ui-card class="distributions">
      <template #header>
        <h4 class="d-flex flex-nowrap align-items-center justify-content-between">
          <span class="card-title">
            Payout dates
          </span>

          <div v-if="isGrossTaxes" class="d-flex align-items-center align-self-start">
            <span class="mr-3 font-16">Automatic</span>
            <ui-switcher
              v-model="automationPayout.isAutomation"
              v-protected
              type="primary"
              size="medium"
              :disabled="loading.automation.data"
            />
          </div>
        </h4>
        <h3 class="card-category mt-3">
          Please select distribution period or choose a date to receive tips and reports automatically
        </h3>
      </template>

      <ui-loading
        v-if="loading.automation.data"
        class="my-5 mx-auto"
        inline
      />

      <template v-else-if="automationPayout.isAutomation">
        <div class="row">
          <div class="col-xl-4 col-lg-12 mt-4 mb-4">
            <ui-radio
              v-for="item in automationPayoutList"
              v-protected
              :label="item.value"
              :value="automationPayout.taxPeriodPay"
              :key="item.value"
              name="tax-period"
              class="mb-3"
              @input="getTaxPeriodPay"
            >
              {{ item.label }}
            </ui-radio>
            <div class="d-flex align-items-center">
              <p
                class="font-16 col-auto mb-1"
                :class="{
                  'disabled': isAutomationPayoutStartDateDisabled,
                }"
              >
                Select payroll date
              </p>
              <ui-calendar-picker
                v-model="automationPayout.startDate"
                v-protected
                :max="maxDate"
                :disabled="isAutomationPayoutStartDateDisabled"
                :disabled-date="disabledDate"
                class="distributions-calendar"
                format="dd.LL.yy"
                input-icon="calendar"
                placeholder="Receive date"
                no-direction
                bordered
              />
            </div>
            <ui-button
              v-protected
              action
              type="primary"
              class="mt-3 w-100"
              :disabled="isAutomationPayoutSaveDisabled || loading.automation.send"
              :is-loading="loading.automation.send"
              @click="sendAutomationPayout"
            >
              {{ $t('form.buttons.save') }}
            </ui-button>
          </div>
          <div class="col-xl-8 col-lg-12">
            <div class="distributions-explanation">
              <div class="d-flex mb-4">
                <div class="distributions-explanation__count">1</div>
                <div class="distributions-explanation__text ml-4">Select an option when your payroll is conducted.</div>
              </div>
              <div class="d-flex mb-4">
                <div class="distributions-explanation__count">2</div>
                <div class="distributions-explanation__text ml-4">If you selected options “every four weeks”, “last working day every two weeks” or ”specific requiring date”, please tell us your payroll date in the calendar. </div>
              </div>
              <div class="d-flex mb-4">
                <div class="distributions-explanation__count">3</div>
                <div class="distributions-explanation__text ml-4">It takes 5 working days to receive payouts. Hence in order for the tips to reach your business account on payroll day, we will transfer all collected tips 5 days before payroll date. Tips collected after a payout will be automatically added to the next period.</div>
              </div>
              <div class="d-flex">
                <div class="distributions-explanation__count">4</div>
                <div class="distributions-explanation__text ml-4">If your payroll is less than 5 days away, your tips will be paid out automatically at the following payroll date.</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <ui-calendar-range
          v-model="calendar.range"
          v-protected
          :min="calendar.min"
          :max="calendar.max"
          :disabled-date="calendarDisabled"
          single-day
          class="col-9 mt-2 px-0"
        />
        <ui-data-table
          :columns="columns"
          :data="data"
          class="mt-5 distributions-table"
          data-table="payout"
          no-empty-text
        >
          <template #payout-period-value="{ value }">
            <span :class="grossTotal ? '' : 'text-danger'">
              {{ value }}
            </span>
          </template>
          <template #payout-gross-amount-value="{ value }">
            {{ value | money(locationCurrency) }}
          </template>
          <template #payout-gross-amount-empty>
            <span class="text-danger"> &mdash; </span>
          </template>
          <template #payout-tax-amount="{ index }">
            <ui-input
              v-model="data[index].taxAmount"
              class="my-0"
              input-classes="w-100 text-right"
              :disabled="!grossTotal"
              @input="updateTaxAmount(index, $event)"
              bordered
              is-float
            />
          </template>
          <template #payout-net-amount-value="{ value }">
            {{ value | money(locationCurrency) }}
          </template>
          <template #payout-net-amount-empty>
            <span class="text-danger"> &mdash; </span>
          </template>

          <template #after>
            <ui-loading
              v-if="loading.data"
              class="my-5 mx-auto"
              inline
            />

            <div v-if="data.length && !isGrossTaxes" class="fluid-container">
              <div class="row">
                <div class="col d-lg-flex align-items-baseline justify-content-between">
                  <ui-button
                    v-protected
                    outline
                    size="sm"
                    type="primary"
                    class="mt-4 mb-3 px-5"
                    :is-loading="loading.calculate"
                    :disabled="someManuallyLoading || !grossTotal"
                    @click="calculate"
                    action
                  >
                    Calculate
                  </ui-button>
                  <template v-if="data.length">
                    <div>
                      <span class="ml-lg-5">Total net amount:</span>
                      <h5 class="ml-2 d-inline">{{ amountTotal | money(locationCurrency) }}</h5>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="distributions-table__submit-row">
              <ui-button
                v-protected
                action
                type="primary"
                :is-loading="loading.manually.send"
                :disabled="disableSendPayoutBtn"
                @click="confirmPayouts"
              >
                {{ $t('form.buttons.sendPayouts') }}
              </ui-button>
              <div v-if="data.length && isGrossTaxes" class="distributions-table__total">
                <div class="distributions-table__total-title">Total:</div>
                <div class="distributions-table__total-value">{{ grossTotal | money(locationCurrency) }}</div>
              </div>
            </div>
          </template>
        </ui-data-table>

        <staff-payouts-confirm
          v-model="showConfirm"
          :items="data"
          :amount="isGrossTaxes ? grossTotal : amountTotal"
          :currency="locationCurrency"
          @apply="sendPayouts"
        />
      </template>

      <staff-payouts-error :show="showPaymentAccountError" />
    </ui-card>

    <payouts-history
      :location="location"
      :reload.sync="reloadHistory"
      :currency="locationCurrency"
      for-distributions
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { sumBy } from 'lodash';
import convertToPointNumber from '@/utils/convertToPointNumber';
import api from '@/api';
import fio from '@/utils/fio';
import formatDate from '@/filters/date';
import StaffPayoutsConfirm from '@/components/StaffPayouts/StaffPayoutsConfirm.vue';
import StaffPayoutsError from '@/components/StaffPayouts/StaffPayoutsError.vue';
import PayoutsHistory from '@/components/Payouts/PayoutsHistory.vue';

export default {

  components: {
    StaffPayoutsError,
    StaffPayoutsConfirm,
    PayoutsHistory,
  },

  data() {
    return {
      data: [],
      reloadHistory: false,
      calendarDisabled: undefined,
      showConfirm: false,
      showPaymentAccountError: false,
      loading: {
        manually: {
          data: false,
          calculate: false,
          send: false,
        },
        automation: {
          data: false,
          send: false,
        },
      },
      calendar: {
        range: [],
        min: null,
        max: null,
      },
      automationPayout: {
        isAutomation: false,
        taxPeriodPay: null,
        startDate: null,
      },
      isAutoPayout: false,
    };
  },

  computed: {
    location() {
      return this.$store.state.user.location;
    },
    isGrossTaxes() {
      return this.location.taxes === 'GROSS';
    },
    locationCurrency() {
      return this.location.currencyCode;
    },
    amountTotal() {
      return this.getTotalSum('netAmount');
    },
    grossTotal() {
      return this.getTotalSum('grossAmount');
    },
    someManuallyLoading() {
      return Object.values(this.loading.manually).some((v) => v);
    },
    periodTitle() {
      return `${formatDate(this.calendar.range[0])} - ${formatDate(this.calendar.range[1])}`;
    },
    columns() {
      return [
        {
          name: 'payroll-id',
          label: this.$t('table.columns.payrollId'),
          prop: 'payrollId',
          empty: this.$t('table.empty.payrollId'),
          align: 'center',
        },
        {
          name: 'payout-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          empty: this.$t('table.empty.easytipId'),
          align: 'center',
        },
        {
          name: 'payout-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          empty: this.$t('table.empty.name'),
          align: 'center',
        },
        {
          name: 'payout-period',
          label: this.$t('table.columns.distributionPeriod'),
          prop: 'from',
          value: () => {
            return this.periodTitle;
          },
          empty: this.$t('table.empty.period'),
          align: 'center',
        },
        {
          name: 'payout-gross-amount',
          label: this.$t('table.columns.grossAmount'),
          prop: 'grossAmount',
          value: ({ row }) => {
            return this.grossTotal ? row.grossAmount : null;
          },
          empty: '—',
          align: 'center',
        },
        {
          name: 'payout-tax-amount',
          label: this.$t('table.columns.taxAmount'),
          prop: 'taxAmount',
          align: 'center',
          visible: !this.isGrossTaxes,
        },
        {
          name: 'payout-net-amount',
          label: this.$t('table.columns.netAmount'),
          prop: 'netAmount',
          value: ({ row }) => {
            return this.grossTotal ? row.netAmount : null;
          },
          empty: '—',
          align: 'center',
          visible: !this.isGrossTaxes,
        },
      ];
    },
    disableSendPayoutBtn() {
      if (this.loading.manually.data) {
        return true;
      }
      if (this.isGrossTaxes) {
        return this.calendar.range.length < 2;
      }
      return !this.amountTotal;
    },
    automationPayoutList() {
      return [
        {
          value: 'LAST_FRIDAY',
          label: 'Last Friday of the month',
        },
        {
          value: 'LAST_WORKING_DAY_MONTHS',
          label: 'Last working day of the month',
        },
        {
          value: 'EVERY_4_WEEK',
          label: 'Every 4 weeks',
        },
        {
          value: 'LAST_WORKING_DAY_EVERY_2_WEEKS',
          label: 'Last working day every two weeks',
        },
        {
          value: 'SPECIFIC_DATE',
          label: 'Specific requiring date',
        },
      ];
    },
    isAutomationPayoutStartDateDisabled() {
      const periods = [
        'EVERY_4_WEEK',
        'LAST_WORKING_DAY_EVERY_2_WEEKS',
        'SPECIFIC_DATE',
      ];

      if (periods.includes(this.automationPayout.taxPeriodPay)) {
        return false;
      }

      return true;
    },
    isAutomationPayoutSaveDisabled() {
      if (this.isAutomationPayoutStartDateDisabled
        && this.automationPayout.taxPeriodPay
      ) {
        return false;
      }

      return !this.automationPayout.taxPeriodPay
        || !this.automationPayout.startDate;
    },
    maxDate() {
      const date = new Date();
      return new Date(date.setDate(date.getDate() + 61));
    },
  },

  watch: {
    location: {
      immediate: true,
      handler() {
        this.loadPayoutStartDate();
      },
    },
    'calendar.range': {
      immediate: true,
      async handler(newValue, oldValue) {
        // Handle watcher on create component.
        if (typeof oldValue === 'undefined') {
          return;
        }

        // Cancel of click to start period (easytip-1311).
        if (oldValue[0] && oldValue[1] === newValue[0] && !newValue[1]) {
          [newValue[0], newValue[1]] = oldValue;

          return;
        }

        if (newValue[1]) {
          this.loadPayoutTaxesCalculation();
        }

        this.data.splice(0);
      },
    },
    'automationPayout.isAutomation': {
      immediate: false,
      handler(value) {
        if (!value && this.isAutoPayout) {
          this.automationPayout.taxPeriodPay = null;
          this.automationPayout.startDate = null;
          this.sendAutomationPayout();
        }
      },
    },
    'automationPayout.taxPeriodPay': {
      immediate: false,
      handler(value) {
        if (this.isAutomationPayoutStartDateDisabled) {
          this.automationPayout.startDate = null;
        }
      },
    },
  },

  mounted() {
    this.getAutomationPayout();
  },

  methods: {
    disabledDate(datetime) {
      const period = this.automationPayout.taxPeriodPay;

      if (period === 'LAST_WORKING_DAY_EVERY_2_WEEKS') {
        if (datetime.date.getDay() === 5) {
          return false;
        }

        return true;
      }

      return false;
    },
    getTaxPeriodPay(value) {
      this.automationPayout.taxPeriodPay = value;
    },
    getTotalSum(attribute) {
      return +Number(
        sumBy(this.data, (item) => {
          return Number.parseFloat(item[attribute], 10) || 0;
        }),

      ).toFixed(2);
    },
    calculate() {
      this.loading.manually.calculate = true;

      this.data.forEach((item) => {
        item.netAmount = +(item.grossAmount - item.taxAmount).toFixed(2);
      });

      this.loading.manually.calculate = false;
    },
    confirmPayouts() {
      this.showConfirm = true;
    },
    async loadPayoutStartDate() {
      this.loading.manually.data = true;

      try {
        const {
          data: {
            startDate,
            endDate,
          },
        } = await api.locations.getPayoutStartDate({
          locationId: this.location.id,
        });

        if (!startDate && !endDate) {
          this.calendar.min = null;
          this.calendar.max = null;
          this.calendar.range = [];
          this.calendarDisabled = () => true;

          return;
        }

        if (!endDate) {
          this.calendar.min = DateTime.fromISO(startDate).toJSDate();
          this.calendar.max = this.calendar.min;
          this.calendar.range = [];
          this.calendarDisabled = undefined;

          return;
        }

        const yesterday = DateTime.now().minus({ day: 1 }).startOf('day');
        const parsedStartDate = DateTime.fromISO(startDate);
        const parsedEndDate = DateTime.min(DateTime.fromISO(endDate), yesterday);

        if (parsedEndDate < parsedStartDate) {
          this.calendar.min = null;
          this.calendar.max = null;
          this.calendar.range = [];
          this.calendarDisabled = () => true;

          return;
        }

        if (parsedStartDate.hasSame(parsedEndDate, 'day')) {
          this.calendar.min = parsedStartDate.toJSDate();
          this.calendar.max = parsedEndDate.toJSDate();
          this.calendar.range = [this.calendar.min, this.calendar.max];
          this.calendarDisabled = undefined;

          return;
        }

        this.calendar.min = parsedStartDate.toJSDate();
        this.calendar.max = parsedEndDate.toJSDate();
        this.calendar.range = [this.calendar.min];
        this.calendarDisabled = undefined;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.manually.data = false;
      }
    },
    async loadPayoutTaxesCalculation() {
      this.loading.manually.data = true;

      try {
        const { data } = await api.locations.getPayoutTaxesCalculation({
          locationId: this.location.id,
          dateFrom: DateTime.fromJSDate(this.calendar.range[0]).toISODate(),
          dateTo: DateTime.fromJSDate(this.calendar.range[1]).toISODate(),
        });

        this.data = data.map((item) => ({
          ...item,
          name: fio(item),
          taxAmount: item.taxAmount || 0,
          netAmount: item.netAmount || null,
        }));

        if (this.isGrossTaxes) {
          this.calculate();
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.manually.data = false;
      }
    },
    async sendPayouts() {
      this.loading.manually.send = true;

      try {
        const netPayouts = this.data.map(({ staffId, netAmount, taxAmount }) => ({
          staffId,
          netAmount,
          taxAmount,
        }));

        const { data } = await api.locations.sendPayoutPeriod({
          locationId: this.location.id,
          dateFrom: DateTime.fromJSDate(this.calendar.range[0]).toISODate(),
          dateTo: DateTime.fromJSDate(this.calendar.range[1]).toISODate(),
          netPayouts,
        });

        this.data.splice(0);

        await this.loadPayoutStartDate();

        this.reloadHistory = true;

        if (data?.errorCode === 2000008) {
          this.$alert({
            title: data.message,
            message: data.description,
            applyButton: this.$t('form.buttons.ok'),
          });

          this.calendarDisabled = () => true;
        }
      } catch (e) {
        if (e.response?.data?.errorCode === 409034) {
          this.showPaymentAccountError = true;
        } else {
          this.$showServerError(e);
        }
      } finally {
        this.loading.manually.send = false;
      }
    },
    async getAutomationPayout() {
      if (this.loading.automation.data) {
        return;
      }

      this.loading.automation.data = true;

      try {
        const { data } = await api.locations.getAutomationPayout({
          locationId: this.location.id,
        });

        if (data.isAutomation) {
          this.automationPayout = {
            ...data,
            startDate: DateTime.fromISO(data.startDate).toJSDate(),
          };

          this.isAutoPayout = this.automationPayout.isAutomation;
        } else {
          this.loadPayoutStartDate();
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.automation.data = false;
      }
    },
    async sendAutomationPayout() {
      if (this.loading.automation.send) {
        return;
      }

      this.loading.automation.send = true;

      try {
        const {
          isAutomation,
          taxPeriodPay,
          startDate,
        } = this.automationPayout;

        await api.locations.sendAutomationPayout({
          locationId: this.location.id,
          isAutomation,
          taxPeriodPay,
          startDate,
        });

        this.isAutoPayout = this.automationPayout.isAutomation;
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.automation.send = false;
      }
    },
    updateTaxAmount(id, value) {
      value = convertToPointNumber(value, 2);
      if (value <= this.data[id].grossAmount) {
        this.data[id].taxAmount = value;
      } else {
        this.data[id].taxAmount = this.data[id].grossAmount;
      }
    },
  },
};
</script>
