var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"col-12 col-xl-6"},[_c('location-user',{key:("owners-" + _vm.countryCode),attrs:{"items":_vm.form.owners,"disabled":_vm.disabled,"label":_vm.$t('form.labels.owner'),"country-code":_vm.countryCode,"subscription":_vm.form.subscription,"location":_vm.form,"user-role":"owners","input-class":"mb-0"},on:{"completed":function($event){return _vm.$emit('completed')}}}),(_vm.isUk || _vm.isUAE)?_c('location-user',{key:("troncs-" + _vm.countryCode),attrs:{"items":_vm.form.troncMasters,"disabled":_vm.troncMastersDisabled,"label":_vm.$t('form.labels.troncMaster', _vm.form.country),"country-code":_vm.countryCode,"subscription":_vm.form.subscription,"location":_vm.form,"user-role":"troncMasters","input-class":"my-0"},on:{"completed":function($event){return _vm.$emit('completed')}}}):_vm._e(),(!_vm.isGermany && !_vm.isUAE)?_c('location-user',{key:("accountants-" + _vm.countryCode),attrs:{"items":_vm.form.accountants,"disabled":_vm.accountantsDisabled,"label":_vm.$t('form.labels.accountant'),"country-code":_vm.countryCode,"subscription":_vm.form.subscription,"location":_vm.form,"user-role":"accountants","input-class":"my-0"},on:{"completed":function($event){return _vm.$emit('completed')}}}):_vm._e(),(_vm.showSharedCardToggle)?_c('div',{staticClass:"my-2"},[_c('label',{staticClass:"font-14 text-grey",class:{ 'disabled': _vm.disabled }},[_vm._v(" Shared payout card ")]),_c('ui-checkbox',{staticClass:"mt-2",attrs:{"disabled":_vm.disabled},model:{value:(_vm.form.isAllowedSharedCard),callback:function ($$v) {_vm.$set(_vm.form, "isAllowedSharedCard", $$v)},expression:"form.isAllowedSharedCard"}},[_vm._v(" Allow tips to shared card ")])],1):_vm._e(),(!_vm.form.isMasterLocation)?[_c('validation-provider',{staticClass:"my-2 mt-5",attrs:{"tag":"div","name":"Pay buttons","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.payButtons,"label":"Pay buttons","placeholder":_vm.$t('form.placeholders.select'),"label-key":"displayName","value-key":"id","value-original":"","filterable":"","required":"","disabled":_vm.disabled,"error":errors[0]},model:{value:(_vm.form.payButtonValue),callback:function ($$v) {_vm.$set(_vm.form, "payButtonValue", $$v)},expression:"form.payButtonValue"}})]}}],null,false,202930764)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.maxTipsAmount', { currency: _vm.locationCurrency }),"rules":"required|decimal|min_value:1","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.maxTipsAmount', { currency: _vm.locationCurrency }),"required":"","error":errors[0]},model:{value:(_vm.form.maxTipsAmount),callback:function ($$v) {_vm.$set(_vm.form, "maxTipsAmount", $$v)},expression:"form.maxTipsAmount"}})]}}],null,false,3044440508)}),(_vm.showPayoutAndTransferFees)?_c('validation-provider',{staticClass:"my-2 mt-5",attrs:{"tag":"div","name":_vm.payoutAndTransferFeesLabel,"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.payoutPlanSetupList,"label-info":_vm.form.payoutPlanSetup ? _vm.form.payoutPlanSetup.toolTipText : _vm.$t(("payoutPlanSetup.tooltipExample." + _vm.countryCode)),"label":_vm.payoutAndTransferFeesLabel,"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"id","value-original":"","filterable":"","required":"","disabled":_vm.disabled || (Boolean(_vm.form.id) && !_vm.isSupportAdmin),"error":errors[0]},model:{value:(_vm.form.payoutPlanSetup),callback:function ($$v) {_vm.$set(_vm.form, "payoutPlanSetup", $$v)},expression:"form.payoutPlanSetup"}})]}}],null,false,2681444695)}):_vm._e(),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.platformComission'),"rules":"required|decimal|min_value:1|max_value:100","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.platformComission'),"required":"","error":errors[0],"disabled":_vm.disabled},model:{value:(_vm.form.feePlan),callback:function ($$v) {_vm.$set(_vm.form, "feePlan", $$v)},expression:"form.feePlan"}})]}}],null,false,2411630989)}),(_vm.form.isShowRyftAccount)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.ryftAccount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"value":_vm.form.paymentSystemUserId || 'No Ryft Company Account Added',"label":_vm.$t('form.labels.ryftAccount'),"error":errors[0],"disabled":"","copy-button":_vm.form.paymentSystemUserId}})]}}],null,false,1643509691)}):_vm._e(),(_vm.showPayroll)?[_c('validation-provider',{attrs:{"tag":"div","name":"Payroll frequency","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.payrollFrequencies,"placeholder":_vm.$t('form.placeholders.select'),"label":"Payroll frequency","label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.payrollFrequency),callback:function ($$v) {_vm.$set(_vm.form, "payrollFrequency", $$v)},expression:"form.payrollFrequency"}})]}}],null,false,697915948)}),(_vm.showPayrollDay)?_c('validation-provider',{attrs:{"tag":"div","name":"Payroll day","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.payrollDays,"placeholder":_vm.$t('form.placeholders.select'),"label":"Payroll day","label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.payrollDay),callback:function ($$v) {_vm.$set(_vm.form, "payrollDay", $$v)},expression:"form.payrollDay"}})]}}],null,false,1082847489)}):_vm._e(),(_vm.showPayrollCustomDate)?_c('validation-provider',{attrs:{"tag":"div","name":"Payroll date","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-calendar-picker',{attrs:{"placeholder":"Payroll date","label":"Payroll date","required":"","error":errors[0]},model:{value:(_vm.form.payrollCustomDate),callback:function ($$v) {_vm.$set(_vm.form, "payrollCustomDate", $$v)},expression:"form.payrollCustomDate"}})]}}],null,false,900773611)}):_vm._e()]:_vm._e()]:_vm._e(),_c('ui-select',{attrs:{"items":_vm.salesPeopleOptions,"placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disabled,"label":"Sales Manager","label-key":"fio","value-key":"id","filterable":"","value-original":""},model:{value:(_vm.form.salesPerson),callback:function ($$v) {_vm.$set(_vm.form, "salesPerson", $$v)},expression:"form.salesPerson"}}),_c('ui-select',{attrs:{"items":_vm.salesPeopleOptions,"placeholder":_vm.$t('form.placeholders.select'),"disabled":_vm.disabled,"label":"Customer Success Manager","label-key":"fio","value-key":"id","filterable":"","value-original":""},model:{value:(_vm.form.customerSuccessManager),callback:function ($$v) {_vm.$set(_vm.form, "customerSuccessManager", $$v)},expression:"form.customerSuccessManager"}}),(_vm.showStripeAccount)?_c('ui-input',{attrs:{"label":"Stripe Account","disabled":true},model:{value:(_vm.form.stripeAccountId),callback:function ($$v) {_vm.$set(_vm.form, "stripeAccountId", $$v)},expression:"form.stripeAccountId"}}):_vm._e(),(_vm.showCheckoutAccount)?_c('ui-input',{attrs:{"label":"Checkout Account","disabled":true},model:{value:(_vm.checkoutAccount),callback:function ($$v) {_vm.checkoutAccount=$$v},expression:"checkoutAccount"}}):_vm._e(),(_vm.showDistributionSchemes)?_c('validation-provider',{staticClass:"mb-2 mt-5",attrs:{"tag":"div","name":"Options for distribution schemes","rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.distributionSchemes,"label":"Options for distribution schemes","placeholder":_vm.$t('form.placeholders.select'),"optional-key":"text","label-key":"shortText","value-key":"id","value-original":"","small-dropdown":"","filterable":"","required":"","disabled":_vm.disabled,"error":errors[0]},model:{value:(_vm.form.distributionSchemes),callback:function ($$v) {_vm.$set(_vm.form, "distributionSchemes", $$v)},expression:"form.distributionSchemes"}})]}}],null,false,1244862172)}):_vm._e(),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.posModel'),"rules":"required|max:128","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.posModel'),"maxlength":"128","required":"","error":errors[0]},model:{value:(_vm.form.posModel),callback:function ($$v) {_vm.$set(_vm.form, "posModel", $$v)},expression:"form.posModel"}})]}}],null,false,2800118356)}),(_vm.status === 'NOT_ACTIVE' && _vm.turnOffDate && _vm.removeReason)?_c('div',{staticClass:"col-12 pl-0 mb-4 col-md-auto mt-2 mt-md-0 d-flex\n    font-16 text-grey flex-column align-items-start justify-content-around"},[_vm._v(" Pay turned off on "+_vm._s(_vm._f("date")(_vm.turnOffDate))),_c('br'),_vm._v(" "+_vm._s(_vm.removeReason)+" ")]):_vm._e(),(_vm.status === 'ACTIVE' && _vm.onboardingDate)?_c('div',{staticClass:"col-12 pl-0 mb-4 col-md-auto mt-2 mt-md-0 d-flex\n    flex-column align-items-start justify-content-around"},[_c('span',{staticClass:"font-16 text-grey"},[_vm._v(" Onboarded on "+_vm._s(_vm._f("date")(_vm.onboardingDate))+" ")]),_c('ui-button',{attrs:{"link":"","type":"primary","disabled":_vm.isOnboardingLoading},on:{"click":function($event){$event.preventDefault();return _vm.$emit('activate')}}},[_vm._v(" "+_vm._s('Edit date')+" ")])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }