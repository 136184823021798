<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-card tag="form">
      <ui-modal-status
        v-model="successModal"
        :message="$t('profile.successModal.subTitle')"
        :closable="false"
        @closed="logoutHandler"
      />

      <template #header>
        <h4 class="card-title">{{ $t('password.title') }}</h4>
        <h3 class="card-category mt-2">{{ $t('password.subtitle') }}</h3>
      </template>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.oldPassword')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-password
              v-model="form.oldPassword"
              v-protected
              :label="$t('form.labels.oldPassword')"
              name="oldPassword"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            vid="newPassword"
            :name="$t('form.labels.newPassword')"
            rules="required|min:8|max:30|password"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-password
              v-model="form.newPassword"
              v-protected
              :label="$t('form.labels.newPassword')"
              maxlength="30"
              minlength="8"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            ref="newPasswordConfirm"
            :name="$t('form.labels.repeatPassword')"
            rules="required|min:8|max:30|password|confirmedPassword:newPassword"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-password
              v-model="form.newPasswordConfirm"
              v-protected
              :label="$t('form.labels.repeatPassword')"
              maxlength="30"
              minlength="8"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>
      </div>

      <ui-button
        v-protected
        action
        type="primary"
        :is-loading="loading"
        :disabled="loading"
        class="mt-4"
        @click.prevent="handleSubmit(send)"
      >
        {{ $t('form.buttons.update') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import resetMixin from '@/mixins/reset-mixin';

export default {

  mixins: [
    resetMixin(() => ({
      form: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    })),
  ],

  data: () => ({
    loading: false,
    successModal: false,
  }),

  methods: {
    ...mapActions('user', [
      'updatePassword',
    ]),
    async send() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await this.updatePassword(this.form);

        this.successModal = true;
        this.reset();
        this.$refs.newPasswordConfirm.reset();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    logoutHandler() {
      this.$router.push({ name: 'logout' });
    },
  },
};
</script>
