<template>
  <div>
    <ui-card
      class="faq-item"
      :class="{
        'faq-item_hidden': hidden,
        'faq-item_short': short,
      }"
    >
      <template #header>
        <div class="faq-item__header">
          <h3 class="faq-item__title">
            {{ title }}
          </h3>
        </div>
      </template>

      <p
        class="faq-item__text"
        :class="{ 'faq-item__text_wrap': hidden }"
        v-html="body"
      ></p>

      <template #footer>
        <div
          class="faq-item__btn"
          @click="open"
        >
          <span v-if="hidden">{{ $t('faq.show') }}</span>
          <span v-else>{{ $t('faq.close') }}</span>
          <ui-icon
            name="arrow-right"
            :size="12"
            class="text-primary"
          />
        </div>
      </template>
    </ui-card>
  </div>
</template>

<script>
export default {

  props: {
    title: String,
    body: String,
  },

  data() {
    return {
      maxTextLength: 300,
      hidden: true,
    };
  },

  computed: {
    short() {
      return this.body.length <= this.maxTextLength;
    },
  },

  methods: {
    open() {
      this.hidden = !this.hidden;
    },
  },
};
</script>
