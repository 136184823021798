<template>
  <div>
    <notification-create-form
      @completed="onCompleted"
    />
    <notification-list
      @update="onUpdate"
    />
  </div>
</template>

<script>
import NotificationCreateForm from '@/components/Notification/NotificationCreateForm.vue';
import NotificationList from '@/components/Notification/NotificationList.vue';

export default {
  name: 'Notifications',

  components: {
    NotificationCreateForm,
    NotificationList,
  },

  methods: {
    onCompleted({ id }) {
      this.$router.push(
        {
          name: 'notification-view',
          params: {
            notificationId: id,
          },
        },
      );
    },
    onUpdate(route) {
      this.$router.push({
        ...route,
      }, () => {});
    },
  },
};
</script>
