<template>
  <div>
    <div
      v-for="item in visibleGroups"
      :key="item.id"
      class="d-flex align-items-center mb-4 px-1"
    >
      <template v-if="item.id">
        <a
          v-if="item.status !== 'NOT_ACTIVE'"
          href="javascript:void(0)"
          @click="openQr(item)"
          class="text-dark"
        >
          <ui-icon
            name="qr"
            size="16"
          />
        </a>
        <ui-icon
          v-else
          name="qr"
          size="16"
          class="mr-3"
        />
      </template>

      <strong>
        {{ item.name }}
      </strong>

      <ui-action-down
        :items="formActions"
        value-key="id"
        class="ml-auto"
        @select="handleAction($event, item)"
      />
    </div>

    <ui-button
      action
      outline
      type="primary"
      size="sm"
      native-type="button"
      class="w-100"
      @click="createGroup"
      :disabled="isGroupFilled"
    >
      {{ $t('form.buttons.addGroup') }}
    </ui-button>

    <validation-provider
      tag="div"
      rules="required|min_value:2"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-input
        v-model="visibleGroups.length"
        type="hidden"
        :error="errors.length ? 'Please enter at least 2 groups' : null"
        class="mt-1"
      />
    </validation-provider>

    <location-group-form
      v-model="formShow"
      :group="formGroup"
      :location="location"
      @update:group="applyForm"
    />

    <qr-code
      v-if="location.id"
      :show="Boolean(QrShow)"
      v-bind="QrShow"
      @closed="closeQr"
    />
  </div>
</template>

<script>
import QrCode from '@/components/QrCode.vue';
import LocationGroupForm from './LocationGroupForm.vue';

export default {

  components: {
    LocationGroupForm,
    QrCode,
  },

  props: {
    groups: {
      type: Array,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    formShow: false,
    formGroup: null,
    QrShow: false,
  }),

  computed: {
    formActions() {
      return [
        {
          id: 'edit',
          label: this.$t('form.buttons.editGroup'),
        },
        {
          id: 'remove',
          label: this.$t('form.buttons.deleteGroup'),
        },
      ];
    },
    visibleGroups() {
      return this.groups.filter(({ removed }) => !removed);
    },
    isGroupFilled() {
      return this.visibleGroups.length > 8;
    },
  },

  methods: {
    openForm(item = null) {
      this.formGroup = item;
      this.formShow = true;
    },
    createGroup() {
      this.openForm();
    },
    async removeGroup(item) {
      if (item.staffs?.length > 0) {
        this.$alert({
          title: this.$t('staffs.modal.confirmation'),
          message: this.$t('staffs.modal.pleaseDeactivate'),
          applyButton: this.$t('form.buttons.ok'),
        });

        return;
      }

      if (
        await this.$confirm({
          title: this.$t('staffs.modal.confirmation'),
          message: this.$t('staffs.modal.sureDeleteGroup'),
          applyButton: this.$t('form.buttons.deleteGroup'),
          closeButton: this.$t('form.buttons.cancel'),
        })
      ) {
        item.removed = true;
      }
    },
    applyForm(group) {
      if (this.formGroup) {
        Object.assign(this.formGroup, group);
      } else {
        this.groups.push({
          ...group,
          newest: true,
          removed: false,
        });
      }

      this.formShow = false;
    },
    handleAction(event, item) {
      if (event === 'edit') {
        this.openForm(item);
      }

      if (event === 'remove') {
        this.removeGroup(item);
      }
    },
    openQr(item) {
      this.QrShow = {
        locationId: this.location.id,
        groupId: item.id,
      };
    },
    closeQr() {
      this.QrShow = null;
    },
  },
};
</script>
