<template>
  <ui-modal v-bind="$attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      <span class="d-flex justify-content-center font-24 text-center mb-3 ml-4">
        {{ $t('reporting.bonusTitle') }}
      </span>
    </template>

    <div class="text-grey font-16 text-center">
      {{ $t('bonusTips.text[0]', { date: activePromoDate }) }}
      <span v-html="$t('bonusTips.text[1]')"></span>
    </div>

    <template #footer="{ close }">
      <div class="d-flex justify-content-center w-100 mt-4">
        <ui-button
          round
          type="primary"
          native-type="button"
          class="w-50"
          @click="close"
        >
          {{ $t('form.buttons.ok') }}
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
export default {
  props: {
    location: Object,
  },

  computed: {
    activePromoDate() {
      return this.$options.filters.date(this.location.activePromo.dateTo, 'dd LLLL yyyy');
    },
  },
};
</script>
