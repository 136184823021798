<template>
  <div class="statistics-table-header">
    <div class="statistics-table-header__cell">Staff</div>
    <div class="statistics-table-header__cell">Error message</div>
    <div class="statistics-table-header__cell statistics-table-header__cell_center">Action</div>
    <div class="statistics-table-header__cell statistics-table-header__cell_center">Seen</div>
    <div class="statistics-table-header__cell statistics-table-header__cell_center">Sent</div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsTableHeader',
};
</script>
