<template>
  <ui-stats-card
    v-bind="$attrs"
    :value="value || 0"
    :is-loading="isLoading"
    icon="rating"
  />
</template>

<script>
import api from '@/api';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    groupId: [Number, String],
  },

  data: () => ({
    value: null,
  }),

  computed: {
    apiMethod() {
      return this.staffId ? api.staff.getRating : api.locations.getRating;
    },
    apiConfig() {
      return {
        locationId: this.locationId,
        staffId: this.staffId || undefined,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
  },

  methods: {
    async load() {
      const { data } = await this.apiMethod(this.apiConfig);

      if (data) {
        this.value = data.value;
      }
    },
  },
};
</script>
