<template>
  <div :key="storeUpdated">
    <component
      v-if="profileComponent"
      :key="`location-${location.id}`"
      :is="profileComponent"
      :groups="groups"
      @updated="loadData"
    />

    <user-phone
      v-if="showPhone && !defaultLocationRequired"
    />

    <user-password />

    <user-stripe-card
      v-if="showStripe"
    />

    <template v-if="!defaultLocationRequired">
      <staff-register
        v-if="showRegister"
        :key="`register-${location.id}`"
        :location="location"
        :is-loading="groupsLoading"
        :is-common="isCommon"
        :groups="groups"
        @completed="loadData"
      />
      <template v-if="showStaffs">
        <template v-if="showGroups">
          <template v-for="(group, index) in groups">
            <staff-list-all
              :key="`staffs-${location.id}-${group.id}`"
              :location="location"
              :group="group"
              :groups="groups"
              :is-loading="staffsLoading"
              :staffs="group.staffs"
              :route-prefix="`staffListGroup${index}`"
              @updated="loadData"
            />
          </template>
        </template>
        <template v-else-if="isMasterLocation">
          <template v-for="(sublocation, index) in sublocationsStaffs">
            <staff-list-all
              :key="`staffs-${location.id}-${sublocation.id}`"
              :location="location"
              :sublocation="sublocation"
              :is-loading="sublocationsStaffsLoading"
              :staffs="sublocation.staffs"
              :route-prefix="`staffListSublocation${index}`"
              @updated="loadData"
            />
          </template>
        </template>
        <staff-list-all
          v-else
          :key="`staffs-${location.id}`"
          :location="location"
          :is-loading="staffsLoading"
          :staffs="staffs"
          route-prefix="staffList"
          @updated="loadData"
        />
      </template>
    </template>
    <ui-modal-status
      v-model="modalStatus.show"
      :key="modalStatus.key"
      :type="modalStatus.type"
      :icon="modalStatus.icon"
      :icon-size="modalStatus.iconSize"
      :title="modalStatus.title"
      :message="modalStatus.message"
      :no-button="modalStatus.noButton"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { uniqueId } from 'lodash';
import storage from '@/plugins/storage';
import resetMixin from '@/mixins/reset-mixin';
import AdminProfile from '@/components/Admin/AdminProfile.vue';
import StaffProfile from '@/components/Staff/StaffProfile.vue';
import ManagerProfile from '@/components/Manager/ManagerProfile.vue';
import UserPhone from '@/components/User/UserPhone.vue';
import UserPassword from '@/components/User/UserPassword.vue';
import StaffRegister from '@/components/Staff/StaffRegister.vue';
import StaffListAll from '@/components/StaffList/StaffListAll.vue';
import UserStripeCard from '@/components/User/UserStripeCard.vue';

const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export default {

  components: {
    UserStripeCard,
    AdminProfile,
    StaffProfile,
    ManagerProfile,
    UserPhone,
    UserPassword,
    StaffRegister,
    StaffListAll,
  },

  mixins: [
    resetMixin(() => ({
      modalStatus: {
        key: '',
        show: false,
        type: 'warning',
        title: '',
        message: '',
        icon: '',
      },
    })),
  ],

  computed: {
    ...mapGetters('auth', [
      'isAdmin',
      'isAccountant',
      'isStaff',
      'isManager',
      'isAdminOrSales',
      'isOwner',
      'isTroncMaster',
      'isTroncStaff',
      'isDistributionMaster',
      'isStaffMaster',
    ]),
    ...mapGetters('user', [
      'defaultLocationRequired',
      'isProfileFilled',
      'isCommon',
      'isMasterLocation',
      'isProfileStripe',
    ]),
    ...mapState({
      staffs: (state) => state.staffs.items,
      staffsLoading: (state) => state.staffs.loading,
      showManagePayout: (state) => state.user.showManagePayout,
      showManageVenuePayout: (state) => state.user.showManageVenuePayout,
      location: (state) => state.user.location,
      groups: (state) => state.groups.items,
      groupsLoading: (state) => state.groups.loading,
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
      sublocations: (state) => state.sublocations.sublocations,
      sublocationsStaffs: (state) => state.sublocations.sublocationsStaffs,
      sublocationsStaffsLoading: (state) => state.sublocations.sublocationsStaffsLoading,
    }),
    profileComponent() {
      if (this.isAdminOrSales) {
        return 'admin-profile';
      }

      if (this.isOwner) {
        return 'manager-profile';
      }

      if (this.isStaff || this.isAccountant || this.isTroncMaster) {
        return 'staff-profile';
      }

      if (this.isManager) {
        return 'manager-profile';
      }

      return false;
    },
    showPhone() {
      return !this.isAdminOrSales;
    },
    showStripe() {
      return this.isProfileStripe && (this.showManagePayout || this.showManageVenuePayout);
    },
    showStaffs() {
      return this.isManager
        || this.isOwner
        || this.isAccountant
        || this.isDistributionMaster;
    },
    showRegister() {
      return this.isStaffMaster && !this.isMasterLocation;
    },
    showGroups() {
      return this.showStaffs && this.location.paymentPage === 'GROUPS';
    },
    isAdminOrViewAsMode() {
      return this.viewAsMode !== null
        || this.isAdmin;
    },
    storeUpdated() {
      return `${this.accessToken}-${this.payoutId}`;
    },
  },

  mounted() {
    this.fillingProfileAlertHandler();
    if (!this.isAdminOrViewAsMode) {
      this.$amplitude.event('OPENED_PAGE', {
        userRoles: this.userRoles,
        hostname: window.location.hostname,
        pagepath: window.location.pathname,
        url: window.location.href,
      });
    }
  },

  watch: {
    defaultLocationRequired: {
      immediate: true,
      handler(value) {
        if (value) {
          return;
        }

        this.loadData();
      },
    },
    storeUpdated: {
      immediate: true,
      async handler() {
        this.loadData();
      },
    },
    sublocations: {
      immediate: true,
      handler() {
        this.loadSublocationsStaffs();
      },
    },
  },

  methods: {
    ...mapActions({
      loadStaffItems: 'staffs/loadItems',
      loadGroupItems: 'groups/loadItems',
      loadSublocationsStaffs: 'sublocations/loadSublocationsStaffs',
    }),
    async loadStaffs() {
      if (!this.showStaffs) {
        return;
      }

      try {
        await this.loadStaffItems({
          locationId: this.location.id,
          onlyActive: false,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadGroups() {
      if (!this.showGroups) {
        return;
      }

      try {
        await this.loadGroupItems({
          locationId: this.location.id,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    loadData() {
      return Promise.all([
        this.loadStaffs(),
        this.loadGroups(),
      ]);
    },
    showEmptyProfileModal() {
      this.reset();

      this.modalStatus = {
        key: uniqueId('complete-profile-modal_'),
        show: true,
        type: 'warning',
        title: this.$t('profile.completeProfileAlert.title'),
        message: this.$t('profile.completeProfileAlert.message'),
        icon: 'filling-form',
      };
    },
    fillingProfileAlertHandler() {
      const hideFillingModalDeadline = storage.get('HIDE_FILLING_MODAL_DEADLINE') || 0;
      if (!this.isProfileFilled && +hideFillingModalDeadline < +new Date()) {
        this.showEmptyProfileModal();
        const showModalAfter = +new Date() + ONE_DAY_IN_MILLISECONDS;
        storage.set('HIDE_FILLING_MODAL_DEADLINE', showModalAfter);
      }
    },
  },
};
</script>
