<template>
  <validation-observer v-slot="{ validate }">
    <ui-card tag="form">
      <div class="form-group mt-0 mb-4">
        <ui-avatar
          v-protected
          :image="image"
          :is-loading="loading.avatar"
          :title="$t('UI.avatar.managerTitle')"
          @save="uploadAvatar"
        />
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <validation-provider
            tag="div"
            :name="$t('form.labels.firstName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.firstName"
              v-protected
              :label="$t('form.labels.firstName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :name="$t('form.labels.lastName')"
            rules="required|min:2|max:30"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.lastName"
              v-protected
              :label="$t('form.labels.lastName')"
              maxlength="30"
              minlength="2"
              capitalized
              required
              :error="errors[0]"
            />
          </validation-provider>

          <template v-if="isOwner">
            <validation-provider
              tag="div"
              :name="$t('form.labels.staffType')"
              :rules="{ required: !staffTypeDisabled }"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.staffType"
                v-protected
                :items="filteredStaffTypes"
                :label="$t('form.labels.staffType')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="value"
                :required="!staffTypeDisabled"
                :error="errors[0]"
                @input="onStaffTypeChanged"
                :disabled="staffTypeDisabled"
              />
            </validation-provider>
          </template>

          <validation-provider
            tag="div"
            :name="$t('form.labels.email')"
            rules="required|email"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.email"
              v-protected
              :label="$t('form.labels.email')"
              required
              :error="errors[0]"
            />
          </validation-provider>

          <validation-provider
            tag="div"
            :rules="dateOfBirthRequired ? 'required' : ''"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-date-birth
              v-model="form.dateOfBirth"
              v-protected
              :required="dateOfBirthRequired"
              input-classes="w-auto"
              :error="errors[0]"
            />
          </validation-provider>

          <template v-if="isOwner">
            <validation-provider
              v-if="showNickName"
              tag="div"
              name="Nickname (for tipping page)"
              rules="min:2|max:30"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.nickName"
                v-protected
                label="Nickname (for tipping page)"
                maxlength="30"
                minlength="2"
                :error="errors[0]"
              />
            </validation-provider>

            <validation-provider
              v-if="showTaxReportingTypes"
              tag="div"
              name="Tax reporting"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.taxReportingType"
                v-protected
                :items="MAIN_TAX_REPORTING_TYPES"
                label-key="label"
                value-key="value"
                label="Tax reporting"
                :placeholder="$t('form.placeholders.select')"
                :disabled="disableTaxReportingType(location.taxReportingType)"
                required
                :error="errors[0]"
              />
            </validation-provider>

            <validation-provider
              v-if="showGroups"
              tag="div"
              :name="$t('form.labels.group')"
              :rules="{ required: !groupDisabled }"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.groupId"
                v-protected
                :items="groups"
                :label="$t('form.labels.group')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="id"
                :required="!groupDisabled"
                :disabled="groupDisabled"
                :error="errors[0]"
              />
            </validation-provider>
          </template>
        </div>

        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <ui-input
            v-model="location.name"
            :label="$t('form.labels.locationName')"
            disabled
          />
          <ui-input
            :value="location.groupName || 'No group'"
            :label="$t('form.labels.groupName')"
            disabled
          />
          <ui-input
            v-model="countryName"
            :label="$t('form.labels.country')"
            disabled
          />
          <ui-input
            v-model="location.city"
            :label="$t('form.labels.city')"
            disabled
          />
          <ui-input
            v-model="location.address"
            :label="$t('form.labels.address')"
            disabled
          />
        </div>
      </div>

      <ui-button
        v-protected
        action
        type="primary"
        :is-loading="loading.form"
        :disabled="loading.form"
        class="mt-4"
        @click.prevent="send(validate)"
      >
        {{ $t('form.buttons.update') }}
      </ui-button>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import api from '@/api';
import parseDate from '@/utils/parseDate';
import toSQLDate from '@/utils/toSQLDate';
import { allCountries } from '@/config/countries';
import { MAIN_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import { NOBODY } from '@/config/staffTypes';
import disableTaxReportingType from '@/utils/disableTaxReportingType';

export default {

  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    loading: {
      avatar: false,
      form: false,
    },
    form: {
      email: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      staffType: null,
      groupId: null,
      taxReportingType: null,
      nickName: null,
    },
    MAIN_TAX_REPORTING_TYPES,
  }),

  computed: {
    ...mapGetters({
      filteredStaffTypes: 'handbook/filteredStaffTypes',
      staffTypeDisabled: 'handbook/staffTypeDisabled',
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
    }),
    ...mapState('user', [
      'profile',
      'location',
      'image',
    ]),
    dateOfBirthRequired() {
      const isUAEOwner = this.isOwner && this.isUAE;
      return (this.form.staffType && this.form.staffType !== NOBODY) || isUAEOwner;
    },
    countryName() {
      return allCountries.find(({ iso }) => iso === this.location.country)?.name
        || this.location.country;
    },
    showGroups() {
      return this.location.paymentPage === 'GROUPS';
    },
    group() {
      return this.groups.find(({ staffs }) => {
        return staffs.some(({ id }) => id === this.profile.staffId);
      });
    },
    groupDisabled() {
      return !this.form.staffType || this.form.staffType === NOBODY;
    },
    showTaxReportingTypes() {
      return this.form.staffType && this.form.staffType !== NOBODY && !this.isGermany && !this.isUAE;
    },
    isGermany() {
      return this.location.country === 'de';
    },
    isUAE() {
      return this.location.country === 'ae';
    },
    showNickName() {
      return this.form.staffType && this.form.staffType !== NOBODY && !this.isCommon;
    },
  },

  watch: {
    groups: {
      immediate: true,
      handler() {
        this.form.groupId = this.group?.id;
      },
    },
  },

  async mounted() {
    this.loadData();

    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  methods: {
    ...mapActions({
      initializeSupport: 'support/initialize',
      loadInfo: 'user/loadInfo',
      uploadImage: 'user/uploadImage',
      refreshToken: 'auth/refreshToken',
      getStaffTypes: 'handbook/getStaffTypes',
    }),
    disableTaxReportingType,
    loadData() {
      this.form.email = this.profile.email;
      this.form.firstName = this.profile.firstName;
      this.form.lastName = this.profile.lastName;
      this.form.dateOfBirth = this.profile.dateOfBirth
        ? parseDate(this.profile.dateOfBirth)
        : null;
      this.form.staffType = this.profile.staffType;
      if (this.disableTaxReportingType(this.location.taxReportingType)) {
        this.form.taxReportingType = this.location.taxReportingType;
      } else {
        this.form.taxReportingType = this.profile.taxReportingType;
      }

      this.form.nickName = this.profile.nickName;
    },
    async uploadAvatar(file) {
      this.loading.avatar = true;

      try {
        await this.uploadImage(file);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.avatar = false;
      }
    },
    async send(validate) {
      if (!await validate()) {
        this.$warning({
          message: this.$t('alerts.requiredModal.message'),
        });

        return;
      }

      if (this.loading.form) {
        return;
      }

      this.loading.form = true;

      const {
        groupId,
        dateOfBirth,
        ...form
      } = this.form;

      try {
        const { data } = await api.user.updateProfile({
          ...form,
          dateOfBirth: toSQLDate(dateOfBirth),
        });

        if (
          this.isOwner
          && this.showGroups
          && data.staffId
          && groupId !== this.group?.id
        ) {
          if (this.group) {
            await api.locations.removeStaffFromGroup({
              locationId: this.location.id,
              staffId: data.staffId,
              groupId: this.group.id,
            });
          }

          if (groupId) {
            await api.locations.addStaffToGroup({
              locationId: this.location.id,
              staffId: data.staffId,
              groupId,
            });
          }
        }

        const isOldNullable = [NOBODY, null].includes(this.profile.staffType);
        const isNewNullable = [NOBODY, null].includes(this.form.staffType);

        await this.loadInfo();
        await this.initializeSupport();

        if (this.isOwner && isOldNullable !== isNewNullable) {
          await this.refreshToken(() => {});
        }

        this.loadData();
        this.$showSuccess();
        this.$emit('updated');
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
    onStaffTypeChanged() {
      if (this.form.staffType === NOBODY) {
        this.form.taxReportingType = null;
        this.form.groupId = null;
        this.form.nickName = null;
      }
    },
  },
};
</script>
