var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-plan",class:{
    'period-plan_selected': _vm.selected,
    'period-plan_disabled': _vm.disabled,
  },on:{"click":function($event){return _vm.$emit('select')}}},[_c('div',{staticClass:"period-plan__body"},[_vm._m(0),_c('div',{staticClass:"period-plan__top"},[_c('div',{staticClass:"period-plan__title"},[_vm._v(" "+_vm._s(_vm.period.header)+" ")]),_c('div',{staticClass:"period-plan__description"},[_vm._v(" "+_vm._s(_vm.period.description)+" ")])]),_c('div',{staticClass:"period-plan__content"},[(_vm.period.activePromo)?_c('div',{staticClass:"period-plan__promo"},[_c('ui-icon',{staticClass:"period-plan__promo_icon",attrs:{"name":"promo","width":"52px","height":"53px"}}),_c('span',{staticClass:"period-plan__promo_label"},[_c('span',[_vm._v("free")]),_c('span',[_vm._v(_vm._s(_vm.period.promoDaysCnt)+" days")])])],1):_vm._e(),_c('div',{staticClass:"period-plan__value",class:{
          'period-plan__value_promo': _vm.period.activePromo,
        }},[_c('div',[_vm._v(" "+_vm._s(_vm.period.info.value)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.period.info.description)+" ")])])]),_c('div',{staticClass:"period-plan__bottom"},[_c('div',{staticClass:"period-plan__example",class:{
          'period-plan__example_promo': _vm.period.activePromo,
        }},_vm._l((_vm.period.example),function(exampleRow){return _c('div',{key:exampleRow},[_vm._v(" "+_vm._s(exampleRow)+" ")])}),0)])]),_c('div',{staticClass:"period-plan__footer"},[_vm._v(" "+_vm._s(_vm.period.periodPayedDescription)+" ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-plan__status"},[_c('div',{staticClass:"period-plan__status-value"})])}]

export { render, staticRenderFns }