<template>
  <validation-observer tag="form" #default="{ invalid, handleSubmit }">
    <ui-modal-alert
      v-bind="$attrs"
      :title="$t('staffs.modal.chooseGroup')"
      :apply-disabled="invalid"
      :apply-button="$t('form.buttons.yes')"
      :close-button="$t('form.buttons.cancel')"
      @apply="handleSubmit(handleApply)"
      @cancel="handleCancel"
    >
      <validation-provider
        tag="div"
        rules="required"
      >
        <ui-checkbox
          v-for="item in groups"
          :key="item.id"
          class="d-flex mb-3"
          v-model="selected"
          :value="item.id"
        >
          {{ item.name }}
        </ui-checkbox>
      </validation-provider>
      <p class="alert alert-warning text-muted px-1 mt-4">
        {{ $t('staffs.modal.moveStaff') }}
      </p>
    </ui-modal-alert>
  </validation-observer>
</template>

<script>
import api from '@/api';

export default {
  name: 'StaffGroupsModal',

  props: {
    staff: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    current: [],
    selected: [],
  }),

  created() {
    this.current = this.groups
      .filter(({ staffs }) => staffs.some(({ id }) => id === this.staff.id))
      .map(({ id }) => id);

    this.selected = [...this.current];
  },

  methods: {
    async handleApply() {
      try {
        const removed = this.current.filter((id) => !this.selected.includes(id));
        const added = this.selected.filter((id) => !this.current.includes(id));

        await Promise.all([
          ...removed.map((id) => {
            return api.locations.removeStaffFromGroup({
              locationId: this.location.id,
              staffId: this.staff.id,
              groupId: id,
            });
          }),
          ...added.map((id) => {
            return api.locations.addStaffToGroup({
              locationId: this.location.id,
              staffId: this.staff.id,
              groupId: id,
            });
          }),
        ]);

        this.$emit('apply');
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
