<template>
  <app-onboarding container-flex>
    <ui-card class="recovery-error">
      <template #header>
        <img class="recovery-error__img" src="@/assets/images/recovery-error.svg" alt="">
      </template>

      <div class="recovery-error__title">
        Error
      </div>

      <div>
        <div class="recovery-error__text">
          Can’t save form data.
        </div>
        <div class="recovery-error__text">
          Please try again later
        </div>
      </div>

      <template #footer>
        <ui-button
          native-type="submit"
          type="primary"
          block
          class="mt-3 mb-3"
          @click="ok">
          Ok
        </ui-button>
      </template>
    </ui-card>
  </app-onboarding>
</template>

<script>
import AppOnboarding from '@/components/AppOnboarding.vue';

export default {
  components: {
    AppOnboarding,
  },

  methods: {
    ok() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
