<template>
  <ui-card
    class="online-menu-background"
    :is-loading="loading.card"
  >
    <template #header>
      <h4 class="online-menu-background__title card-title">
        {{ $t('onlineMenu.menuBackground.title') }}
      </h4>
    </template>

    <div class="online-menu-background__body">
      <div class="online-menu-background__settings">
        <p class="online-menu-background__subtitle">
          {{ $t('onlineMenu.menuBackground.description') }}
        </p>

        <div class="online-menu-background__selected-image">
          <div
            v-if="backgroundName"
            class="online-menu-background__image-file"
          >
            <div class="online-menu-background__image-file-data">
              <span class="online-menu-background__image-file-name">
                {{ backgroundName }}
              </span>
              <span class="online-menu-background__image-file-size">
                {{ backgroundSize }}
              </span>
            </div>

            <ui-icon-button
              v-protected
              class="online-menu-background__image-file-delete text-primary"
              name="trash"
              width="16px"
              height="17px"
              @click="triggerDeleteBackgroundModal"
            />
          </div>

          <ui-button-upload
            v-protected
            class="online-menu-background__button-upload"
            title="Add background"
            accept-files="image/*"
            action
            @upload="sendBackground"
          />
        </div>
      </div>

      <div class="online-menu-background__preview">
        <p class="online-menu-background__preview-subtitle">
          {{ $t('onlineMenu.menuBackground.description') }}
        </p>

        <div class="online-menu-background__preview-image">
          <div
            v-if="loading.image"
            class="online-menu-background__preview-image_loading"
          >
            <ui-loading />
          </div>
          <div
            v-else-if="backgroundImage"
            class="online-menu-background__preview-image_has-file"
          >
            <img
              :src="backgroundImage"
              :alt="backgroundName"
            />
          </div>
          <div v-else class="online-menu-background__preview-image_no-file">
            <p class="online-menu-background__preview-image-title">
              No background<br>uploaded
            </p>
          </div>
        </div>
      </div>
    </div>

    <ui-modal-alert
      v-model="deleteBackgroundModal"
      :title="$t('onlineMenu.modal.deleteBackground.title')"
      :apply-button="$t('form.buttons.remove')"
      :close-button="$t('form.buttons.cancel')"
      @apply="deleteBackground"
    >
      {{ $t('onlineMenu.modal.deleteBackground.description') }}
    </ui-modal-alert>
  </ui-card>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import toBase64 from '@/utils/toBase64';
import fromBytesToMb from '@/utils/fromBytesToMb';
import fromBytesToKb from '@/utils/fromBytesToKb';
import resetMixin from '@/mixins/reset-mixin';

const parseImage = (imageData) => {
  const uploadedImage = Array.from(imageData.target.files)[0];

  return {
    fileName: uploadedImage.name,
    fileSize: uploadedImage.size,
    file: uploadedImage,
  };
};

export default {
  name: 'OnlineMenuBackground',

  mixins: [
    resetMixin(() => {
      return {
        menuBackground: {
          fileName: '',
          fileSize: '',
          link: '',
        },
      };
    }),
  ],

  data() {
    return {
      maxFileSize: 5e6,
      loading: {
        image: false,
        card: false,
      },
      deleteBackgroundModal: false,
    };
  },

  mounted() {
    this.loadBackground();
  },

  computed: {
    ...mapState('user', ['location']),
    backgroundName() {
      return this.menuBackground?.fileName;
    },
    backgroundImage() {
      return this.menuBackground?.link;
    },
    backgroundSize() {
      return this.menuBackground?.fileSize;
    },
  },

  methods: {
    triggerDeleteBackgroundModal() {
      this.deleteBackgroundModal = true;
    },
    loadImage(link) {
      this.loading.image = true;

      const img = new Image();
      img.src = link;

      img.onload = () => {
        this.loading.image = false;
      };

      return link;
    },
    async loadBackground() {
      this.loading.card = true;

      try {
        const { data } = await api.menu.getBackground({
          locationId: this.location.id,
        });

        if (data) {
          this.menuBackground = {
            ...data,
            link: data.link ? this.loadImage(data.link) : null,
            fileSize: data.fileSize > 1e6
              ? `(${fromBytesToMb(data.fileSize)} Mb)`
              : `(${fromBytesToKb(data.fileSize)} Kb)`,
          };
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.card = false;
      }
    },
    async sendBackground(payload) {
      const parsedFile = parseImage(payload);

      if (parsedFile.fileSize > this.maxFileSize) {
        this.$showServerError({
          message: `Max image size: ${fromBytesToMb(this.maxFileSize)}Mb`,
        });

        return;
      }

      this.loading.card = true;

      try {
        const parsedImage = await toBase64(parsedFile.file);

        const { data } = await api.menu.setBackground({
          locationId: this.location.id,
          data: parsedImage,
          fileName: parsedFile.fileName,
        });

        if (data) {
          this.menuBackground = {
            ...data,
            link: this.loadImage(data.link),
            fileSize: data.fileSize > 1e6
              ? `(${fromBytesToMb(data.fileSize)} Mb)`
              : `(${fromBytesToKb(data.fileSize)} Kb)`,
          };
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.card = false;
      }
    },
    async deleteBackground() {
      this.loading.card = true;

      try {
        await api.menu.deleteBackground({
          locationId: this.location.id,
        });

        this.reset();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.card = false;
      }
    },
  },
};
</script>
