<template>
  <ui-modal  v-bind="$attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      Overdue distributions
    </template>

    <div
      v-for="item in distributions"
      :key="item.workplace.id"
      class="d-flex justify-content-between align-items-center mt-4"
    >
      <router-link
        :to="{ name: 'location-view', params: { locationId: item.workplace.id }}"
        class="card-label"
      >
        {{ item.workplace.name }}
      </router-link>
      <div class="card-value d-flex align-items-center">
        {{ item.amount.amount | money(item.amount.currency)}}
        <stats-overdue-info
          :days="item.overdueDays"
          :since="item.firstNoneDistributedDate"
          class="ml-2"
        />
      </div>
    </div>
  </ui-modal>
</template>

<script>
import StatsOverdueInfo from '@/components/Stats/StatsOverdueInfo.vue';

export default {

  components: {
    StatsOverdueInfo,
  },

  props: {
    distributions: {
      type: Array,
      required: true,
    },
  },
};
</script>
