<template>
  <iframe v-if="iframe" :src="url" seamless allow="camera *; microphone *; clipboard-read *; clipboard-write *; web-share *"></iframe>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { APP_PLATFORM, APP_VERSION } from '@/config/app';
import storage from '@/plugins/storage';

export default {

  data: () => ({
    iframe: process.env.VUE_APP_NEW_IFRAME === 'true',
  }),

  computed: {
    ...mapState('auth', [
      'accessToken',
      'refreshToken',
      'userStatus',
    ]),
    ...mapGetters('auth', [
      'expiresInSeconds',
    ]),
    url() {
      const {
        accessToken,
        refreshToken,
        userStatus,
        expiresInSeconds,
      } = this;

      const query = [
        `accessToken=${accessToken}`,
        `appPlatform=${APP_PLATFORM}`,
        `appVersion=${APP_VERSION}`,
        `refreshToken=${refreshToken}`,
        `expiresInSeconds=${expiresInSeconds || ''}`,
        `userStatus=${userStatus || ''}`,
      ];

      if (this.$apiSwitch) {
        query.push(`server=${this.$apiSwitch.selectedValue}`);
      }

      return `${process.env.VUE_APP_NEW_BRIDGE}token?${query.join('&')}`;
    },
  },

  mounted() {
    this.setRefreshDisabled(true);

    if (this.iframe) {
      window.addEventListener('message', this.onMessage);
    } else {
      window.location.href = this.url;
    }
  },

  beforeDestroy() {
    this.setRefreshDisabled(false);

    window.removeEventListener('message', this.onMessage);
  },

  methods: {
    ...mapMutations('auth', [
      'setToken',
      'removeToken',
      'setRefreshDisabled',
    ]),
    onMessage(evt) {
      try {
        const parsed = JSON.parse(evt.data);
        const { event, payload } = {
          event: 'unknown',
          payload: null,
          ...parsed,
        };

        switch (event) {
          case 'logout-as':
            this.logoutAs();
            break;
          case 'logout':
            this.logout();
            break;
          case 'refresh-token':
            this.authRefresh(payload);
            break;
          case 'remove-token':
            this.removeToken();
            break;
          case 'redirect':
            this.redirect(payload);
            break;
          case 'unset-new-app':
            this.unsetNewAppByDefault();
            break;
          default:
        }
      } catch (e) {
        // noop.
      }
    },
    logoutAs() {
      storage.set('NEW_APP_BY_DEFAULT', false);
      return this.$router.push({ name: 'view-as-logout' });
    },
    logout() {
      storage.set('NEW_APP_BY_DEFAULT', false);
      return this.$router.push({ name: 'logout' });
    },
    authRefresh(payload) {
      if (payload?.accessToken) {
        this.setToken(payload);
      } else {
        this.$router.push({ name: 'logout' });
      }
    },
    redirect(payload) {
      this.$router.push(payload);
    },
    unsetNewAppByDefault() {
      storage.set('NEW_APP_BY_DEFAULT', false);
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style scoped>
iframe {
  display: block;
  width: 100vw;
  height: 100vh;
}
</style>
