/**
 * Universal file opener.
 *
 * @param {Blob} blob
 * @param {string} filename
 */
export default (blob) => {
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('target', '_blank');

  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};
