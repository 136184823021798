<template>
  <div
    class="support"
    :key="`${accessToken}-${payoutId}`"
  >
    <div :class="{ 'support__row': showNotificationMessages }">
      <support-chat :class="{ 'support__chat': showNotificationMessages }"/>
      <notification-messages
        v-if="showNotificationMessages"
        class="support__notifications"
      />
    </div>

    <faq-list class="support__faq-list" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SupportChat from '@/components/Support/SupportChat.vue';
import NotificationMessages from '@/components/Notification/NotificationMessages.vue';
import FaqList from '@/components/Faq/FaqList.vue';

export default {

  components: {
    SupportChat,
    NotificationMessages,
    FaqList,
  },

  computed: {
    ...mapState({
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
    }),
    ...mapGetters({
      isAccountant: 'auth/isAccountant',
      isAdminOrSales: 'auth/isAdminOrSales',
    }),
    showNotificationMessages() {
      return !this.isAdminOrSales && !this.isAccountant;
    },
  },

  mounted() {
    if (this.viewAsMode === null) {
      this.$amplitude.event('OPENED_PAGE', {
        userRoles: this.userRoles,
        hostname: window.location.hostname,
        pagepath: window.location.pathname,
        url: window.location.href,
      });
    }
  },
};
</script>
