<template>
  <ui-modal
    show
    title="App preview"
    class="menu-preview-modal"
    @closed="$emit('closed')"
  >
    <template #header>
        <span class="menu-preview-modal__title">
          App preview
        </span>
    </template>
    <ui-loading v-if="!iframeLoaded" />
    <iframe
      :src="payUrl"
      class="menu-preview-modal__iframe"
      :class="{ 'menu-preview-modal__iframe_loading': !iframeLoaded }"
      @load="iframeLoadedHandler"
    />
    <template #footer>
      <div class="menu-preview-modal__footer">
        <ui-button
          outline
          size="sm"
          round
          class="menu-preview-modal__button"
          @click="$emit('closed')"
        >
          Cancel
        </ui-button>
        <ui-button
          v-protected
          round
          size="sm"
          type="primary"
          class="menu-preview-modal__button"
          @click="$emit('publish')"
        >
          Publish
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
export default {
  name: 'MenuPreviewModal',

  props: {
    locationId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      iframeLoaded: false,
    };
  },

  computed: {
    payUrl() {
      // TODO: для локальной разработки
      // const payUrl = 'https://localhost:8080';
      const payUrl = process.env.VUE_APP_PAY_URL;
      return `${payUrl}/${this.locationId}/location?preview=true`;
    },
  },

  methods: {
    iframeLoadedHandler() {
      this.iframeLoaded = true;
    },
  },
};
</script>
