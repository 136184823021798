<template>
  <ui-card :is-loading="loading.card" class="online-menu-contact-info">
    <template #header>
      <h4 class="online-menu-contact-info__title card-title">
        {{ $t('onlineMenu.menuContactInfo.title') }}
      </h4>
    </template>

    <div class="online-menu-contact-info__body">
      <p class="online-menu-contact-info__subtitle">
        {{ $t('onlineMenu.menuContactInfo.description') }}
      </p>

      <div class="online-menu-contact-info__form">
        <div class="online-menu-contact-info__form-row">
          <validation-provider
            ref="address"
            tag="div"
            :name="$t('form.labels.address')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="menuContactInfo.address"
              v-protected
              :label="$t('form.labels.address')"
              required
              :error="errors[0]"
            />
          </validation-provider>

          <ui-phone
            v-model="menuContactInfo.phoneNumber"
            v-protected
            :label="$t('form.labels.phoneNumber')"
            :country-code="countryCode"
          />
        </div>

        <div class="online-menu-contact-info__form-row">
          <div class="online-menu-contact-info__form-social">
            <ui-icon
              name="instagram-lg"
              width="28px"
              height="28px"
            />

            <ui-input
              v-model="menuContactInfo.instagramUrl"
              v-protected
              :label="$t('form.labels.instagramUrl')"
            />
          </div>

          <div class="online-menu-contact-info__form-social">
            <ui-icon
              name="facebook-lg"
              width="28px"
              height="28px"
            />
            <ui-input
              v-model="menuContactInfo.facebookUrl"
              v-protected
              :label="$t('form.labels.facebookUrl')"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="online-menu-contact-info__footer">
        <ui-button
          action
          type="primary"
          :is-loading="loading.preview"
          :disabled="loading.preview"
          @click.prevent="handleSave(true)"
        >
          {{ $t('form.buttons.preview') }}
        </ui-button>

        <ui-button
          v-protected
          action
          type="primary"
          :is-loading="loading.publish"
          :disabled="loading.publish"
          @click.prevent="handleSave(false)"
        >
          {{ $t('form.buttons.publish') }}
        </ui-button>
      </div>
    </template>

    <menu-preview-modal
      v-if="showPreviewModal"
      :location-id="location.payoutId"
      @closed="showPreviewModal = false"
      @publish="handlePreviewModalPublish"
    />
  </ui-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { uniqueId } from 'lodash';
import api from '@/api';
import MenuPreviewModal from '@/components/MenuPreviewModal.vue';

export default {
  name: 'OnlineMenuContactInfo',

  components: {
    MenuPreviewModal,
  },

  data() {
    return {
      loading: {
        card: false,
        preview: false,
        publish: false,
      },
      menuContactInfo: {
        address: '',
        phoneNumber: '',
        instagramUrl: '',
        facebookUrl: '',
      },
      showPreviewModal: false,
    };
  },

  computed: {
    ...mapState('user', [
      'location',
    ]),
    ...mapState('menu', [
      'sectionsMode',
      'sectionsMode',
      'multiSectionList',
    ]),
    countryCode() {
      return this.location.country.toUpperCase();
    },
  },

  mounted() {
    this.loadContactInfo();
  },

  methods: {
    ...mapActions('menu', ['sendData']),
    ...mapMutations('menu', ['setSectionValidationKey']),
    async handlePreviewModalPublish() {
      await this.handleSave(false);

      this.showPreviewModal = false;
    },
    async loadContactInfo() {
      this.loading.card = true;

      try {
        const { data } = await api.menu.getContactInfo({
          locationId: this.location.id,
        });

        if (data) {
          this.menuContactInfo = data;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.card = false;
      }
    },
    async handleSave(preview) {
      const isAddressValid = await this.$refs.address.validate();
      let isSectionNameValid = true;

      if (this.sectionsMode) {
        this.setSectionValidationKey(uniqueId('section_'));
        isSectionNameValid = !this.multiSectionList.some(({ name }) => !name.length);
      }

      if (isAddressValid && isSectionNameValid) {
        if (preview) {
          this.loading.preview = true;
        } else {
          this.loading.publish = true;
        }

        try {
          await this.sendData({
            ...this.menuContactInfo,
            preview,
          });

          if (preview) {
            this.showPreviewModal = true;
          }
        } catch (e) {
          this.$showServerError(e);
        } finally {
          if (preview) {
            this.loading.preview = false;
          } else {
            this.loading.publish = false;
          }
        }
      }
    },
  },
};
</script>
