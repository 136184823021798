<template>
  <ui-modal v-bind="$attrs" v-on="$listeners" size="xl">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      {{ $t('reviews.complaintsTitle') }}
    </template>

    <div class="container">
      <div class="row justify-content-between mt-4">
        <div
          v-for="group in groups"
          :key="group.starsCnt"
          class="col-12 col-lg-4 px-0 mt-4 mt-md-0"
        >
          <ui-stars
            :value="group.starsCnt"
            size="36px"
          />
          <div class="d-flex flex-column mt-4">
            <template v-if="group.reviews.length">
              <div
                v-for="item in group.reviews"
                :key="`group${group.starsCnt}_${item.id}`"
                class="d-flex mt-4"
              >
                <div class="card-value card-value_complaints">
                  {{ item.stats }}
                </div>
                <div class="card-label ml-3">
                  {{ $t(item.name) }}
                </div>
              </div>
            </template>
            <span v-else class="text-gray">
              {{ $t('noData') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </ui-modal>
</template>

<script>
import { range } from 'lodash';

const MAX_STARS = 3;

export default {

  props: {
    reviews: {
      type: Array,
      required: true,
    },
    locationType: {
      type: String,
      default: 'restaurant',
    },
  },

  computed: {
    groups() {
      return range(1, MAX_STARS + 1).map((starsCnt) => {
        return {
          reviews: this.reviews.filter((item) => item.starsCnt === starsCnt),
          starsCnt,
        };
      });
    },
  },
};
</script>
