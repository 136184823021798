<template>
  <div class="chat">
    <h4 class="chat__title">
      {{ $t('support.chatTitle') }}
    </h4>
    <ui-card
      class="chat__content"
      no-padding
    >
      <div
        v-if="messages.length"
        ref="chatBody"
        class="chat__body scrollable"
      >
        <template v-for="(item, index) in messages">
          <div
            v-if="index > 0 && item.date !== messages[index - 1].date"
            :key="`date-${index}`"
            class="chat__row"
          >
            <div class="chat__date">
              {{ item.date }}
            </div>
          </div>
          <div
            v-if="!item.read && (index === 0 || item.read !== messages[index - 1].read)"
            :key="`newest-${index}`"
            class="chat__row"
          >
            <div class="chat__newest">
              {{ $t('support.newMessages') }}
            </div>
          </div>
          <div
            v-if="item.body"
            class="chat__row"
            :key="`row-${index}`"
          >
            <div :class="item.isUser ? 'chat__user' : 'chat__support'">
              <div class="chat__message">
              <span class="chat__username">
                {{ item.author.name }}
              </span>
                <p class="chat__text">
                  {{ item.body }}
                </p>
              </div>
              <div class="chat__time">
                {{ item.time }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="chat__body">
        <ui-alert :message="$t('support.noMessages')" class="chat__alert mx-auto" />
      </div>
      <div class="chat__control">
        <ui-input
          v-model="newMessage"
          v-protected
          :placeholder="$t('support.message')"
          bordered
          @keyup.enter="sendMessage"
        />
        <ui-icon-button
          v-protected
          name="support-button"
          size="44px"
          class="ml-auto"
          :is-loading="isLoading"
          :disabled="!newMessage"
          @click="sendMessage"
        />
      </div>
    </ui-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

const READ_TIMEOUT = 5e3;

export default {
  name: 'SupportChat',

  data: () => ({
    newMessage: '',
    isLoading: false,
    handler: null,
  }),

  computed: {
    ...mapState({
      user: (state) => state.support.user,
      conversation: (state) => state.support.conversation,
      messages: (state) => state.support.messages,
    }),
    ...mapGetters('support', [
      'newMessagesCount',
    ]),
  },

  beforeDestroy() {
    this.stopReading();
  },

  watch: {
    'messages.length': {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.chatBody) {
            this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
          }
        });
      },
    },
    newMessagesCount: {
      immediate: true,
      handler(value) {
        if (value) {
          this.startReading();
        }
      },
    },
  },

  methods: {
    ...mapActions('support', [
      'createMessage',
      'readMessages',
    ]),
    async sendMessage() {
      if (!this.newMessage || this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        await this.createMessage({ message: this.newMessage });

        this.newMessage = '';
        this.readMessages();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    startReading() {
      this.stopReading();

      this.handler = setTimeout(() => {
        this.readMessages();
      }, READ_TIMEOUT);
    },
    stopReading() {
      if (this.handler) {
        clearTimeout(this.handler);
        this.handler = null;
      }
    },
  },
};
</script>
