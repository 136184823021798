<template>
  <img
    :src="getImage()"
    :width="size"
  />
</template>

<script>
import reviewsConfig from '@/config/reviews';

export default {

  props: {
    name: {
      type: String,
      required: true,
    },
    locationType: {
      type: String,
      default: 'restaurant',
    },
    size: {
      type: [String, Number],
      default: '50px',
    },
  },

  methods: {
    getImage() {
      const image = reviewsConfig?.[this.locationType.toLowerCase()]?.[this.name];

      // eslint-disable-next-line global-require, import/no-dynamic-require
      return image ? require(`@/assets/images/reviews/${image}`) : require('@/assets/images/noimage.png');
    },
  },
};
</script>
