export default [
  {
    label: 'Design 1 (default)',
    value: 'DESIGN_ONE',
  },
  {
    label: 'Design 1, Caption',
    value: 'DESIGN_ONE_CAPTION',
  },
  {
    label: 'Design 2, Button 2',
    value: 'DESIGN_TWO_BUTTON_TWO',
  },
  {
    label: 'Design 2, Button 3',
    value: 'DESIGN_TWO_BUTTON_THREE',
  },
  {
    label: 'Design 2, Button 2, Caption',
    value: 'DESIGN_TWO_BUTTON_TWO_CAPTION',
  },
  {
    label: 'Design 2, Button 3, Caption',
    value: 'DESIGN_TWO_BUTTON_THREE_CAPTION',
  },
];
