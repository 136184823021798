<template>
  <staff-list
    v-bind="$attrs"
    v-on="listeners"
    :staffs="visibleStaffs"
    :show-all-filter="filteredStaffs.length > 0"
    :page-size="pageSize"
    :total="total"
  />
</template>

<script>
import StaffList from './StaffList.vue';

export default {
  name: 'StaffListAll',

  components: {
    StaffList,
  },

  props: {
    staffs: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    page: 1,
    showAll: false,
    pageSize: 10,
  }),

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        load: this.handleLoad,
      };
    },
    filteredStaffs() {
      return this.staffs.filter(({ status }) => this.showAll || status === 'ACTIVE');
    },
    visibleStaffs() {
      return this.filteredStaffs.slice(this.start, this.start + this.pageSize);
    },
    total() {
      return this.filteredStaffs.length;
    },
    start() {
      return (this.page - 1) * this.pageSize;
    },
    totalPages() {
      return this.total > 0
        ? Math.ceil(this.total / this.pageSize)
        : 0;
    },
  },

  methods: {
    handleLoad({ page, showAll }) {
      this.showAll = showAll;

      if (page && (this.totalPages === 0 || page <= this.totalPages)) {
        this.page = page;
      }
    },
  },
};
</script>
