<template>
  <div>
    <ui-card
      :is-loading="isLoading"
      class="overview-card h-100"
    >
      <div class="card-header overview-card__header mb-4 pb-2">
        <ui-icon name="overdue-payouts" size="32px"/>
        <h4 class="card-title overview-card__title">
          Unclaimed taxes
        </h4>
      </div>
      <template v-if="previewTaxes.length">
        <div
          v-for="item in previewTaxes"
          :key="item.id"
          class="d-flex justify-content-between mt-3"
        >
          <router-link
            :to="{ name: 'location-view', params: { locationId: item.id }}"
            class="card-label overview-card__label"
          >
            {{ item.name }}
          </router-link>
          <div class="card-value">
            {{ item.amount | money(item.currency) }}
          </div>
        </div>
      </template>
      <span v-else class="text-gray">
        {{ $t('noData') }}
      </span>

      <template #footer>
        <stats-unclaimed-taxes-modal
          v-if="statsTaxes.length > previewCount"
          :taxes="statsTaxes"
        >
          <template #activator="{ open }">
            <ui-button
              link
              type="primary"
              @click="open"
            >
              {{ $t('form.buttons.viewAll') }}
            </ui-button>
          </template>
        </stats-unclaimed-taxes-modal>
      </template>
    </ui-card>
  </div>
</template>

<script>
import api from '@/api';
import StatsUnclaimedTaxesModal from '@/components/Stats/StatsUnclaimedTaxesModal.vue';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    StatsUnclaimedTaxesModal,
  },

  data: () => ({
    items: [],
    previewCount: 5,
  }),

  computed: {
    statsTaxes() {
      return this.items.map(({ amount, workplace }) => ({
        ...workplace,
        ...amount,
      }));
    },
    previewTaxes() {
      return this.statsTaxes.slice(0, this.previewCount);
    },
  },

  methods: {
    async load() {
      const { data } = await api.locations.getUnclaimedTaxes({
        salesId: this.staffId,
      });

      if (data) {
        this.items = data;
      }
    },
  },
};
</script>
