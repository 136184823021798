<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-modal
      class="notifications-modal"
      :show="show"
      :is-loading="isLoading"
      @closed="closed"
      @input="$emit('input', $event)"
      v-on="$listeners"
    >
      <template #header>
        <span class="notifications-modal__title">
          {{ title }}
        </span>
        <slot name="control"></slot>
      </template>

      <div class="notifications-modal__body">
        <slot name="body"></slot>
      </div>

      <template #footer="{ close }">
        <div class="notifications-modal__actions">
          <ui-button
            action
            type="primary"
            @click.prevent="handleSubmit(() => apply(close))"
          >
            {{ $t('form.buttons.apply') }}
          </ui-button>
        </div>
      </template>
    </ui-modal>
  </validation-observer>
</template>

<script>
export default {
  name: 'NotificationModal',

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    show: Boolean,
    isLoading: Boolean,
    title: String,
  },

  methods: {
    closed() {
      this.$emit('closed');
    },
    apply(close) {
      close();

      this.$emit('confirm');
    },
  },
};
</script>
