<template>
  <div
    v-if="faqList.length > 0"
    class="faq-list"
  >
    <h3 class="faq-list__title">
      {{ $t('faq.title') }}
    </h3>
    <div class="faq-list__tabs">
      <div class="faq-list__tabs-group">
        <button
          v-for="tab in tabList"
          :key="tab.value"
          class="faq-list__tab"
          :class="[
            `faq-list__tab_${tab.value}`,
            {
              'faq-list__tab_active': currentTab === tab.value,
            },
          ]"
          @click="handleTabSelect(tab.value)"
        >
          {{ tab.label }}
        </button>
      </div>
    </div>
    <div class="faq-list__cards">
      <faq-item
        v-for="item in faqList"
        :key="item.id"
        :title="item.title"
        :body="item.answer"
        class="faq-list__card"
      />
    </div>
  </div>
</template>

<script>
import FaqItem from './FaqItem.vue';

export default {

  components: {
    FaqItem,
  },

  data() {
    return {
      currentTab: 'general',
    };
  },

  computed: {
    tabList() {
      return [
        {
          value: 'general',
          label: this.$t('faq.tabs.general'),
        },
        {
          value: 'employers',
          label: this.$t('faq.tabs.employers'),
        },
        {
          value: 'employees',
          label: this.$t('faq.tabs.employees'),
        },
      ];
    },
    faqList() {
      return this.$t('faq.list')
        .filter(({ group }) => group === this.currentTab);
    },
  },

  methods: {
    handleTabSelect(name) {
      this.currentTab = name;
    },
  },
};
</script>
