<template>
  <div v-if="items.length">
    <ui-card
      v-bind="$attrs"
      :is-loading="isLoading"
      class="h-100"
    >
      <div class="card-header pt-0 px-0">
        <h4 class="card-title">
          {{ 'Top earners' }}
        </h4>
      </div>
      <template v-if="previewEarners.length">
        <div
          v-for="(item, index) in previewEarners"
          :key="index"
          class="d-flex justify-content-between mt-4"
        >
          <div class="card-label">
            {{ index + 1 }}. {{ item.fio }}
          </div>
          <div class="card-value">
            <template v-if="item.sum">
              {{ item.sum.amount | money(item.sum.currency) }}
            </template>
          </div>
        </div>
      </template>
      <span v-else class="text-gray">
      {{ $t('noData') }}
    </span>

      <template #footer>
        <stats-earners-modal
          v-if="statsEarners.length > previewCount"
          :items="statsEarners"
        >
          <template #activator="{ open }">
            <ui-button
              link
              type="primary"
              @click="open"
            >
              {{ $t('form.buttons.viewAll') }}
            </ui-button>
          </template>
        </stats-earners-modal>
      </template>
    </ui-card>
  </div>
</template>

<script>
import api from '@/api';
import StatsEarnersModal from '@/components/Stats/StatsEarnersModal.vue';
import getFio from '@/utils/fio';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    StatsEarnersModal,
  },

  props: {
    groupId: [Number, String],
  },

  data: () => ({
    items: [],
    previewCount: 4,
  }),

  watch: {
    items: {
      handler(value) {
        if (value.length) {
          this.$emit('visible');
        }
      },
    },
  },

  computed: {
    statsEarners() {
      return this.items.map((item) => ({
        ...item,
        fio: getFio(item.staff) || item.staff.login || 'Noname',
      }));
    },
    previewEarners() {
      return this.statsEarners.slice(0, this.previewCount);
    },
  },

  methods: {
    async load() {
      const { data } = await api.locations.getTopEarners({
        locationId: this.locationId,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });

      if (data) {
        this.items = data;
      }
    },
  },
};
</script>
