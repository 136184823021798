export const ENABLE_BOOLEAN_SELECT_VALUES = [
  {
    name: 'Enabled',
    value: true,
  },
  {
    name: 'Disabled',
    value: false,
  },
];
