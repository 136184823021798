<template>
  <div v-if="show" class="col-12 col-xl-6">
    <location-user
      :items="form.owners"
      :disabled="disabled"
      :label="$t('form.labels.owner')"
      :country-code="countryCode"
      :key="`owners-${countryCode}`"
      :subscription="form.subscription"
      :location="form"
      user-role="owners"
      input-class="mb-0"
      @completed="$emit('completed')"
    />
    <location-user
      v-if="isUk || isUAE"
      :items="form.troncMasters"
      :disabled="troncMastersDisabled"
      :label="$t('form.labels.troncMaster', form.country)"
      :country-code="countryCode"
      :key="`troncs-${countryCode}`"
      :subscription="form.subscription"
      :location="form"
      user-role="troncMasters"
      input-class="my-0"
      @completed="$emit('completed')"
    />
    <location-user
      v-if="!isGermany && !isUAE"
      :items="form.accountants"
      :disabled="accountantsDisabled"
      :label="$t('form.labels.accountant')"
      :country-code="countryCode"
      :key="`accountants-${countryCode}`"
      :subscription="form.subscription"
      :location="form"
      user-role="accountants"
      input-class="my-0"
      @completed="$emit('completed')"
    />

    <div
      v-if="showSharedCardToggle"
      class="my-2"
    >
      <label
        class="font-14 text-grey"
        :class="{ 'disabled': disabled }"
      >
        Shared payout card
      </label>
      <ui-checkbox
        v-model="form.isAllowedSharedCard"
        class="mt-2"
        :disabled="disabled"
      >
        Allow tips to shared card
      </ui-checkbox>
    </div>

    <template v-if="!form.isMasterLocation">
      <validation-provider
        tag="div"
        name="Pay buttons"
        rules="required"
        mode="passive"
        class="my-2 mt-5"
        v-slot="{ errors }"
      >
        <ui-select
          v-model="form.payButtonValue"
          :items="payButtons"
          label="Pay buttons"
          :placeholder="$t('form.placeholders.select')"
          label-key="displayName"
          value-key="id"
          value-original
          filterable
          required
          :disabled="disabled"
          :error="errors[0]"
        />
      </validation-provider>

      <validation-provider
        tag="div"
        :name="$t('form.labels.maxTipsAmount', { currency: locationCurrency })"
        rules="required|decimal|min_value:1"
        mode="passive"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="form.maxTipsAmount"
          :label="$t('form.labels.maxTipsAmount', { currency: locationCurrency })"
          required
          :error="errors[0]"
        />
      </validation-provider>

      <validation-provider
        v-if="showPayoutAndTransferFees"
        tag="div"
        :name="payoutAndTransferFeesLabel"
        rules="required"
        mode="passive"
        class="my-2 mt-5"
        v-slot="{ errors }"
      >
        <ui-select
          v-model="form.payoutPlanSetup"
          :items="payoutPlanSetupList"
          :label-info="form.payoutPlanSetup ? form.payoutPlanSetup.toolTipText : $t(`payoutPlanSetup.tooltipExample.${countryCode}`)"
          :label="payoutAndTransferFeesLabel"
          :placeholder="$t('form.placeholders.select')"
          label-key="name"
          value-key="id"
          value-original
          filterable
          required
          :disabled="disabled || (Boolean(form.id) && !isSupportAdmin)"
          :error="errors[0]"
        />
      </validation-provider>

      <validation-provider
        tag="div"
        :name="$t('form.labels.platformComission')"
        rules="required|decimal|min_value:1|max_value:100"
        mode="passive"
        v-slot="{ errors }"
      >
        <ui-input
          v-model="form.feePlan"
          :label="$t('form.labels.platformComission')"
          required
          :error="errors[0]"
          :disabled="disabled"
        />
      </validation-provider>

      <validation-provider
        v-if="form.isShowRyftAccount"
        tag="div"
        :name="$t('form.labels.ryftAccount')"
        v-slot="{ errors }"
      >
        <ui-input
          :value="form.paymentSystemUserId || 'No Ryft Company Account Added'"
          :label="$t('form.labels.ryftAccount')"
          :error="errors[0]"
          disabled
          :copy-button="form.paymentSystemUserId"
        />
      </validation-provider>

      <template v-if="showPayroll">
        <validation-provider
          tag="div"
          name="Payroll frequency"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
        >
          <ui-select
            v-model="form.payrollFrequency"
            :items="payrollFrequencies"
            :placeholder="$t('form.placeholders.select')"
            label="Payroll frequency"
            label-key="label"
            value-key="value"
            filterable
            required
            :error="errors[0]"
          />
        </validation-provider>

        <validation-provider
          v-if="showPayrollDay"
          tag="div"
          name="Payroll day"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
        >
          <ui-select
            v-model="form.payrollDay"
            :items="payrollDays"
            :placeholder="$t('form.placeholders.select')"
            label="Payroll day"
            label-key="label"
            value-key="value"
            filterable
            required
            :error="errors[0]"
          />
        </validation-provider>

        <validation-provider
          v-if="showPayrollCustomDate"
          tag="div"
          name="Payroll date"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
        >
          <ui-calendar-picker
            v-model="form.payrollCustomDate"
            placeholder="Payroll date"
            label="Payroll date"
            required
            :error="errors[0]"
          />
        </validation-provider>
      </template>
    </template>

    <ui-select
      v-model="form.salesPerson"
      :items="salesPeopleOptions"
      :placeholder="$t('form.placeholders.select')"
      :disabled="disabled"
      label="Sales Manager"
      label-key="fio"
      value-key="id"
      filterable
      value-original
    />

    <ui-select
      v-model="form.customerSuccessManager"
      :items="salesPeopleOptions"
      :placeholder="$t('form.placeholders.select')"
      :disabled="disabled"
      label="Customer Success Manager"
      label-key="fio"
      value-key="id"
      filterable
      value-original
    />

    <ui-input
      v-if="showStripeAccount"
      v-model="form.stripeAccountId"
      label="Stripe Account"
      :disabled="true"
    />

    <ui-input
      v-if="showCheckoutAccount"
      v-model="checkoutAccount"
      label="Checkout Account"
      :disabled="true"
    />

    <validation-provider
      v-if="showDistributionSchemes"
      tag="div"
      name="Options for distribution schemes"
      rules="required"
      mode="passive"
      class="mb-2 mt-5"
      v-slot="{ errors }"
    >
      <ui-select
        v-model="form.distributionSchemes"
        :items="distributionSchemes"
        label="Options for distribution schemes"
        :placeholder="$t('form.placeholders.select')"
        optional-key="text"
        label-key="shortText"
        value-key="id"
        value-original
        small-dropdown
        filterable
        required
        :disabled="disabled"
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      tag="div"
      :name="$t('form.labels.posModel')"
      rules="required|max:128"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-input
        v-model="form.posModel"
        :label="$t('form.labels.posModel')"
        maxlength="128"
        required
        :error="errors[0]"
      />
    </validation-provider>

    <div
      v-if="status === 'NOT_ACTIVE' && turnOffDate && removeReason"
      class="col-12 pl-0 mb-4 col-md-auto mt-2 mt-md-0 d-flex
      font-16 text-grey flex-column align-items-start justify-content-around"
    >
      Pay turned off on {{ turnOffDate | date }}<br>
      {{ removeReason }}
    </div>

    <div
      v-if="status === 'ACTIVE' && onboardingDate"
      class="col-12 pl-0 mb-4 col-md-auto mt-2 mt-md-0 d-flex
      flex-column align-items-start justify-content-around"
    >
      <span class="font-16 text-grey">
        Onboarded on {{ onboardingDate | date }}
      </span>
      <ui-button
        link
        type="primary"
        :disabled="isOnboardingLoading"
        @click.prevent="$emit('activate')"
      >
        {{ 'Edit date' }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { capitalize } from 'lodash';
import getCurrency from '@/utils/getCurrency';
import maxTipsAmount from '@/config/maxTipsAmount';
import { CASE_TYPE } from '@/config/caseType';
import { PAYMENT_SYSTEM } from '@/config/paymentSystems';
import LocationUser from './LocationUser.vue';

export default {

  components: {
    LocationUser,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isOnboardingLoading: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    payrollFrequencies: [
      {
        value: 'NOT_AVAILABLE',
        label: 'N/A',
      },
      {
        value: 'FORTNIGHTLY',
        label: 'Fortnightly',
      },
      {
        value: 'MONTHLY',
        label: 'Monthly',
      },
    ],
    payrollDays: [
      {
        value: 'LAST_FRIDAY',
        label: 'Last friday of the month',
      },
      {
        value: 'LAST_BUSINESS_DAY',
        label: 'Last business day of the month',
      },
      {
        value: 'OTHER',
        label: 'Other',
      },
    ],
  }),

  computed: {
    ...mapState('settings', [
      'payButtons',
      'payoutPlanSetupList',
      'salesPeople',
      'distributionSchemes',
    ]),
    ...mapGetters({
      isSupportAdmin: 'auth/isSupportAdmin',
    }),
    locationCurrency() {
      return getCurrency(this.form.country).label;
    },
    salesPeopleOptions() {
      return [
        {
          id: null,
          fio: 'N/A',
        },
        ...this.salesPeople,
      ];
    },
    countryCode() {
      return this.form.country.toUpperCase();
    },
    isTipPayoutIndividual() {
      return this.form.tipPayoutType === 'INDIVIDUAL';
    },
    isTipPayoutEmployer() {
      return this.form.tipPayoutType === 'EMPLOYER';
    },
    isCommon() {
      return this.form.tipsType === 'COMMON'
        || this.form.tipsType === 'COMMON_PRIORITY';
    },
    troncMastersDisabled() {
      return !this.isCommon
        || this.isTipPayoutEmployer
        || this.disabled
        || this.form.caseType === CASE_TYPE.CASE_3;
    },
    accountantsDisabled() {
      return this.form.isMasterLocation || this.disabled;
    },
    isGermany() {
      return this.form.country === 'de';
    },
    isUAE() {
      return this.form.country === 'ae';
    },
    isUk() {
      return this.form.country === 'gb';
    },
    isSwitzerland() {
      return this.form.country === 'ch';
    },
    show() {
      return !((this.isGermany || this.isUAE) && this.disabled);
    },
    showSharedCardToggle() {
      return this.isUAE
        && !this.isTipPayoutEmployer;
    },
    isSelfReporting() {
      return this.form.taxReportingType === 'SELF_REPORTING';
    },
    isPayerMax() {
      return this.form.paymentSystem === PAYMENT_SYSTEM.PAYER_MAX;
    },
    showPayoutAndTransferFees() {
      if (this.isUAE) {
        return (this.isPersonal && !this.isTipPayoutEmployer)
          || (this.isCommon && this.isTipPayoutEmployer && this.isPayerMax);
      }

      if (this.isUk) {
        return this.isPersonal && !this.isTipPayoutEmployer && this.isSelfReporting;
      }

      return false;
    },
    showDistributionSchemes() {
      return this.isCommon
        && !this.isTipPayoutEmployer
        && this.form.caseType !== CASE_TYPE.CASE_3;
    },
    showPayroll() {
      return (this.isUk || this.isSwitzerland) && !this.form.caseType;
    },
    showPayrollDay() {
      return this.form.payrollFrequency
          && this.form.payrollFrequency !== 'NOT_AVAILABLE';
    },
    showPayrollCustomDate() {
      return this.form.payrollDay === 'OTHER';
    },
    isPersonal() {
      return this.form.tipsType === 'PERSONAL';
    },
    showStripeAccount() {
      return this.form.showStripeAccount
        && this.form.paymentSystem === PAYMENT_SYSTEM.STRIPE;
    },
    showCheckoutAccount() {
      return this.form.checkoutCard?.suffix
        && this.form.paymentSystem === PAYMENT_SYSTEM.CHECKOUT;
    },
    checkoutAccount() {
      const suffix = this.form.checkoutCard?.suffix;

      return suffix
        ? `.... ${suffix}`
        : '';
    },
    status() {
      return this.data?.status || '';
    },
    onboardingDate() {
      return this.data?.onboardingDate || '';
    },
    turnOffDate() {
      return this.data?.turnOffDate || '';
    },
    removeReason() {
      return this.data?.removeReason
        ? capitalize(this.data?.removeReason.split('_').join(' '))
        : '';
    },
    payoutAndTransferFeesLabel() {
      return `Payout and Transfer fees, ${this.locationCurrency}`;
    },
  },

  methods: {
    setDefaultMaxTipsAmount() {
      this.form.maxTipsAmount = maxTipsAmount?.[this.form.country]?.[this.form.type] || '';
    },
  },

  watch: {
    'form.payrollFrequency': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.payrollDay = null;
        }
      },
    },
    'form.payrollDay': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.payrollCustomDate = null;
        }
      },
    },
    'form.tipsType': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.payoutPlanSetup = null;
        }
      },
    },
    'form.country': {
      handler() {
        this.setDefaultMaxTipsAmount();
      },
    },
    'form.type': {
      handler() {
        this.setDefaultMaxTipsAmount();
      },
    },
    'form.caseType': {
      handler(caseType) {
        if (caseType === CASE_TYPE.CASE_3) {
          this.form.distributionSchemes = null;
        }

        if (caseType) {
            this.form.payrollFrequency = null;
            this.form.payrollDay = null;
            this.form.payrollCustomDate = null;
        }
      },
    },
  },
};
</script>
