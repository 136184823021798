<template>
  <ui-loading v-if="defaultLocationRequired" inline />
  <div
    v-else
    class="checkout-payouts"
    :key="`${accessToken}-${payoutId}-${updateToken}`"
  >
    <!-- Блок с вводом карты -->
    <info-card
      v-if="showCard"
      :title="$t('payoutCard.title')"
    >
      <template v-if="isOnlyStaffProfileSharedCard" #subDescription>
        <p class="checkout-payouts__title">{{ $t('payoutCard.shared.title') }}</p>
        <div class="checkout-payouts__description">
          <span
            class="checkout-payouts__content checkout-payouts__description_muted"
            v-html="$t('payoutCard.shared.description')"
          />
          <ui-toggle
            v-protected
            :elements="sharedCardStaffValues"
            :selected-value="isEmployerCardActive ? 'employer-card' : 'my-card'"
            @select="handleSharedCardToggler"
          />
        </div>
        <hr v-if="!isEmployerCardActive" class="m-0 p-0 my-4">
      </template>
      <template v-if="!isEmployerCardActive" #description>
        <p
          v-if="isOnlyStaffProfileSharedCard"
          class="checkout-payouts__title"
        >
          Where should we send your tips to?
        </p>
        <span class="checkout-payouts__description_muted">
          <span v-html="$t('payoutCard.description')"/>
          <router-link class="checkout-payouts__link" :to="{ name: 'support' }">
            {{ $t('payoutCard.link') }}
          </router-link>.
        </span>
      </template>
      <checkout-card-info
        v-if="!isEmployerCardActive || isOwner"
        :user-id="profile.id"
        @card-loaded="onCardLoaded"
        @card-status="onCardStatus"
      />
    </info-card>

    <!-- Блок с выбором тарифа -->
    <template v-if="showTariff">
      <info-card
        v-if="showPlans && payoutPricePlanType === 'BY_AMOUNT'"
        :title="$t('payoutPlan.title')"
      >
        <radio-card-group
          v-protected
          :elements="computedPayoutPlans"
          :selected-id="currentPlanId"
          class="checkout-payouts__plans"
          @select-element="setNewPlan"
        />
        <template #description class="checkout-payouts__description">
          <span
            class="checkout-payouts__description_muted"
            v-html="$t('payoutPlan.subtitle')"
          />
        </template>
      </info-card>

      <!-- Блок с выбором тарифа периодов -->
      <info-card
        v-if="showPlans && payoutPricePlanType === 'BY_PERIOD'"
        :title="$t('payoutPlan.title')"
      >
        <period-plan-group class="checkout-payouts__period-plans">
          <period-plan
            v-for="period in computedPayoutPlans"
            v-protected
            :key="period.id"
            :period="period"
            :selected="currentPlanId === period.id"
            :disabled="period.disabled"
            @select="setNewPlan(period)"
          />
        </period-plan-group>
        <template #description class="checkout-payouts__description">
            <span
              class="checkout-payouts__description_muted"
              v-html="$t('payoutPlan.subtitleFull')"
            />
        </template>
      </info-card>

      <!-- Блок с балансом -->
      <info-card
        v-if="showAccountBalance && payoutPricePlanType === 'BY_PERIOD'"
        :title="$t('payoutBalance.title')"
      >
        <template
          #description
          class="checkout-payouts__description"
        >
          <span
            v-html="instantSubtitle"
            class="checkout-payouts__description_muted period-balance__description"
          />
        </template>
        <div class="period-balance__current-balance-wrapper">
          <div
            class="period-balance__current-balance"
            :class="{ 'period-balance__current-balance_zero': disableInstantPayout }"
          >
            <div class="period-balance__current-balance-integer">
              {{ progressAmountCeil(progressAmount) }}
            </div>
            <div class="period-balance__current-balance-fraction">
              .{{ progressAmountFraction(progressAmount) }}
            </div>
          </div>
          <div class="period-balance__description_desktop-wrapper">
            <div class="period-balance__description_desktop">
              <ui-icon
                v-if="instantButtonState === 'PENDING_PREVIOUS_PAYOUT'"
                name="card-status-warning"
                size="20"
                class="period-balance__description_desktop-icon"
              />
              <div v-html="instantSubtitle" class="period-balance__description-text" />
            </div>
          </div>
          <div class="period-balance__instant-payout-wrapper">
            <span
              v-if="progressFeeAmount"
              class="period-balance__fee"
              :class="{ 'period-balance__fee_zero': disableInstantPayout }"
            >
              {{ $t('payoutBalance.fee') }}:
              <span class="period-balance__action-description_marked">
                {{ progressFeeAmount | money(progressCurrency) }}
              </span>
            </span>
            <ui-button
              v-protected
              class="period-balance__btn"
              :class="{ 'period-balance__btn_zero': disableInstantPayout }"
              type="primary"
              size="sm"
              :disabled="disableInstantPayout || !currentCard || !isCardActive"
              @click="openInstantPayoutModal"
            >
              {{ $t('form.buttons.instantPayout') }}
            </ui-button>
          </div>
        </div>
      </info-card>

      <!-- Блок с прогресс-баром -->
      <info-card
        v-if="showAccountBalance && payoutPricePlanType === 'BY_AMOUNT'"
        :title="$t('payoutBalance.title')"
        class="account-balance"
      >
        <template #description>
          <div class="account-balance__info">
            <div
              class="account-balance__current-balance"
              :class="{ 'account-balance__current-balance_zero': disableInstantPayout }"
            >
              {{ progressAmount | money(progressCurrency) }}
            </div>
            <div v-if="progressPhrase.length" class="account-balance__description">
              <span
                v-for="(phraseRow, index) in progressPhrase"
                :key="phraseRow"
              >
                <br v-if="index" class="mobile-hide" />
                {{ phraseRow }}
              </span>
            </div>
          </div>
        </template>
        <checkout-progress-bar
          :value="progressAmount"
          :steps="progressSteps"
        />
        <template #footer>
          <div class="account-balance__footer">
            <div class="account-balance__footer-title_mobile">
              {{ footerTitle }}
            </div>
            <ui-button
              v-protected
              type="primary"
              :disabled="disableInstantPayout || !currentCard || !isCardActive"
              @click="openInstantPayoutModal"
            >
              {{ $t('form.buttons.instantPayout') }}
            </ui-button>
            <div class="account-balance__action-description">
              <span class="account-balance__footer-title">
                {{ footerTitle }}
              </span>
              <span v-if="progressFeeAmount">
                {{ $t('payoutBalance.fee') }}:
                <span class="account-balance__action-description_marked">
                  {{ progressFeeAmount | money(progressCurrency) }}
                </span>
              </span>
            </div>
          </div>
        </template>
      </info-card>
    </template>

    <!-- Карточка с балансом -->
    <template v-if="showBalance">
      <div class="row">
        <div v-if="showSharedCardBalance" class="col-12 col-xl-4">
          <stats-account-balance
            :is-loading="false"
            :currency="sharedCardCurrency"
            :amount="sharedCardBalance"
          />
        </div>

        <!-- Карточка с планом -->
        <div v-if="showSharedCardPlan" class="col-12 col-xl-4">
          <stats-payout-plan
            :is-loading="false"
            :payout-plan="sharedCardPlan"
            :fee="sharedCardPlanFee"
            :currency="sharedCardCurrency"
          />
        </div>

        <!-- Карточка с датой следующей выплаты -->
        <div v-if="showSharedCardNextPayout" class="col-12 col-xl-4">
          <stats-next-payout
            :is-loading="false"
            :payout-date="sharedCardNextPayoutDate"
          />
        </div>
      </div>
    </template>

    <payouts-history
      :location="location"
      :currency="locationCurrency"
    />

    <checkout-payout-modal
      v-model="confirmPayoutModal"
      :fee="draftFee"
      :balance="draftAmount"
      :currency="progressCurrency"
      :confirm-payout-loading="confirmPayoutLoading"
      @submit="makeInstantPayoutHandler"
    />

    <ui-modal-status
      v-model="modalStatus.show"
      :key="modalStatus.key"
      :type="modalStatus.type"
      :icon="modalStatus.icon"
      :icon-size="modalStatus.iconSize"
      :title="modalStatus.title"
      :message="modalStatus.message"
      :no-button="modalStatus.noButton"
    />

    <shared-card-terms-modal
      v-model="sharedCardTerms"
      user-role="staff"
      @apply="handleAllowSharedCard"
      @cancel="handleCancelSharedCard"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { uniqueId } from 'lodash';
import api from '@/api';
import money from '@/filters/money';
import resetMixin from '@/mixins/reset-mixin';
import InfoCard from '@/components/UI/Cards/InfoCard.vue';
import RadioCardGroup from '@/components/UI/RadioCards/RadioCardGroup.vue';
import CheckoutProgressBar from '@/components/Checkout/CheckoutProgressBar.vue';
import CheckoutCardInfo from '@/components/Checkout/CheckoutCardInfo.vue';
import PayoutsHistory from '@/components/Payouts/PayoutsHistory.vue';
import CheckoutPayoutModal from '@/components/Checkout/CheckoutPayoutModal.vue';
import PeriodPlanGroup from '@/components/Checkout/PeriodPlan/PeriodPlanGroup.vue';
import PeriodPlan from '@/components/Checkout/PeriodPlan/PeriodPlan.vue';
import StatsAccountBalance from '@/components/Stats/StatsAccountBalance.vue';
import StatsPayoutPlan from '@/components/Stats/StatsPayoutPlan.vue';
import StatsNextPayout from '@/components/Stats/StatsNextPayout.vue';
import SharedCardTermsModal from '@/components/SharedCardTermsModal.vue';

export default {
  name: 'Payouts',

  components: {
    PeriodPlan,
    PeriodPlanGroup,
    CheckoutPayoutModal,
    CheckoutCardInfo,
    CheckoutProgressBar,
    RadioCardGroup,
    InfoCard,
    PayoutsHistory,
    StatsAccountBalance,
    StatsPayoutPlan,
    StatsNextPayout,
    SharedCardTermsModal,
  },

  mixins: [
    resetMixin(() => ({
      modalStatus: {
        key: '',
        show: false,
        type: 'success',
        icon: '',
        iconSize: '',
        title: '',
        message: '',
        noButton: false,
      },
    })),
  ],

  data() {
    return {
      confirmPayoutModal: false,
      successInstantModal: false,
      updateToken: 1,
      confirmPayoutLoading: false,
      isCardLoaded: false,
      isCardActive: false,
      isEmployerCardActive: false,
      sharedCardTerms: false,
    };
  },

  computed: {
    ...mapState({
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
      profile: (state) => state.user.profile,
      location: (state) => state.user.location,
      checkoutPublicKey: (state) => state.payouts.checkoutPublicKey,
      currentPlan: (state) => state.payouts.currentPlan,
      payoutPlans: (state) => state.payouts.payoutPlans,
      sharedCardStaffValues: (state) => state.payouts.sharedCardStaffValues,
      currentProgress: (state) => state.payouts.currentProgress,
      payoutInstantDraft: (state) => state.payouts.payoutInstantDraft,
      currentCard: (state) => state.payouts.currentCard,
      payoutPricePlanType: (state) => state.user.profile.payoutPricePlanType,
      locale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      computedPayoutPlans: 'payouts/computedPayoutPlans',
      currentPlanId: 'payouts/currentPlanId',
      isOwner: 'auth/isOwner',
      isStaff: 'auth/isStaff',
      isStaffMaster: 'auth/isStaffMaster',
      isLocationEmployerReporting: 'user/isEmployerReportingTypeLocation',
      isLocationMixReporting: 'user/isMixReportingTypeLocation',
      isProfileSelfReporting: 'user/isSelfReportingTypeProfile',
      defaultLocationRequired: 'user/defaultLocationRequired',
      isCheckout: 'user/isCheckout',
      isProfileCheckout: 'user/isProfileCheckout',
      isProfilePayerMax: 'user/isProfilePayerMax',
      isPersonalCardDisabled: 'payouts/isPersonalCardDisabled',
      isCompanyCardAvailable: 'payouts/isCompanyCardAvailable',
      instantButtonState: 'payouts/instantButtonState',
      isCommon: 'user/isCommon',
      availableCardTypeList: 'payouts/availableCardTypeList',
      isSharedCard: 'user/isSharedCard',
    }),
    showTariff() {
      return !this.isEmployerCardActive && !this.isCommon;
    },
    showBalance() {
      return (this.isEmployerCardActive || this.isOwnerStaffLocationSharedCard) && !this.isCommon;
    },
    isPersonalCardTypeAvailable() {
      return !this.isPersonalCardDisabled;
    },
    cardDoesNotExist() {
      return this.currentCard === null;
    },
    isLocationAllowedSharedCard() {
      return this.location.isAllowedSharedCard;
    },
    isOnlyStaffProfileSharedCard() {
      return this.isStaff && !this.isOwner && this.profile.isAllowedSharedCard;
    },
    isOnlyOwnerLocationSharedCard() {
      return !this.isStaff && this.isOwner && this.isLocationAllowedSharedCard;
    },
    isOwnerStaffLocationSharedCard() {
      return this.isStaff && this.isOwner && this.isLocationAllowedSharedCard;
    },
    footerTitle() {
      return this.currentCard
        ? this.$t('payoutPlan.footerTitleTips')
        : this.$t('payoutPlan.footerTitleCard');
    },
    draftAmount() {
      return this.payoutInstantDraft?.totalBalance || 0;
    },
    draftFee() {
      return this.payoutInstantDraft?.fee || 0;
    },
    progressAmount() {
      return this.currentProgress?.amount || 0;
    },
    progressCurrency() {
      return this.currentProgress?.currentPlan?.currency
        || this.payoutPlans[0]?.currency
        || this.locationCurrency;
    },
    showCard() {
      const hasAvailableCardTypes = this.availableCardTypeList.length > 0;
      const isCheckoutAvailable = this.checkoutPublicKey && this.isCheckout;
      const staffCheckoutAvailable = this.isStaff && this.isProfileSelfReporting;
      const troncCheckoutAvailable = this.isStaffMaster && hasAvailableCardTypes;
      const ownerCheckoutAvailable = this.isOwner && (
        this.isLocationEmployerReporting
        || this.isLocationMixReporting
        || this.isLocationAllowedSharedCard
        || this.location.workplaceFee
      ) && hasAvailableCardTypes;
      return isCheckoutAvailable && (staffCheckoutAvailable || ownerCheckoutAvailable || troncCheckoutAvailable);
    },
    showPlans() {
      return this.computedPayoutPlans?.length && (this.isProfileCheckout || this.isProfilePayerMax)
        && ((this.isStaff && this.isProfileSelfReporting)
          || this.isOwnerStaffLocationSharedCard
          || this.isOnlyOwnerLocationSharedCard);
    },
    showAccountBalance() {
      return this.currentProgress && (this.isProfileCheckout || this.isProfilePayerMax)
        && this.isStaff && this.isProfileSelfReporting
        && !this.isOwnerStaffLocationSharedCard;
    },
    showSharedCardPlan() {
      return this.currentProgress?.currentPlan
        && (this.isProfileCheckout || this.isProfilePayerMax)
        && ((this.isStaff && this.isProfileSelfReporting)
          || this.isOwnerStaffLocationSharedCard);
    },
    showSharedCardBalance() {
      return this.showAccountBalance
        || this.isOwnerStaffLocationSharedCard;
    },
    showSharedCardNextPayout() {
      return this.sharedCardNextPayoutDate
        || this.isOwnerStaffLocationSharedCard;
    },
    locationCurrency() {
      return this.location.currencyCode;
    },
    progressSteps() {
      return this.currentProgress ? this.payoutPlans.map((plan) => ({
        value: plan.targetAmount,
        label: money(plan.targetAmount, plan.currency, { fractionDigits: 0 }),
      })) : [];
    },
    instantSubtitle() {
      return this.$t(`payoutBalance.subtitle.${this.instantButtonState}`);
    },
    disableInstantPayout() {
      return this.instantButtonState !== 'AVAILABLE';
    },
    progressFeeAmount() {
      return this.currentProgress?.instantFee;
    },
    progressPhrase() {
      if (!this.currentProgress?.goalPercent) {
        return [];
      }

      if (this.currentProgress?.goalPercent < 50) {
        return [
          this.$t('payouts.progress[0]'),
          this.$t('payouts.progress[1]'),
        ];
      }

      if (this.currentProgress?.goalPercent < 100) {
        return [
          this.$t('payouts.progress[2]'),
          this.$t('payouts.progress[3]'),
        ];
      }

      return [
        this.$t('payouts.progress[4]'),
        this.$t('payouts.progress[5]'),
      ];
    },
    sharedCardBalance() {
      return this.progressAmount;
    },
    sharedCardCurrency() {
      return this.progressCurrency;
    },
    sharedCardPlan() {
      return this.currentProgress?.currentPlan?.name;
    },
    sharedCardPlanFee() {
      return this.currentProgress?.currentPlan?.feeAmount;
    },
    sharedCardNextPayoutDate() {
      return this.currentProgress?.nextPayoutDate;
    },
  },

  watch: {
    locale() {
      this.getPayoutPlans();
    },
  },

  mounted() {
    if (this.$route.query.s === 'email') {
      this.$amplitude.event('FROM_EMAIL_CHECKOUT_CLICKED');
    }

    if (this.isOnlyStaffProfileSharedCard) {
      this.isEmployerCardActive = this.isSharedCard;
    }
  },

  methods: {
    ...mapActions('payouts', [
      'initPayouts',
      'selectNewPlan',
      'createDraftInstantPayout',
      'makeInstantPayout',
      'getPayoutPlans',
    ]),
    ...mapActions('user', [
      'loadUser',
    ]),
    handleSharedCardToggler(type) {
      if (type === 'employer-card') {
        this.sharedCardTerms = true;
      } else {
        this.isEmployerCardActive = false;
        this.toggleSharedCard('removeSharedCard');
      }
    },
    handleAllowSharedCard() {
      this.isEmployerCardActive = true;
      this.toggleSharedCard('setSharedCard');
      this.sharedCardTerms = false;
    },
    handleCancelSharedCard() {
      this.sharedCardTerms = false;
    },
    showStatusModal(modalData) {
      this.reset();

      this.modalStatus = {
        key: uniqueId('modal-status_'),
        show: true,
        ...modalData,
      };
    },
    onCardStatus(status) {
      this.isCardActive = status === 'ACTIVE';
    },
    onCardLoaded() {
      if (!this.isCardLoaded) {
        this.isCardLoaded = true;

        this.validateCardRequired();
      }
    },
    validateCardRequired() {
      if (this.cardDoesNotExist && (this.isPersonalCardTypeAvailable || this.isCompanyCardAvailable)) {
        this.showStatusModal({
          icon: 'cards-required',
          iconSize: '100',
          title: this.$t('payouts.modal.cardRequired.title'),
          message: this.$t('payouts.modal.cardRequired.description'),
          noButton: false,
        });

        return false;
      }

      return true;
    },
    progressAmountCeil(amount) {
      return money(amount, this.progressCurrency, {
        fractionDigits: 0,
        style: this.progressCurrency === 'aed' ? 'decimal' : 'currency',
      });
    },
    progressAmountFraction(amount) {
      return money(amount, this.progressCurrency, {
        fractionDigits: 2,
        style: this.progressCurrency === 'aed' ? 'currency' : 'decimal',
      }).replace(/^-*[\d\s]+\./g, '');
    },
    async toggleSharedCard(method) {
      try {
        await api.checkout[method](this.profile.id);

        this.initPayouts();
        this.loadUser();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async setNewPlan(plan) {
      if (!this.validateCardRequired() || plan.disabled) {
        return;
      }

      const currentPlanPeriod = this.currentPlan?.period;

      try {
        await this.selectNewPlan(plan.id);

        this.$amplitude.event('STAFF_TARIFF_PLAN_CHANGED', {
          from: currentPlanPeriod,
          to: plan.period,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async openInstantPayoutModal() {
      if (!this.progressAmount && this.payoutPricePlanType === 'BY_AMOUNT') {
        return;
      }

      this.confirmPayoutModal = true;

      try {
        await this.createDraftInstantPayout();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async makeInstantPayoutHandler() {
      this.confirmPayoutLoading = true;
      try {
        await this.makeInstantPayout();

        this.confirmPayoutModal = false;
        this.showStatusModal({
          message: this.$t('payouts.modal.success.info'),
          noButton: true,
        });
        this.updateToken += 1;
        this.initPayouts();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.confirmPayoutLoading = false;
      }
    },
  },
};
</script>
