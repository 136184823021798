<template>
  <validation-observer tag="form" ref="form" v-slot="{ handleSubmit, invalid }">
    <ui-card :is-loading="loading">
      <template #header>
        <h4 class="card-title mb-xl-0 mb-lg-0 mb-3">
          {{ $t('promotions.message.title') }}
        </h4>
      </template>

      <div class="row">
        <validation-provider
          tag="div"
          class="col-12 col-lg-3 col-lg-4 col-md-6"
          :name="$t('form.labels.title')"
          rules="required"
          v-slot="{ errors }"
        >
          <ui-input
            v-model="form.title"
            :label="$t('form.labels.title')"
            :placeholder="$t('form.placeholders.enter')"
            class="mb-3"
            :error="errors[0]"
            :disabled="!staffList.length"
            required
          />
        </validation-provider>
      </div>

      <div class="row flex-column">
        <span
          class="col-12 text-grey font-14 mb-3"
          :class="{ 'disabled': !staffList.length }"
        >
          To
          <span
            class="text-danger"
            :class="{ 'disabled': !staffList.length }"
          >
            *
          </span>
        </span>
        <template v-if="staffList.length">
          <div class="col-12">
            <ui-checkbox
              class="mb-3"
              v-model="checkedAll"
            >
              All
            </ui-checkbox>
          </div>

          <div class="col-12">
            <div class="d-flex flex-wrap">
              <ui-checkbox
                v-for="staff in staffList"
                :key="staff.id"
                class="col-12 col-xl-3 col-lg-3 col-md-3 mb-3"
                v-model="staff.checked"
              >
                {{ staffName(staff) }}
              </ui-checkbox>
            </div>
          </div>
        </template>
      </div>

      <div class="row">
        <validation-provider
          tag="div"
          class="col-12 col-lg-3 col-lg-4 col-md-6 d-flex"
          :name="$t('form.labels.message')"
          rules="required|max:190"
          v-slot="{ errors }"
        >
          <ui-input
            tag="textarea"
            v-model="form.text"
            :label="$t('form.labels.message')"
            :placeholder="$t('form.placeholders.enter')"
            class="w-100 mt-4"
            :error="errors[0]"
            :disabled="!staffList.length"
            required
          />
          <span
            class="d-flex align-items-end text-grey font-12 mb-4 ml-2"
            :class="{ 'disabled': !staffList.length }"
          >
            {{ form.text.length }}/190
          </span>
        </validation-provider>
      </div>

      <div class="row mb-4">
        <validation-provider
          tag="div"
          class="col-12 col-lg-3 col-lg-4 col-md-6"
          :name="$t('form.labels.notificationType')"
          rules="required"
          v-slot="{ errors }"
        >
          <ui-select
            v-model="form.notificationChannel"
            :items="notificationTypes"
            :label="$t('form.labels.notificationType')"
            :placeholder="$t('form.placeholders.enter')"
            class="mb-3"
            label-key="label"
            value-key="value"
            :error="errors[0]"
            :disabled="!staffList.length"
            required
          />
        </validation-provider>
      </div>

      <div class="row mx-2 mx-xl-0 mx-lg-0 mx-md-0">
        <ui-button
          action
          type="primary"
          :is-loading="loading"
          :disabled="loading || invalid || !checkedCount"
          @click.prevent="handleSubmit(send)"
        >
          {{ $t('form.buttons.send') }}
        </ui-button>
      </div>

    </ui-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/api';
import fio from '@/utils/fio';
import resetMixin from '@/mixins/reset-mixin';

export default {

  props: {
    promotionId: [String, Number],
    locationId: [String, Number],
  },

  mixins: [
    resetMixin(() => ({
      form: {
        title: '',
        text: '',
        notificationChannel: '',
      },
      checkedAll: false,
    })),
  ],

  data() {
    return {
      staffList: [],
      loading: false,
    };
  },

  computed: {
    ...mapState('staffs', {
      staff: 'items',
    }),
    notificationTypes() {
      return [
        {
          label: 'PUSH',
          value: 'PUSH',
        },
        {
          label: 'SMS',
          value: 'SMS',
        },
      ];
    },
    checkedCount() {
      return this.staffList.filter(({ checked }) => checked).length;
    },
  },

  watch: {
    checkedAll: {
      handler(value) {
        if (value) {
          this.toggleCheckboxes(true);
        }

        if (!value && this.checkedCount < this.staffList.length) {
          this.checkedAll = false;
        }

        if (!value && this.checkedCount === this.staffList.length) {
          this.toggleCheckboxes(false);
        }
      },
    },
    checkedCount: {
      handler(value) {
        if (this.staffList.length === value) {
          this.checkedAll = true;
        } else {
          this.checkedAll = false;
        }
      },
    },
  },

  mounted() {
    this.loadStaff();
  },

  methods: {
    ...mapActions({
      loadStaffItems: 'staffs/loadItems',
    }),
    toggleCheckboxes(checkValue) {
      this.staffList = this.staffList.map(({ checked, ...item }) => ({
        ...item,
        checked: checkValue,
      }));
    },
    staffName(staff) {
      return staff.firstName && staff.lastName
        ? fio(staff)
        : staff.login;
    },
    async loadStaff() {
      this.loading = true;

      try {
        await this.loadStaffItems({
          locationId: this.locationId,
        });

        this.staffList = this.staff.filter(({ status }) => status === 'ACTIVE').map((item) => ({
          ...item,
          checked: false,
        }));
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    async send() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        const staff = this.staffList.filter(({ checked }) => checked).map(({ id }) => id);

        const data = {
          promotionId: this.promotionId,
          staffIds: staff,
          ...this.form,
        };

        await api.promotions.sendNotification(data);

        this.toggleCheckboxes(false);
        this.reset();
        this.$refs.form.reset();
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
