<template>
  <div
    class="period-plan"
    :class="{
      'period-plan_selected': selected,
      'period-plan_disabled': disabled,
    }"
    @click="$emit('select')"
  >
    <div class="period-plan__body">
      <div class="period-plan__status">
        <div class="period-plan__status-value" />
      </div>
      <div class="period-plan__top">
        <div class="period-plan__title">
          {{ period.header }}
        </div>
        <div class="period-plan__description">
          {{ period.description }}
        </div>
      </div>
      <div class="period-plan__content">
        <div
          v-if="period.activePromo"
          class="period-plan__promo"
        >
          <ui-icon
            class="period-plan__promo_icon"
            name="promo"
            width="52px"
            height="53px"
          />
          <span class="period-plan__promo_label">
            <span>free</span>
            <span>{{ period.promoDaysCnt }} days</span>
          </span>
        </div>
        <div
          class="period-plan__value"
          :class="{
            'period-plan__value_promo': period.activePromo,
          }"
        >
          <div>
            {{ period.info.value }}
          </div>
          <div>
            {{ period.info.description }}
          </div>
        </div>
      </div>
      <div class="period-plan__bottom">
        <div
          class="period-plan__example"
          :class="{
            'period-plan__example_promo': period.activePromo,
          }"
        >
          <div
            v-for="exampleRow in period.example"
            :key="exampleRow"
          >
            {{ exampleRow }}
          </div>
        </div>
      </div>
    </div>
    <div class="period-plan__footer">
      {{ period.periodPayedDescription }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PeriodPlan',

  props: {
    period: {
      type: Object,
      required: true,
    },
    selected: Boolean,
    disabled: Boolean,
  },
};
</script>
