<template>
  <user-onfido
    :sdk-token="sdkToken"
    @complete="handleComplete"
  />
</template>

<script>
import UserOnfido from '@/components/User/UserOnfido.vue';

const REDIRECT_URL = process.env.VUE_APP_ONFIDO_REDIRECT_URL;

export default {
  name: 'OnboardOnfido',

  components: {
    UserOnfido,
  },

  computed: {
    sdkToken() {
      return this.$route.query.token;
    },
    iframe() {
      return this.$route.query.iframe;
    },
  },

  methods: {
    handleComplete() {
      if (window.top !== window.self) {
        window.top.postMessage('complete', '*');
      } else {
        window.location.href = REDIRECT_URL;
      }
    },
  },
};
</script>
