<template>
  <div class="online-menu">
    <online-menu-card />
    <online-menu-background />
    <online-menu-contact-info />
  </div>
</template>

<script>
import OnlineMenuCard from '@/components/OnlineMenu/OnlineMenuCard.vue';
import OnlineMenuBackground from '@/components/OnlineMenu/OnlineMenuBackground.vue';
import OnlineMenuContactInfo from '@/components/OnlineMenu/OnlineMenuContactInfo.vue';

export default {
  name: 'OnlineMenu',

  components: {
    OnlineMenuCard,
    OnlineMenuBackground,
    OnlineMenuContactInfo,
  },
};
</script>
