<template>
  <ui-card
    class="exports-modal"
    tag="form"
    :is-loading="isLoading"
  >
    <template #header>
      <div class="exports-modal__header">
        <div class="exports-modal__header-wrapper">
          <h4 class="exports-modal__header-title card-title mr-3">
            {{ $t('transactions.title') }}
          </h4>
          <ui-checkbox
            v-if="items.length"
            v-model="showRefunded"
            class="exports-modal__header-refunded mb-0"
            inline
          >
            Show refunded
          </ui-checkbox>
        </div>
        <ui-icon-button
          v-if="items.length"
          class="exports-modal__header-export mx-2"
          size="22px"
          name="excel-sm"
          @click="exportXls"
        />
      </div>
    </template>

    <ui-data-table
      data-table="export"
      class="exports-modal__table"
      :columns="columns"
      :data="filteredItems"
      :column-class="getColumnClass"
    >
      <template #before>
        <div class="row mb-4">
          <div class="col-12 col-md-auto mt-1 px-2">
            <ui-date-picker
              v-model="datePicker"
              type="daterange"
              format="dd.MM.yy"
              range-separator="—"
              placeholder="Pick a date range"
              start-placeholder="Start date"
              end-placeholder="End date"
              :max="new Date()"
              @change="updateFilter({ page: 1 })"
            />
          </div>
          <div class="col-12 col-xl-2 col-lg-4 col-md-6 mt-1 px-2">
            <ui-input
              v-model="filter.text"
              :placeholder="$t('form.placeholders.search')"
              class="my-0"
              bordered
              search
              @keydown.enter.prevent="updateFilter({ page: 1 })"
            />
          </div>
          <div class="d-flex align-items-center col-md-auto mt-1 px-2">
            <span class="font-16 px-0 pl-2 col-4 col-xl-4 col-lg-4 col-md-4 col-sm-3">Search in</span>
            <ui-select
              v-model="filter.paymentSystem"
              :items="paymentTypesList"
              placeholder="Payment gateway"
              class="px-0 col-8 col-xl-8 col-lg-8 col-md-8 col-sm-9 my-0"
              label-key="label"
              value-key="id"
              @change="updateFilter({ page: 1 })"
              bordered
              large
            />
          </div>
          <div class="col-12 col-md-auto mt-1 px-2">
            <ui-select
              v-model="filter.sales"
              :items="salesPeopleList"
              placeholder="Manager"
              class="my-0"
              label-key="fio"
              value-key="id"
              @change="updateFilter({ page: 1 })"
              bordered
              large
            />
          </div>
          <div class="col-12 col-xl-2 col-md-auto mt-1 px-2">
            <ui-select
              v-model="filter.country"
              :items="countriesList"
              placeholder="Location country"
              class="my-0"
              label-key="name"
              value-key="iso"
              @change="updateFilter({ page: 1 })"
              bordered
              large
            />
          </div>
        </div>
      </template>

      <template #export-id="{ value }">
        <div
          v-if="value"
          class="d-flex justify-content-between align-items-center"
        >
          <span class="d-inline-block text-truncate mr-1">
            {{ value }}
          </span>
          <ui-text-copy
            :text="value"
          />
        </div>
        <span
          v-else
          class="text-gray"
        >
          -
        </span>
      </template>

      <template #export-transaction-id="{ value }">
        <div
          v-if="value && value.length > 13"
          class="d-flex justify-content-between align-items-center"
        >
          <span class="d-inline-block text-truncate mr-1">
            {{ value }}
          </span>
          <ui-text-copy
            :text="value"
          />
        </div>
        <div v-else-if="value">
          {{ value }}
        </div>
        <span
          v-else
          class="text-gray"
        >
          -
        </span>
      </template>

      <template #export-date="{ row }">
        <div class="d-flex flex-column align-items-start">
          <span>
            {{ row.transactionDate | date }}
          </span>
          <span>
            {{ row.transactionDate | time }}
          </span>
        </div>
      </template>

      <template #export-name="{ value }">
        <div
          v-if="value.length"
          class="d-flex flex-column align-items-start"
        >
          <span class="d-inline-block text-truncate">
            {{ value[0] }}
          </span>
          <span class="d-inline-block text-truncate">
            {{ value[1] }}
          </span>
        </div>
        <span
          v-else
          class="text-gray"
        >
          {{ $t('table.empty.name') }}
        </span>
      </template>

      <template #export-method="{ value }">
        <div class="d-flex align-items-center">
          <ui-icon
            class="mr-2"
            :name="value.icon"
            :width="value.width"
            :height="value.height"
          />
          <span>...{{ value.suffix }}</span>
        </div>
      </template>

      <template #export-tips="{ row }">
        {{ row.amount | money(row.currency) }}
      </template>

      <template #export-location-name="{ row }">
        <router-link
          class="text-primary word-break-word"
          :to="{
            name: 'location-view',
            params: {
              locationId: row.workplace.id,
            },
          }"
        >
          {{ row.workplace.name }}
        </router-link>
      </template>

      <template #export-action="{ row }">
        <div
          v-if="isTransactionLoading(row)"
          class="position-relative px-3 ml-n2"
        >
          <ui-loading />
        </div>
        <ui-action-down
          v-else
          :items="getTransactionActions(row)"
          @select="handleAction($event, row)"
        />
      </template>
    </ui-data-table>

    <ui-pagination
      v-if="total > pageSize"
      v-model="filter.page"
      :total="total"
      :per-page="pageSize"
      :show-pages="7"
      class="mt-4"
      @input="updateFilter"
    />

    <validation-observer tag="form" #default="{ invalid, handleSubmit }">
      <ui-modal-alert
        v-model="checkoutRefundForm.show"
        class="exports-modal__refund-modal checkout-refund-modal"
        title="Refund"
        :body-offset="false"
        :apply-disabled="invalid"
        :apply-button="$t('form.buttons.yes')"
        :close-button="$t('form.buttons.cancel')"
        @apply="handleSubmit(() => checkoutRefundForm.callback(true))"
        @cancel="checkoutRefundForm.callback(false)"
      >
        <span class="checkout-refund-modal__amount">
          {{ checkoutRefundForm.amount | money(checkoutRefundForm.currency) }}
        </span>
        <p class="checkout-refund-modal__description">
          Are you sure you want <br> to refund this transaction?
        </p>
      </ui-modal-alert>
    </validation-observer>

    <validation-observer tag="form" #default="{ invalid, handleSubmit }">
      <ui-modal-alert
        v-model="stripeRefundForm.show"
        class="exports-modal__refund-modal stripe-refund-modal"
        title="Refund"
        :apply-disabled="invalid"
        apply-button="Go to Stripe"
        :close-button="$t('form.buttons.cancel')"
        @apply="handleSubmit(() => stripeRefundForm.callback(true))"
        @cancel="stripeRefundForm.callback(false)"
      >
        <p class="stripe-refund-modal__description">
          This refund needs to be made in Stripe
        </p>
      </ui-modal-alert>
    </validation-observer>
  </ui-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import getFio from '@/utils/fio';
import parseDate from '@/utils/parseDate';
import download from '@/utils/download';
import paymentCardList from '@/config/paymentCards';
import { PAYMENT_SYSTEM, PAYMENT_SYSTEM_LABEL, PAYMENT_SYSTEMS_REFUND } from '@/config/paymentSystems';
import api from '@/api';

export default {

  data() {
    return {
      isLoading: false,
      filter: {
        page: 1,
        dateFrom: null,
        dateTo: null,
        sales: null,
        text: null,
        country: null,
        paymentSystem: null,
      },
      checkoutRefundForm: {
        show: false,
        amount: 0,
        callback: () => {},
      },
      stripeRefundForm: {
        show: false,
        callback: () => {},
      },
      showRefunded: true,
      transactionsLoading: [],
      datePicker: null,
      items: [],
      locationsList: [],
      total: 0,
      pageSize: 10,
    };
  },

  computed: {
    ...mapState('settings', [
      'salesPeople',
    ]),
    paymentTypesList() {
      return [
        {
          id: PAYMENT_SYSTEM.STRIPE,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.STRIPE],
          visible: this.$apiSwitch?.selectedValue !== 'uae',
        },
        {
          id: PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD],
          visible: this.$apiSwitch?.selectedValue !== 'uae',
        },
        {
          id: PAYMENT_SYSTEM.RYFT,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.RYFT],
          visible: this.$apiSwitch?.selectedValue !== 'uae',
        },
        {
          id: PAYMENT_SYSTEM.CHECKOUT,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT],
          visible: true,
        },
        {
          id: PAYMENT_SYSTEM.CHECKOUT_UAE,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UAE],
          visible: this.$apiSwitch?.selectedValue !== 'eu',
        },
        {
          id: PAYMENT_SYSTEM.PAYER_MAX,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.PAYER_MAX],
          visible: this.$apiSwitch?.selectedValue !== 'eu',
        },
      ].filter(({ visible }) => visible);
    },
    countriesList() {
      return this.$getCountries({ empty: true });
    },
    salesPeopleList() {
      return [
        {
          id: undefined,
          firstName: '',
          lastName: '',
        },
        ...this.salesPeople,
      ];
    },
    start() {
      return (this.filter.page - 1) * this.pageSize;
    },
    apiConfig() {
      return {
        ...this.filter,
        start: this.start,
        pageSize: this.pageSize,
        startDate: parseDate(this.filter.dateFrom),
        endDate: parseDate(this.filter.dateTo),
        salesIdList: this.filter.sales ? [this.filter.sales] : [0],
      };
    },
    filteredItems() {
      return this.items.filter(({ status }) => this.showRefunded || status !== 'REFUNDED');
    },
    columns() {
      return [
        {
          name: 'export-id',
          label: this.$t('table.columns.payoutId'),
          prop: 'payoutId',
          empty: '-',
          align: 'center',
        },
        {
          name: 'export-transaction-id',
          label: this.$t('table.columns.transactionId'),
          prop: 'displayTransactionId',
          empty: '-',
        },
        {
          name: 'export-easytip-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'workplace',
          value: ({ row }) => (row.staff.easyTipId || row.workplace.payoutId),
          empty: this.$t('table.empty.easytipId'),
        },
        {
          name: 'export-date',
          label: this.$t('table.columns.date'),
          prop: 'transactionDate',
          empty: this.$t('table.empty.date'),
        },
        {
          name: 'export-name',
          label: this.$t('table.columns.name'),
          prop: 'staff',
          value: ({ row }) => {
            const firstName = row.staff?.firstName;
            const lastName = row.staff?.lastName;

            return firstName && lastName
              ? [firstName, lastName]
              : [];
          },
          empty: '-',
        },
        {
          name: 'export-gateway',
          label: this.$t('table.columns.gateway'),
          prop: 'paymentSystem',
          value: ({ row }) => PAYMENT_SYSTEM_LABEL[row.paymentSystem],
          empty: '-',
        },
        {
          name: 'export-method',
          label: this.$t('table.columns.method'),
          prop: 'paymentInfo',
          value: ({ row }) => {
            const item = paymentCardList[row.paymentInfo?.paymentMethod];

            return {
              icon: item?.icon,
              width: item?.width,
              height: item?.height,
              suffix: row.paymentInfo?.cardSuffix,
            };
          },
          empty: '-',
        },
        {
          name: 'export-tips',
          label: this.$t('table.columns.tips'),
          prop: 'amount',
          empty: this.$t('table.empty.tips'),
        },
        {
          name: 'export-location-name',
          label: this.$t('table.columns.locationName'),
          prop: 'workplace',
          empty: this.$t('table.empty.locationName'),
        },
        {
          name: 'export-action',
        },
      ];
    },
  },

  watch: {
    datePicker(value) {
      let [dateFrom, dateTo] = value || [];

      if (dateFrom) {
        dateFrom = DateTime.fromJSDate(dateFrom);
        dateFrom = dateFrom.startOf('day').toSQLDate();
      }

      if (dateTo) {
        dateTo = DateTime.fromJSDate(dateTo);
        dateTo = dateTo.endOf('day').toSQLDate();
      }

      this.filter.dateFrom = dateFrom;
      this.filter.dateTo = dateTo;
    },
    '$route.query': {
      immediate: true,
      handler(query) {
        const parsedQuery = this.parseQuery(query);

        this.filter = {
          ...this.filter,
          ...parsedQuery,
        };

        if (isEmpty(query)) {
          this.updateFilter();
        } else {
          this.loadItems();
        }
      },
    },
  },

  async mounted() {
    try {
      await this.loadSalesPeople();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  methods: {
    getFio,
    ...mapActions({
      loadSalesPeople: 'settings/loadSalesPeople',
    }),
    getColumnClass(colScope, rowScope) {
      return rowScope.status === 'REFUNDED'
          && colScope.name !== 'export-action'
        ? 'opacity-30'
        : '';
    },
    isTransactionLoading({ transactionId }) {
      return this.transactionsLoading.some((val) => val === transactionId);
    },
    getTransactionActions({ status, paymentSystem }) {
      return [
        {
          id: 'refund',
          label: 'Refund',
          visible: true,
          disabled: status === 'REFUNDED',
        },
        {
          id: 'receipt',
          label: 'Receipt',
          visible: paymentSystem !== PAYMENT_SYSTEM.STRIPE,
        },
      ].filter(({ visible }) => visible);
    },
    parseQuery(query) {
      const parsedQuery = this.filter;

      if (query.page) {
        parsedQuery.page = parseInt(query.page, 10);
      }

      // Format dateTo param.
      if (!query.dateTo) {
        parsedQuery.dateTo = DateTime.local();
      } else {
        parsedQuery.dateTo = DateTime.fromJSDate(parseDate(query.dateTo, null));
      }

      // Format dateFrom param.
      if (!query.dateFrom) {
        parsedQuery.dateFrom = parsedQuery.dateTo.minus({ months: 1 });
      } else {
        parsedQuery.dateFrom = DateTime.fromJSDate(parseDate(query.dateFrom, null));
      }

      // Push dates into datePicker.
      parsedQuery.dateTo = parsedQuery.dateTo.endOf('day');
      parsedQuery.dateFrom = parsedQuery.dateFrom.startOf('day');

      this.datePicker = [
        parsedQuery.dateFrom.toJSDate(),
        parsedQuery.dateTo.toJSDate(),
      ];

      parsedQuery.dateTo = parsedQuery.dateTo.toSQLDate();
      parsedQuery.dateFrom = parsedQuery.dateFrom.toSQLDate();

      if (!query.country) {
        parsedQuery.country = this.countriesList[0].iso;
      } else {
        parsedQuery.country = query.country;
      }

      if (!query.paymentSystem) {
        parsedQuery.paymentSystem = this.paymentTypesList[0].id;
      } else {
        parsedQuery.paymentSystem = query.paymentSystem;
      }

      return parsedQuery;
    },
    async handleAction(event, item) {
      this.transactionsLoading.push(item.transactionId);

      try {
        if (event === 'refund') {
          if (PAYMENT_SYSTEMS_REFUND.includes(item.paymentSystem)) {
            await this.checkoutRefund(item);
          }

          if (item.paymentSystem === PAYMENT_SYSTEM.STRIPE) {
            await this.stripeRefund(item);
          }
        }

        if (event === 'receipt') {
          if (PAYMENT_SYSTEMS_REFUND.includes(item.paymentSystem)) {
            await this.checkoutReceipt(item);
          }
        }
      } finally {
        const index = this.transactionsLoading.find((val) => val === item.transactionId);

        if (index) {
          this.transactionsLoading.splice(index, 1);
        }
      }
    },
    async checkoutRefund(item) {
      return new Promise((callback) => {
        this.checkoutRefundForm = {
          show: true,
          amount: item.amount,
          currency: item.currency,
          callback,
        };
      }).then(async (result) => {
        if (result) {
          await api.transaction.sendCheckoutRefund({
            transactionId: item.transactionId,
          });

          this.items.forEach((t) => {
            if (t.transactionId === item.transactionId) {
              t.status = 'REFUNDED';
            }
          });
        }
      });
    },
    async stripeRefund(item) {
      return new Promise((callback) => {
        this.stripeRefundForm = {
          show: true,
          callback,
        };
      }).then((result) => {
        const { refundUrl } = item.paymentInfo;

        if (result && refundUrl) {
          window.open(refundUrl, '_blank');
        }
      });
    },
    async checkoutReceipt(item) {
      try {
        const {
          data,
          filename,
        } = await api.transaction.getCheckoutReceipt({
          transactionId: item.transactionId,
        });

        if (data && filename) {
          download(data, filename);
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadItems() {
      this.isLoading = true;
      this.total = 0;

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.transaction.getTransactionsList(this.apiConfig);

        if (data) {
          this.total = totalRecords;
          this.items = data;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async exportXls() {
      const {
        start,
        pageSize,
        ...apiData
      } = this.apiConfig;

      try {
        const {
          data,
          filename,
        } = await api.transaction.getExportsExcel(apiData);

        if (data && filename) {
          download(data, filename);
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    updateFilter(params) {
      if (params?.page) {
        this.filter.page = params.page;
      }

      this.$router.replace({
        query: this.filter,
      }, () => {});
    },
  },
};
</script>
