<template>
  <div class="d-flex align-items-center">
    <ui-tooltip placement="top">
      <template #content>
        <div class="d-flex flex-column align-items-center">
          <span>{{ label }}</span>
        </div>
      </template>
      <ui-icon
        :name="icon"
        width="24"
        height="24"
      />
    </ui-tooltip>
  </div>
</template>
<script>
export default {
  name: 'StaffTableKYCTooltip',

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
