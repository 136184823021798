var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('ui-card',{attrs:{"is-loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"card-title mb-xl-0 mb-lg-0 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('promotions.message.title'))+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"col-12 col-lg-3 col-lg-4 col-md-6",attrs:{"tag":"div","name":_vm.$t('form.labels.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"mb-3",attrs:{"label":_vm.$t('form.labels.title'),"placeholder":_vm.$t('form.placeholders.enter'),"error":errors[0],"disabled":!_vm.staffList.length,"required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1),_c('div',{staticClass:"row flex-column"},[_c('span',{staticClass:"col-12 text-grey font-14 mb-3",class:{ 'disabled': !_vm.staffList.length }},[_vm._v(" To "),_c('span',{staticClass:"text-danger",class:{ 'disabled': !_vm.staffList.length }},[_vm._v(" * ")])]),(_vm.staffList.length)?[_c('div',{staticClass:"col-12"},[_c('ui-checkbox',{staticClass:"mb-3",model:{value:(_vm.checkedAll),callback:function ($$v) {_vm.checkedAll=$$v},expression:"checkedAll"}},[_vm._v(" All ")])],1),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.staffList),function(staff){return _c('ui-checkbox',{key:staff.id,staticClass:"col-12 col-xl-3 col-lg-3 col-md-3 mb-3",model:{value:(staff.checked),callback:function ($$v) {_vm.$set(staff, "checked", $$v)},expression:"staff.checked"}},[_vm._v(" "+_vm._s(_vm.staffName(staff))+" ")])}),1)])]:_vm._e()],2),_c('div',{staticClass:"row"},[_c('validation-provider',{staticClass:"col-12 col-lg-3 col-lg-4 col-md-6 d-flex",attrs:{"tag":"div","name":_vm.$t('form.labels.message'),"rules":"required|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{staticClass:"w-100 mt-4",attrs:{"tag":"textarea","label":_vm.$t('form.labels.message'),"placeholder":_vm.$t('form.placeholders.enter'),"error":errors[0],"disabled":!_vm.staffList.length,"required":""},model:{value:(_vm.form.text),callback:function ($$v) {_vm.$set(_vm.form, "text", $$v)},expression:"form.text"}}),_c('span',{staticClass:"d-flex align-items-end text-grey font-12 mb-4 ml-2",class:{ 'disabled': !_vm.staffList.length }},[_vm._v(" "+_vm._s(_vm.form.text.length)+"/190 ")])]}}],null,true)})],1),_c('div',{staticClass:"row mb-4"},[_c('validation-provider',{staticClass:"col-12 col-lg-3 col-lg-4 col-md-6",attrs:{"tag":"div","name":_vm.$t('form.labels.notificationType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{staticClass:"mb-3",attrs:{"items":_vm.notificationTypes,"label":_vm.$t('form.labels.notificationType'),"placeholder":_vm.$t('form.placeholders.enter'),"label-key":"label","value-key":"value","error":errors[0],"disabled":!_vm.staffList.length,"required":""},model:{value:(_vm.form.notificationChannel),callback:function ($$v) {_vm.$set(_vm.form, "notificationChannel", $$v)},expression:"form.notificationChannel"}})]}}],null,true)})],1),_c('div',{staticClass:"row mx-2 mx-xl-0 mx-lg-0 mx-md-0"},[_c('ui-button',{attrs:{"action":"","type":"primary","is-loading":_vm.loading,"disabled":_vm.loading || invalid || !_vm.checkedCount},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.send)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.send'))+" ")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }