<template>
  <div class="statistics-table">
    <statistics-table-header />
    <div class="statistics-table__blur_top"></div>
    <slot />
    <div class="statistics-table__blur_bottom"></div>
  </div>
</template>

<script>
import StatisticsTableHeader from '@/components/Statistics/StatisticsTableHeader.vue';

export default {
  name: 'StatisticsTable',
  components: {
    StatisticsTableHeader,
  },
};
</script>
