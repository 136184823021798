<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-modal
      v-bind="$attrs"
      v-on="$listeners"
      @before-open="beforeOpen"
      class="staff-hours-form"
    >
      <template #title>
        <div class="modal-title text-center">
          {{ title }}
        </div>
      </template>
      <template #footer="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <ui-button
            native-type="button"
            type="primary"
            class="staff-hours-form__prev"
            :class="{ 'staff-hours-form__prev-disabled': disabledPrevPage }"
            @click="prevPage"
            size="lg"
            round
          >
            <ui-icon
              name="arrow-left"
              class="staff-hours-form__arrow"
            />
          </ui-button>
          <ui-button
            type="primary"
            native-type="button"
            class="staff-hours-form__approve w-100 mx-2"
            @click="handleSubmit(apply(close))"
            :disabled="invalidForm"
          >
            {{ $t('form.buttons.approve') }}
          </ui-button>
          <ui-button
            native-type="button"
            type="primary"
            class="staff-hours-form__next"
            :class="{ 'staff-hours-form__next-disabled': disabledNextPage }"
            @click="nextPage"
            size="lg"
            round
          >
            <ui-icon
              name="arrow-right"
              class="staff-hours-form__arrow"
            />
          </ui-button>
        </div>
      </template>

      <div class="container">
        <div class="row">
          <div class="col-4 offset-8">
            <div class="mb-4 text-muted text-right">
              {{ timesheet.label }}
            </div>
          </div>
        </div>
        <validation-provider
          v-for="(item) in showCountStaffPerPage"
          :key="item.staffId"
          immediate
          name=""
          rules="required"
          v-slot="{ errors }"
          class="row"
          tag="div"
        >
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">
            <div class="staff-hours-form__staff-info form-group mt-0 d-flex align-items-center">
              <strong
                :class="{'text-primary': errors.length > 0}"
              >
                {{ item.fio || item.payoutId }}
              </strong>
              <span class="ml-2 text-muted">
                ({{ getStaffTypeName(item.staffType) }}
                {{ item.isTronc && item.isTroncmaster ? ` ${this.$t('form.labels.troncMaster', this.location.country)}` : '' }}
                {{ item.isTronc && !item.isTroncmaster ? ` Deputy ${this.$tl('form.labels.troncMaster', this.location.country)}` : '' }})
              </span>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
            <ui-input
              :value="getStaffHours(item)"
              @keypress="isNumber($event)"
              @input="setStaffHours(item, $event)"
              input-classes="w-100 text-right"
              class="mt-0"
              bordered
              required
              :error="errors.length > 0"
            >
              <template #addonRight>
                {{ timesheet.short }}
              </template>
            </ui-input>
          </div>
        </validation-provider>
      </div>

      <div
        v-if="pageSize$"
        class="d-flex justify-content-center"
      >
        <ui-icon
          v-for="item in pageSize$"
          :key="item"
          name="circle-pagination-indication"
          class="staff-hours-form__indication"
          :class="{ 'staff-hours-form__indication-active': item === page }"
          size="8"
        />
      </div>
    </ui-modal>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import ResetMixin from '@/mixins/reset-mixin';

export default {

  mixins: [
    ResetMixin(() => ({
      form: [],
      invalidForm: true,
    })),
  ],

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    location: Object,
    staffs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      perPage: 5,
      page: 1,
      total: 5,
      start: 0,
    };
  },

  async mounted() {
    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  computed: {
    title() {
      return this.data.length
        ? this.$t('form.buttons.editStaffMembers')
        : this.$t('form.buttons.addStaffMembers');
    },
    timesheet() {
      if (this.location.timesheetType === 'UNITS') {
        return {
          label: 'units',
          short: 'u',
        };
      }

      return {
        label: 'hours',
        short: 'h',
      };
    },
    pageSize$() {
      if (this.staffs.length > 0 && this.perPage > 0) {
        return Math.ceil(this.staffs.length / this.perPage);
      }

      return 1;
    },
    showCountStaffPerPage() {
      return this.staffs.slice(this.start, this.total);
    },
    disabledNextPage() {
      return this.page === this.pageSize$;
    },
    disabledPrevPage() {
      return this.page <= 1;
    },
  },

  watch: {
    form: {
      immediate: true,
      handler(value) {
        const staffs = this.staffs.length;

        if (value.length < staffs) {
          this.invalidForm = true;
        }

        if (value.length === staffs) {
          if (this.filterFormHoursBy(value, '').length > 0) {
            this.invalidForm = true;
          } else if (this.filterFormHoursBy(value, 0).length === staffs) {
            this.invalidForm = true;
          } else {
            this.invalidForm = false;
          }
        }
      },
    },
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    getStaffTypeName(staffType) {
      return this.$getStaffTypeName(staffType);
    },
    filterFormHoursBy(from, value) {
      return from.filter(({ hours }) => hours === value);
    },
    isNumber(evt) {
      evt = (evt) || window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    beforeOpen() {
      this.reset();

      if (this.data) {
        this.form = [
          ...this.data.map((item) => ({
            ...item,
          })),
        ];
      }
    },
    getStaffHours(item) {
      return this.form.find(({ staffId }) => staffId === item.id)?.hours;
    },
    setStaffHours(item, value) {
      const index = this.form.findIndex(({ staffId }) => staffId === item.id);
      value = String(value).replace(',', '.').replace(/[^.\d]+/g, '').replace(/^([^.]*\.)|\./g, '$1');
      const valueArray = value.split('.');

      if (valueArray[0] === '') {
        valueArray[0] = 0;
      }

      if (valueArray.length > 1) {
        value = `${valueArray[0]}.${valueArray[1].substr(0, 2)}`;
      } else {
        value = parseInt(value, 10);
      }

      if (value >= 0) {
        item = {
          staffId: item.id,
          hours: value,
        };

        if (index > -1) {
          this.form.splice(index, 1, item);
        } else {
          this.form.push(item);
        }
      } else if (index > -1) {
        this.form.splice(index, 1);
      }
    },
    apply(close) {
      this.$emit('update:data', this.form.filter(({ hours }) => hours > 0));

      close();
    },
    changePage(page) {
      if (page > this.page) {
        this.page = page;
        this.start = this.total;
        this.total += this.perPage;
      } else {
        this.page = page;
        this.total -= this.perPage;
        this.start -= this.perPage;
      }
    },
    nextPage() {
      this.changePage(this.page + 1);
    },
    prevPage() {
      this.changePage(this.page - 1);
    },
  },
};
</script>
