<template>
  <ui-card v-bind="$attrs">
    <div class="card-header pt-0 px-0">
      <h4 class="card-title">
        {{ $t('distributions.totalTitle') }}
      </h4>
    </div>
    <div class="d-flex align-items-end">
      <div class="card-stats__icon text-nowrap">
        <ui-icon name="available" size="44" />
      </div>
      <div class="card-stats__value text-nowrap">
        {{ total || 0 | money(currency) }}
      </div>
    </div>

    <div class="card-header mt-4 px-0">
      <h4 class="card-title">
        {{ $t('distributions.dateTitle') }}
      </h4>
    </div>
    <div class="d-flex align-items-end">
      <div class="card-stats__icon text-nowrap">
        <ui-icon name="available" size="44" />
      </div>
      <div class="card-stats__value text-nowrap">
        {{ daily || 0 | money(currency) }}
      </div>
    </div>
  </ui-card>
</template>

<script>
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    date: Date,
    currency: String,
    total: null,
    daily: null,
  },
};
</script>

<style lang="scss">
.stats-balance {
  &__value {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  &__help {
    font-weight: normal;
    font-size: 20px;
  }
}
</style>
