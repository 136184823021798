<template>
  <staff-list
    v-bind="$attrs"
    v-on="listeners"
    :is-loading="isLoading"
    :location="location"
    :staffs="staffs"
    show-all-filter
    :page-size="pageSize"
    :total="total"
  />
</template>

<script>
import api from '@/api';
import getFio from '@/utils/fio';
import StaffList from './StaffList.vue';

export default {
  name: 'StaffListLocation',

  components: {
    StaffList,
  },

  props: {
    reload: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    staffs: [],
    isLoading: false,
    total: 0,
    page: 1,
    showAll: false,
    pageSize: 10,
  }),

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        updated: this.handleUpdated,
        load: this.handleLoad,
      };
    },
    start() {
      return (this.page - 1) * this.pageSize;
    },
    totalPages() {
      return this.total > 0
        ? Math.ceil(this.total / this.pageSize)
        : 0;
    },
  },

  watch: {
    reload(value) {
      if (value) {
        this.loadStaffs();
        this.$emit('update:reload', false);
      }
    },
  },

  methods: {
    handleLoad({ page, showAll }) {
      this.showAll = showAll;

      if (page && (this.totalPages === 0 || page <= this.totalPages)) {
        this.page = page;
      }

      this.loadStaffs();
    },
    async loadStaffs() {
      this.isLoading = true;
      this.staffs.splice(0);

      try {
        const { data } = await api.locations.getStaffsPage({
          locationId: this.location.id,
          isActive: !this.showAll || null,
          start: this.start,
          pageSize: this.pageSize,
        });

        if (data) {
          this.total = data.totalRecords;
          this.staffs = data.data.map((item) => ({
            ...item,
            fio: getFio(item),
          }));
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    handleUpdated() {
      this.loadStaffs();
      this.$emit('updated');
    },
  },
};
</script>
