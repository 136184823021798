<template>
  <div>
    <ui-stats-card
      :is-loading="isLoading"
      class="overview-card h-100"
    >
      <div class="card-header overview-card__header">
        <ui-icon name="new-clients" size="32px" />
        <h4 class="card-title overview-card__title">
          New clients
        </h4>
      </div>
      <div class="card-stats__value overview-card__value text-nowrap">
        {{ newClients }}
      </div>

      <hr class="my-4">

      <div class="card-header overview-card__header">
        <ui-icon name="coins" size="32px" />
        <h4 class="card-title overview-card__title">
          GMV per client
        </h4>
      </div>
      <div class="card-stats__value overview-card__value text-nowrap">
        {{ gmv.amount || 0 | money(gmv.currency) }}
      </div>
    </ui-stats-card>
  </div>
</template>

<script>
import api from '@/api';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  data: () => ({
    newClients: 0,
    gmv: 0,
  }),

  computed: {
    apiConfig() {
      return {
        salesId: this.staffId,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
  },

  methods: {
    async load() {
      return Promise.all([
        this.loadNewClients(),
        this.loadGmv(),
      ]);
    },
    async loadNewClients() {
      const { data } = await api.locations.getNewClients(this.apiConfig);

      if (data?.cnt) {
        this.newClients = data.cnt;
      }
    },
    async loadGmv() {
      const { data } = await api.locations.getGmv(this.apiConfig);

      if (data?.amountDto) {
        this.gmv = data.amountDto;
      }
    },
  },
};
</script>
