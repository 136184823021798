<template>
  <ui-loading v-if="defaultLocationRequired" inline />
  <div v-else :key="storeUpdated">
    <div class="row">
      <sublocations-selector
        v-if="isMasterLocation"
        class="col-auto"
      />
      <div class="col-auto" v-if="showGroups">
        <ui-select
          v-model="groupId"
          :items="groupOptions"
          label-key="name"
          class="select-width"
          value-key="id"
          bordered
        />
      </div>
      <div class="col-auto">
        <ui-calendar-period v-model="period" />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-12 pb-4">
        <stats-rating
          :key="`rating-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          class="my-n1 h-100"
        />
      </div>
      <stats-last-reviews
        :key="`reviews-${location.id}-${groupId}-${from}-${to}`"
        :location-id="location.id"
        :group-id="groupId"
        :date-from="from"
        :date-to="to"
      />
      <div
        class="col-12 pb-4"
        :class="{
          'col-lg-4': visibleCardsCount >= 3,
          'col-lg-6': visibleCardsCount < 3
        }"
      >
        <stats-compliments
          :key="`compliments-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :location-type="location.type"
          :group-id="groupId"
          :reviews="reviewsAll"
          :date-from="from"
          :date-to="to"
          :location-country="location.country"
          class="h-100"
          @visible="onCardVisible('compliments')"
        />
      </div>

      <div
        class="col-12 pb-4"
        :class="{
          'col-lg-4': visibleCardsCount >= 3,
          'col-lg-6': visibleCardsCount < 3
        }"
      >
        <stats-complaints
          :key="`complaints-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :location-type="location.type"
          :group-id="groupId"
          :reviews="reviewsAll"
          :date-from="from"
          :date-to="to"
          class="h-100"
          @visible="onCardVisible('complaints')"
        />
      </div>
      <div
        v-if="showEarners"
        class="col-12 pb-4"
        :class="{
          'col-lg-4': visibleCardsCount >= 3,
          'col-lg-6': visibleCardsCount < 3
        }"
      >
        <stats-earners
          :key="`earners-${location.id}-${groupId}-${from}-${to}`"
          :location-id="location.id"
          :group-id="groupId"
          :date-from="from"
          :date-to="to"
          class="h-100"
          @visible="onCardVisible('earners')"
        />
      </div>
    </div>

    <location-reviews
      v-if="showLocationReviews"
      :location="location"
      @loaded="onLoaded"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapState, mapGetters, mapActions } from 'vuex';
import StatsRating from '@/components/Stats/StatsRating.vue';
import StatsLastReviews from '@/components/Stats/StatsLastReviews.vue';
import StatsCompliments from '@/components/Stats/StatsCompliments.vue';
import StatsComplaints from '@/components/Stats/StatsComplaints.vue';
import StatsEarners from '@/components/Stats/StatsEarners.vue';
import LocationReviews from '@/components/Location/LocationReviews.vue';
import SublocationsSelector from '@/components/SublocationsSelector.vue';

export default {

  components: {
    SublocationsSelector,
    StatsRating,
    StatsLastReviews,
    StatsCompliments,
    StatsComplaints,
    LocationReviews,
    StatsEarners,
  },

  data: () => ({
    reviewsAll: [],
    groupId: 0,
    period: [],
    visibleCards: [],
  }),

  computed: {
    ...mapGetters({
      isOwner: 'auth/isOwner',
      isAccountant: 'auth/isAccountant',
      defaultLocationRequired: 'user/defaultLocationRequired',
      isMasterLocation: 'user/isMasterLocation',
    }),
    ...mapState({
      currentLocation: (state) => state.user.location,
      selectedSublocation: (state) => state.sublocations.selectedSublocation,
      groups: (state) => state.groups.items,
      groupsLoaded: (state) => state.groups.loaded,
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
    }),
    location() {
      return this.selectedSublocation || this.currentLocation;
    },
    showGroups() {
      return (this.isOwner || this.isAccountant) && this.location.paymentPage === 'GROUPS';
    },
    showEarners() {
      return (this.isOwner || this.isAccountant) && this.location.tipsType === 'PERSONAL';
    },
    groupOptions() {
      return [
        {
          id: 0,
          name: this.$t('form.labels.allGroups'),
        },
        ...this.groups,
      ];
    },
    from() {
      return this.period?.[0]
        || DateTime.local().minus({ days: 30 }).toJSDate();
    },
    to() {
      return this.period?.[1]
        || DateTime.local().toJSDate();
    },
    storeUpdated() {
      return `${this.accessToken}-${this.payoutId}`;
    },
    visibleCardsCount() {
      return this.visibleCards.length;
    },
    showLocationReviews() {
      return !this.currentLocation.isMasterLocation;
    },
  },

  mounted() {
    if (this.viewAsMode === null) {
      this.$amplitude.event('OPENED_PAGE', {
        userRoles: this.userRoles,
        hostname: window.location.hostname,
        pagepath: window.location.pathname,
        url: window.location.href,
      });
    }
  },

  watch: {
    defaultLocationRequired: {
      immediate: true,
      handler(value) {
        if (value && !this.groupsLoaded) {
          this.loadGroups();
        }
      },
    },
    storeUpdated: {
      immediate: true,
      async handler() {
        if (!this.groupsLoaded) {
          this.loadGroups();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      loadGroupItems: 'groups/loadItems',
    }),
    onCardVisible(cardName) {
      this.visibleCards.push(cardName);
    },
    onLastReviewsNoData() {
      this.showLastReviews = false;
    },
    onLoaded(items) {
      this.reviewsAll = items;
    },
    selectedPeriod({ dateFrom, dateTo }) {
      this.from = dateFrom;
      this.to = dateTo;
    },
    async loadGroups() {
      if (!this.showGroups) {
        return;
      }

      try {
        await this.loadGroupItems({
          locationId: this.location.id,
        });
      } catch (e) {
        this.$showServerError(e);
      }
    },
  },
};
</script>
