<template>
  <ui-modal
    :show="value"
    class="checkout-payouts-modal"
    @closed="$emit('closed')"
  >
    <template #header>
      <span class="checkout-payouts-modal__title">
        {{ $t('payouts.modal.confirmPayout.title') }}
      </span>
    </template>
    <div class="checkout-payouts-modal__body">
      <div class="checkout-payouts-modal__your-balance">
        {{ $t('payouts.modal.confirmPayout.balance') }}:
      </div>
      <div class="checkout-payouts-modal__balance">
        {{ balance | money(currency) }}
      </div>
      <div class="checkout-payouts-modal__fee-text">
        <span>
          {{ $t('payouts.modal.confirmPayout.fee') }}
        </span>
        <span class="checkout-payouts-modal__fee">
          {{ fee | money(currency) }}
        </span>
      </div>
    </div>
    <template #footer="{ close }">
      <div class="checkout-payouts-modal__actions">
        <ui-button
          round
          size="sm"
          outline
          class="checkout-payouts-modal__button"
          @click="close"
        >
          {{ $t('form.buttons.cancel') }}
        </ui-button>
        <ui-button
          round
          size="sm"
          outline
          class="checkout-payouts-modal__button"
          :disabled="confirmPayoutLoading"
          :is-loading="confirmPayoutLoading"
          @click="$emit('submit')"
        >
          {{ $t('form.buttons.ok') }}
        </ui-button>
      </div>
    </template>
  </ui-modal>
</template>

<script>
export default {
  name: 'CheckoutPayoutModal',
  model: {
    prop: 'value',
    event: 'closed',
  },
  props: {
    fee: {
      type: [String, Number],
      required: true,
    },
    balance: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    value: Boolean,
    confirmPayoutLoading: Boolean,
  },
};
</script>
