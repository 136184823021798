<template>
  <validation-observer tag="form" #default="{ invalid, handleSubmit }">
    <ui-modal-alert
      v-bind="$attrs"
      :title="$t('staffs.modal.confirmation')"
      :apply-disabled="invalid"
      :apply-button="$t('form.buttons.yes')"
      :close-button="$t('form.buttons.cancel')"
      @apply="handleSubmit(handleApply)"
      @cancel="handleCancel"
    >
      <p>{{ $t('staffs.modal.deactivate') }}</p>

      <div class="row mb-0 mt-4">
        <div class="col-auto mx-auto">
          <validation-provider
            tag="div"
            :name="$t('form.labels.deactivateDate')"
            rules="required"
          >
            <ui-calendar-picker
              v-model="deactivateDate"
              :min="minDate"
              :max="maxDate"
              :label="$t('form.labels.deactivateDate')"
              :placeholder="$t('form.labels.deactivateDate')"
              input-classes="text-center"
            />
          </validation-provider>
        </div>
      </div>
    </ui-modal-alert>
  </validation-observer>
</template>

<script>
import api from '@/api';

export default {
  name: 'StaffDeactivateModal',

  props: {
    staff: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    deactivateDate: null,
    minDate: null,
    maxDate: null,
  }),

  created() {
    const minDate = new Date(this.staff.startWorkDate);
    const maxDate = new Date();

    minDate.setDate(minDate.getDate() - 1);
    maxDate.setDate(maxDate.getDate() + 1);

    this.minDate = minDate;
    this.maxDate = maxDate;
  },

  methods: {
    async handleApply() {
      try {
        await api.locations.removeStaff({
          locationId: this.location.id,
          staffId: this.staff.id,
          deactivateDate: this.deactivateDate,
        });

        this.$emit('apply');
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
