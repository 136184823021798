export const TARGET_USER_ACTIONS = [
  {
    value: 'NONE',
    name: 'None',
  },
  {
    value: 'CARD_ADDED',
    name: 'Card added',
  },
  {
    value: 'PROFILE_COMPLETED',
    name: 'Profile completed',
  },
  {
    value: 'ID_INFO_PROVIDED',
    name: 'ID info provided',
  },
  {
    value: 'DISTRIBUTION_COMPLETED',
    name: 'Distribution completed',
  },
  {
    value: 'KYC_COMPLETED',
    name: 'KYC completed',
  },
  {
    value: 'PAYOUT_PLAN_CHANGED',
    name: 'Payout plan changed',
  },
];

export const DEFAULT_TARGET_USER_ACTION = TARGET_USER_ACTIONS[0];
