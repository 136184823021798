<template>
  <div class="radio-card-group">
    <radio-card
      v-for="element in elements"
      :key="element.id"
      :title="element.title"
      :info="element.info"
      :example="element.example"
      :description="element.description"
      :icon="element.icon"
      :selected="selectedId === element.id"
      :header="element.header"
      @select="$emit('select-element', element)"
    />
  </div>
</template>

<script>
import RadioCard from '@/components/UI/RadioCards/RadioCard.vue';

export default {
  name: 'RadioCards',

  components: {
    RadioCard,
  },

  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: String,
      required: true,
    },
  },
};
</script>
