import * as UAParser from 'ua-parser-js';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK, APP_GALLERY_LINK } from '@/config/storeLinks';
import { APP_PLATFORM } from '@/config/app';

export default () => {
  const parser = new UAParser();
  const OS = parser.getOS().name;

  if (APP_PLATFORM === 'HarmonyOS' || OS === 'HarmonyOS') {
    return APP_GALLERY_LINK;
  }

  if (APP_PLATFORM === 'Android') {
    return GOOGLE_PLAY_LINK;
  }

  if (APP_PLATFORM === 'IOS') {
    return APP_STORE_LINK;
  }

  return '';
};
