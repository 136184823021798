<template>
  <div>
    <ui-modal
      show
      no-footer
      class="user-change-phone-modal"
      @closed="handleClose"
    >
      <template #title>
        <div class="modal-title text-center">
          Update phone
        </div>
      </template>

      <template>
        <div class="container">
          <div
            class="row justify-content-md-center"
          >
            <div class="col-7 center-block">
              <validation-provider
                tag="div"
                :name="$t('form.labels.phoneNumber')"
                :rules="{
                  required: {
                    invalid: phoneInvalid,
                  },
                }"
                mode="passive"
                v-slot="{ errors }"
              >
                <ui-phone
                  v-model="phone"
                  v-protected
                  :label="$t('form.labels.phoneNumber')"
                  required
                  :disabled="isConfirmation"
                  :error="errors[0]"
                  :country-code="countryCode"
                  @validate="phoneInvalid = $event"
                />
              </validation-provider>

              <div v-if="showOtp">
                <validation-provider
                  key="recovery-code"
                  name="Code"
                  rules="digits:6"
                  mode="passive"
                  v-slot="{ errors }"
                >
                  <ui-input
                    v-model="code"
                    label="Enter OTP code"
                    input-class="form-control p-1"
                    :error="otpInvalidError || errors[0]"
                    required
                  />
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-7">
              <div class="row justify-content-around">
                <ui-button
                  v-if="!showOtp"
                  type="primary"
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-3 mt-3 ml-auto"
                  block
                  @click.prevent="sendOtp"
                  :is-loading="loading"
                >
                  Update phone
                </ui-button>

                <ui-button
                  v-if="showOtp"
                  type="primary"
                  class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3 mal-auto"
                  block
                  @click.prevent="verify"
                  :disabled="!code"
                >
                  Update
                </ui-button>

                <ui-button
                  v-if="showOtp"
                  type="primary"
                  class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3 mal-auto"
                  block
                  @click.prevent="handleClose"
                >
                  Cancel
                </ui-button>

                <resend-code
                  v-if="showOtp"
                  @resend="sendOtp"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-modal>
    <ui-modal-status
      :show="status.show"
      :title="status.title"
      :message="status.message"
      :type="status.type"
      @closed="handleStatusClose"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import resetMixin from '@/mixins/reset-mixin';
import api from '@/api';
import ResendCode from '@/components/ResendCode.vue';

const INITIAL_STATUS = {
  show: false,
  type: 'success',
  title: 'Phone has been updated',
  message: '',
};

export default {
  name: 'UserChangePhoneModal',

  components: { ResendCode },

  props: {
    user: {
      type: Object,
      required: false,
    },
  },

  mixins: [
    resetMixin(() => ({
      isConfirmation: false,
      phoneInvalid: false,
      otpInvalidError: null,
    })),
  ],

  data: () => ({
    code: null,
    loading: false,
    phone: null,
    showOtp: false,
    otpId: null,
    status: { ...INITIAL_STATUS },
  }),

  computed: {
    ...mapState('user', [
      'profile',
    ]),
    countryCode() {
      const isoCode = this.user?.workPlace?.country;
      return isoCode?.toUpperCase();
    },
  },

  methods: {
    setPhone() {
      this.phone = this.user?.phoneNumber || '';
    },
    handleClose() {
      this.status = { ...INITIAL_STATUS };
      this.$emit('close');
    },
    handleStatusClose() {
      if (this.status.type === 'success') {
        this.$emit('completed');
        this.handleClose();
      }

      this.status = { ...INITIAL_STATUS };
    },
    handleError(e) {
      switch (e.response.data.errorCode) {
        case 429100:
        case 429001:
          this.status = {
            show: true,
            type: 'warning',
            title: 'Failed to update phone',
            message: `Maximum number of attempts exceeded.<br>Please try once again in ${e?.response?.data?.message} seconds`,
          };
          break;
        default:
          this.status = {
            show: true,
            type: 'warning',
            title: 'Failed to update phone',
            message: e?.response?.data?.message || 'Please try again or contact system administrator',
          };
          break;
      }
    },
    handleSuccess() {
      this.status = {
        show: true,
        type: 'success',
        title: 'Phone has been updated',
      };
    },
    async sendOtp() {
      this.loading = true;
      this.isConfirmation = true;
      try {
        const { data } = await api.otp.getOtp({
          userId: this.profile.id,
          otpType: 'SUPPORT_PHONE_CHANGE',
        });

        this.otpId = data.id;
        this.showOtp = true;
      } catch (e) {
        this.handleError(e);

        this.code = null;
        this.showOtp = false;
        this.isConfirmation = false;
      } finally {
        this.loading = false;
      }
    },
    async verify() {
      try {
        const { data } = await api.otp.verify({
          userId: this.profile.id, otpCode: this.code, id: this.otpId, otpType: 'SUPPORT_PHONE_CHANGE',
        });

        if (data.valid) {
          await this.changePhone();
          this.isConfirmation = false;
        } else {
          this.otpInvalidError = 'Invalid code. Please try again.';
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async changePhone() {
      try {
        await api.manager.changePhone({
          userId: this.user.id,
          otpId: this.otpId,
          phone: this.phone,
        });
        this.handleSuccess();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.code = null;
        this.showOtp = false;
        this.isConfirmation = false;
        this.otpInvalidError = null;
      }
    },
  },

  mounted() {
    this.setPhone();
  },
};
</script>
