<template>
  <div class="notifications-edit-form">
    <validation-observer tag="form" ref="form" v-slot="{ handleSubmit, invalid }">
      <ui-card :is-loading="formLoading">
        <template #header>
          <h4 class="card-title mb-xl-0 mb-lg-0 mb-3">
            {{ $t('notifications.form.editTitle') }}
          </h4>
        </template>
        <div v-if="!formLoading">
          <div class="row px-0">
            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              :name="$t('form.labels.country')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.country"
                :items="countryList"
                :label="$t('form.labels.country')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="iso"
                filterable
                required
                :disabled="isFieldDisabled"
                :error="errors[0]"
                @input="changeCountry($event)"
              />
            </validation-provider>

            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              :name="$t('form.labels.locations')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.workplaces"
                :items="modal.locations.allItems"
                :label="$t('form.labels.locations')"
                :placeholder="$t('form.placeholders.select')"
                class="notifications-edit-form__locations-control"
                multiple
                filterable
                collapse-tags
                hide-empty-dropdown
                hide-dropdown-loading
                input-icon="location-point"
                label-key="name"
                value-key="id"
                value-original
                required
                :error="errors[0]"
                :disabled="isFieldDisabled"
                @focus="openModalLocations"
              />
            </validation-provider>

            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              name="Topic"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.topic"
                label="Topic"
                placeholder="Enter title"
                required
                :disabled="isFieldDisabled"
                :error="errors[0]"
              />
            </validation-provider>
          </div>

          <h4 class="card-title mt-3 mb-4">
            {{ $t('notifications.form.message') }}
          </h4>

          <div class="row px-0">
            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              name="Message title"
              rules="max:30"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.title"
                id="formTitle"
                label="Message title"
                placeholder="Enter title"
                class="w-100"
                ext-postfix
                :error="errors[0]"
                :disabled="isFieldDisabled"
              >
                <template #extPostfix>
                  <div class="d-flex -w-70 align-items-center justify-content-between">
                    <template v-if="form.title">
                      {{ form.title.length }} / {{ MAX_TITLE_LENGTH }}
                    </template>
                    <template v-else>
                      0 / {{ MAX_TITLE_LENGTH }}
                    </template>
                    <ui-popover
                      placement="bottom-end"
                      width="240"
                      trigger="click"
                      title="Emojis"
                      icon="smile"
                      icon-size="20px"
                      icon-class="text-primary"
                      :disabled="isFieldDisabled"
                    >
                      <ui-emoji
                        @selected="titleSelectEmoji"
                      />
                    </ui-popover>
                  </div>
                </template>
              </ui-input>
            </validation-provider>

            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              name="Type"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.type"
                :items="notificationTypesList"
                label="Type"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="id"
                filterable
                required
                :error="errors[0]"
                :disabled="isFieldDisabled"
              />
            </validation-provider>

            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-6"
              name="Category"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.category"
                :items="categoryList"
                label="Category"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="id"
                filterable
                required
                :error="errors[0]"
                :disabled="isFieldDisabled"
              />
            </validation-provider>
          </div>

          <div class="row px-0">
            <validation-provider
              tag="div"
              class="col-12 col-xl-8 col-lg-8 col-md-6"
              name="Message text"
              rules="required|max:160"
              v-slot="{ errors }"
            >
              <ui-input
                tag="textarea"
                v-model="form.text"
                id="formText"
                label="Message text"
                placeholder="Enter text"
                class="w-100 mt-4"
                required
                ext-postfix
                :error="errors[0]"
                :disabled="isFieldDisabled"
              >
                <template #extPostfix>
                  <div class="d-flex -w-75 align-items-center justify-content-between">
                    <template v-if="form.text">
                      {{ form.text.length }} / {{ MAX_MESSAGE_LENGTH }}
                    </template>
                    <template v-else>
                      0 / {{ MAX_MESSAGE_LENGTH }}
                    </template>
                    <ui-popover
                      placement="bottom-end"
                      width="240"
                      trigger="click"
                      title="Emojis"
                      icon="smile"
                      icon-size="20px"
                      icon-class="text-primary"
                      :disabled="isFieldDisabled"
                    >
                      <ui-emoji
                        @selected="textSelectEmoji"
                      />
                    </ui-popover>
                  </div>
                </template>
              </ui-input>
            </validation-provider>
            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-4 col-md-4"
              name="Target user action"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.targetUserAction"
                :items="TARGET_USER_ACTIONS"
                label="Target user action"
                :placeholder="$t('form.placeholders.select')"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>

          <h4 class="card-title mt-3 mb-4">
            {{ $t('notifications.form.users') }}
          </h4>

          <div class="row px-0">
            <div class="col-12 col-xl-4 col-lg-4 col-md-6">
              <ui-select
                v-model="filter.staffTypes"
                :items="modal.staffTypes.items"
                label="Staff type"
                :placeholder="$t('form.placeholders.select')"
                class="notifications-edit-form__staff-types-control"
                multiple
                filterable
                collapse-tags
                hide-empty-dropdown
                hide-dropdown-loading
                input-icon="user"
                label-key="label"
                value-key="id"
                value-original
                :disabled="isFieldDisabled || !modal.locations.allItems.length"
                @focus="openModalStaffTypes"
              />
            </div>

            <div class="col-12 col-xl-4 col-lg-4 col-md-6">
              <ui-select
                v-model="filter.paymentSystems"
                :items="paymentSystems"
                label="Filter"
                :placeholder="$t('form.placeholders.select')"
                class="notifications-edit-form__filter-control"
                multiple
                filterable
                collapse-tags
                hide-empty-dropdown
                hide-dropdown-loading
                input-icon="filter"
                label-key="label"
                value-key="id"
                value-original
                :disabled="isFieldDisabled || !modal.locations.allItems.length"
                @focus="openModalPaymentSystems"
              />
            </div>
          </div>

          <div class="row flex-column notifications-edit-form__staffs">
            <span class="col-12 text-grey font-14 mb-3">
              To
            </span>
            <template v-if="staffList.length">
              <div class="col-12">
                <ui-checkbox
                  class="mb-3"
                  v-model="checkedAllStaffs"
                  :disabled="isFieldDisabled"
                >
                  All
                </ui-checkbox>
              </div>

              <div class="notifications-edit-form__staff-list">
                <div
                  v-for="item in staffList"
                  :key="item.id"
                  class="d-flex flex-column"
                >
                  <span
                    v-if="item.workplaceName"
                    class="notifications-edit-form__staff-list-name"
                  >
                    {{ item.workplaceName }}
                  </span>
                  <ui-checkbox
                    v-else
                    v-model="item.chosen"
                    @input="checkStaffByItem(item)"
                    :disabled="isFieldDisabled"
                  >
                    <span
                      v-if="item.user.firstName"
                      class="notifications-edit-form__staff-list-check"
                    >
                      {{ item.user.firstName }} {{ item.user.lastName }}
                    </span>
                    <span v-else>
                      {{ item.user.login }}
                    </span>
                  </ui-checkbox>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-12 text-grey font-16 notifications-edit-form__staffs_empty">
                No data, either try changing the filters, either select a location,<br>
                or there are no employees in the selected locations.
              </div>
            </template>
          </div>

          <div class="row px-0 mt-xl-4 mt-lg-4 mt-5">
            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-3 col-md-6"
              name="Schedule date"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-calendar-picker
                v-model="form.scheduleDate"
                label="Schedule date"
                placeholder="Select"
                :min="scheduleDateMin"
                format="dd.LL.yy"
                input-icon="calendar"
                no-direction
                required
                :error="errors[0]"
                :disabled="isFieldDisabled"
              />
            </validation-provider>

            <div class="d-flex col-12 col-xl-4 col-lg-5 col-md-6">
              <validation-provider
                tag="div"
                class="min-w-70 w-100"
                name="Hours"
                rules="required"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.scheduleHours"
                  :items="hoursList"
                  label="Hours"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="label"
                  value-key="id"
                  value-original
                  filterable
                  required
                  :error="errors[0]"
                  :disabled="isFieldDisabled"
                />
              </validation-provider>
              <span class="align-self-center text-grey mb-3 mx-4">:</span>
              <validation-provider
                tag="div"
                class="min-w-70 w-100"
                name="Minutes"
                rules="required"
                v-slot="{ errors }"
              >
                <ui-select
                  v-model="form.scheduleMinutes"
                  :items="minutesList"
                  label="Minutes"
                  :placeholder="$t('form.placeholders.select')"
                  label-key="label"
                  value-key="id"
                  value-original
                  filterable
                  required
                  :error="errors[0]"
                  :disabled="isFieldDisabled"
                />
              </validation-provider>
            </div>

            <validation-provider
              tag="div"
              class="col-12 col-xl-4 col-lg-3 col-md-6"
              name="User notification feed"
            >
              <ui-select
                v-model="form.userNotificationFeed"
                :items="feedList"
                label="User notification feed"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="id"
                filterable
                disabled
              />
            </validation-provider>
          </div>

          <div class="notifications-edit-form__footer">
            <ui-button
              action
              type="primary"
              :is-loading="updateButtonLoading"
              :disabled="updateButtonLoading || invalid || !selectedStaffList.length || isFieldDisabled"
              class="notifications-edit-form__submit"
              @click.prevent="handleSubmit(update)"
            >
              {{ $t('form.buttons.update') }}
            </ui-button>
            <div
              v-if="form.createdByName || form.createdAt"
              class="notifications-edit-form__author-info"
            >
              <div v-if="form.createdByName">Created by: {{ form.createdByName }}</div>
              <div v-if="form.createdAt">Created at: {{ formatDate(form.createdAt) }}</div>
            </div>
          </div>
        </div>
      </ui-card>
    </validation-observer>

    <!-- Locations Modal -->
    <notification-modal
      v-model="modal.locations.show"
      class="notifications-modal__location"
      title="Choose locations"
      :is-loading="modal.locations.loading.modal"
      @confirm="applyLocations"
      @scrolled-bottom="onModalLocationsScrolledBottom"
    >
      <template #control>
        <ui-input
          v-model="modal.locations.searchField"
          class="notifications-modal__location-control"
          :placeholder="$t('form.placeholders.locationNameOrId')"
          input-icon="search"
          bordered
          @keypress.enter="filterLocations"
        />
      </template>

      <template #body>
        <div
          v-if="modal.locations.allItems.length"
          class="notifications-modal__location-content"
        >
          <div class="notifications-modal__location-items">
            <ul>
              <li
                v-for="item in modal.locations.allItems"
                :key="item.id"
                class="notifications-modal__location-item"
              >
                <ui-checkbox
                  v-model="item.selected"
                  class="notifications-modal__location-checkbox"
                  inline
                >
                  <span class="notifications-modal__location-title">
                    <span>
                      {{ item.name }}
                    </span>
                    <span
                      v-if="item.payoutId"
                      class="text-grey"
                    >
                      &nbsp;(ID {{ item.payoutId }})
                    </span>
                  </span>
                </ui-checkbox>
              </li>
              <div
                v-if="modal.locations.loading.content"
                class="d-flex justify-content-center my-5"
              >
                <ui-loading inline />
              </div>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center mt-3 mb-5"
        >
          <ui-icon
            name="search-in-list"
            size="24"
            class="mb-2"
          />
          <p class="d-flex flex-column align-items-center text-grey text-center font-16">
            <span class="text-black mb-2">
              No data found
            </span>
            <span>
              There is no such location, <br>
              try changing the search field
            </span>
          </p>
        </div>
      </template>
    </notification-modal>

    <!-- Staff Types Modal -->
    <notification-modal
      v-model="modal.staffTypes.show"
      class="notifications-modal__staff-types"
      title="Choose staff type"
      @confirm="applyStaffTypes"
      @closed="closedModalStaffTypes"
    >
      <template #control>
        <ui-input
          v-model="modal.staffTypes.searchField"
          class="notifications-modal__staff-types-control"
          placeholder="Staff type"
          input-icon="search"
          bordered
          @keypress.enter="filterStaffTypes"
        />
      </template>

      <template #body>
        <div
          v-if="modal.staffTypes.items.length"
          class="notifications-modal__staff-types-content"
        >
          <div class="notifications-modal__staff-types-items">
            <ul>
              <li
                v-for="item in modal.staffTypes.items"
                :key="item.id"
                class="notifications-modal__staff-types-item"
              >
                <ui-checkbox
                  v-model="item.selected"
                  class="notifications-modal__staff-types-checkbox"
                  inline
                >
                  <span class="notifications-modal__staff-types-title">
                    {{ item.label }}
                  </span>
                </ui-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center mt-3 mb-5"
        >
          <ui-icon
            name="search-in-list"
            size="24"
            class="mb-2"
          />
          <p class="d-flex flex-column align-items-center text-grey text-center font-16">
            <span class="text-black mb-2">
              No data found
            </span>
            <span>
              There is no such employee type, <br>
              try changing the search field
            </span>
          </p>
        </div>
      </template>
    </notification-modal>

    <!-- Payment Systems Modal -->
    <notification-modal
      v-model="modal.paymentSystems.show"
      class="notifications-modal__paymentSystems"
      title="Choose filter"
      @confirm="applyPaymentSystems"
      @closed="closedModalPaymentSystems"
    >
      <template #control>
        <ui-input
          v-model="modal.paymentSystems.searchField"
          class="notifications-modal__payment-systems-control"
          placeholder="Search filter"
          input-icon="search"
          bordered
          @keypress.enter="filterPaymentSystems"
        />
      </template>

      <template #body>
        <div
          v-if="modal.paymentSystems.items.length"
          class="notifications-modal__payment-systems-content"
        >
          <div class="notifications-modal__payment-systems-items">
            <ul>
              <li
                v-for="item in modal.paymentSystems.items"
                :key="item.id"
                class="notifications-modal__payment-systems-item"
              >
                <ui-checkbox
                  v-model="item.selected"
                  class="notifications-modal__payment-systems-checkbox"
                  inline
                >
                  <span class="notifications-modal__payment-systems-title">
                    {{ item.label }}
                  </span>
                </ui-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center mt-3 mb-5"
        >
          <ui-icon
            name="search-in-list"
            size="24"
            class="mb-2"
          />
          <p class="d-flex flex-column align-items-center text-grey text-center font-16">
            <span class="text-black mb-2">
              No data found
            </span>
            <span>
              There is no such payment system type,  <br>
              try changing the search field
            </span>
          </p>
        </div>
      </template>
    </notification-modal>
  </div>
</template>

<script>
import { unionBy, uniqBy, flatten } from 'lodash';
import { DateTime } from 'luxon';
import api from '@/api';
import resetMixin from '@/mixins/reset-mixin';
import formatDate from '@/filters/date';
import NotificationModal from '@/components/Notification/NotificationModal.vue';
import { DEFAULT_TARGET_USER_ACTION, TARGET_USER_ACTIONS } from '@/config/targetUserActions';
import { PAYMENT_SYSTEM, PAYMENT_SYSTEM_LABEL } from '@/config/paymentSystems';

const MAX_TITLE_LENGTH = 30;
const MAX_MESSAGE_LENGTH = 160;

export default {
  name: 'NotificationEditForm',

  props: {
    notificationId: [String, Number],
  },

  components: {
    NotificationModal,
  },

  mixins: [
    resetMixin(() => ({
      modal: {
        locations: {
          show: false,
          loading: {
            modal: false,
            content: false,
          },
          searchField: '',
          searchData: null,
          allItems: [],
          selectedItems: [],
          loadedItems: [],
          page: 1,
          pageSize: 10,
          total: 0,
        },
        staffTypes: {
          show: false,
          searchField: '',
          items: [],
        },
        paymentSystems: {
          show: false,
          searchField: '',
          items: [],
        },
      },
      filter: {
        staffTypes: [],
        paymentSystems: [],
      },
    })),
  ],

  data() {
    return {
      formLoading: false,
      updateButtonLoading: false,
      form: {
        country: '',
        workplaces: [],
        topic: '',
        title: '',
        type: '',
        category: '',
        text: '',
        scheduleDate: DateTime.now().toJSDate(),
        scheduleHours: {},
        scheduleMinutes: {},
        userNotificationFeed: '',
        targetUserAction: DEFAULT_TARGET_USER_ACTION,
      },
      dirtyWorkplaces: [],
      staffList: [],
      scheduleDateMin: DateTime.now().minus({ day: 1 }).toJSDate(),
      checkedAllStaffs: false,
      MAX_TITLE_LENGTH,
      MAX_MESSAGE_LENGTH,
      TARGET_USER_ACTIONS,
    };
  },

  watch: {
    checkedAllStaffs: {
      immediate: false,
      handler(value) {
        const totalStaffCount = this.totalStaffList.length;
        const selectedStaffCount = this.selectedStaffList.length;

        if (value) {
          this.toggleCheckAllStaff(true);
        } else {
          if (selectedStaffCount < totalStaffCount) {
            return;
          }

          this.toggleCheckAllStaff(false);
        }
      },
    },
    selectedStaffList: {
      immediate: false,
      handler(value) {
        const totalStaffCount = this.totalStaffList.length;
        const selectedStaffCount = value.length;

        if (selectedStaffCount < totalStaffCount) {
          this.checkedAllStaffs = false;
        }

        if (selectedStaffCount === totalStaffCount) {
          this.checkedAllStaffs = true;
        }
      },
    },
  },

  mounted() {
    this.loadData();
  },

  computed: {
    timeZoneList() {
      return {
        gb: 'Europe/London',
        de: 'Europe/Berlin',
        us: 'America/New_York',
        ae: 'Asia/Dubai',
        ch: 'Europe/Zurich',
      };
    },
    isFieldDisabled() {
      return this.form.status === 'SENT';
    },
    locationsStart() {
      return (this.modal.locations.page - 1) * this.modal.locations.pageSize;
    },
    countryList() {
      return this.$getCountries();
    },
    notificationTypesList() {
      return [
        {
          id: 'PUSH',
          label: 'Push',
        },
        {
          id: 'SMS',
          label: 'SMS',
        },
      ];
    },
    categoryList() {
      return [
        {
          id: 'MARKETING',
          label: 'Marketing',
        },
        {
          id: 'KYC',
          label: 'KYC',
        },
        {
          id: 'REGISTRATION',
          label: 'Registration',
        },
        {
          id: 'PAYMENTS',
          label: 'Payments',
        },
      ];
    },
    paymentSystems() {
      return [
        {
          id: PAYMENT_SYSTEM.CHECKOUT_UAE,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UAE],
          selected: false,
        },
        {
          value: PAYMENT_SYSTEM.CHECKOUT,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT],
          selected: false,
        },
        {
          value: PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD],
          selected: false,
        },
        {
          id: PAYMENT_SYSTEM.STRIPE,
          label: PAYMENT_SYSTEM_LABEL[PAYMENT_SYSTEM.STRIPE],
          selected: false,
        },
      ];
    },
    staffTypes() {
      return this.$store.getters['handbook/staffTypes']
        .filter(({ countries }) => countries.includes(this.form.country))
        .filter(({ types }) => types.some((t) => this.currentLocationsTypes.includes(t)))
        .map((item) => ({
          id: item.value,
          label: item.name,
          types: item.types,
          selected: false,
        }));
    },
    hoursList() {
      const selectedDate = DateTime.fromJSDate(this.form.scheduleDate);
      const currentDate = this.currentDateByCountry(this.form.country);
      const currentHour = currentDate.get('hour');
      const currentMinute = currentDate.get('minute');

      const isToday = currentDate.toISODate() === selectedDate.toISODate();

      const hoursList = [];
      const endHour = 23;
      let startHour = 0;

      if (isToday && this.form.status === 'DRAFT') {
        if (currentHour === 0) {
          this.form.scheduleDate = selectedDate.plus({ day: 1 }).toJSDate();
          this.form.scheduleHours = { id: '00', label: '00' };
          this.form.scheduleMinutes = { id: '00', label: '00' };
        } else {
          this.form.scheduleHours = {
            id: this.getTwoDigitValue(currentHour),
            label: this.getTwoDigitValue(currentHour),
          };

          this.form.scheduleMinutes = {
            id: this.getTwoDigitValue(currentMinute),
            label: this.getTwoDigitValue(currentMinute),
          };
        }
      }

      while (startHour <= endHour) {
        hoursList.push({
          id: this.getTwoDigitValue(startHour),
          label: this.getTwoDigitValue(startHour),
          disabled: isToday
            ? currentHour > startHour
            : false,
        });

        startHour += 1;
      }

      return hoursList;
    },
    minutesList() {
      const selectedDate = DateTime.fromJSDate(this.form.scheduleDate);
      const currentDate = this.currentDateByCountry(this.form.country);
      const isToday = currentDate.toISODate() === selectedDate.toISODate();
      const selectedHour = +this.form.scheduleHours.id;
      const currentHour = currentDate.get('hour');
      const currentMinute = currentDate.get('minute');

      const minutesList = [];
      const endMinute = 59;
      let startMinute = 0;

      if (isToday && selectedHour === currentHour) {
        this.form.scheduleMinutes = {
          id: this.getTwoDigitValue(currentMinute),
          label: this.getTwoDigitValue(currentMinute),
        };
      }

      while (startMinute <= endMinute) {
        minutesList.push({
          id: this.getTwoDigitValue(startMinute),
          label: this.getTwoDigitValue(startMinute),
          disabled: isToday && selectedHour === currentHour
            ? currentMinute > startMinute
            : false,
        });

        startMinute += 1;
      }

      return minutesList;
    },
    feedList() {
      return [
        {
          id: 'SHOW',
          label: 'Show',
        },
        {
          id: 'HIDE',
          label: 'Hide',
        },
      ];
    },
    updateApiConfig() {
      const {
        scheduleDate,
        scheduleHours,
        scheduleMinutes,
        ...apiData
      } = this.form;

      const sDate = DateTime.fromJSDate(scheduleDate).toISODate();
      const sTime = `T${scheduleHours.label}:${scheduleMinutes.label}:00`;

      return {
        id: this.notificationId,
        scheduleDate: sDate.concat(sTime),
        ...apiData,
      };
    },
    locationsApiConfig() {
      return {
        country: this.form.country,
        start: this.locationsStart,
        pageSize: this.modal.locations.pageSize,
        ...this.modal.locations.searchData,
      };
    },
    totalStaffList() {
      return this.staffList
        .filter((item) => item?.staffId);
    },
    selectedStaffList() {
      return this.totalStaffList
        .filter(({ chosen }) => chosen);
    },
    currentLocationsTypes() {
      return uniqBy(this.form.workplaces.map(({ type }) => type));
    },
  },

  methods: {
    formatDate,
    currentDateByCountry(country) {
      return DateTime.now().setZone(this.timeZoneList[country]).plus({ minute: 10 });
    },
    titleSelectEmoji(emoji) {
      if (!this.form.title) {
        this.form.title = '';
      }

      this.form.title += emoji;
    },
    textSelectEmoji(emoji) {
      if (!this.form.text) {
        this.form.text = '';
      }

      this.form.text += emoji;
    },
    getTwoDigitValue(value) {
      return value.toString().padStart(2, '0');
    },
    lower(str) {
      return str.toLowerCase();
    },
    changeCountry(e) {
      this.reset();
      this.form.workplaces.splice(0);
      this.staffList.splice(0);

      const {
        sDate,
        sHours,
        sMinutes,
      } = this.calcDateTime(null, e);

      this.form = {
        ...this.form,
        scheduleDate: sDate,
        scheduleHours: sHours,
        scheduleMinutes: sMinutes,
      };
    },
    openModalLocations(e) {
      e.target.blur();

      if (!this.modal.locations.loadedItems.length) {
        this.loadLocations({
          append: true,
          entity: 'modal',
        });
      }

      this.modal.locations.show = true;
    },
    onModalLocationsScrolledBottom() {
      const modal = this.modal.locations;

      const totalPages = modal.total / modal.pageSize;
      const isEnoughItems = modal.total >= modal.pageSize;
      const isOutOfItems = modal.total === modal.loadedItems.length;

      if (modal.page >= totalPages || !isEnoughItems || isOutOfItems) {
        return;
      }

      modal.page += 1;
      this.loadLocations({
        append: true,
        entity: 'content',
      });
    },
    async applyLocations() {
      const workplaces = this.modal.locations.allItems
        .filter(({ selected }) => selected)
        .map((item) => {
          const { selected, ...fields } = item;

          return fields;
        });

      await this.update({
        showMessage: false,
        showLoading: false,
        redirectBackToList: false,
        workplaces,
      });
    },
    filterLocations() {
      const modal = this.modal.locations;
      const query = modal.searchField;

      if (!query) {
        modal.searchData = null;
      } else if (!Number.isNaN(+query)) {
        modal.searchData = {
          payoutId: query,
        };
      } else {
        modal.searchData = {
          locationName: query,
        };
      }

      modal.page = 1;
      this.loadLocations({
        append: false,
        query: modal.searchData,
        entity: 'modal',
      });
    },
    openModalStaffTypes(e) {
      e.target.blur();

      this.modal.staffTypes.searchField = '';
      this.modal.staffTypes.items = this.staffTypes;
      this.modal.staffTypes.show = true;
    },
    filterStaffTypes() {
      const {
        items,
        searchField,
      } = this.modal.staffTypes;

      const selectedStaffTypes = items
        .filter(({ selected }) => selected);

      const filteredStaffTypes = this.staffTypes
        .filter(({ label }) => this.lower(label).includes(this.lower(searchField)));

      this.modal.staffTypes.items = unionBy(selectedStaffTypes, filteredStaffTypes);
    },
    applyStaffTypes() {
      this.filter.staffTypes = this.modal.staffTypes.items
        .filter(({ selected }) => selected);

      this.filterStaffList();
    },
    closedModalStaffTypes() {
      const selectedStaffTypes = this.modal.staffTypes.items
        .filter(({ selected }) => selected);

      if (!selectedStaffTypes.length) {
        this.filter.staffTypes.splice(0);

        this.filterStaffList();
      }
    },
    filterStaffList() {
      const staffTypes = this.filter.staffTypes
        .map(({ id }) => id);
      const paymentSystems = this.filter.paymentSystems
        .map(({ id }) => id);

      this.toggleCheckAllStaff(false);

      if (staffTypes.length && !paymentSystems.length) {
        this.staffList = this.getFilteredStaffs(this.dirtyWorkplaces, ({ staffs, ...fields }) => ({
          ...fields,
          staffs: staffs.filter(({ staffType }) => {
            return staffTypes.includes(staffType);
          }),
        }));
      } else if (!staffTypes.length && paymentSystems.length) {
        this.staffList = this.getFilteredStaffs(this.dirtyWorkplaces, ({ staffs, ...fields }) => ({
          ...fields,
          staffs: staffs.filter(({ user: { paymentSystem } }) => {
            return paymentSystems.includes(paymentSystem);
          }),
        }));
      } else if (staffTypes.length && paymentSystems.length) {
        this.staffList = this.getFilteredStaffs(this.dirtyWorkplaces, ({ staffs, ...fields }) => ({
          ...fields,
          staffs: staffs.filter(({ staffType, user: { paymentSystem } }) => {
            return staffTypes.includes(staffType) && paymentSystems.includes(paymentSystem);
          }),
        }));
      } else {
        this.staffList = this.getFilteredStaffs(this.dirtyWorkplaces);
      }
    },
    openModalPaymentSystems(e) {
      e.target.blur();

      this.modal.paymentSystems.searchField = '';
      this.modal.paymentSystems.items = this.paymentSystems;
      this.modal.paymentSystems.show = true;
    },
    filterPaymentSystems() {
      const {
        items,
        searchField,
      } = this.modal.paymentSystems;

      const selectedPymentSystems = items
        .filter(({ selected }) => selected);

      const filteredPaymentSystems = this.paymentSystems
        .filter(({ label }) => this.lower(label).includes(this.lower(searchField)));

      this.modal.paymentSystems.items = unionBy(selectedPymentSystems, filteredPaymentSystems);
    },
    applyPaymentSystems() {
      this.filter.paymentSystems = this.modal.paymentSystems.items
        .filter(({ selected }) => selected);

      this.filterStaffList();
    },
    closedModalPaymentSystems() {
      const selectedPaymentSystems = this.modal.paymentSystems.items
        .filter(({ selected }) => selected);

      if (!selectedPaymentSystems.length) {
        this.filter.paymentSystems.splice(0);

        this.filterStaffList();
      }
    },
    getFilteredStaffs(items, callbackMap = (values) => values) {
      return flatten(items
        .map((values) => callbackMap(values))
        .filter(({ staffs }) => staffs.length)
        .map((item) => ([
          {
            workplaceId: item.id,
            workplaceName: item.name,
          },
          ...item.staffs,
        ])));
    },
    checkStaffByItem(item) {
      this.form.workplaces
        .filter(({ staffs }) => staffs.length)
        .forEach((workplace) => {
          if (workplace.id === item.workplaceId) {
            workplace.staffs.forEach((staff) => {
              if (staff.staffId === item.staffId) {
                staff.chosen = item.chosen;
              }
            });
          }
        });
    },
    toggleCheckAllStaff(status) {
      this.form.workplaces
        .filter(({ staffs }) => staffs.length)
        .forEach((workplace) => {
          workplace.staffs.forEach((staff) => {
            if (this.selectedStaffList.includes(staff.staffId) && this.selectedStaffList.length) {
              staff.chosen = status;
            } else if (!this.selectedStaffList.length) {
              staff.chosen = status;
            }

            staff.chosen = false;
          });
        });

      this.staffList.forEach((item) => {
        item.chosen = status;
      });
    },
    calcDateTime(date, country) {
      const currentDate = this.currentDateByCountry(country);

      let sDate = currentDate.toJSDate();
      let sHours = {
        id: this.getTwoDigitValue(currentDate.get('hour')),
        label: this.getTwoDigitValue(currentDate.get('hour')),
      };
      let sMinutes = {
        id: this.getTwoDigitValue(currentDate.get('minute')),
        label: this.getTwoDigitValue(currentDate.get('minute')),
      };

      if (date) {
        const dateTime = DateTime.fromISO(date);

        sDate = dateTime.toJSDate();
        sHours = {
          id: this.getTwoDigitValue(dateTime.get('hour')),
          label: this.getTwoDigitValue(dateTime.get('hour')),
        };
        sMinutes = {
          id: this.getTwoDigitValue(dateTime.get('minute')),
          label: this.getTwoDigitValue(dateTime.get('minute')),
        };
      }

      return {
        sDate,
        sHours,
        sMinutes,
      };
    },
    setData(data) {
      const {
        scheduleDate,
        workplaces,
        ...itemData
      } = data;

      const actualScheduleDate = data.status === 'DRAFT'
        ? this.currentDateByCountry(data.country)
        : scheduleDate;

      const {
        sDate,
        sHours,
        sMinutes,
      } = this.calcDateTime(actualScheduleDate, data.country);

      const formData = {
        ...itemData,
        scheduleDate: sDate,
        scheduleHours: sHours,
        scheduleMinutes: sMinutes,
        type: data.type || 'PUSH',
        workplaces,
      };

      const locations = workplaces
        .map((item) => {
          const { staffs, ...fields } = item;

          return {
            ...fields,
            selected: true,
          };
        });

      this.form = formData;
      this.modal.locations.allItems = locations;
      this.staffList = this.getFilteredStaffs(workplaces);
      this.dirtyWorkplaces = workplaces;
    },
    setLocationData(items, append) {
      const data = items.map((item) => ({
        ...item,
        selected: false,
      }));

      const selectedLocations = this.modal.locations.allItems
        .filter(({ selected }) => selected);

      if (append) {
        this.modal.locations.loadedItems.push(...data);
      } else {
        this.modal.locations.loadedItems = data;
      }

      this.modal.locations.allItems = unionBy(selectedLocations, this.modal.locations.loadedItems, 'id');
    },
    async loadLocations(config) {
      this.modal.locations.loading[config.entity] = true;

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.notifications.getLocations({
          ...this.locationsApiConfig,
          ...config?.query,
        });

        if (data) {
          this.modal.locations.total = totalRecords;
          this.setLocationData(data, config.append);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.modal.locations.loading[config.entity] = false;
      }
    },
    async loadData() {
      if (this.formLoading || !this.notificationId) {
        return;
      }

      this.formLoading = true;

      try {
        const {
          data,
        } = await api.notifications.getNotification({
          notificationId: this.notificationId,
        });

        if (data) {
          this.setData(data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.formLoading = false;
      }
    },
    async update(optionalFields = {}) {
      const {
        showMessage = true,
        showLoading = true,
        redirectBackToList = true,
        ...apiFields
      } = optionalFields;

      if (this.updateButtonLoading) {
        return;
      }

      if (showLoading) {
        this.updateButtonLoading = true;
      }

      try {
        const {
          data,
        } = await api.notifications.updateNotification({
          ...this.updateApiConfig,
          ...apiFields,
        });

        if (data) {
          this.setData(data);
        }

        if (showMessage) {
          this.$showSuccess();
        }

        if (redirectBackToList) {
          this.$router.push({ name: 'notifications' });
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        if (showLoading) {
          this.updateButtonLoading = false;
        }
      }
    },
  },
};
</script>
