<template>
  <ui-card>
    <template #header>
      <h4 class="card-title">
        {{ $t('staffHours.title') }}
      </h4>
    </template>

    <div class="row">
      <div class="col-12 col-md-8 border-right pr-3">
        <ui-calendar-range
          v-protected
          :value="range"
          :disabled-date="disabledDate"
          single-day
          v-bind="$attrs"
          @input="$emit('update:range', $event)"
        />
      </div>
      <div class="col-12 col-md-4 pl-4">
        <staff-hours-members
          v-if="range"
          v-protected
          :range="range"
          :staffs="staffs"
          :location="location"
          :staff-hours="staffHours"
          :is-loading="isLoading"
          :disabled="disabled"
          v-on="$listeners"
        />
      </div>
    </div>

    <ui-button
      v-protected
      type="primary"
      class="mt-4"
      action
      :is-loading="isLoading"
      :disabled="disabled || isLoading || !staffHours.length"
      @click="$emit('calculate')"
    >
      {{ $t('form.buttons.calculate') }}
    </ui-button>
  </ui-card>
</template>

<script>
import StaffHoursMembers from './StaffHoursMembers.vue';

export default {

  components: {
    StaffHoursMembers,
  },

  props: {
    range: Array,
    isLoading: Boolean,
    disabled: Boolean,
    location: Object,
    staffs: {
      type: Array,
      default: () => [],
    },
    staffHours: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    disabledDate() {
      if (this.range) {
        return undefined;
      }

      return () => true;
    },
  },
};
</script>
