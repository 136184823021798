<template>
  <ui-modal v-bind="$attrs" v-on="$listeners">
    <template #activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <template #title>
      Restricted soon accounts
    </template>

    <div
      v-for="item in accounts"
      :key="item.id"
      class="d-flex justify-content-between align-items-center mt-4"
    >
      <router-link
        :to="{ name: 'location-view', params: { locationId: item.id }}"
        class="card-label"
      >
        {{ item.fio }}
      </router-link>
    </div>
  </ui-modal>
</template>

<script>
export default {

  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },
};
</script>
