<template>
  <div class="col-12 col-md-8 col-lg-6 col-xl-5">
    <validation-provider
      tag="div"
      name="Landing type"
      rules="required"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-select
        v-model="form.landingType"
        @input="onLandingTypeChange"
        :items="landingTypes"
        label="Landing type"
        :placeholder="$t('form.placeholders.select')"
        label-key="label"
        value-key="value"
        filterable
        required
        :disabled="Boolean(form.id)"
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      tag="div"
      name="Comission"
      rules="required|decimal|min_value:1|max_value:100"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-input
        v-model="form.feePlan"
        label="Comission, %"
        required
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      tag="div"
      :name="$t('form.labels.promoPeriod')"
      rules="numeric"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-input
        :value="form.promoPeriod"
        @input="form.promoPeriod = castInt($event)"
        :label="$t('form.labels.promoPeriod')"
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      v-if="form.landingType === 'INDIVIDUAL'"
      tag="div"
      name="Tip distributions"
      rules="required"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-select
        v-model="form.tipDistributions"
        @input="onTipDistributionsChange"
        :items="tipDistributions"
        label="Tip distributions"
        :placeholder="$t('form.placeholders.select')"
        label-key="label"
        value-key="value"
        filterable
        required
        :disabled="Boolean(form.id)"
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      v-if="form.tipDistributions === 'COMMON_ADVANCED'"
      tag="div"
      name="Tipout from"
      rules="required"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-select
        v-model="form.primaryStaff"
        :items="staffTypes"
        label="Tipout from"
        :placeholder="$t('form.placeholders.select')"
        label-key="label"
        value-key="value"
        filterable
        required
        :disabled="Boolean(form.id)"
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      v-if="form.primaryStaff"
      tag="div"
      name="From comission"
      rules="required|decimal|min_value:1|max_value:100"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-input
        v-model="form.primaryPercentage"
        label="From comission, %"
        required
        :error="errors[0]"
      />
    </validation-provider>

    <validation-provider
      v-if="form.tipDistributions === 'COMMON_SIMPLE' || form.tipDistributions === 'COMMON_ADVANCED'"
      tag="div"
      name="Distributions frequency"
      rules="required"
      mode="passive"
      v-slot="{ errors }"
    >
      <ui-select
        v-model="form.distributionType"
        :items="distributions"
        label="Distribution frequency"
        :placeholder="$t('form.placeholders.select')"
        label-key="label"
        value-key="value"
        filterable
        required
        :disabled="Boolean(form.id)"
        :error="errors[0]"
      />
    </validation-provider>
  </div>
</template>

<script>
/* eslint-disable func-names */
import { mapActions } from 'vuex';

export default {

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    distributions: [
      {
        value: 'DAILY',
        label: 'Daily',
      },
      {
        value: 'WEEKLY',
        label: 'Weekly',
      },
    ],
    landingTypes: [
      {
        value: 'INDIVIDUAL',
        label: 'Individual',
      },
      {
        value: 'LIST',
        label: 'List',
      },
    ],
    tipDistributions: [
      {
        value: 'INDIVIDUAL',
        label: 'Individual',
      },
      {
        value: 'COMMON_SIMPLE',
        label: 'Common simple',
      },
      {
        value: 'COMMON_ADVANCED',
        label: 'Common advanced',
      },
    ],
  }),

  computed: {
    staffTypes() {
      return this.$store.getters['handbook/staffTypes']
        .filter(({ countries }) => countries.includes(this.form.country));
    },
  },

  watch: {
    form: {
      immediate: true,
      handler() {
        this.form.tipsType = this.calculateTipsType();

        if (this.form.type === 'BEAUTY_SALON') {
          this.form.landingType = 'LIST';
        } else if (this.form.type === 'RESTAURANT'
          || this.form.type === 'DELIVERY') {
          if (this.form.tipsType === 'PERSONAL') {
            this.form.landingType = 'INDIVIDUAL';
            this.form.tipDistributions = 'COMMON_SIMPLE';
          } else if (this.form.tipsType === 'COMMON_PRIORITY') {
            this.form.landingType = 'INDIVIDUAL';
            this.form.tipDistributions = 'COMMON_ADVANCED';
          } else if (this.form.tipsType === 'COMMON') {
            this.form.landingType = 'INDIVIDUAL';
            this.form.tipDistributions = 'COMMON_SIMPLE';
          }
        }
      },
    },
    'form.country': {
      immediate: true,
      async handler(value) {
        if (value) {
          try {
            await this.getStaffTypes();
          } catch (e) {
            this.$showServerError(e);
          }
        }
      },
    },
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    onLandingTypeChange(landingType) {
      this.form.type = landingType === 'LIST' ? 'BEAUTY_SALON' : 'RESTAURANT';
      this.form.tipsType = this.calculateTipsType();
      this.form.tipDistributions = null;
      this.form.primaryStaff = null;
      this.form.primaryPercentage = null;
    },
    onTipDistributionsChange() {
      this.form.tipsType = this.calculateTipsType();
      this.form.primaryStaff = null;
      this.form.primaryPercentage = null;
    },
    calculateTipsType() {
      if (this.form.type === 'BEAUTY_SALON') {
        return 'PERSONAL';
      }

      if (this.form.landingType === 'LIST') {
        return 'PERSONAL';
      }

      if (this.form.tipDistributions === 'COMMON_SIMPLE') {
        return 'COMMON';
      }

      if (this.form.tipDistributions === 'COMMON_ADVANCED') {
        return 'COMMON_PRIORITY';
      }

      return 'PERSONAL';
    },
  },
};
</script>
