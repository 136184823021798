<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="upload-modal"
    :is-loading="loading"
    @closed="onClosed"
    @before-open="beforeOpen"
  >
    <template #title>
      <div class="upload-modal__title">
        No Logo found
      </div>
    </template>

    <div class="d-flex flex-column align-items-between">
      <ui-alert
        v-if="imageSizeExceeded"
        class="mb-4"
        title="The file is too big!"
        :message="`This file is ${imageSize} in size, Please try again.`"
        icon="warning"
        type="danger"
      />
      <p class="upload-modal__text">
        We don't have an avatar (Logo) of your venue.
        Please upload it here to proceed
      </p>
      <ui-avatar
        :image="imageUrl"
        :title="$t('UI.avatar.locationTitle')"
        class="my-4"
        ref="upload"
        @save="cropImage"
      />
    </div>

    <template #footer="{ close }">
      <div class="w-100">
        <div class="row justify-content-center">
          <div class="col-7">
            <ui-button
              v-if="isChooseFile"
              round
              type="primary"
              native-type="button"
              class="upload-modal__btn-upload"
              @click="selectImage"
            >
              Choose file
            </ui-button>
            <ui-button
              v-else
              round
              type="primary"
              native-type="button"
              class="upload-modal__btn-upload"
              @click="uploadImage(close)"
            >
              Upload avatar
            </ui-button>
          </div>
          <div class="col-5">
            <ui-button
              round
              outline
              small
              type="default"
              native-type="button"
              class="upload-modal__btn-cancel"
              @click="close"
            >
              Cancel
            </ui-button>
          </div>
        </div>
      </div>
    </template>
  </ui-modal>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/api';
import ResetMixin from '@/mixins/reset-mixin';
import formatBytes from '@/utils/formatBytes';

export default {

  model: {
    prop: 'show',
    event: 'input',
  },

  mixins: [
    ResetMixin(() => ({
      show: false,
      loading: false,
      imageFile: null,
      imageUrl: null,
      imageSize: null,
      imageSizeExceeded: false,
    })),
  ],

  props: {
    locationImage: String,
    locationId: [String, Number],
  },

  computed: {
    isChooseFile() {
      return !this.imageUrl;
    },
  },

  methods: {
    ...mapActions('user', {
      loadLocations: 'loadLocationList',
    }),
    onClosed() {
      this.reset();
    },
    beforeOpen() {
      this.imageUrl = this.locationImage;
    },
    selectImage() {
      this.$refs.upload.openSelectFile();
    },
    checkImageSize(size) {
      const imageSize = formatBytes(size)[0];
      const imageExt = formatBytes(size)[1];

      if (imageExt === 'MB' && imageSize > 1) {
        this.imageSize = `${imageSize} ${imageExt}`;
        this.imageSizeExceeded = true;

        return false;
      }

      this.imageSize = null;
      this.imageSizeExceeded = false;
      return true;
    },
    cropImage(data) {
      if (!this.checkImageSize(data.size)) {
        return;
      }

      this.imageFile = data;
      this.imageUrl = URL.createObjectURL(data);
    },
    async uploadImage(close) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await api.locations.uploadImage({
          locationId: this.locationId,
          file: this.imageFile,
        });

        await this.loadLocations();

        close();
        this.$emit('upload');
      } catch (err) {
        this.$showServerError();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
