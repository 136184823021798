<template>
  <ui-card class="select-sublocation-card">
    {{ $t('sublocation.selectAlert') }}
  </ui-card>
</template>

<script>
export default {
  name: 'SelectSublocationCard',
};
</script>
