<template>
  <div
    class="col-xl-6 col-lg-6 col-12"
    :class="`${lastReviews.length ? 'pb-4' : 'pb-0'}`"
  >
    <ui-card
      v-if="lastReviews.length"
      v-bind="$attrs"
      :is-loading="isLoading"
      class="last-reviews my-n1 h-100"
    >
      <template #header>
        <h4 class="card-title">
          {{ $t('reviews.lastReviewsTitle') }}
        </h4>
      </template>

      <div class="last-reviews__list">
        <div
          v-for="(item, index) in previewLastReviews"
          :key="`${review.message}-${index}`"
          class="last-reviews__review"
          :class="{ 'mt-0': index < 1 }"
        >
          <div class="last-reviews__tools">
            <div class="last-reviews__stars">
              <ui-stars
                :value="item.stars"
                size="16"
              />
            </div>
            <div
              v-if="showSocialMediaButton"
              v-protected
              class="last-reviews__link"
              @click="openPictureModal(item)"
            >
              <ui-icon
                size="20"
                name="instagram"
                class="mr-1"
              />
              <ui-button
                type="primary"
                link
              >
                Create a post
              </ui-button>
            </div>
          </div>
          <p class="last-reviews__message text-nowrap">
            {{ item.message }}
          </p>
          <div class="last-reviews__cards">
            <ui-badge
              v-for="itemCard in item.cards"
              :key="itemCard"
              type="default"
            >
              {{ itemCard }}
            </ui-badge>
          </div>
        </div>
      </div>

      <template #footer>
        <stats-last-reviews-modal
          v-if="lastReviews.length > previewCount"
          :reviews="lastReviews"
          @create-picture="openPictureModal"
          @opened="onLastReviewsModalOpen"
        >
          <template #activator="{ open }">
            <ui-button
              link
              type="primary"
              @click="open"
            >
              {{ $t('form.buttons.viewAll') }}
            </ui-button>
          </template>
        </stats-last-reviews-modal>
      </template>
    </ui-card>

    <stats-last-reviews-picture-modal
      v-model="showPictureModal"
      :review="review"
      :location-image="image"
      @opened="onPictureModalOpen"
    />

    <stats-last-reviews-logo-modal
      v-model="showLogoModal"
      :location-image="image"
      :location-id="locationId"
      @upload="handleLogoUpload"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import api from '@/api';
import socialMediaReviewTypes from '@/config/socialMediaReviewTypes';
import StatsLastReviewsModal from './StatsLastReviewsModal.vue';
import StatsLastReviewsPictureModal from './StatsLastReviewsPictureModal.vue';
import StatsLastReviewsLogoModal from './StatsLastReviewsLogoModal.vue';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  components: {
    StatsLastReviewsModal,
    StatsLastReviewsPictureModal,
    StatsLastReviewsLogoModal,
  },

  props: {
    groupId: [Number, String],
  },

  data: () => {
    return {
      review: {},
      showPictureModal: false,
      showLogoModal: false,
      lastReviews: [],
      previewCount: 2,
      image: null,
    };
  },

  computed: {
    ...mapState({
      locations: (state) => state.user.locations,
      location: (state) => state.user.location,
      userRoles: (state) => state.auth.roles,
      viewAsMode: (state) => state.auth.viewAs,
    }),
    apiConfig() {
      return {
        locationId: this.locationId,
        groupId: this.groupId || undefined,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      };
    },
    previewLastReviews() {
      return this.lastReviews.slice(0, this.previewCount);
    },
    currentLocationImage() {
      const location = this.locations.find((item) => Number(item.id) === Number(this.locationId));

      return location?.image;
    },
    showSocialMediaButton() {
      return socialMediaReviewTypes.includes(this.location.typeOfReview);
    },
  },

  methods: {
    onLastReviewsModalOpen() {
      if (this.viewAsMode === null) {
        this.$amplitude.event('MODAL_OPEN', {
          userRoles: this.userRoles,
          modalType: 'REVIEWS_LATEST',
        });
      }
    },
    onPictureModalOpen() {
      if (this.viewAsMode === null) {
        this.$amplitude.event('MODAL_OPEN', {
          userRoles: this.userRoles,
          modalType: 'REVIEWS_GENERATE_POST',
        });
      }
    },
    handleLogoUpload() {
      this.openPictureModal(this.review);
    },
    openPictureModal(review) {
      if (this.currentLocationImage) {
        this.image = this.currentLocationImage;
      }

      this.review = review;

      if (this.image) {
        this.showPictureModal = true;
      } else {
        this.showLogoModal = true;
      }
    },
    async load() {
      let { data } = await api.locations.getLastReviews(this.apiConfig);

      data = data.map(({ createdDate, ...fields }) => ({
        ...fields,
        time: DateTime.fromISO(createdDate).toLocaleString(DateTime.TIME_SIMPLE),
        date: DateTime.fromISO(createdDate).toFormat('dd.LL.yyyy'),
      }));

      this.lastReviews = data.filter(({ message }) => message);
    },
  },
};
</script>
