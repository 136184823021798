<template>
  <div>
    <promotion-form
      @completed="onCompleted"
    />
    <promotion-list
      :reload.sync="reload"
      @update="onUpdate"
    />
  </div>
</template>

<script>
import PromotionForm from '@/components/Promotion/PromotionForm.vue';
import PromotionList from '@/components/Promotion/PromotionList.vue';

export default {

  components: {
    PromotionForm,
    PromotionList,
  },

  data() {
    return {
      reload: false,
    };
  },

  methods: {
    onCompleted() {
      this.reload = true;
    },
    onUpdate(route) {
      this.$router.push({
        ...route,
      }, () => {});
    },
  },
};
</script>
