export const MAIN_TAX_REPORTING_TYPES = [
  {
    value: 'SELF_REPORTING',
    label: 'Self reporting',
  },
  {
    value: 'EMPLOYER',
    label: 'Employer reporting',
  },
];

export const ALL_TAX_REPORTING_TYPES = [
  {
    value: 'SELF_REPORTING',
    label: 'Self reporting',
  },
  {
    value: 'EMPLOYER',
    label: 'Employer reporting',
  },
  {
    value: 'MIX_REPORTING',
    label: 'Mix reporting',
  },
];
