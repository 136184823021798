<template>
  <div class="location-sublocations">
    <div class="location-sublocations__list">
      <div
        v-for="sublocation in sublocations"
        :key="sublocation.id"
        class="location-sublocations__sublocation"
      >
        <div class="location-sublocations__sublocation_left">
          <ui-icon-button
            name="qr"
            size="16px"
            class="location-sublocations__qr-code"
            @click="openQr(sublocation.id)"
          />
          <div
            v-if="sublocation.name"
            class="location-sublocations__name"
            @click="goToSublocation(sublocation.id)"
          >
            <span>{{ sublocation.name }}</span>
            <span
              v-if="sublocation.subLocationType"
              class="location-sublocations__type"
            >
              ({{ $t(`sublocation.types.${sublocation.subLocationType}`) }})
            </span>
          </div>
        </div>
        <div>
          <ui-action-down
            :items="notificationActions"
            @select="handleAction($event, sublocation)"
          />
        </div>
      </div>
    </div>
    <ui-button
      native-type="button"
      outline
      type="primary"
      size="sm"
      class="location-sublocations__add-btn"
      @click="openSulocationModal"
    >
      {{ $t('sublocation.button.add') }}
    </ui-button>

    <location-sublocation-modal
      v-model="showSublocationModal"
      :location="location"
      :title="$t('sublocation.addSublocation')"
      :sublocations="sublocations"
      :current-sublocation="currentSublocation"
      @closed="clearCurrentSublocation"
    />

    <qr-code
      :show="Boolean(qrIdToShow)"
      :location-id="qrIdToShow"
      @closed="closeQr"
    />
  </div>
</template>

<script>
import LocationSublocationModal from '@/components/Location/LocationSublocationModal.vue';
import QrCode from '@/components/QrCode.vue';

export default {
  name: 'LocationSublocations',
  components: {
    QrCode,
    LocationSublocationModal,
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
    sublocations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showSublocationModal: false,
      currentSublocation: null,
      qrIdToShow: null,
      notificationActions: [
        {
          id: 'edit',
          label: 'Edit',
        },
        {
          id: 'remove',
          label: 'Remove',
        },
      ],
    };
  },

  methods: {
    openSulocationModal() {
      this.showSublocationModal = true;
    },
    handleAction(event, item) {
      switch (event) {
        case 'edit':
          this.editSublocation(item);
          break;

        case 'remove':
          this.removeSublocation(item);
          break;

        default:
          break;
      }
    },
    editSublocation(item) {
      this.currentSublocation = item;
      this.showSublocationModal = true;
    },
    removeSublocation(item) {
      const removingIndex = this.sublocations.findIndex((sublocation) => sublocation.id === item.id);
      this.sublocations.splice(removingIndex, 1);
    },
    clearCurrentSublocation() {
      this.currentSublocation = null;
    },
    goToSublocation(sulocationId) {
      this.$router.push({
        name: 'location-view',
        params: { locationId: sulocationId },
      });
    },
    openQr(sublocationId) {
      this.qrIdToShow = sublocationId;
    },
    closeQr() {
      this.qrIdToShow = null;
    },
  },
};
</script>
