export default {
  gb: {
    DELIVERY: 500,
    HOTEL: 500,
    RESTAURANT: 500,
    SPA: 500,
    BEAUTY_SALON: 500,
  },
  ae: {
    DELIVERY: 1000,
    HOTEL: 1000,
    RESTAURANT: 1000,
    SPA: 1000,
    BEAUTY_SALON: 1000,
  },
};
