<template>
  <div
    class="location-service-icon"
    :class="{
      'location-service-icon_active': isActive,
    }"
  >
    <div class="location-service-icon__image">
      <img :src="icon" :alt="label" />
    </div>
    <div class="location-service-icon__label">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {

  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isActive: Boolean,
  },
};
</script>
