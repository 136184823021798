<template>
  <div class="review">
    <div class="app-title app-title_secondary">
      {{ $t('manageReviews.title') }}
    </div>

    <ui-card tag="form" :is-loading="loading.data">
      <template #header>
        <h3 class="review__subtitle">
          {{ $t('manageReviews.subtitle') }}
        </h3>
      </template>

      <div class="row">
        <div
          v-for="item in visibleItems"
          v-protected
          :key="item.id"
          class="col-auto review__item"
          @click="toggleActive(item)"
        >
          <div class="d-flex h-100 flex-column justify-content-end align-items-center px-1">
            <review-icon
              :name="item.name"
              :location-type="item.workPlaceType"
              size="50px"
            />
            <div
              class="text-center mt-3"
              :class="{ 'border-danger border-bottom': isActive(item) }"
              :style="{ 'border-width': isActive(item) ? '3px!important' : 0 }"
            >{{ $t(item.name, location.country) }}</div>
          </div>
        </div>
      </div>

      <ui-button
        v-protected
        action
        type="primary"
        :is-loading="loading.form"
        :disabled="isDisabled"
        class="mt-4"
        @click.prevent="save"
      >
        {{ $t('form.buttons.save') }}
      </ui-button>
    </ui-card>
  </div>
</template>

<script>
import api from '@/api';
import reviewsCount from '@/config/reviewsCount';
import ReviewIcon from '@/components/ReviewIcon.vue';

export default {

  components: {
    ReviewIcon,
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: {
      data: false,
      form: false,
    },
    items: [],
  }),

  computed: {
    visibleItems() {
      return this.items.filter(({ type, workPlaceType }) => {
        return type === 'COMPLIMENT' && workPlaceType === this.location?.type;
      });
    },
    selectedItems() {
      return this.visibleItems.filter(this.isActive);
    },
    isDisabled() {
      return this.loading.form
        || !reviewsCount.includes(this.selectedItems.length);
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    isActive({ status }) {
      return status === 'ACTIVE';
    },
    toggleActive({ id }) {
      const found = this.items.find((item) => item.id === id);

      if (found) {
        found.status = this.isActive(found) ? 'NOT_ACTIVE' : 'ACTIVE';
      }
    },
    async loadData() {
      this.loading.data = true;

      try {
        const { data } = await api.locations.getReviews({
          locationId: this.location.id,
        });

        if (data) {
          this.items = data;
          this.$emit('loaded', data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.data = false;
      }
    },
    async save() {
      if (this.loading.form) {
        return;
      }

      this.loading.form = true;

      try {
        await api.locations.updateReviews({
          locationId: this.location.id,
          data: this.items,
        });

        this.loadData();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
  },

  watch: {
    'location.id': {
      handler() {
        this.loadData();
      },
    },
  },
};
</script>
