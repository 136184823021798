<template>
  <ui-card
    v-bind="$attrs"
    class="payouts-next-payout"
  >
    <div class="card-header pt-0 px-0">
      <h4 class="payouts-next-payout__title">
        {{ $t('sharedCard.payoutDateNext.title') }}
      </h4>
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <div class="payouts-next-payout__value text-nowrap">
        {{ formatedDate }}
      </div>
      <div class="card-stats__icon text-nowrap">
        <ui-icon name="stats-calendar" size="64" />
      </div>
    </div>
  </ui-card>
</template>

<script>
import formatDate from '@/filters/date';
import StatsMixin from './StatsMixin';

export default {

  mixins: [
    StatsMixin,
  ],

  props: {
    payoutDate: String,
  },

  computed: {
    formatedDate() {
      return formatDate(this.payoutDate, 'dd LLL');
    },
  },
};
</script>
