<template>
  <div class="checkout-progress">
    <div class="checkout-progress-bar">
      <div
        class="checkout-progress-bar__fill"
        :style="barStyles"
      />
      <div
        v-for="step in innerSteps"
        :key="step.value"
        class="checkout-progress-step"
        :class="{ 'checkout-progress-step_completed': step.completed }"
        :style="step.styles"
      >
        <div class="checkout-progress-step__separator" />
        <div class="checkout-progress-step__inner">
          <div class="checkout-progress-step__circle" />
          <div class="checkout-progress-step__label">
            {{ step.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const PROGRESS_BAR_PAD_PERCENT = 5;

export default {
  name: 'CheckoutProgressBar',

  props: {
    value: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    firstStep() {
      return this.steps?.[0];
    },
    lastStep() {
      return [...this.steps].reverse()?.[0];
    },
    firstValue() {
      return this.firstStep?.value || 0;
    },
    lastValue() {
      return this.lastStep?.value || 100;
    },
    barStyles() {
      return {
        width: this.value ? this.getValueOffset(this.value) : '0%',
      };
    },
    innerSteps() {
      return this.steps.map(({ value, label }) => ({
        value,
        label,
        completed: this.value >= value,
        styles: {
          left: this.getValueOffset(value),
        },
      }));
    },
  },

  methods: {
    getValueOffset(value) {
      if (value <= this.firstValue) {
        return `calc(${PROGRESS_BAR_PAD_PERCENT}% * ${value / this.firstValue})`;
      }

      if (value >= this.lastValue) {
        return `calc(${100 - PROGRESS_BAR_PAD_PERCENT}% + ${PROGRESS_BAR_PAD_PERCENT}% * ${Math.min(1, (value - this.lastValue) / this.firstValue)})`;
      }

      return `calc(${PROGRESS_BAR_PAD_PERCENT}% + ${100 - 2 * PROGRESS_BAR_PAD_PERCENT}% * ${(value - this.firstValue) / (this.lastValue - this.firstValue)})`;
    },
  },
};
</script>
