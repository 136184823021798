<template>
  <ui-modal
    :show="value"
    class="payout-details-modal"
    no-footer
    @closed="$emit('closed')"
  >
    <template #header>
        <span class="payout-details-modal__title">
          {{ $t('payoutDetailsModal.title') }}
        </span>
    </template>
    <ui-loading v-if="loading.info" />
    <div
      v-else
      class="payout-details-modal__content"
    >
      <payout-detail-row
        name="Payout ID"
        :value="payoutDetails.payoutId || '-'"
        small
        value-truncated
        value-truncated-copy
      />
      <payout-detail-row
        name="Payout date"
        :value="payoutDetails.payoutDate || '-'"
        small
      />
      <payout-detail-row
        v-if="payoutDetails.distributionPeriod"
        name="Distribution period"
        :value="formatPeriod(payoutDetails.distributionPeriod.startDate, payoutDetails.distributionPeriod.endDate)"
        small
      />
      <payout-detail-row
        v-if="payoutDetails.payoutPeriod"
        name="Distribution period"
        :value="formatPeriod(payoutDetails.payoutPeriod.startDate, payoutDetails.payoutPeriod.endDate)"
        small
      />
      <payout-detail-row
        v-if="venueCharge"
        name="Venue charge"
        :value="venueCharge"
        bold
        small
      />
      <payout-detail-row
        v-if="total"
        name="Total"
        :value="total"
        bold
        small
      />
      <template v-if="staffDetailsList && staffDetailsList.length">
        <div class="payout-details-modal__divider" />
        <div class="payout-details-modal__list">
          <payout-detail-row
            v-for="staffInfo in staffDetailsList"
            :key="staffInfo.user.id"
            :name="`${staffInfo.user.firstName} ${staffInfo.user.lastName}`"
            :value="money(staffInfo.amount.amount, staffInfo.amount.currency, { fractionDigits: 2 })"
          />
        </div>
        <div class="payout-details-modal__actions">
          <ui-button
            outline
            type="primary"
            class="payout-details-modal__button"
            @click="$emit('closed')"
          >
            {{ $t('payoutDetailsModal.actions.close') }}
          </ui-button>
          <ui-button
            :disabled="loading.excel"
            :is-loading="loading.excel"
            type="primary"
            class="payout-details-modal__button"
            @click="loadDetailsExcel"
          >
            {{ $t('payoutDetailsModal.actions.download') }}
          </ui-button>
        </div>
      </template>
    </div>
  </ui-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import formatPeriod from '@/utils/formatPeriod';
import api from '@/api';
import money from '@/filters/money';
import download from '@/utils/download';
import PayoutDetailRow from '@/components/Payouts/PayoutDetailRow.vue';

export default {
  name: 'PayoutDetailsModal',

  components: {
    PayoutDetailRow,
  },

  model: {
    prop: 'value',
    event: 'closed',
  },

  props: {
    payoutInfo: {
      type: Object,
      required: true,
    },
    profileId: {
      type: String,
      required: true,
    },
    value: Boolean,
  },

  data() {
    return {
      loading: {
        info: false,
        excel: false,
      },
      payoutDetails: {},
    };
  },

  computed: {
    ...mapGetters({
      isOwner: 'auth/isOwner',
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
    }),
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    location() {
      return this.$store.state.user.location;
    },
    total() {
      if (this.payoutDetails.total) {
        return money(this.payoutDetails.total.amount, this.payoutDetails.total.currency, { fractionDigits: 2 });
      }
      return '-';
    },
    venueCharge() {
      if (this.payoutDetails.venueCharge) {
        return money(this.payoutDetails.venueCharge.amount, this.payoutDetails.venueCharge.currency, { fractionDigits: 2 });
      }
      return '';
    },
    staffDetailsList() {
      return this.payoutDetails.detailsList;
    },
  },

  methods: {
    money,
    formatPeriod,
    async loadDetails() {
      this.loading.info = true;
      this.payoutDetails = {};

      try {
        const { data } = await api.payouts.getPayoutDetails({
          isOwnerUAECommon: this.isOwnerUAECommon,
          locationId: this.location.id,
          userId: this.profileId,
          payoutId: this.payoutInfo.id,
        });
        this.payoutDetails = data;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.info = false;
      }
    },
    async loadDetailsExcel() {
      this.loading.excel = true;

      try {
        const { data, filename } = await api.payouts.getPayoutDetailsExcel({
          isOwnerUAECommon: this.isOwnerUAECommon,
          locationId: this.location.id,
          userId: this.profileId,
          payoutId: this.payoutInfo.id,
        });
        if (data) {
          download(data, filename);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.excel = false;
      }
    },
  },

  watch: {
    payoutInfo() {
      this.loadDetails();
    },
  },
};
</script>
