<template>
  <div class="reporting">
    <div class="row">
      <div class="col-auto">
        <ui-calendar-period v-model="period" />
      </div>
    </div>

    <div class="reporting__cards">
      <stats-total-amount
        :key="`total-${profile.staffId}-${locationCurrency}-${from}-${to}`"
        :title="$t('reporting.tipsTitle.individual')"
        :location-id="location.id"
        :staff-id="profile.staffId"
        :date-from="from"
        :date-to="to"
        @toggle-promo-date="togglePromoDate"
      />
      <stats-bonus-tips
        v-if="isActivePromo"
        :key="`bonus-${location.id}-${profile.staffId}-${locationCurrency}-${from}-${to}`"
        :location-id="location.id"
        :location="location"
        :staff-id="profile.staffId"
        :currency="locationCurrency"
        :date-from="from"
        :date-to="to"
      />
      <stats-total-rating
        v-if="isActiveIndividual"
        :key="`rating-${location.id}-${profile.staffId}-${from}-${to}`"
        :title="$t('reporting.ratingTitle.individual')"
        :location-id="location.id"
        :staff-id="profile.staffId"
        :date-from="from"
        :date-to="to"
      />
    </div>

    <transaction-graph
      :key="`graph-${location.id}-${from}-${to}`"
      :location="location"
      :date-from="from"
      :date-to="to"
      class="mt-3 mt-md-4"
    />

    <transaction-list
      id="transaction-list"
      :key="`table-${location.id}-${from}-${to}`"
      :location="location"
      :show-id="showTransactionsIdNameCols"
      :show-name="showTransactionsIdNameCols"
      :date-from="from"
      :date-to="to"
      show-amount
      show-promo
      class="mt-3 mt-md-4"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import StatsTotalAmount from '@/components/Stats/StatsTotalAmount.vue';
import StatsBonusTips from '@/components/Stats/StatsBonusTips.vue';
import StatsTotalRating from '@/components/Stats/StatsTotalRating.vue';
import TransactionGraph from '@/components/Payins/TransactionGraph.vue';
import TransactionList from '@/components/Payins/TransactionList.vue';

export default {

  components: {
    StatsTotalAmount,
    StatsBonusTips,
    StatsTotalRating,
    TransactionGraph,
    TransactionList,
  },

  data: () => ({
    period: [],
  }),

  computed: {
    ...mapState('user', [
      'image',
      'location',
      'profile',
    ]),
    ...mapGetters('user', [
      'isCommon',
      'isUAE',
    ]),
    locationCurrency() {
      return this.location.currencyCode;
    },
    isActivePromo() {
      return this.location.activePromo;
    },
    isActiveIndividual() {
      return (this.isCommon && !this.isUAE) || !this.isCommon;
    },
    showTransactionsIdNameCols() {
      return this.isCommon && !this.isUAE;
    },
    from() {
      return this.period?.[0]
        || DateTime.local().minus({ days: 30 }).toJSDate();
    },
    to() {
      return this.period?.[1]
        || DateTime.local().toJSDate();
    },
  },

  methods: {
    togglePromoDate(value) {
      this.period = [
        DateTime.fromISO(value[0]).toJSDate(),
        DateTime.fromISO(value[1]).toJSDate(),
      ];

      this.$scroll('#transaction-list');
    },
    selectedPeriod({ dateFrom, dateTo }) {
      this.from = dateFrom;
      this.to = dateTo;
    },
  },
};
</script>
