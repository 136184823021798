<template>
  <component
    v-if="distributionsComponent"
    :is="distributionsComponent"
    :key="`${accessToken}-${payoutId}`"
  />
  <ui-loading v-else inline />
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AccountantDistributions from '@/components/Accountant/AccountantDistributions.vue';
import ManagerDistributions from '@/components/Manager/ManagerDistributions.vue';

export default {

  components: {
    AccountantDistributions,
    ManagerDistributions,
  },

  computed: {
    ...mapState({
      accessToken: (state) => state.auth.accessToken,
      payoutId: (state) => state.user.location.payoutId,
      selectedSublocation: (state) => state.sublocations.selectedSublocation,
    }),
    ...mapGetters({
      isAccountant: 'auth/isAccountant',
      isDistributionMaster: 'auth/isDistributionMaster',
      defaultLocationRequired: 'user/defaultLocationRequired',
      isMasterLocation: 'user/isMasterLocation',
    }),
    distributionsComponent() {
      if (this.defaultLocationRequired) {
        return false;
      }

      if (this.isAccountant && this.isDistributionMaster) {
        if (this.$route.name === 'distributions-account') {
          return 'accountant-distributions';
        }

        if (this.$route.name === 'distributions-manager') {
          return 'manager-distributions';
        }
      }

      if (this.isAccountant) {
        return 'accountant-distributions';
      }

      if (this.isDistributionMaster || this.isMasterLocation) {
        return 'manager-distributions';
      }

      return false;
    },
  },
};
</script>
