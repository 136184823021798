<template>
  <ui-modal v-bind="$attrs">
    <div class="staff-payout-error">
      <div class="staff-payout-error__title">
        {{ $t('Error') }}
      </div>
      <div class="staff-payout-error__description">
        {{ $t('This venue does not have a payout account. Please contact the venue’s owner to setup a payout account.') }}
      </div>
      <ui-icon
        name="warning"
        size="100"
        class="text-primary"
      />
    </div>
  </ui-modal>
</template>

<script>
export default {
  name: 'StaffPayoutsError',
};
</script>
