<template>
  <ui-card
    class="notifications-messages"
    no-padding
  >
    <template #header>
      <div class="notifications-messages__header">
        <h4 class="notifications-messages__header-title card-title">
          {{ $t('notifications.listTitle') }}
        </h4>

        <ui-button
          v-if="newMessagesCount"
          v-protected
          class="notifications-messages__header-mark"
          link
          @click="markAllAsRead"
        >
          {{ $t('notifications.markAll') }}
        </ui-button>
      </div>
    </template>

    <div class="notifications-messages__filters">
      <ui-toggle
        class="notifications-messages__filters-toggler"
        v-model="filter.includeRead"
        :elements="messagesStateList"
        :disabled="someLoading"
        @select="updateFilter({ page: 1, source: 'toggle' })"
      />

      <ui-date-picker
        class="notifications-messages__filters-period"
        v-model="filter.datePicker"
        type="daterange"
        format="dd.MM.yy"
        range-separator="—"
        placeholder="Pick a date range"
        start-placeholder="Start date"
        end-placeholder="End date"
        :max="new Date()"
        :disabled="someLoading"
        @change="updateFilter({ page: 1, source: 'date-picker' })"
      />
    </div>

    <div
      v-if="someLoading"
      class="notifications-messages__loader"
    >
      <ui-loading inline />
    </div>
    <template v-else>
      <ul
        v-if="messages.length"
        class="notifications-messages__list"
        :class="{
          'scrollable': messages.length > 3,
        }"
        ref="cardBody"
      >
        <li
          v-for="message in messages"
          v-protected
          :key="message.id"
          class="notifications-messages__item"
          :class="{
            'disabled': message.isRead,
          }"
          @click="markMessageAsRead(message)"
        >
          <span class="notifications-messages__title">
            {{ message.title }}
          </span>
          <p class="notifications-messages__description">
            {{ message.description }}
          </p>
          <div class="notifications-messages__timestamp">
            <span class="notifications-messages__timestamp-date">
              {{ message.date }}
            </span>
            <span class="notifications-messages__timestamp-time">
              {{ message.time }}
            </span>
          </div>
        </li>
        <div
          v-if="loading.data"
          class="d-flex justify-content-center my-5"
        >
          <ui-loading inline />
        </div>
      </ul>
      <div
        v-else
        class="notifications-messages__empty mx-auto"
      >
        {{ $t('notifications.empty.description') }}
      </div>
    </template>
  </ui-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'NotificationMessages',

  data() {
    return {
      beforeScrollDetectionWatcher: null,
      filter: {
        includeRead: null,
        datePicker: [],
      },
      loading: {
        filter: false,
        data: false,
        markOne: false,
        markAll: false,
      },
      page: 1,
      pageSize: 5,
    };
  },

  computed: {
    ...mapState({
      cardLoading: (state) => state.notifications.loading,
      total: (state) => state.notifications.total,
      messages: (state) => state.notifications.messages,
      dateFrom: (state) => state.notifications.dateFrom,
      dateTo: (state) => state.notifications.dateTo,
      includeRead: (state) => state.notifications.includeRead,
    }),
    ...mapGetters({
      newMessagesCount: 'notifications/newMessagesCount',
    }),
    messagesStateList() {
      return [
        {
          label: this.$t('notifications.all'),
          value: true,
          disabled: false,
        },
        {
          label: this.$t('notifications.unread'),
          value: false,
          disabled: false,
        },
      ];
    },
    apiConfig() {
      return {
        start: this.start,
        pageSize: this.pageSize,
        includeRead: this.filter.includeRead,
        dateFrom: DateTime.fromJSDate(this.filter.datePicker[0]).toISODate(),
        dateTo: DateTime.fromJSDate(this.filter.datePicker[1]).toISODate(),
      };
    },
    start() {
      return (this.page - 1) * this.pageSize;
    },
    someLoading() {
      return this.loading.filter
        || this.loading.markOne
        || this.loading.markAll;
    },
    beforeScrollDetectionInit() {
      return `${this.messages}|${this.cardLoading}|${this.someLoading}`;
    },
  },

  mounted() {
    this.filter.datePicker = [
      DateTime.fromISO(this.dateFrom).toJSDate(),
      DateTime.fromISO(this.dateTo).toJSDate(),
    ];

    this.beforeScrollDetectionWatcher = this.$watch('beforeScrollDetectionInit', (value) => {
      if (value) {
        this.initScrollDetection();
      }
    }, { immediate: false });
  },

  beforeDestroy() {
    this.stopScrollDetection();
    this.beforeScrollDetectionWatcher();
  },

  watch: {
    includeRead: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.filter.includeRead = this.includeRead;
        }
      },
    },
  },

  methods: {
    ...mapActions({
      loadMessages: 'notifications/loadMessages',
      markAsRead: 'notifications/markMessageAsRead',
      markAllAsRead: 'notifications/markMessagesAsRead',
    }),
    initScrollDetection() {
      if (!this.$refs.cardBody) {
        return;
      }

      this.$refs.cardBody.addEventListener('scroll', this.onScrollDetection);
    },
    onScrollDetection(e) {
      const element = e.target;

      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.loadDataOnScrollHitEnd();
      }
    },
    loadDataOnScrollHitEnd() {
      const totalPages = this.total / this.pageSize;
      const isEnoughItems = this.total >= this.pageSize;
      const isOutOfItems = this.total === this.messages.length;

      if (this.page >= totalPages || !isEnoughItems || isOutOfItems) {
        return;
      }

      this.page += 1;
      this.loadData({
        append: true,
        loader: 'data',
      });
    },
    stopScrollDetection() {
      if (!this.$refs.cardBody) {
        return;
      }

      this.$refs.cardBody.removeEventListener('scroll', this.onScrollDetection);
    },
    updateFilter(params) {
      if (params?.source === 'toggle'
        && this.filter.includeRead === this.includeRead) {
        return;
      }

      if (params?.page) {
        this.page = params.page;
      }

      this.loadData({
        loader: 'filter',
      });
    },
    async markMessageAsRead(message) {
      if (this.loading.markOne || message.isRead) {
        return;
      }

      this.loading.markOne = true;

      try {
        await this.markAsRead({
          messageId: message.id,
        });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.markOne = false;
      }
    },
    async markMessagesAsRead() {
      if (this.loading.markAll) {
        return;
      }

      this.loading.markAll = true;

      try {
        await this.markAllAsRead();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.markAll = false;
      }
    },
    async loadData(payload) {
      if (this.loading[payload?.loader]) {
        return;
      }

      this.loading[payload?.loader] = true;

      try {
        await this.loadMessages({
          ...this.apiConfig,
          append: payload?.append || false,
        });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading[payload?.loader] = false;
      }
    },
  },
};
</script>
