<template>
  <ui-card tag="form">
    <ui-loading v-if="loading.search" />
    <template #header>
      <div class="d-flex flex-nowrap justify-content-between">
        <h4 class="card-title">
          {{ title || defaultTitle }}
        </h4>
        <div>
          <ui-icon-button
            size="22px"
            name="pdf"
            class="mr-2"
            @click="exportPdf"
          />
          <ui-icon-button
            size="22px"
            name="excel-sm"
            class="mr-2"
            @click="exportXls"
          />
        </div>
      </div>
    </template>

    <ui-data-table
      :columns="columns"
      :data="items"
      data-table="transaction"
      :class="{ 'transactions_loading': loading.search }"
      @sort="setSort"
    >
      <template #before>
        <div class="row mb-4">
          <div v-if="false && showName" class="col-12 col-md-auto mt-1">
            <ui-input
              v-model="staffName"
              placeholder="Staff name"
              class="my-0"
              bordered
              search
            />
          </div>
          <div v-if="false && showLocation" class="col-12 col-md-auto mt-1">
            <ui-input
              v-model="locationName"
              placeholder="Location name"
              class="my-0"
              bordered
              search
            />
          </div>
          <div class="d-xl-none col-12 col-md-auto ml-auto mt-1">
            <ui-select
              :value="sortBy"
              :items="sortList"
              class="w-auto my-0"
              @input="setSort({ sortBy: $event, sortDirection: 'ASC' })"
            ></ui-select>
          </div>

          <ui-button
            round
            type="primary"
            size="sm"
            class="ml-2 mt-0 d-none"
            :disabled="someLoading"
            :is-loading="loading.search"
            @click.prevent="search"
          >
            {{ $t('form.buttons.search') }}
          </ui-button>
        </div>
      </template>

      <template #transaction-date="{ row }">
        {{ row.time | date }}<br />
        {{ row.time | time }}
      </template>

      <template #transaction-payout-id-value="{ value }">
        <span class="text-primary">
          {{ value }}
        </span>
      </template>

      <template #transaction-name="{ row, value }">
        {{ value }}
        <div v-if="showLocation && row.location" class="text-nowrap">
          ({{ row.location.name }})
        </div>
      </template>

      <template #transaction-amount-value="{ row }">
        <div class="d-flex align-items-center flex-nowrap">
          <template v-if="row.showValueAmount === 'visible'">
            <span>{{ row.amount | money(row.currency) }}</span>
            <ui-tooltip
              v-if="showAmountTooltip(row)"
              :content="row.status"
              class="d-flex align-items-center"
            >
              <ui-icon
                v-if="row.status === 'PENDING'"
                name="amount-pending"
                size="14px"
                class="ml-1"
              />
              <ui-icon
                v-if="row.status === 'SUCCEED'"
                name="amount-available"
                size="14px"
                class="ml-1"
              />
            </ui-tooltip>
            <ui-tag
              v-if="isActivePromo && row.bonusAmount"
              class="d-flex ml-3"
            >
              +{{ row.bonusAmount.amount | money(row.bonusAmount.currency) }}
              <ui-icon
                name="bonus-sm"
                size="20px"
              />
            </ui-tag>
          </template>
          <template v-else-if="row.showValueAmount === 'notValue'">
            <ui-icon
              name="dash"
              size="14px"
              class="ml-1"
            />
          </template>
          <template v-else-if="row.showValueAmount === 'hidden'">
            <ui-icon
              name="check"
              size="14px"
              class="ml-1"
            />
          </template>
        </div>
        <div v-if="row.availableOn" class="text-nowrap">
          ({{ row.availableOn | date }})
        </div>
      </template>

      <template #transaction-stars-value="{ value }">
        <ui-stars
          :value="value"
          size="14px"
        />
      </template>

      <template #transaction-rating="{ row: { stars, cards, message, phoneNumber, email } }">
        <div>
          <div v-if="stars" class="d-none d-md-block">
            <ui-stars
              :value="stars"
              size="14px"
            />
          </div>
          <div v-if="message" class="mt-1">
            {{ message }}
          </div>
          <div v-if="phoneNumber || email" class="d-flex flex-nowrap align-items-center mt-1">
            <ui-icon
              name="phone"
              size="14px"
              class="mr-1"
            />
            {{ phoneNumber || email }}
          </div>
          <div v-if="cards && cards.length" class="d-flex flex-wrap my-1 mx-n1 mx-1">
            <div v-for="value in cards" :key="value" class="m-1 badge bg-secondary text-white">
              {{ $t(value) }}
            </div>
          </div>
        </div>
      </template>
    </ui-data-table>
    <div class="text-center mt-4">
      <ui-pagination
        v-if="total > pageSize"
        v-model="page"
        :total="total"
        :per-page="pageSize"
        :show-pages="7"
        class="mt-4"
        @input="search"
      />
    </div>
  </ui-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import download from '@/utils/download';
import getFio from '@/utils/fio';
import api from '@/api';

export default {

  props: {
    title: {
      type: String,
    },
    showId: Boolean,
    showName: Boolean,
    showLocation: Boolean,
    showAmount: Boolean,
    showPromo: Boolean,
    location: Object,
    groupId: [Number, String],
    dateFrom: Date,
    dateTo: Date,
  },

  data: () => ({
    loading: {
      search: false,
      next: false,
    },
    datePicker: null,
    staffName: '',
    locationName: '',
    sortDirection: 'DESC',
    sortBy: 'TIME',
    items: [],
    page: 1,
    total: 0,
    pageSize: 10,
  }),

  watch: {
    period: {
      immediate: true,
      handler() {
        this.search();
      },
    },
  },

  computed: {
    ...mapGetters({
      isStaff: 'auth/isStaff',
      isOwner: 'auth/isOwner',
      isSelfReportingTypeProfile: 'user/isSelfReportingTypeProfile',
    }),
    isActivePromo() {
      return this.location.activePromo && this.showPromo;
    },
    sortList() {
      return [
        {
          value: 'TIME',
          name: this.$t('transactionList.sortList.TIME'),
        },
        {
          value: 'AMOUNT',
          name: this.$t('transactionList.sortList.AMOUNT'),
        },
        {
          value: 'STARS',
          name: this.$t('transactionList.sortList.STARS'),
        },
      ];
    },
    defaultTitle() {
      return this.$t('transactionList.title');
    },
    someLoading() {
      return Object.values(this.loading).some((v) => v);
    },
    more() {
      return this.items.length < this.total;
    },
    columns() {
      return [
        {
          name: 'transaction-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          sorting: true,
          empty: this.$t('table.empty.easytipId'),
          visible: this.showId,
        },
        {
          name: 'transaction-payout-id',
          label: 'Payout ID',
          prop: 'checkoutPayoutId',
          empty: 'No payoutId',
          visible: this.showPayoutIdColumn,
        },
        {
          name: 'transaction-date',
          label: this.$t('table.columns.date'),
          prop: 'time',
          sorting: true,
          empty: this.$t('table.empty.date'),
        },
        {
          name: 'transaction-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          value: ({ row }) => getFio(row),
          sorting: true,
          empty: this.$t('table.empty.name'),
          visible: this.showName,
        },
        {
          name: 'transaction-amount',
          label: this.$t('table.columns.amount'),
          prop: 'showValueAmount',
          sorting: true,
          empty: this.$t('table.empty.amount'),
          visible: this.showAmount,
        },
        {
          name: 'transaction-stars',
          label: this.$t('table.columns.rating'),
          prop: 'stars',
          empty: this.$t('table.empty.rating'),
        },
        {
          name: 'transaction-rating',
          label: this.$t('table.columns.rating'),
          prop: 'stars',
          sorting: true,
          visible: this.location.typeOfReview !== 'DISABLE_REVIEWS',
        },
      ];
    },
    period() {
      return `${this.dateFrom}-${this.dateTo}`;
    },
    showPayoutIdColumn() {
      return this.location.tipsType === 'PERSONAL'
        && (this.isStaff || this.isOwner)
        && this.isSelfReportingTypeProfile;
    },
  },

  methods: {
    getFio,
    showAmountTooltip(row) {
      return this.location.tipsType !== 'COMMON'
        && this.location.tipsType !== 'COMMON_PRIORITY'
        && row.status;
    },
    setSort({ sortBy, sortDirection }) {
      if (sortBy === 'payoutId') {
        sortBy = 'PAYOUT_ID';
      }

      if (sortBy === 'name') {
        sortBy = 'WAITER_NAME';
      }

      if (sortBy === 'showValueAmount') {
        sortBy = 'AMOUNT';
      }

      this.sortBy = sortBy.toUpperCase();
      this.sortDirection = sortDirection;
      this.search();
    },
    createConfig(page = 1) {
      let startDate = this.dateFrom;
      let endDate = this.dateTo;

      if (!startDate || !endDate) {
        return false;
      }

      startDate = DateTime.fromJSDate(startDate);
      endDate = DateTime.fromJSDate(endDate);

      startDate = startDate.startOf('day').toJSDate();
      endDate = endDate.endOf('day').toJSDate();

      const {
        pageSize,
        staffName,
        locationName,
        sortDirection,
        sortBy,
        location,
      } = this;

      return {
        start: (page - 1) * this.pageSize,
        pageSize,
        locationId: location.id,
        groupId: this.groupId || undefined,
        startDate,
        endDate,
        staffName: staffName || undefined,
        locationName: locationName || undefined,
        orderField: sortBy,
        direction: sortDirection,
      };
    },
    setItems(items) {
      this.items = items;
    },
    async loadItems(page = 1) {
      const {
        data: {
          data,
          totalRecords,
        },
      } = await api.transaction.getStripeListIncoming(
        this.createConfig(page),
      );

      if (data) {
        this.total = totalRecords;
        this.setItems(data);
      }
    },
    async search(page = 1) {
      if (this.loading.search) {
        return;
      }

      this.loading.search = true;

      try {
        await this.loadItems(page);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.search = false;
      }
    },
    async exportXls() {
      try {
        const { data } = await api.transaction.getTransactionsExcel(
          this.createConfig(this.page),
        );

        if (data) {
          download(data, 'transactions.xlsx');
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async exportPdf() {
      try {
        const { data } = await api.transaction.getTransactionsPDF(
          this.createConfig(this.page),
        );

        if (data) {
          download(data, 'transactions.pdf');
        }
      } catch (e) {
        this.$showServerError(e);
      }
    },
  },
};
</script>
