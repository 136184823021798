<template>
  <ui-modal
    v-bind="$attrs"
    v-on="$listeners"
    ref="upload-modal"
    :is-loading="loading"
    class="staff-hours-upload"
  >
    <template #title>
      <div class="staff-hours-upload__title">
        Upload Excel with {{ hoursOrUnits }}
      </div>
    </template>

    <p class="staff-hours-upload__description">
      Please prepare and upload formatted Excel file: 2 columns with Full names in the first column and {{ hoursOrUnits }} in the second one.<br>(Full Names should be same as the ones in EasyTip staff list)
    </p>
    <a
      href="/example.xlsx?v=2.18.0"
      class="staff-hours-upload__link"
    >
      Download Excel example
    </a>

    <ui-upload
      @selected-file="selected"
    />
  </ui-modal>
</template>

<script>
import api from '@/api';

export default {
  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    range: Array,
    location: Object,
    hoursOrUnits: String,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    selected(file) {
      this.upload(file);
    },
    async upload(file) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      try {
        const { data } = await api.locations.uploadFile({
          file,
          locationId: this.location.id,
          dateFrom: this.range[0],
          dateTo: this.range[1],
        });

        this.$emit('uploaded', data);
        this.$refs['upload-modal'].close();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
