<template>
  <ui-card :is-loading="loading">
    <template #header>
      <div class="d-flex flex-nowrap justify-content-between">
        <h4 class="card-title">
          {{ $t('transactionGraph.title') }}
        </h4>
        <div class="flex-shrink-0">
          <ui-icon-button
            name="chart-line"
            size="22px"
            :class="chartType === 'line' ? 'text-primary' : 'text-muted'"
            @click="chartType = 'line'"
          />
          <ui-icon-button
            name="chart-bar"
            size="22px"
            class="ml-2"
            :class="chartType === 'bar' ? 'text-primary' : 'text-muted'"
            @click="chartType = 'bar'"
          />
        </div>
      </div>
    </template>

    <ui-line-chart
      :key="loading"
      :labels="chartData.labels"
      :height="300"
      :color="chart.color"
      :extra-options="chart.options"
      :datasets="chartData.datasets"
      :chart-type="chartType"
      style="height: 300px"
    />
  </ui-card>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import Chart from 'chart.js';
import { DateTime } from 'luxon';
import api from '@/api';
import money from '@/filters/money';

// @see {@link https://stackoverflow.com/questions/45159895/moving-vertical-line-when-hovering-over-the-chart-using-chart-js}
const oldLine = Chart.controllers.line;
Chart.controllers.line = Chart.controllers.line.extend({
  draw(ease) {
    oldLine.prototype.draw.call(this, ease);

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      const activePoint = this.chart.tooltip._active[0];
      const { ctx } = this.chart;
      const { x } = activePoint.tooltipPosition();
      const topY = this.chart.legend.bottom;
      const bottomY = this.chart.chartArea.bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#CCC';
      ctx.stroke();
      ctx.restore();
    }
  },
});

export default {

  props: {
    location: Object,
    groupId: [Number, String],
    dateFrom: Date,
    dateTo: Date,
  },

  data: () => ({
    chartType: 'line',
    loading: false,
    items: [],
    from: null,
    to: null,
  }),

  computed: {
    chartData() {
      return {
        labels: this.items.map(({ date }) => date),
        datasets: [{
          label: this.$t('table.columns.amountTips'),
          borderColor: '#A21514',
          hoverBackgroundColor: '#A21514',
          pointBackgroundColor: '#A21514',
          pointRadius: 0,
          pointHitRadius: 20,
          pointHoverRadius: 7,
          lineTension: 0,
          fill: this.chartType === 'bar',
          backgroundColor: this.chartType === 'bar' ? '#CCCCCC' : '#A21514',
          borderWidth: this.chartType === 'bar' ? 0 : 2,
          data: this.items.map(({ amount }) => amount),
        }],
      };
    },
    chart() {
      return {
        color: '#f17e5d',
        options: {
          tooltips: {
            intersect: false,
            backgroundColor: 'white',
            borderColor: '#CCCCCC',
            titleFontColor: '#808080',
            bodyFontColor: ' #000000',
            cornerRadius: 4,
            borderWidth: 0.5,
            displayColors: false,
            xPadding: 8,
            yPadding: 8,
            bodyFontSize: 12,
            bodyFontStyle: 500,
            titleFontSize: 12,
            titleFontStyle: 'normal',
            caretSize: 0,
            caretPadding: 20,
            titleMarginBottom: 8,
            titleFontFamily: 'Roboto, sans-serif',
            bodyFontFamily: 'Roboto, sans-serif',
            callbacks: {
              label: (tooltipItem, data) => {
                return money(tooltipItem.value, this.location.currencyCode);
              },
            },
            tooltipFillColor: 'rgba(0,0,0,0.5)',
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: 'normal',
            tooltipFontColor: '#fff',
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: 'bold',
            tooltipTitleFontColor: '#fff',
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },
          scales: {
            yAxes: [{
              ticks: {
                fontColor: '#000',
                beginAtZero: true,
                padding: 20,
                maxTicksLimit: 5,
                callback: (value) => {
                  return money(value, this.location.currencyCode);
                },
              },
              gridLines: {
                drawBorder: false,
                zeroLineColor: 'transparent',
                color: '#ECECEC',
              },
            }],
            xAxes: [{
              barPercentage: 0.9,
              ticks: {
                beginAtZero: false,
                maxTicksLimit: 25,
                padding: 12,
                fontColor: '#000',
              },
              gridLines: {
                drawBorder: true,
                drawOnChartArea: false,
                borderDash: [8, 5],
                color: '#ECECEC',
                zeroLineColor: 'transparent',
              },
            }],
          },
        },
      };
    },
    dateGroup() {
      if (!this.from || !this.to) {
        return 'DAY';
      }

      const diff = this.to.diff(this.from);

      if (diff.as('days') < 31) {
        return 'DAY';
      }

      if (diff.as('months') < 5) {
        return 'WEEK';
      }

      if (diff.as('months') < 13) {
        return 'MONTH';
      }

      return 'YEAR';
    },
    period() {
      return this.dateFrom && this.dateTo;
    },
  },

  watch: {
    period: {
      immediate: true,
      handler(value) {
        if (value) {
          this.from = DateTime.fromJSDate(this.dateFrom);
          this.to = DateTime.fromJSDate(this.dateTo);

          this.loadData();
        }
      },
    },
  },

  methods: {
    dateGroupFormat(date) {
      switch (this.dateGroup) {
        case 'YEAR':
          return date.toFormat('yyyy');
        case 'MONTH':
          return date.toFormat('LLL yy');
        case 'WEEK':
          date = date.startOf('week');
        // eslint-disable-next-line no-fallthrough
        default:
          return date.toFormat('dd.LL');
      }
    },
    setItems(data) {
      data = data.reduce((carry, { date, amount }) => {
        date = DateTime.fromSQL(date);
        date = this.dateGroupFormat(date);

        carry.set(date, (carry.get(date) || 0) + amount);

        return carry;
      }, new Map());

      const unit = `${this.dateGroup.toLowerCase()}s`;

      let from = this.from.minus({ [unit]: 1 });
      const to = this.to.plus({ [unit]: 1 });

      const items = [];
      let counter = 0;

      while (from.startOf('day') <= to.startOf('day')) {
        const date = this.dateGroupFormat(from);
        const amount = data.get(date) || 0;

        items.push({
          amount,
          date: this.dateGroup === 'DAY' ? date.slice(0, 5) : date,
        });

        from = from.plus({ [unit]: 1 });

        // eslint-disable-next-line no-plusplus
        if (counter++ >= 1000) {
          break;
        }
      }

      this.items.splice(0);
      this.items.push(
        ...items,
      );
    },
    async loadData() {
      if (!this.from || !this.to) {
        return;
      }

      this.loading = true;

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.transaction.getStripeListIncomingByDay({
          locationId: this.location.id,
          groupId: this.groupId || undefined,
          dateGroup: this.dateGroup,
          dateFrom: this.from.startOf('day').toJSDate(),
          dateTo: this.to.endOf('day').toJSDate(),
        });

        if (data) {
          this.setItems(data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
