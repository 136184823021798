<template>
  <validation-observer v-slot="{ handleSubmit }" tag="form" disabled>
    <ui-modal
      v-bind="$attrs"
      v-on="$listeners"
      @before-open="beforeOpen"
      modal-classes="mt-4"
    >
      <template #title>
        <div class="modal-title text-center">
          {{ $t('staffs.title') }}
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-center w-100 mt-4">
          <ui-button
            action
            type="primary"
            native-type="button"
            class="mx-2"
            @click="handleSubmit(apply)"
          >
            {{ $t('form.buttons.applyGroup') }}
          </ui-button>
        </div>
      </template>

      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-12 mt-lg-0 mt-4 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0">
            <validation-provider
              tag="div"
              :name="$t('form.labels.groupName')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.name"
                :label="$t('form.labels.groupName')"
                required
                input-classes="w-100"
                :error="errors[0]"
              ></ui-input>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-12 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0">
            <validation-provider
              tag="div"
              :name="$t('form.labels.tipCollectionType')"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.tipsType"
                :items="tipsTypes"
                :label="$t('form.labels.tipCollectionType')"
                :placeholder="$t('form.placeholders.select')"
                label-key="label"
                value-key="value"
                required
                :error="errors[0]"
               />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-lg-0 mt-4 px-xl-3 px-lg-3 px-md-3 px-sm-3 px-0">
            <validation-provider
              tag="div"
              rules="required"
              mode="passive"
              v-slot="{ errors }"
            >
              <location-service-type
                v-model="activeType"
                :type-list="groupTypeList"
                :label="$t('staffs.selectIcon')"
                :error="errors[0]"
                class="is-filled"
                @toggle-icon="toggleIcon"
              />
            </validation-provider>
          </div>
        </div>
      </div>
    </ui-modal>
  </validation-observer>
</template>

<script>
import ResetMixin from '@/mixins/reset-mixin';
import LocationServiceType from '@/components/Location/LocationServiceType.vue';

export default {
  components: {
    LocationServiceType,
  },

  mixins: [
    ResetMixin(() => ({
      form: {
        name: null,
        tipsType: null,
        icon: null,
      },
    })),
  ],

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    group: Object,
    location: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      groupTypeList: [
        {
          name: 'ATTENDANT',
          icon: require('@/assets/images/groups/spa-attendant.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.ATTENDANT'),
        },
        {
          name: 'BAR',
          icon: require('@/assets/images/groups/spa-bar.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.BAR'),
        },
        {
          name: 'BEAUTICIAN',
          icon: require('@/assets/images/groups/spa-beautician.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.BEAUTICIAN'),
        },
        {
          name: 'CAFE',
          icon: require('@/assets/images/groups/spa-cafe.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.CAFE'),
        },
        {
          name: 'FITNESS',
          icon: require('@/assets/images/groups/spa-fitness.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.FITNESS'),
        },
        {
          name: 'NAILS',
          icon: require('@/assets/images/groups/spa-nails.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.NAILS'),
        },
        {
          name: 'RECEPTION',
          icon: require('@/assets/images/groups/spa-reception.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.RECEPTION'),
        },
        {
          name: 'SALON',
          icon: require('@/assets/images/groups/spa-salon.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.SALON'),
        },
        {
          name: 'SAUNA',
          icon: require('@/assets/images/groups/spa-sauna.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.SAUNA'),
        },
        {
          name: 'YOGA',
          icon: require('@/assets/images/groups/spa-yoga.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.YOGA'),
        },
        {
          name: 'WELLNESS',
          icon: require('@/assets/images/groups/spa-wellness.svg'), // eslint-disable-line global-require
          label: this.$t('staffGroups.WELLNESS'),
        },
      ],
    };
  },

  computed: {
    activeType() {
      return this.form.icon?.name;
    },
    tipsTypes() {
      return [
        {
          value: 'PERSONAL',
          label: this.$t('tipsTypes.individual'),
        },
        {
          value: 'COMMON',
          label: this.$t('tipsTypes.commonSimple'),
        },
      ];
    },
  },

  methods: {
    beforeOpen() {
      this.reset();

      if (this.group) {
        this.form = {
          ...this.form,
          ...this.group,
        };
      }
    },
    apply() {
      this.$emit('update:group', this.form);
    },
    toggleIcon(name) {
      this.form.icon = { name };
    },
  },
};
</script>
