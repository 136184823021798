import { DateTime } from 'luxon';

export default {

  props: {
    locationId: [Number, String],
    staffId: [Number, String],
    dateFrom: {
      type: Date,
      default() {
        return DateTime.local(2021, 6, 1).toJSDate();
      },
    },
    dateTo: {
      type: Date,
      default() {
        return DateTime.local().toJSDate();
      },
    },
  },

  data: () => ({
    isLoading: false,
  }),

  mounted() {
    this.forceLoad();
  },

  methods: {
    async forceLoad() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        await this.load();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    load() {
      // Redeclare required.
    },
  },
};
