<template>
  <div>
    <ui-card :is-loading="isLoading">
      <template #header>
        <h4 class="card-title">
          {{ $t('staffPayouts.title') }}
        </h4>
      </template>

      <staff-payouts-table
        :items="data"
        :staffs="staffs"
        :location-country="locationCountry"
        :location-currency="locationCurrency"
        :empty-header="isEmptyHeader"
      >
        <template #after="{ amountTotal }">
          <staff-payouts-confirm
            v-model="showConfirm"
            :items="data"
            :amount="amountTotal"
            :currency="locationCurrency"
            @apply="sendPayouts"
          />
        </template>
      </staff-payouts-table>

      <ui-button
        action
        type="primary"
        class="mt-5"
        :is-loading="sendLoading"
        :disabled="!data.length || sendLoading"
        @click="submitPayouts"
      >
        {{ $t('form.buttons.sendPayouts') }}
      </ui-button>
    </ui-card>

    <ui-modal-alert
      v-model="showError"
      :title="$t('staffPayouts.error.title')"
      :apply-button="$t('form.buttons.proceed')"
      :close-button="$t('form.buttons.cancel')"
      @apply="sendPayouts"
    >
      {{ $t('staffPayouts.error.text[0]') }}
      {{ lessStaffTypes.join(', ') }}
      {{ $t('staffPayouts.error.text[1]') }}
    </ui-modal-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/api';
import StaffPayoutsTable from './StaffPayoutsTable.vue';
import StaffPayoutsConfirm from './StaffPayoutsConfirm.vue';

export default {

  components: {
    StaffPayoutsTable,
    StaffPayoutsConfirm,
  },

  props: {
    isLoading: Boolean,
    staffs: {
      type: Array,
      default: () => [],
    },
    staffHours: {
      type: Array,
      default: () => [],
    },
    staffPayouts: {
      type: Array,
      default: () => [],
    },
    location: Object,
    locationDistributions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      sendLoading: false,
      showConfirm: false,
      showError: false,
    };
  },

  computed: {
    ...mapGetters({
      isUAE: 'user/isUAE',
      isCommon: 'user/isCommon',
      isOwner: 'auth/isOwner',
      isTroncMaster: 'auth/isTroncMaster',
    }),
    locationId() {
      return this.location?.id;
    },
    locationCountry() {
      return this.location?.country;
    },
    locationCurrency() {
      return this.location?.currencyCode;
    },
    isOwnerUAECommon() {
      return this.isOwner && this.isUAE && this.isCommon;
    },
    isTroncUAECommon() {
      return this.isTroncMaster && this.isUAE && this.isCommon;
    },
    isEmptyHeader() {
      return this.isTroncUAECommon || this.isOwnerUAECommon;
    },
    data() {
      return this.staffPayouts.map((item) => ({
        ...item,
        staff: this.getStaff(item),
      }));
    },
    requiredStaffTypes() {
      return this.locationDistributions
        .filter(({ percentage }) => percentage > 0)
        .map(({ staffType }) => staffType);
    },
    lessStaffTypes() {
      return this.requiredStaffTypes
        .filter((staffType) => {
          return !this.data.some((item) => item.staff?.staffType === staffType);
        })
        .map((staffType) => this.$getStaffTypeName(staffType));
    },
  },

  methods: {
    getStaff({ staffId }) {
      return this.staffs.find((item) => item.id === staffId);
    },
    submitPayouts() {
      if (this.lessStaffTypes.length > 0) {
        this.showError = true;
      } else {
        this.showConfirm = true;
      }
    },
    async sendPayouts() {
      if (this.sendLoading) {
        return;
      }

      this.sendLoading = true;

      try {
        const response = await api.locations.createDraftStaffPayouts({
          locationId: this.locationId,
          data: this.staffHours,
        });

        await api.locations.confirmStaffPayouts({
          locationId: this.locationId,
          draftPayoutCalculationId: response.data.id,
        });

        this.$emit('done');
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.sendLoading = false;
      }
    },
  },
};
</script>
