<template>
  <validation-observer v-slot="{ handleSubmit }">
    <ui-card tag="form" :is-loading="loading.data || isLoading">
      <template #header>
        <h4 class="card-title">
          {{ $t('staffMembers.registerTitle') }}
        </h4>
      </template>

      <div class="row">
        <div v-if="showGroups" class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.group')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.workplaceGroup"
              v-protected
              :items="groups"
              :label="$t('form.labels.group')"
              :placeholder="$t('form.placeholders.select')"
              :disabled="disableRegistration"
              label-key="name"
              value-key="id"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.phoneNumber')"
            :rules="{
              required: {
                invalid: phoneInvalid,
              },
            }"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-phone
              v-model="form.phoneNumber"
              v-protected
              :label="$t('form.labels.staffPhone')"
              :disabled="disableRegistration"
              :error="phoneError || errors[0]"
              :country-code="countryCode"
              required
              @validate="phoneInvalid = $event"
            />
          </validation-provider>
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.id')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.id"
              v-protected
              :items="staffSlots"
              :label="$t('form.labels.id')"
              :placeholder="$t('form.placeholders.select')"
              :disabled="disableRegistration"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div v-if="showTaxReportingTypes" class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            name="Tax reporting"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.taxReportingType"
              v-protected
              :items="MAIN_TAX_REPORTING_TYPES"
              label-key="label"
              value-key="value"
              label="Tax reporting"
              :placeholder="$t('form.placeholders.select')"
              :disabled="disableRegistration || disableTaxReportingType(location.taxReportingType)"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.staffType')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-select
              v-model="form.staffType"
              v-protected
              :items="staffTypes"
              :label="$t('form.labels.staffType')"
              :placeholder="$t('form.placeholders.select')"
              :disabled="disableRegistration"
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div v-if="isCommon" class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.staffStartDate')"
            rules="required"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-calendar-picker
              v-model="form.startWorkDate"
              v-protected
              :label="$t('form.labels.staffStartDate')"
              :calendar-info="calendarInfo"
              :disabled="disableRegistration"
              input-icon="calendar"
              no-direction
              required
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div v-if="isUK" class="col-12 col-md-8 col-lg-4">
          <validation-provider
            tag="div"
            :name="$t('form.labels.email')"
            rules="email"
            mode="passive"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="form.email"
              v-protected
              :label="$t('form.labels.email')"
              :error="errors[0]"
            />
          </validation-provider>
        </div>

        <div class="col-12">
          <ui-button
            v-protected
            action
            type="primary"
            :is-loading="loading.form"
            :disabled="loading.form || disableRegistration"
            class="mt-4"
            @click.prevent="handleSubmit(send)"
          >
            {{ $t('form.buttons.sendRegistrationLink') }}
          </ui-button>
          <p
            v-if="disableRegistration"
            class="mt-3 ml-3 text-primary"
          >
            Please fill out the module 'Set distribution'
          </p>
        </div>
      </div>
    </ui-card>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import { DateTime } from 'luxon';
import api from '@/api';
import resetMixin from '@/mixins/reset-mixin';
import { MAIN_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import { NOT_MIX_REPORTING_TYPES } from '@/config/notMixReportingTypes';
import disableTaxReportingType from '@/utils/disableTaxReportingType';

export default {

  mixins: [
    resetMixin(() => ({
      loading: {
        data: false,
        form: false,
      },
      form: {
        id: null,
        email: null,
        phoneNumber: null,
        staffType: null,
        taxReportingType: null,
        workplaceGroup: null,
        startWorkDate: null,
      },
      phoneError: null,
      phoneInvalid: false,
    })),
  ],

  props: {
    location: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
    distributions: Array,
    isCommon: Boolean,
    disableRegistration: Boolean,
  },

  data: () => ({
    staffSlots: [],
    MAIN_TAX_REPORTING_TYPES,
  }),

  computed: {
    isUK() {
      return this.location.country === 'gb';
    },
    calendarInfo() {
      if (this.location.country === 'gb' || this.location.country === 'ch') {
        return this.$t('UI.calendar.gbInfo');
      }

      return this.$t('UI.calendar.commonInfo');
    },
    countryCode() {
      return this.location.country.toUpperCase();
    },
    staffTypes() {
      return this.$store.getters['handbook/staffTypes']
        .filter(({ types }) => types.includes(this.location.type))
        .filter(({ countries }) => countries.includes(this.location.country));
    },
    showGroups() {
      return this.location.paymentPage === 'GROUPS';
    },
    showTaxReportingTypes() {
      return this.location.country !== 'de' && this.location.country !== 'ae';
    },
    nextStaffSlotId() {
      return this.staffSlots.length
        ? this.staffSlots[0].value
        : null;
    },
  },

  watch: {
    'location.taxReportingType': {
      immediate: true,
      handler(taxReportingType) {
        this.setTaxReportingType(taxReportingType);
      },
    },
  },

  async mounted() {
    this.loadData();

    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    disableTaxReportingType,
    setTaxReportingType(taxReportingType) {
      if (NOT_MIX_REPORTING_TYPES.includes(taxReportingType)) {
        this.form.taxReportingType = taxReportingType;
      } else {
        this.form.taxReportingType = null;
      }
    },
    async loadData() {
      this.loading.data = true;
      this.staffSlots.splice(0);

      try {
        const { data } = await api.locations.getStaffSlots({
          locationId: this.location.id,
        });

        if (data) {
          this.staffSlots.push(
            ...data
              .filter(({ slotStatus }) => slotStatus === 'FREE')
              .map(({ id }) => ({
                name: id,
                value: id,
              })),
          );

          this.form.id = this.nextStaffSlotId;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.data = false;
      }
    },
    async send() {
      this.loading.form = true;

      try {
        const params = {
          locationId: this.location.id,
          ...this.form,
          startWorkDate: DateTime.fromJSDate(this.form.startWorkDate).toISODate(),
          phoneNumber: this.form.phoneNumber,
          email: this.form.email,
        };

        if (this.showGroups) {
          params.workplaceGroup = {
            id: this.form.workplaceGroup,
          };
        }

        await api.locations.registerStaff(params);

        this.staffSlots = this.staffSlots.filter(({ value }) => value !== this.form.id);
        this.reset();
        this.setTaxReportingType(this.location.taxReportingType);
        this.form.id = this.nextStaffSlotId;
        this.$emit('completed');
      } catch (e) {
        if (e?.response?.data?.errorCode === 422002) {
          this.phoneError = e?.response?.data?.message;
        } else {
          this.$showServerError(e);
        }
      } finally {
        this.loading.form = false;
      }
    },
  },
};
</script>
